import {
    IS_PAGE_DATA_LOADING,
    IS_PAGE_DATA_SAVING
} from '../actions/types'

const initialState = {
    isPageDataLoading: false, 
    isPageDataSaving: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case IS_PAGE_DATA_LOADING:
            return {
                ...state,
                isPageDataLoading: action.payload
            }
        case IS_PAGE_DATA_SAVING:
            return {
                isPageDataSaving: action.payload
            }
        default:
            return state
    }
}
