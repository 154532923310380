import axios from 'axios'
import { getAppBaseUrl } from './api'
import { SET_PROMOTION, RESET_PROMOTION } from './types'
import { getAppSettings } from './appSettingsActions'
import _ from 'lodash'
import { setSweetAlert } from './sweetAlertActions'

const BASE_URL = getAppBaseUrl()

export const getPromotionSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/promotion-setting/get-promotion-settings')
        .then((res) => {
            callback(res.data)
        }).catch((err) => {
            alert(err)
            callback()
        })
}

export const savePromotionSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/promotion-setting/save-promotion-settings', data)
        .then(() => {
            dispatch(getAppSettings(() => {
                alert('update settings successfully.')
                callback()
            }))
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', 'Error in update settings.')
            alert(errorMessage)
            callback()
        })
}

export const savePromotionOffer = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/promotion-setting/save-promotion', data)
        .then(() => {
            if (data.is_repeat_promotion === true) {
                let text = ''
                if (parseInt(data.date_expire) === 1) {
                    text = '1 hour'
                } else if (parseInt(data.date_expire) < 25) {
                    text = `${data.date_expire} hours`
                } else {
                    const day = parseInt(data.date_expire) / 24
                    text = `${day} days`
                }
                const payload = {
                    description: `The promotion will repeat every ${text}. You'll need to manually cancel the promotion when you want it to end.`
                }
                dispatch(setSweetAlert(payload))
            } else {
                dispatch(setSweetAlert({ description: 'Promotion successfully saved.' }))
            }
            callback()
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', 'Error in save promotion.')
            dispatch(setSweetAlert({ description: errorMessage }))
            callback()
        })
}

export const getPromotionOffer = (data, callback) => () => {
    axios.post(BASE_URL + '/api/promotion-setting/promotion', data)
        .then((res) => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', 'Error in get promotion.')
            alert(errorMessage)
            callback()
        })
}

export const endPromotionOffer = (data, callback) => () => {
    axios.post(BASE_URL + '/api/promotion-setting/end-promotion', data)
        .then((res) => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', 'Error in end promotion.')
            alert(errorMessage)
            callback()
        })
}

export const checkPromotion = (callback) => () => {
    axios.post(BASE_URL + '/api/promotion-setting/check-promotion')
        .then((res) => {
            callback(res.data)
        }).catch((error) => {
            console.log(error)
            callback(false)
        })
}

export const getPromotionReports = (data, callback) => () => {
    axios.post(BASE_URL + '/api/promotion-setting/get-promotion-reports', data)
        .then((res) => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', 'Error in getting promotion reports data.')
            alert(errorMessage)
            callback()
        })
}

export const getAllPromotionOffers = () => dispatch => {
    axios.get(BASE_URL + '/api/offer/check-offer')
        .then((res) => {
            dispatch(setPromotion(res.data.data))
        }).catch((error) => {
            console.log(error)
        })
}

// Set promotion
export const setPromotion = (data) => {
    return {
        type: SET_PROMOTION,
        payload: data
    }
}

export const resetPromotion = () => {
    return {
        type: RESET_PROMOTION
    }
}
