import axios from 'axios'
import { getAppBaseUrl } from './api'
import { setSweetAlert } from './sweetAlertActions'
import _ from 'lodash'
import { hideMigrationPopup } from './authActions'
import { googleAnalyticsTrackEvent } from '../utils/GoogleAnalyticsEvent'
import { SET_USER_SUBSCRIPTION_TO_0 } from './types'

const BASE_URL = getAppBaseUrl()

export const getMigrateSubscriptionSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/migrate-subscription')
        .then((res) => {
            return callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            return callback()
        })
}

export const saveMigrateSubscriptionSettings = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/migrate-subscription', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const saveCondition = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/save-condition', data)
        .then((res) => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            return callback(res.data)
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

// Cancel Subscription
export const cancelSubscription = (callback) => dispatch => {
    axios.post(BASE_URL + '/api/cancel-subscription')
        .then(res => {
            if (res.data.data.cancelled === true) {
                const payload = {
                    description: res.data.message
                }
                dispatch(setSweetAlert(payload))
                dispatch(hideMigrationPopup())
                if (res.data.data.ccbill_subscription_status === '0') {
                    dispatch(updateUserCCbillSubscriptionStatus())
                }
            } else {
                const payload = {
                    description: 'There was a problem cancelling your subscription. Please try again after an hour.'
                }
                dispatch(setSweetAlert(payload))
            }
            callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            callback()
        })
}

export const subscription = (callback) => dispatch => {
    axios.post(BASE_URL + '/api/migrate-subscription-for-user')
        .then(res => {
            if (res.data.data.accepted === true) {
                const { transactionId, amount } = res.data.data
                googleAnalyticsTrackEvent('purchase', transactionId, amount, 'CCBill API', 'subscription', 'subscription-migration')
            } else {
                if (res.data.data.ccbill_subscription_status === '0') {
                    dispatch(updateUserCCbillSubscriptionStatus())
                }
            }
            let payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            dispatch(hideMigrationPopup())
            callback()
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            let payload = {
                description: errorMessage
            }
            if (errorMessage !== 'An unknown error occurred while canceling the subscription. Please try after some time') {
                dispatch(hideMigrationPopup())
            }
            dispatch(setSweetAlert(payload))
            callback()
        })
}

export const loadMigrationData = (callback) => dispatch => {
    axios.get(BASE_URL + '/api/migration-data')
        .then((res) => {
            return callback(res.data)
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const updateUserCCbillSubscriptionStatus = () => {
    return {
        type: SET_USER_SUBSCRIPTION_TO_0
    }
}
