export const getAppBaseUrl = function getAppBaseUrl() {
    if (window.location.hostname === 'localhost') {
        return 'http://localhost:8000'
    }
    let host = window.location.host
    let protocol = window.location.protocol

    if (host.startsWith('www.')) {
        let newHost = host.replace('www.', '')
        return `${protocol}//www.api.${newHost}`
    } else {
        return `${protocol}//api.${host}`
    }

}

export const getWebsiteUrl = function getWebsiteUrl() {
    if (window.location.hostname === 'localhost') {
        const port = window.location.port
        return `http://localhost:${port}`
    }
    let host = window.location.host
    let protocol = window.location.protocol

    if (host.startsWith('www.')) {
        let newHost = host.replace('www.', '')
        return `${protocol}//www.${newHost}`
    } else {
        return `${protocol}//${host}`
    }

}
