import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import {
    Button
} from '@material-ui/core'
import _ from 'lodash'
import { updateUserOptInStatus, updateUserDetail } from '../../actions/optInEmailActions'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { getAppBaseUrl } from './../../actions/api'
import styled from 'styled-components'
const BASE_URL = getAppBaseUrl()

const EmailPromptBtn = styled.div`
    display: flex;
    justify-content: space-around;

    .btn-no-thanks, .btn-enable{
        display: inline-block;
    }
    .btn-no-thanks {
        width: 100px;
    }

    @media(max-width: 479px) {
        display: block;
        .btn-no-thanks, .btn-enable{
            display: block;
            margin: 0 auto;
            width: 260px;
        }
        .btn-enable {
            margin: 0 auto 10px;
        }
    }
`
const useStateWithCallback = (initialState) => {
    const [state, setState] = useState(initialState)
    const callbackRef = useRef(() => undefined)

    const setStateCB = (newState, callback) => {
        callbackRef.current = callback
        setState(newState)
    }

    useEffect(() => {
        if (callbackRef.current) {
            callbackRef.current()
        }
    }, [state])

    return [state, setStateCB]
}

function OptInEmail(props) {
    const appSettings = props.auth.appSettings
    // const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [emailOptInStatus, setEmailOptInStatus] = useStateWithCallback(_.get(props.auth.user, 'emailOptInStatus', false))
    const userEmail = _.get(props.auth.user, 'email', false)
    const [isResendMessage, setIsResendMessage] = useState(false)
    let date = localStorage.getItem('link_sent_date_time')
    const emailEventType = _.get(props.auth.user, 'emailEventType', 'none')
    const emailEventStatus = _.get(props.auth.user, 'emailEventStatus', false)
    const [isTimer, updateIsTimer] = useState(false)
    const [timer, updateTimer] = useState('')
    const [isSamePage, updateIsSamePage] = useState(false)
    const shouldPresentEmailOptInForExistingUser = useStateWithCallback(_.get(props.auth.user, 'shouldPresentEmailOptInForExistingUser', false))
    const [isOptInPopupStatusUpdated, setIsOptInPopupStatusUpdated] = useState(false)

    const setModelContent = (status) => {
        if (['bounce', 'spamreport'].includes(emailEventType) && emailEventStatus === 'pending') {
            setContent(`We could not deliver your email notifications to ${userEmail}.  If you wish to continue to receive notifications, please update your email now.`)
        } else if (status === 'pending') {
            (shouldPresentEmailOptInForExistingUser === true) ?
                setContent(<>Do you wish to continue receiving <b>Email Notifications</b> each time I send you a chat message or respond to one of your messages?  I’ll also be notifying you when I Go Live. If you don’t see the email, be sure to check your spam folder.</>)
                : setContent(<>Do you wish to receive <b>Email Notifications</b> each time I send you a chat message or respond to one of your messages?  I’ll also be notifying you when I Go Live.</>)
        } else if (status === 'opt_out') {
            setContent(<>Do you wish to continue receiving <b>Email Notifications</b> each time I send you a chat message or respond to one of your messages?  I’ll also be notifying you when I Go Live. If you don’t see the email, be sure to check your spam folder.</>)
        } else if (status === 'link_sent') {
            setContent(`We just sent an email to ${userEmail}.  Please check your email and click on the confirmation link.  If you don’t see the email, be sure to check your spam folder.`)
        }
    }

    useEffect(() => {
        if (isOptInPopupStatusUpdated === false && props.auth.user.isOptInEmailPopupDisplayed === false) {
            axios.get(BASE_URL + '/api/update-opt-in-email-popup-display-status').then(res => {
                setIsOptInPopupStatusUpdated(true)
            })
        }
        if (emailEventType !== 'none' && emailEventStatus === 'pending') {
            if (window.location.pathname === '/profile/update') {
                updateIsSamePage(true)
            }
        }
        if (isLoading === true) {
            if (emailOptInStatus !== false) {
                setModelContent(emailOptInStatus)
            }
            setIsLoading(false)
            let expectedDate = new Date(new Date(date).setMinutes(new Date(date).getMinutes() + 2))
            if (expectedDate > new Date()) {
                setIsResendMessage(false)
            }
        }
        if (isTimer === true) {
            setInterval(() => getResendTimer(), 1000)
        }
    }, [emailOptInStatus, isLoading, isTimer])

    const getResendTimer = () => {
        let date
        if (emailOptInStatus === 'link_sent' && emailEventType === 'none') {
            date = localStorage.getItem('link_sent_date_time')
        } else {
            date = localStorage.getItem('change_email_link_sent_date_time')
        }
        const expectedDate = new Date(date).setMinutes(new Date(date).getMinutes() + 2)
        let diff = expectedDate - new Date()

        const getSecond = Math.floor(diff / 1000)

        updateTimer(getSecond)
        if (getSecond === 0) {
            updateIsTimer(false)
            setIsResendMessage(false)
        }
    }

    if (isLoading === true) {
        return <div className='text-center mt-3'>
            <div className='spinner-border' role='status' style={{ color: appSettings.site_font_color }}>
                <span className='sr-only'>Loading...</span>
            </div>
        </div>
    }

    const handleSubmit = () => {
        if (emailEventType !== 'none' && emailEventStatus === 'pending') {
            return <>
                <div className='col-12 col-md-6 mb-3 text-md-right text-center'>
                    <Button
                        onClick={() => updateStatus('change_my_email')}
                        style={{ textTransform: 'none', backgroundColor: appSettings.content_color, color: appSettings.content_font_color, width: '100px' }}
                        className='btn shadow-none w-100 py-2'
                    >
                        CHANGE MY EMAIL ADDRESS
                    </Button>
                </div>
                <div className='col-12 col-md-6 mb-3 text-md-left text-center'>
                    <Button
                        onClick={() => updateStatus('no_thanks')}
                        style={{ textTransform: 'none', backgroundColor: appSettings.content_color, color: appSettings.content_font_color, width: '100px' }}
                        className='btn shadow-none w-100 py-2'
                    >
                        NO THANKS
                    </Button>
                </div></>
        } else if (['opt_out', 'pending'].includes(emailOptInStatus)) {
            const noThanks = emailOptInStatus === 'opt_out' ? 'no_thanks_opt_out' : 'no_thanks'
            return <>
                <EmailPromptBtn className='col-12'>
                    <Button
                        onClick={() => updateStatus('yes')}
                        style={{ textTransform: 'none', backgroundColor: appSettings.content_color, color: appSettings.content_font_color }}
                        className='btn shadow-none py-2 btn-enable'
                    >
                        ENABLE EMAIL NOTIFICATION
                    </Button>
                    <Button
                        onClick={() => updateStatus(noThanks)}
                        className='btn shadow-none py-2 btn-no-thanks'
                        style={{ textTransform: 'none', backgroundColor: appSettings.content_color, color: appSettings.content_font_color }}
                    >
                        NO THANKS
                    </Button>
                </EmailPromptBtn>
            </>
        } else if (emailOptInStatus === 'link_sent') {
            return <>
                <div className='col-12 text-center'>
                    <Button
                        onClick={() => updateStatus('continue')}
                        style={{ textTransform: 'none', backgroundColor: appSettings.content_color, color: appSettings.content_font_color, width: '100px' }}
                        className='btn shadow-none py-2 px-9'
                    >
                        CONTINUE
                    </Button>
                </div>
                <div className='col-12 text-sm-right text-center mt-5'>
                    <Link
                        onClick={() => updateStatus('resend')}
                        style={{ textDecoration: 'underline', textTransform: 'none', color: appSettings.site_font_color }}
                        className='shadow-none px-1'
                    >
                        Resend
                    </Link>|
                    <Link
                        onClick={() => updateStatus('change_my_email')}
                        style={{ textDecoration: 'underline', textTransform: 'none', color: appSettings.site_font_color }}
                        className='shadow-none px-1'
                    >
                        Change my email
                    </Link>
                </div>
            </>
        }
    }

    const updateStatus = (status) => {
        const data = {}
        let isApiReadyForCall = false
        if (status === 'yes') {
            isApiReadyForCall = true
            data.opt_in_status = 'link_sent'
            localStorage.setItem('link_sent_date_time', new Date())
        } else if (status === 'no_thanks') {
            isApiReadyForCall = true
            localStorage.removeItem('showEmailNotificationPrompt')
            data.opt_in_status = 'no_thanks'
            setEmailOptInStatus('no_thanks')
        } else if (status === 'continue') {
            props.updateUserDetail()
        } else if (status === 'resend') {
            setIsResendMessage(false)
            let date = localStorage.getItem('link_sent_date_time')
            let expectedDate = new Date(new Date(date).setMinutes(new Date(date).getMinutes() + 2))
            if (expectedDate <= new Date()) {
                isApiReadyForCall = true
                data.opt_in_status = 'link_sent'
                localStorage.setItem('link_sent_date_time', new Date())
            } else {
                updateIsTimer(true)
                setIsResendMessage(true)
            }
        } else if (status === 'change_my_email') {
            props.updateUserDetail()
            if (window.location.pathname === '/profile/update') {
                updateIsSamePage(true)
            }
            props.history.push('/profile/update')
        } else if (status === 'no_thanks_opt_out') {
            isApiReadyForCall = true
            data.opt_in_status = 'no_thanks_opt_out'
            setEmailOptInStatus('no_thanks_opt_out')
        }

        if (isApiReadyForCall === true) {
            props.updateUserOptInStatus(data, () => {
                if (status === 'yes') {
                    setModelContent('link_sent')
                    setEmailOptInStatus('link_sent', () => {
                    })
                } else {
                    if (status === 'resend') {
                        alert('Resend email successfully')
                    }
                }
            })
        }
    }

    if (isSamePage === true) {
        return <></>
    }

    if (['pending', 'opt_out', 'link_sent'].includes(emailOptInStatus) || (emailEventStatus === 'pending' && ['bounce', 'spamreport'].includes(emailEventType))) {
        return (
            <WebsiteFontColor>
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: appSettings.card_background_color }}>
                            <div className='modal-body px-2'>
                                <div className='container'>
                                    {content}
                                    <div className='row mt-5'>
                                        {handleSubmit()}
                                    </div>
                                    {isResendMessage === true ? <div className='col-12 text-center text-red mx-3'>Please wait {timer} second{timer > 1 ? 's' : ''}  before requesting an email resend.</div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WebsiteFontColor >
        )
    }
    return <></>
}

OptInEmail.propTypes = {
    auth: PropTypes.object.isRequired,
    updateUserOptInStatus: PropTypes.func.isRequired,
    updateUserDetail: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps, { updateUserOptInStatus, updateUserDetail }
)(withRouter(OptInEmail))
