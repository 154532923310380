import styled from 'styled-components'

const HTMLDiv = styled.div`
    * {
        color: inherit !important;
        background-color: inherit !important;
    }
`

export default HTMLDiv
