import axios from 'axios'
import _ from 'lodash'
import { getAppBaseUrl } from './api'
import { setSweetAlert } from './sweetAlertActions'
import { updateAppSettingSingleKey } from './appSettingsActions'
import {
    UPDATE_COUPON_LOADING,
    SET_COUPON_CODE,
    UPDATE_COUPON_STATUS,
    SET_COUPON_DETAILS,
    UPDATE_COUPON_LIST,
    UPDATE_SINGLE_COUPON_IN_LIST,
    UPDATE_COUPON_REPORT_PAGINATION
} from './types'

const BASE_URL = getAppBaseUrl()

/**
 * @description Save coupon setting
 *
 * @param {object} data coupon setting data
 * @param {boolean} data.enable_coupons is coupon functionality enable
 * @param {boolean} data.display_coupon_box_to_user display input boc of coupon in subscription form
 * @returns {}
 */
export const saveCouponSetting = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/coupon/save`
    try {
        const res = await axios.post(url, data)

        dispatch(updateAppSettingSingleKey('enable_coupons', data.enable_coupons))
        dispatch(updateAppSettingSingleKey('display_coupon_box_to_user', data.display_coupon_box_to_user))
        dispatch(setSweetAlert({ description: res.data.message }))

        return
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while save coupon code settings.')
        dispatch(setSweetAlert({ description: errorMessage }))
        throw new Error(errorMessage)
    }
}

/**
 * @description Add new coupon
 *
 * @param {object} data coupon details
 * @returns {}
 */
export const addCoupon = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/coupon/add`
    try {
        const res = await axios.post(url, data)
        dispatch(setSweetAlert({ description: res.data.message }))
        return
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while add coupon code.')
        dispatch(setSweetAlert({ description: errorMessage }))
        throw new Error(errorMessage)
    }
}

/**
 * @description Edit coupon active, usage limit, and expiration date
 *
 * @param {object} data coupon updated details
 * @returns {}
 */
export const editCoupon = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/coupon/edit`
    try {
        const res = await axios.post(url, data)
        dispatch(setSweetAlert({ description: res.data.message }))
        const coupon = _.get(res, 'data.data.couponDetails', {})
        if (!_.isEmpty(coupon)) {
            dispatch(updateSingleCouponInList(coupon))
        }
        return res.data.data.couponDetails
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while update coupon code details.')
        dispatch(setSweetAlert({ description: errorMessage }))
        throw new Error(errorMessage)
    }
}

/**
 * @description Get coupon list
 *
 * @param {number} pageNum page number
 * @returns {object} Coupon List
 */
export const getCouponList = (pageNum) => async (dispatch) => {
    const url = `${BASE_URL}/api/coupon/get-coupons`
    try {
        const config = { params: { pageNum } }
        const res = await axios.get(url, config)

        const couponList = _.get(res, 'data.data.couponList', [])
        dispatch(updateCouponList(couponList))
        const paginationData = _.get(res, 'data.data.paginationData', { currentPage: 1, totalPages: 0, totalRows: 0, limit: 0 })
        dispatch(updateCouponReportPagination(paginationData))
        return res.data.data
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while save coupon code settings.')
        dispatch(setSweetAlert({ description: errorMessage }))
        throw new Error(errorMessage)
    }
}

/**
 * @description Check coupon is valid
 *
 * @param {object} data coupon code
 * @returns {object} coupon details
 */
export const checkCouponIsValid = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/coupon/check`
    try {
        const res = await axios.post(url, data)
        dispatch(setCouponCode(data.code))
        dispatch(setCouponDetails(res.data.data))
        dispatch(updateCouponStatus('valid'))
        return res.data.data
    } catch (error) {
        dispatch(setCouponCode(''))
        dispatch(setCouponDetails({}))
        dispatch(updateCouponStatus('invalid'))
        const codeErrorMessage = _.get(error, 'response.data.errors.code', '')
        const errorMessage = _.get(error, 'response.data.message', 'Something went wrong please try again after some time')
        if (_.isEmpty(codeErrorMessage)) {
            dispatch(setSweetAlert({ description: errorMessage }))
        }
        throw new Error(codeErrorMessage)
    } finally {
        dispatch(updateCouponLoading(false))
    }
}

/**
 * @description get coupon details
 *
 * @param {string} couponId coupon ID
 * @param {number} pageNum page num for user list
 * @returns {object} list of users
 */
export const getCouponDetails = (couponId, pageNum) => async (dispatch) => {
    const url = `${BASE_URL}/api/coupon/get-details`
    try {
        const config = { params: { couponId, pageNum } }
        const res = await axios.get(url, config)
        return res.data.data
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while get coupon details.')
        dispatch(setSweetAlert({ description: errorMessage }))
        throw new Error(errorMessage)
    }
}

export const resetCouponStore = () => (dispatch) => {
    dispatch(updateCouponLoading(false))
    dispatch(setCouponDetails({}))
    dispatch(setCouponCode(''))
    dispatch(updateCouponStatus('notChecked'))
}

export const updateCouponLoading = (data) => {
    return {
        type: UPDATE_COUPON_LOADING,
        payload: data
    }
}

export const setCouponDetails = (data) => {
    return {
        type: SET_COUPON_DETAILS,
        payload: data
    }
}

export const setCouponCode = (data) => {
    return {
        type: SET_COUPON_CODE,
        payload: data
    }
}

export const updateCouponStatus = (data) => {
    return {
        type: UPDATE_COUPON_STATUS,
        payload: data
    }
}

export const updateCouponList = (data) => {
    return {
        type: UPDATE_COUPON_LIST,
        payload: data
    }
}

export const updateSingleCouponInList = (data) => {
    return {
        type: UPDATE_SINGLE_COUPON_IN_LIST,
        payload: data
    }
}

export const updateCouponReportPagination = (data) => {
    return {
        type: UPDATE_COUPON_REPORT_PAGINATION,
        payload: data
    }
}
