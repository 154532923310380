import {
    RESET_PROMOTION,
    SET_PROMOTION
} from '../actions/types'

const initialState = {
    isSetPromotion: false,
    subscriptionPromotion: false,
    lockedContentPromotion: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PROMOTION:
            return {
                ...state,
                isSetPromotion: true,
                subscriptionPromotion: action.payload.subscription_promotion,
                lockedContentPromotion: action.payload.locked_content_promotion
            }
        case RESET_PROMOTION:
            return {
                ...state,
                isSetPromotion: false,
                subscriptionPromotion: false,
                lockedContentPromotion: false
            }
        default:
            return state
    }
}
