import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import { checkCouponIsValid, setCouponCode, setCouponDetails, updateCouponLoading, updateCouponStatus } from '../../actions/couponAction'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import { size } from '../../utils/common'

const InputGroup = styled.div`
    .coupon-input {
        height: 46px;
        padding: 10px 15px;
        text-transform: uppercase;
        width: 70% !important;
    }

    .coupon-input::placeholder {
        text-transform: capitalize;
    }

    .coupon-btn {
        height: 46px;
        border-left: transparent !important;
    }
    
    .coupon-input:focus {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid #0028641f !important;
        border-right: transparent !important;
    }
    
    .coupon-btn:focus {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid #0028641f !important;
        border-left: transparent !important;
    }

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        .coupon-input {
            padding: 10px 5px;
            width: 60% !important;
            border-right: transparent !important;
        }
    }
`


function CouponCodeInputBox(props) {

    const { disabled, classes } = props
    const { display_coupon_box_to_user, enable_promotion } = props.auth.appSettings
    const { isSubscribedEver, isRebillFailed } = props.auth.user
    const { subscriptionPromotion } = props.promotion
    const { isUserEligibleForOffer } = props.resubscriptionOffer
    const {
        isLoading,
        couponCode,
        couponStatus,
        couponCodeDetails
    } = props.couponCode
    const { isPaymentProcessing } = props.payment

    let otherOfferIsOn = isUserEligibleForOffer
    if (enable_promotion && subscriptionPromotion !== false) {
        if (subscriptionPromotion.applicable_to === 'ALL_USERS') {
            otherOfferIsOn = true
        } else if (subscriptionPromotion.applicable_to === 'NEW_USERS' && isSubscribedEver !== true) {
            otherOfferIsOn = true
        } else if (subscriptionPromotion.applicable_to === 'OLD_USERS' && isSubscribedEver === true) {
            otherOfferIsOn = true
        }
    }

    const [coupon, setCoupon] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        if (!_.isEmpty(couponCode) && isRebillFailed !== true) {
            setCoupon(couponCode)
            if (couponStatus !== 'valid') {
                checkCouponValid(couponCode)
            }
        }
    }, [])

    useEffect(() => {
        if (couponCodeDetails && otherOfferIsOn && showAlert) {
            showSweetAlert()
        }
    }, [couponCodeDetails, otherOfferIsOn, showAlert])

    const checkCouponValid = async (code) => {
        try {
            code = code.trim().toUpperCase()
            props.updateCouponLoading(true)
            setErrorMessage('')
            props.setCouponDetails({})
            if (_.isEmpty(code.trim())) {
                setErrorMessage('Enter valid coupon')
                props.updateCouponStatus('notChecked')
                props.updateCouponLoading(false)
                return
            }
            await props.checkCouponIsValid({ code: code })
            setShowAlert(true)
        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    const showSweetAlert = () => {
        let message = ''
        const { discount_type, discount_value_for_initial, discount_value_for_recurring } = couponCodeDetails
        if (discount_type === 'percentage') {
            if (discount_value_for_recurring === 0) {
                message = `You have received a flat ${discount_value_for_initial}% discount on the first month membership price.`
            } else if (discount_value_for_initial === discount_value_for_recurring) {
                message = `You have received a flat ${discount_value_for_initial}% discount on the membership price.`
            } else {
                message = `You have received a ${discount_value_for_initial}% discount on your first month membership price and ${discount_value_for_recurring}% discount on the membership price after the first month.`
            }
        } else {
            if (discount_value_for_recurring === 0) {
                message = `You have received a flat $${discount_value_for_initial} discount on the first month membership price.`
            } else if (discount_value_for_initial === discount_value_for_recurring) {
                message = `You have received a flat $${discount_value_for_initial} discount on the membership price.`
            } else {
                message = `You have received a $${discount_value_for_initial} discount on your first month membership price and $${discount_value_for_recurring} discount on the membership price after the first month.`
            }
        }
        const description = <div>
            <h2 > <span role='img' aria-label='party-icon'>🎉</span> Coupon Applied Successfully! <span role='img' aria-label='party-icon'>🎉</span></h2>
            {message && <p>{message}</p>}
        </div>
        props.setSweetAlert({ description: description })
        setShowAlert(false)
    }

    const removeAppliedCoupon = async () => {
        props.updateCouponLoading(true)
        setCoupon('')
        setErrorMessage('')
        props.setCouponCode('')
        props.updateCouponStatus('notChecked')
        props.setCouponDetails({})
        props.updateCouponLoading(false)
        return
    }

    if (display_coupon_box_to_user !== true) {
        return <></>
    }

    return <div className={classes}>
        <InputGroup className='input-group'>
            <input
                type='text'
                className='form-control coupon-input'
                name='coupon-code'
                value={coupon}
                onChange={(e) => { setCoupon(e.target.value); setErrorMessage('') }}
                placeholder='Enter Coupon Code'
                disabled={disabled || isLoading || couponStatus === 'valid' || isPaymentProcessing}
            />
            {couponStatus === 'valid' ? <button
                type='button'
                className='form-control coupon-btn'
                onClick={() => removeAppliedCoupon()}
                disabled={isLoading || isPaymentProcessing}
            >
                Remove
            </button> : <button
                type='button'
                className='form-control coupon-btn'
                onClick={() => checkCouponValid(coupon)}
                disabled={isLoading || isPaymentProcessing}
            >
                Apply
            </button>}
        </InputGroup>
        {otherOfferIsOn && <small
            className='form-text text-muted mx-1'
            style={{ whiteSpace: 'pre-wrap' }}
        >
            Note: You can not combine coupon with other offers.
        </small>}
        {_.isEmpty(errorMessage) === false && <span className='text-red mx-1'>{errorMessage}</span>}
        {couponStatus === 'valid' && <span className='text-green mx-1'>Coupon Code Applied!</span>}
    </div>
}

CouponCodeInputBox.propTypes = {
    classes: PropTypes.string,
    auth: PropTypes.object.isRequired,
    couponCode: PropTypes.object.isRequired,
    resubscriptionOffer: PropTypes.object.isRequired,
    promotion: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    updateCouponLoading: PropTypes.func.isRequired,
    checkCouponIsValid: PropTypes.func.isRequired,
    setCouponDetails: PropTypes.func.isRequired,
    updateCouponStatus: PropTypes.func.isRequired,
    setCouponCode: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    couponCode: state.couponCode,
    resubscriptionOffer: state.resubscriptionOffer,
    promotion: state.promotion,
    payment: state.payment
})

export default connect(mapStateToProps, {
    updateCouponLoading,
    checkCouponIsValid,
    setCouponDetails,
    updateCouponStatus,
    setCouponCode,
    setSweetAlert
})(withRouter(CouponCodeInputBox))

