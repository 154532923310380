import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'

const UnorderedList = styled.ul`
    .page-item .page-link {
        border: 1px solid ${props => props.contentColor} !important;
        background-color: #fff;
        color: #000;
    }

    .page-item .page-link:hover {
        background-color: ${props => props.contentColor};
        color: ${props => props.contentFontColor};
    }
    
    .active-link{
        background-color: ${props => props.contentColor} !important;
        color: ${props => props.contentFontColor} !important;
    }
`

const Pagination = (props) => {
    let currentPage = props.currentPage ? props.currentPage : 1
    const totalPages = props.totalPages                 // This is total count of records available in database
    const contentColor = props.contentColor || '#fff'
    const contentFontColor = props.contentFontColor || '#000'
    const totalItems = props.totalItems || 0
    const totalAllItems = props.totalAllItems || 0          // This is count of records fetched after filtering the data
    const itemsPerPage = props.itemsPerPage || 0
    const isFiltered = props.isFiltered || false
    const showFirstAndLastBtn = props.showFirstAndLastBtn || false
    const isLoading = _.get(props, 'isLoading', false)

    const addPageNumbers = (totalPages, currentPage) => {
        let numbers = []
        let pad = 3

        if (currentPage <= pad) {
            for (let index = 1; index < currentPage; index++) {
                numbers.push(index)
            }
        }

        if (currentPage > pad) {
            for (let index = currentPage - pad; index < currentPage; index++) {
                numbers.push(index)
            }
        }

        numbers.push(currentPage)

        if (totalPages > currentPage) {
            for (let index = currentPage + 1; index <= totalPages; index++) {
                if (index > currentPage + pad) {
                    break
                }
                numbers.push(index)
            }
        }

        return numbers
    }


    const handleClick = (page) => {
        if (props.onItemClick) {
            props.onItemClick(page)
        }
    }

    let numbers = addPageNumbers(totalPages, currentPage)
    let start = itemsPerPage * (currentPage - 1) + 1
    let end = itemsPerPage * (currentPage)
    if (end > totalItems) {
        end = totalItems
    }

    let message = ''
    if (totalItems > 0) {
        message = `Showing ${start} to ${end} of ${totalItems} entries`
    }
    if (isFiltered) {
        message += ` (filtered from ${totalAllItems} total entries)`
    }
    return (
        <div className="row position-relative" style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
            {message &&
                <div className="col">
                    {message}
                </div>
            }
            <div className="col">
                <nav>
                    <UnorderedList className='pagination justify-content-end' contentColor={contentColor} contentFontColor={contentFontColor}>
                        {showFirstAndLastBtn ? <li className={(currentPage === 1) || isLoading ? 'page-item disabled' : 'page-item'}>
                            <span className='page-link' onClick={() => handleClick(1)}>
                                &lt;&lt;
                            </span>
                        </li> : null}
                        {numbers.map((item, i) => {
                            let isActive = (currentPage === item)
                            return (<li key={i} className={isActive && !isLoading ? 'page-item active' : 'page-item'}>
                                <span className={isActive && !isLoading ? 'page-link active-link' : 'page-link'} onClick={() =>
                                    isActive ? null : handleClick(item)
                                }>{item}</span>
                            </li>
                            )
                        })}
                        {showFirstAndLastBtn ? <li className={(totalPages === currentPage) || isLoading ? 'page-item disabled' : 'page-item'}>
                            <span className='page-link' onClick={() => handleClick(totalPages)}>
                                &gt;&gt;
                            </span>
                        </li> : null}
                    </UnorderedList>
                </nav>
            </div>
        </div>
    )
}

Pagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    contentColor: PropTypes.string,
    contentFontColor: PropTypes.string,
    onItemClick: PropTypes.func,
    totalItems: PropTypes.number,
    totalAllItems: PropTypes.number,
    itemsPerPage: PropTypes.number,
    isFiltered: PropTypes.bool,
    showFirstAndLastBtn: PropTypes.bool,
    isLoading: PropTypes.bool
}

export default Pagination
