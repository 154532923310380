import axios from 'axios'
import _ from 'lodash'
import { getAppBaseUrl } from './api'
import { SET_RESUBSCRIPTION_OFFER } from './types'
import { setSweetAlert } from './sweetAlertActions'

const BASE_URL = getAppBaseUrl()

export const getResubscribeOfferSettings = (callback) => () => {
    axios.get(BASE_URL + '/api/resubscribe-offer/get-settings')
        .then((res) => {
            callback(res.data.data)
        }).catch(error => {
            alert(error.message)
            callback()
        })
}

export const saveResubscribeOfferSettings = (data, callback) => dispatch => {
    axios.put(BASE_URL + '/api/resubscribe-offer/save-settings', data)
        .then((res) => {
            dispatch(setSweetAlert({description: res.data.message}))
            callback(res.data)
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            callback()
        })
}

export const checkUserEligibleForOffer = () => dispatch => {
    axios.get(BASE_URL + '/api/offer/check-offer')
    .then((res) => {
            dispatch(setSubscriptionOffer(res.data.data))
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
        })
}

export const saveResubscribeOffer = (data, callback) => dispatch => {
    axios.put(BASE_URL + '/api/resubscribe-offer/save-offer', data)
        .then((res) => {
            callback(res.data)
            dispatch(setSweetAlert({description: res.data.message}))
        }).catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            callback()
        })
}

// Set subscription offer.
export const setSubscriptionOffer = (data) => {
    return {
        type: SET_RESUBSCRIPTION_OFFER,
        payload: data
    }
}

export const sendResubscriptionReportRequest = (data) => async (dispatch) => {
    try {
        const response = await axios.post(BASE_URL + '/api/resubscribe-offer/request-new-resubscription-user-report', data)
        dispatch(setSweetAlert({description: response.data.message}))
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
        dispatch(setSweetAlert({description: errorMessage}))
    }
}

export const getResubscriptionReportRequest = (data) => async (dispatch) => {
    try {
        const response = await axios.post(BASE_URL + '/api/resubscribe-offer/get-new-resubscription-user-report', data)
        return response.data
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
        dispatch(setSweetAlert({description: errorMessage}))
    }
}
