import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4'
import _ from 'lodash'
import { install } from 'ga-gtag'
import PropTypes from 'prop-types'
import { hideNotificationPopup } from '../../actions/utilityActions'
import { createReCaptchaScript } from './../../utils/reCaptcha'


window.isGALoaded = false

class GoogleAnalytics extends Component {
    componentDidMount() {
        createReCaptchaScript()
    }

    render() {
        const { google_analytics_4_measurement_id } = this.props.auth.appSettings

        if (this.props.auth.isReady && (!_.isEmpty(google_analytics_4_measurement_id)) && window.isGALoaded === false) {
            ReactGA.initialize(google_analytics_4_measurement_id)

            // set google tracking id for gtag module
            install(google_analytics_4_measurement_id)

            let pathname = this.props.history.location.pathname

            if (pathname.includes('private-chat') || pathname.includes('purchased-contents')) {
                pathname = pathname.substring(0, pathname.lastIndexOf('/') + 1)
                pathname = pathname + 'user_id'
            }
            if (pathname.includes('purchased-by/locked-exclusive-content/')) {
                pathname = pathname.substring(0, pathname.lastIndexOf('/') + 1)
                pathname = pathname + 'blog_id'
            }

            if (pathname.includes('mass-message')) {
                pathname = pathname.substring(0, pathname.lastIndexOf('/') + 1)
                pathname = pathname + 'udid'
            }

            ReactGA.send({ hitType: 'pageview', page: pathname })


            this.props.history.listen((location) => {
                pathname = location.pathname
                if (pathname.includes('private-chat') || pathname.includes('purchased-contents')) {
                    pathname = pathname.substring(0, pathname.lastIndexOf('/') + 1)
                    pathname = pathname + 'user_id'
                }
                if (pathname.includes('purchased-by/locked-exclusive-content/')) {
                    pathname = pathname.substring(0, pathname.lastIndexOf('/') + 1)
                    pathname = pathname + 'blog_id'
                }
                if (pathname.includes('mass-message')) {
                    pathname = pathname.substring(0, pathname.lastIndexOf('/') + 1)
                    pathname = pathname + 'udid'
                }

                ReactGA.send({ hitType: 'pageview', page: pathname })
            })
            window.isGALoaded = true
        }

        // Hide notification popup
        this.props.history.listen(() => {
            this.props.hideNotificationPopup()
        })

        return (
            <>
                {this.props.children}
            </>
        )
    }
}

GoogleAnalytics.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    hideNotificationPopup: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat
})

export default connect(
    mapStateToProps, { hideNotificationPopup }
)(withRouter((GoogleAnalytics)))
