import {
    UPDATE_COUPON_LOADING,
    SET_COUPON_CODE,
    UPDATE_COUPON_STATUS,
    SET_COUPON_DETAILS,
    UPDATE_COUPON_LIST,
    UPDATE_SINGLE_COUPON_IN_LIST,
    UPDATE_COUPON_REPORT_PAGINATION
} from '../actions/types'

const initialState = {
    isLoading: false,
    couponCodeDetails: {},
    couponCode: '',
    couponStatus: 'notChecked', // valid, invalid, notChecked,
    couponList: [],
    couponReportPagination: {
        currentPage: 1,
        totalPages: 0,
        totalRows: 0,
        limit: 0
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_COUPON_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_COUPON_CODE:
            return {
                ...state,
                couponCode: action.payload
            }
        case UPDATE_COUPON_STATUS:
            return {
                ...state,
                couponStatus: action.payload
            }
        case SET_COUPON_DETAILS:
            return {
                ...state,
                couponCodeDetails: action.payload
            }
        case UPDATE_COUPON_LIST:
            return {
                ...state,
                couponList: action.payload
            }
        case UPDATE_SINGLE_COUPON_IN_LIST: {
            const list = state.couponList
            const couponIndex = list.findIndex((coupon) => coupon._id === action.payload._id)
            list[couponIndex] = action.payload
            return {
                ...state,
                couponList: list
            }
        }
        case UPDATE_COUPON_REPORT_PAGINATION:
            return {
                ...state,
                couponReportPagination: action.payload
            }
        default:
            return state
    }
}
