import axios from 'axios'
import { getAppBaseUrl } from './api'
import { setSweetAlert } from './sweetAlertActions'
import _ from 'lodash'
import {
    UNIVERSAL_LOGIN_MERGE_WEBSITE_LIST,
    UNIVERSAL_LOGIN_WEBSITE_LIST,
    SHOW_UNIVERSAL_LOGIN_ADD_ACCOUNT_POPUP,
    SHOW_FORGOT_PASSWORD_POPUP,
    SET_FORGOT_PASSWORD_EMAIL,
    UNIVERSAL_LOGIN_OLD_USER_WEBSITE_LIST,
    SHOW_OLD_USER_MERGE_ACCOUNT_POPUP,
    UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_SITE_LIST,
    SHOW_DIFFERENT_PASSWORD_SITE_POPUP,
    SET_MERGE_OLD_USER,
    IS_ENABLED_UNIVERSAL_LOGIN_SETTING
} from './types'
import {
    setLoginUserInfo,
    setRegisterUserInfo,
    loginButtonLoading,
    redirectRegisterUser
} from './authActions'
import { LOGIN, REGISTER } from '../utils/universalLoginConstant'
const BASE_URL = getAppBaseUrl()

/**
 * Save Universal Login settings
 * @param {boolean} data save universal login enable status
 */
export const updateUniversalLoginSettings = (data) => async (dispatch) =>  {
    try {
        const res = await axios.post(`${BASE_URL}/api/universal-login/save-settings`, data)
        const payload = {
            description: res.data.message
        }
        dispatch(isEnabledUniversalLoginSetting(res.data.data))
        dispatch(setSweetAlert(payload))
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was a problem updating Universal Login settings')
        dispatch(setSweetAlert({description: errorMessage }))
    }
}

/**
 * @description get universal merge website list from service
 * @param {string} email user email
 * @returns universal merge website list
 */
export const universalMergeWebsiteList = (email) => async (dispatch) => {
    const URL = `${BASE_URL}/api/universal-login/universal-login-merge-domain-list?email=${email}`
    try {
        const res = await axios.get(URL)
        if (res && res.data.success === 1) {
            dispatch(universalLoginMergeWebsite(res.data.data))
        }
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was a problem while fetching merged websites list.')
        dispatch(setSweetAlert({ description: errorMessage }))
    }
}

/**
 * @description show add account popup and store register or login user info
 * @param {object} accountList list of add account list
 * @param {object} userData object data of user email and password
 * @param {string} requestFrom request from login or register
 * @returns return after store add account list and show merge popup
 */
export const handleShowUniversalAddAccountPopup = (accountList, userData, requestFrom) => (dispatch) => {
    const addAccountList = _.get(accountList, 'data.data.siteListOfMergeAccount', [])
    // store website list for add account in universal login
    dispatch(universalLoginWebsiteList(addAccountList))
    // set status of show universal login add account popup
    dispatch(showUniversalLoginAddAccountPopup(true))
    // set user info for use while login users from universal login add account popup
    if (requestFrom === LOGIN) {
        dispatch(setLoginUserInfo(userData))
    }
    // set user info for use while register users from universal login add account popup
    if (requestFrom === REGISTER) {
        dispatch(setRegisterUserInfo(userData))
    }
    return
}

/**
 * @description handle empty merge website list store object and hide add account popup
 * @param {void} history
 * @param {string} from from register or login
 * @param {string} requestFrom requestFrom newRegisterPage, ccbill and sticky io
 * @param {string} redirectUrl user redirect url while user register
 * @returns return after user empty merge website list store and hide add account popup
 */
export const handleHideUniversalAddAccountPopup = (history, from, requestFrom, redirectUrl) => (dispatch) => {
    // empty object after add account website in universal login
    dispatch(universalLoginWebsiteList([]))
    // set show universal login popup to false
    dispatch(showUniversalLoginAddAccountPopup(false))
    // empty object of store login user info after add account user in the universal login
    if (from === LOGIN) {
        dispatch(loginButtonLoading(false))
        dispatch(setLoginUserInfo({}))
        // check and redirect user while user add account from login page
        dispatch(redirectRegisterUser(history, requestFrom, redirectUrl))
    }
    // empty object of store register user info after add account user in the universal login
    if (from === REGISTER) {
        dispatch(setRegisterUserInfo({}))
    }
    return
}

/**
 * @description store universal login user merge website list for show in transaction report
 * @param {Array} data universal user merge website list
 */
export  const universalLoginMergeWebsite = (data) => {
    return {
        type: UNIVERSAL_LOGIN_MERGE_WEBSITE_LIST,
        payload: data
    }
}

/**
 * @description store add account website list while user login and register
 * @param {Array} data universal add account list
 */
export  const universalLoginWebsiteList = (data) => {
    return {
        type: UNIVERSAL_LOGIN_WEBSITE_LIST,
        payload: data
    }
}

/**
 * @description show universal login add account popup
 * @param {boolean} data true or false
 */
export  const showUniversalLoginAddAccountPopup = (data) => {
    return {
        type: SHOW_UNIVERSAL_LOGIN_ADD_ACCOUNT_POPUP,
        payload: data
    }
}

/**
 * @description store universal merge website and show forgot password popup
 * @param {object} data object of user add account list
 * @param {string} email user email
 * @returns return after show forgot password popup
 */
export const showUniversalForgotPasswordPopup = (data, email) => (dispatch) => {
    dispatch(showForgotPasswordPopup(true))
    dispatch(setForgotPasswordEmail(email))
    const addAccountList = _.get(data, 'data.data.siteListOfMergeAccount', [])
    // store website list for add account in universal login
    dispatch(universalLoginWebsiteList(addAccountList))
    // set to merge old user while show different password popup if only found old user with different password
    const oldUser = _.get(data, 'data.data.mergeOldUser', false)
    dispatch(mergeOldUser(oldUser))
    return
}

/**
 * @description show forgot password popup while register or login with different password
 * @param {boolean} data true or false
 * @returns store show forgot password popup status
 */
export const showForgotPasswordPopup = (data) => {
    return {
        type: SHOW_FORGOT_PASSWORD_POPUP,
        payload: data
    }
}
/**
 * @description set user email for send forgot password link to user from register and login screen
 * @param {string} email
 * @returns store user email
 */
export const setForgotPasswordEmail = (email) => {
    return {
        type: SET_FORGOT_PASSWORD_EMAIL,
        payload: email
    }
}

/**
 *
 * @description show old user merge account popup
 * @param {Array} accountList list of old user merge domain
 * @param {object} userData object data of user email and password
 * @returns return after show old user account
 */
export const showOldUserMergeAccountPopup = (accountList, userData, requestFrom) => (dispatch) => {
    const mergeAccountList = _.get(accountList, 'data.data.siteListOfMergeOldAccount', [])
    const differentPasswordSiteList = _.get(accountList, 'data.data.siteListOfDifferentPassword', [])
    // store website list for add account in universal login
    dispatch(universalLoginMergeWebsiteList(mergeAccountList))
    // set status of show universal login add account popup
    dispatch(showUniversalLoginMergeAccountPopup(true))
    // set different password site list
    if (differentPasswordSiteList.length > 0) {
        localStorage.setItem('differentPasswordPopupReminderCount', 0)
    }
    dispatch(universalLoginDifferentPasswordSite(differentPasswordSiteList))
    // set user info for use while login users from universal login add account popup
    if (requestFrom === LOGIN) {
        dispatch(setLoginUserInfo(userData))
    }
    // set user info for use while register users from universal login add account popup
    if (requestFrom === REGISTER) {
        dispatch(setRegisterUserInfo(userData))
    }
    return
}

/**
 * @description hide old user merge popup while merge account
 * @param {string} requestFrom requestFrom login or register
 * @returns hide old user merge popup
 */
export const hideOldUserMergeAccountPopup = (requestFrom) => (dispatch) => {
    // empty store website list for merge account in universal login
    dispatch(universalLoginMergeWebsiteList([]))
    // set status of show universal login merge account popup
    dispatch(showUniversalLoginMergeAccountPopup(false))
    // set empty user info for use while login users from universal login add account popup
    if (requestFrom === LOGIN) {
        dispatch(setLoginUserInfo({}))
    }
    // set empty user info for use while register users from universal login add account popup
    if (requestFrom === REGISTER) {
        dispatch(setRegisterUserInfo({}))
    }
    return
}

/**
 * @description show different password popup and store different password site list
 * @param {Array} accountList different password site list
 * @param {object} userData user email and password while show different password
 * @returns show different password popup and store different password site
 */
export const setAndShowDifferentPasswordSiteList = (accountList, userData) => (dispatch) => {
    const differentPasswordSite = _.get(accountList, 'data.data.siteListOfDifferentPassword', [])
    dispatch(setLoginUserInfo(userData))
    // store different password site list
    dispatch(universalLoginDifferentPasswordSite(differentPasswordSite))
    // show different password site popup for reset password
    dispatch(showDifferentPasswordSitePopup(true))
    return
}

/**
 * @description add website list in redux state
 * @param {Array} data list of merge website
 * @returns store merge website list
 */
export const universalLoginMergeWebsiteList = (data) => {
    return {
        type: UNIVERSAL_LOGIN_OLD_USER_WEBSITE_LIST,
        payload: data
    }
}

/**
 * @description Store value in redux state to show and hide universal
 * @param {boolean} data hide or show merge popup
 * @returns return after hide or show universal old user merge popup
 */
export const showUniversalLoginMergeAccountPopup = (data) => {
    return {
        type: SHOW_OLD_USER_MERGE_ACCOUNT_POPUP,
        payload: data
    }
}

/**
 * @description store list of different password site list in redux state
 * @param {Array} data list of different password site
 * @returns return after store different password list in redux state
 */
export const universalLoginDifferentPasswordSite = (data) => {
    return {
        type: UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_SITE_LIST,
        payload: data
    }
}

/**
 * @description Update redux state to show or hide different password site popup
 * @param {boolean} data true or false
 * @returns show different password popup
 */
export const showDifferentPasswordSitePopup = (data) => {
    return {
        type: SHOW_DIFFERENT_PASSWORD_SITE_POPUP,
        payload: data
    }
}

/**
 * @description check for merge old user while send forgot password link
 * @param {boolean} data true or false
 * @returns return after update redux state
 */
export const mergeOldUser = (data) => {
    return {
        type: SET_MERGE_OLD_USER,
        payload: data
    }
}

/**
 * @description update universal login setting in redux state
 * @param {boolean} data true or false
 * @returns update redux state enable universal login setting
 */
export const isEnabledUniversalLoginSetting = (data) => {
    return {
        type: IS_ENABLED_UNIVERSAL_LOGIN_SETTING,
        payload: data
    }
}

/**
 * @description Block User universally
 *
 * @param {string} email user email
 */
export const blockUserUniversally = (email) => async (dispatch) => {
    const URL = `${BASE_URL}/api/universal-login/block-user-universally`
    try {
        const res = await axios.post(URL, { email })
        return res.data
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was a problem while block user universally')
        dispatch(setSweetAlert({ description: errorMessage }))
        return {}
    }
}
