import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Countdown from 'react-countdown'
import { getAllPromotionOffers, resetPromotion } from '../../actions/PromotionActions'
import _ from 'lodash'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { getFormattedTimeString } from '../../utils/getFormattedTimeString'
import { closePromotionBanner } from '../../actions/utilityActions'

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '767px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1850px',
    desktopL: '2560px'
}

const PromoTextDiv = styled.div`
    font-size: ${props => props.promoFontSize[3]};
    font-family: ${props => props.promoFontFamily};
    color: ${props => props.ribbonColor};
    padding-bottom: ${props => props.ribbonBottomPadding}px;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: ${props => props.promoFontSize[0]};
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        font-size: ${props => props.promoFontSize[1]};
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        font-size: ${props => props.promoFontSize[2]};
    }
`

const TimeRemainingTextDiv = styled.div`
    font-size: ${props => props.promoFontSize[3]};
    font-family: ${props => props.promoFontFamily};
    color: ${props => props.ribbonColor};

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: ${props => props.promoFontSize[0]};
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        font-size: ${props => props.promoFontSize[1]};
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        font-size: ${props => props.promoFontSize[2]};
    }
`

function PromotionNotificationBar(props) {

    const { enable_promotion, content_color, content_font_color, promotion_settings } = props.auth.appSettings
    const [activePromotionData, setActivePromotionData] = useState(false)
    const { isAdmin, ccbillSubscriptionStatus, isSubscribedEver } = props.auth.user
    const { isAuthenticated } = props.auth
    const { subscriptionPromotion, lockedContentPromotion, isSetPromotion } = props.promotion

    useEffect(() => {
        if (enable_promotion) {
            if (isSetPromotion === false) {
                props.getAllPromotionOffers()
            }
            if (isAdmin === true || ccbillSubscriptionStatus === '2' || ccbillSubscriptionStatus === '1') {
                setActivePromotionData(lockedContentPromotion)
            } else {
                if (isAuthenticated === true) {
                    if (subscriptionPromotion.applicable_to === 'ALL_USERS') {
                        setActivePromotionData(subscriptionPromotion)
                    } else if (subscriptionPromotion.applicable_to === 'NEW_USERS' && isSubscribedEver !== true) {
                        setActivePromotionData(subscriptionPromotion)
                    } else if (subscriptionPromotion.applicable_to === 'OLD_USERS' && isSubscribedEver === true) {
                        setActivePromotionData(subscriptionPromotion)
                    }
                } else {
                    const newUsersPromotionOffer = ['ALL_USERS', 'NEW_USERS']
                    if (newUsersPromotionOffer.includes(subscriptionPromotion.applicable_to)) {
                        setActivePromotionData(subscriptionPromotion)
                    }
                }
            }
        }
    }, [props.promotion, isAuthenticated])

    const upcomingChatCountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <></>
        } else {
            const formattedTime = getFormattedTimeString(days, hours, minutes, seconds)
            return formattedTime
        }
    }

    const onCompleteTimer = () => {
        setActivePromotionData(false)
        props.resetPromotion()
    }

    const backgroundColor = _.get(promotion_settings, 'ribbon_background_color', content_font_color)
    const ribbonPaddingTop = _.get(promotion_settings, 'ribbon_padding_top', '0')
    const ribbonPaddingBottom = _.get(promotion_settings, 'ribbon_padding_bottom', '0')
    const ribbonPaddingBetween = _.get(promotion_settings, 'ribbon_padding_between', '0')
    const promoFontColor = _.get(promotion_settings, 'promo_text_color', content_color)
    let promoFontSize = _.get(promotion_settings, 'promo_text_font_size', '25px|25px|30px|30px')
    const promoFontFamily = _.get(promotion_settings, 'promo_text_font', 'inherit')
    const timeRemainingFontColor = _.get(promotion_settings, 'time_remaining_text_color', content_color)
    let timeRemainingFontSize = _.get(promotion_settings, 'time_remaining_text_font_size', '20px|20px|25px|25px')
    const timeRemainingFontFamily = _.get(promotion_settings, 'time_remaining_text_font', 'inherit')
    const ribbonAnimation = _.get(promotion_settings, 'ribbon_animation', 'none')
    const animationDelay = _.get(promotion_settings, 'animation_delay', 0)
    const animationDuration = _.get(promotion_settings, 'animation_duration', 1000)
    promoFontSize = promoFontSize.split('|')
    timeRemainingFontSize = timeRemainingFontSize.split('|')
    if (promoFontSize.length !== 4) {
        timeRemainingFontSize = ['25px', '25px', '30px', '30px']
    }
    if (timeRemainingFontSize.length !== 4) {
        timeRemainingFontSize = ['20px', '20px', '25px', '25px']
    }

    return (
        <>
            {enable_promotion === true && activePromotionData !== false && props.utility.isPromotionBannerOpen &&
                <Animated animationIn={ribbonAnimation} animationInDelay={animationDelay} animationInDuration={animationDuration} isVisible={true}>
                    <>
                        <div className='float-right'>
                            <button className='close mr-2 mt-1' style={{ color: '#ffffff', opacity: '1.0', fontSize: '1.3em' }} onClick={() => props.closePromotionBanner(false)}></button>
                        </div>
                        <div className='text-center' style={{ width: '100 %', background: backgroundColor, paddingTop: ribbonPaddingTop + 'px', paddingBottom: ribbonPaddingBottom + 'px' }}>
                            <PromoTextDiv ribbonColor={promoFontColor} ribbonBottomPadding={ribbonPaddingBetween} promoFontSize={promoFontSize} promoFontFamily={promoFontFamily} > {activePromotionData.ribbon_text}</PromoTextDiv >
                            <TimeRemainingTextDiv ribbonColor={timeRemainingFontColor} promoFontSize={timeRemainingFontSize} promoFontFamily={timeRemainingFontFamily}>Ends in &nbsp;
                                <Countdown date={activePromotionData.date_expire}
                                    onComplete={onCompleteTimer}
                                    renderer={upcomingChatCountdownRenderer}
                                />
                            </TimeRemainingTextDiv>
                        </div>
                    </>
                </Animated>
            }
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    promotion: state.promotion,
    utility: state.utility
})

PromotionNotificationBar.propTypes = {
    auth: PropTypes.object.isRequired,
    promotion: PropTypes.object.isRequired,
    getAllPromotionOffers: PropTypes.func.isRequired,
    resetPromotion: PropTypes.func.isRequired,
    closePromotionBanner: PropTypes.func.isRequired,
    utility: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { getAllPromotionOffers, resetPromotion, closePromotionBanner })(withRouter(PromotionNotificationBar))
