import {
    SET_MEDIA,
    ADD_MEDIA,
    REMOVE_MEDIA,
    SET_PROCESSED_MEDIA,
    ADD_MEDIA_ON_SCROLL,
    ADD_CATEGORY,
    SET_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_FILTER,
    INC_MEDIA_COUNT,
    RESET_STATE,
    UPDATE_SELECTED_CATEGORY,
    UPDATE_CATEGORY_COUNT,
    UPDATE_MEDIA_COUNT_IN_CATEGORY
} from '../actions/types'

const initialState = {
    media: [],
    processedMedia: { media: [], currentPage: 1, totalPages: 1 },
    filter: { type: 'all', activeCategory: '', selectedCategory: {}, stateHadBeenReset: false },
    category: [],
    totalPages: 1,
    currentPage: 1,
    allMedia: []
}

export default function (state = initialState, action) {
    let newCategory = []
    let media, currentPage
    switch (action.type) {
        case SET_MEDIA: {
            const newRecords = action.payload.media.filter(newRecord => {
                if (newRecord.processing === true) {
                    return false
                }
                return !state.allMedia.some(record => record._id === newRecord._id)
            })
            return {
                ...state,
                media: action.payload.media,
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                allMedia: [...state.allMedia, ...newRecords],
                filter: {
                    ...state.filter,
                    stateHadBeenReset: false
                }
            }
        }
        case ADD_MEDIA_ON_SCROLL: {
            media = action.payload.media
            currentPage = action.payload.currentPage
            const existingMedia = action.payload.media.filter(newRecord => {
                if (newRecord.processing === true) {
                    return false
                }
                return !state.allMedia.some(record => record._id === newRecord._id)
            })
            return {
                ...state,
                media: [...state.media, ...media],
                currentPage: currentPage,
                allMedia: [...state.allMedia, ...existingMedia]
            }
        }
        case ADD_MEDIA:
            return {
                ...state,
                media: [action.payload, ...state.media]
            }
        case REMOVE_MEDIA: {
            const filterMedia = state.media.filter(obj => !action.payload.includes(obj._id))
            return {
                ...state,
                media: filterMedia
            }
        }
        case SET_PROCESSED_MEDIA: {
            const { media, currentPage, totalPages } = action.payload
            return {
                ...state,
                processedMedia: {
                    ...state.processedMedia,
                    media: [...state.processedMedia.media, ...media],
                    currentPage: currentPage,
                    totalPages: totalPages
                }
            }
        }
        case ADD_CATEGORY: {
            return {
                ...state,
                category: [...state.category, action.payload]
            }
        }
        case SET_CATEGORY: {
            const allMediaCategory = action.payload.find(obj => obj.slug === 'all media')
            return {
                ...state,
                category: action.payload,
                filter: {
                    ...state.filter,
                    activeCategory: state.filter.activeCategory ? state.filter.activeCategory : allMediaCategory._id,
                    selectedCategory: state.filter.selectedCategory.slug ? state.filter.selectedCategory : allMediaCategory
                }
            }
        }
        case UPDATE_SELECTED_CATEGORY: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    activeCategory: action.payload
                }
            }
        }
        case UPDATE_CATEGORY: {
            for (let i = 0; i < state.category.length; i++) {
                let element = state.category[i]

                if (element._id === action.payload._id) {
                    element = action.payload
                    newCategory.push(element)
                } else {
                    newCategory.push(element)
                }
            }
            return {
                ...state,
                category: newCategory
            }
        }
        case UPDATE_CATEGORY_COUNT: {
            const { category, newMediaId, activeCategoryId } = action.payload
            const newCategory = state.category.map(cat => {
                const update = category.find(item => item._id === cat._id)
                return update ? update : cat
            })
            const activeCategory = state.category.find(item => item._id === activeCategoryId)
            let updatedMedia = []
            if (activeCategory.slug !== 'all media') {
                updatedMedia = state.allMedia.filter(item1 =>
                    newMediaId.some(item2 => item2._id === item1._id)
                )
            }
            return {
                ...state,
                category: newCategory,
                media: activeCategory.slug !== 'all media' ? updatedMedia : state.media
            }
        }
        case DELETE_CATEGORY: {
            const newCategory = state.category.filter(obj => obj._id !== action.payload)
            return {
                ...state,
                category: newCategory
            }
        }
        case UPDATE_FILTER: {
            return {
                ...state,
                filter: { ...state.filter, ...action.payload }
            }
        }
        case INC_MEDIA_COUNT: {
            const { type } = action.payload
            let updatedCategory = state.category.map(obj => {
                if (obj.slug === 'all media') {
                    return {
                        ...obj,
                        photo_count: type === 'photo' ? obj.photo_count + 1 : obj.photo_count,
                        video_count: type === 'video' ? obj.video_count + 1 : obj.video_count
                    }
                }
                return obj
            })

            return {
                ...state,
                category: updatedCategory
            }
        }
        case RESET_STATE: {
            const { filter, category } = state
            const defaultCategory = category.filter(item => item.slug === 'all media')
            const updatedFilter = {}
            if (filter.type !== 'all') {
                updatedFilter.type = 'all'
                updatedFilter.stateHadBeenReset = true
            }
            if (filter.activeCategory !== defaultCategory[0]._id) {
                updatedFilter.activeCategory = defaultCategory[0]._id
                updatedFilter.stateHadBeenReset = true
            }
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...updatedFilter
                }
            }
        }
        case UPDATE_MEDIA_COUNT_IN_CATEGORY: {
            const { slug, mediaType } = action.payload
            let updatedCategory = state.category
            if (state.category && state.category.length > 0) {
                updatedCategory = state.category.map(item => {
                    if (item.slug === slug) {
                        if (mediaType === 'video') {
                            item.video_count = item.video_count ? item.video_count + 1 : 1
                        } else {
                            item.photo_count = item.photo_count ? item.photo_count + 1 : 1
                        }
                    }
                    return item
                })
            }
            return {
                ...state,
                category: updatedCategory
            }
        }
        default:
            return state
    }
}
