import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import MediaContainer from './MediaContainer'
import ModalPopUp from '../Layouts/ModalPopUp'
import TextFieldGroup from '../Layouts/TextFieldGroup'
import Loader from '../Layouts/Loader'
import MediaUpload from './MediaUpload'
import Button from '../Layouts/Button'
import { updateSelectedCategory } from '../../actions/mediaAction'
import MediaCategoryPopup from './MediaCategoryPopup'
import BackButton from '../Layouts/BackButton'
import _ from 'lodash'

const MainContainer = styled.div`
    h3 {
        text-align: center;
    }

    .outline {
        outline: 1px solid ${props => props.border_color} !important;
    }

    .category {
        cursor: pointer;
        transition: all 0.13s ease;
        height: 70px;
        display: flex;
        align-items: center;
        border-top: ${props => props.borderTop ? `1px solid ${props.border_color}` : 'none'};
        border-bottom: ${props => props.borderBottom ? `1px solid ${props.border_color}` : 'none'};

        h4 {
            font-size: 14px;
        }
    }

    .category.active {
        color: ${props => props.content_font_color};
        background-color: ${props => props.content_color};

        .fa-ellipsis-v {
            color: ${props => props.content_font_color} !important;   
        }

        .small-dot {
            background-color: ${props => props.content_font_color};
        }
    }

    .small-dot {
        position: relative;
        width: 5px;
        height: 5px;
        background-color: ${props => props.site_font_color};
        border-radius: 50%;
        right: 6px;
        bottom: 2px;
    }

    .not-found {
        font-size: 16px;
        font-weight: 600;
    }

    .small-dot {
        position: relative;
        width: 5px;
        height: 5px;
        background-color: ${props => props.site_font_color};
        border-radius: 50%;
        right: 6px;
        bottom: 2px;
    }

    .category.active {
        .small-dot {
            background-color: ${props => props.content_font_color};
        }
    }

    .category.active {
        .fa-ellipsis-v {
            color: ${props => props.content_font_color} !important;   
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 2 columns for small screens and up */
        gap: 1rem; /* Adjust as needed */
    }

    .grid-item.pointer {
        cursor: pointer;
    }

    .react-select__option--is-selected {
        .small-dot {
            background-color: ${props => props.content_font_color} !important;
        }
    }

    @media (max-width: 576px) {
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;

        .grid-container {
            gap: 0.5rem;
        }
    }

    @media (min-width: 992px) {
        .grid-container {
            grid-template-columns: repeat(4, 1fr); /* 4 columns for large screens and up */
        }
    }

    @media (min-width: 1280px) {
        .grid-container {
            grid-template-columns: repeat(5, 1fr); /* 5 columns for extra large screens */
        }
    }
`

const Tabs = styled.div`
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .main-tabs {
        display: flex !important;
        justify-content: center;
    }
    .main-tabs button.btn.btn-primary {
        background-color: ${props => props.content_color} !important;
        color: ${props => props.content_font_color};
        border-color: transparent !important;
    }
    .main-tabs button.btn.btn-outline-primary {
        color: ${props => props.content_color} !important;
        background-color: ${props => props.content_font_color} !important;
        border-color: transparent !important;
        padding: 5px 12px;
    }

    .main-tabs button {
        outline: 1px solid ${props => props.content_color};
    }
    .main-tabs button.btn.btn-primary {
        box-shadow: 0 2px 5px 0 ${props => props.button_shadow_color}, 0 2px 10px 0 ${props => props.button_shadow_color};
        border-color: transparent !important;
    }

    button.btn.mx-0 {
        width: 20%;
    }

    @media(max-width: 991px) {
        .btn-group.main-tabs {
            width: -webkit-fill-available;
        }

        .main-tabs button.btn {
            width: 30%;
        }
    }

    @media(max-width: 767px) {
        button.btn.mx-0 {
            width: 40%;
        }

        .main-tabs {
            flex-direction: column;
        }
        .main-tabs button.btn {
            width: 100%;
        }
    }
`

const CancelButton = styled.button`
    border-color: ${props => props.btnBorderColor} !important;
    color: ${props => props.btnColor} !important;
    box-shadow: none;
    margin-right: 20px;
    padding-x: 50px;
`

const Dropdown = styled.span`
    position: absolute;
    right: -6px;
    top: 10px;
`

const DropdownButton = styled.button`
    box-shadow: none;
    border-radius: 20px !important;
    color: ${props => props.site_font_color};
    &:hover, &:focus {
        box-shadow: none !important;
        border-radius: 20px;
        color: ${props => props.site_font_color};
    }
`

const DropdownActionLink = styled.div`
    cursor: pointer;
    &:hover, &:active, &:focus {
        color: #16181b !important;
    }
`

const DropDownMenu = styled.div`
    right: 9px;
    left: -10px !important;
    top: 35px !important;
    z-index: 12 !important;
`

const SelectCategoryDiv = styled.div`
    inset: 0 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 18px;
`

const StyledDiv = styled.div`
    pointer-events: ${props => props.disableDiv ? 'none' : ''};
`

const MediaCommonContent = (props) => {
    const { handleSubmit } = useForm()
    const [searchQuery, setSearchQuery] = useState('')
    const [width, setWidth] = useState(window.innerWidth)
    const [dropDownIndex, setDropDownIndex] = useState(-1)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [categoryPopupLoading, setCategoryPopupLoading] = useState(false)
    const [isCategoryMediaOpen, setIsCategoryMediaOpen] = useState(false)
    const [categoryName, setCategoryName] = useState('')
    const { content_color, content_font_color, card_header_border_color, site_font_color, button_shadow_color } = props.auth.appSettings
    const { media, category } = props.media

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value)
    }

    const errorHandle = _.isEmpty(props.isError)

    // Filter the category Array
    const filteredCategory = category.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
    )

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
            if (window.innerWidth > 992) {
                setIsCategoryMediaOpen(false)
            }
        })
        if (window.innerWidth > 992 && props.isPopup) {
            props.setShowSelectButton(true)
        }
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', () => setWidth(window.innerWidth))
        }
    }, [])

    const toggleDropdown = (index) => {
        setIsDropdownOpen(true)
        setDropDownIndex(index)

        if (isDropdownOpen === true && dropDownIndex === index) {
            setIsDropdownOpen(false)
        }
    }

    const handleClose = () => {
        props.setShowCategoryPopup(false)
        props.setIsOpenCategory(false)
        props.setNewCategoryId('')
        props.setIsMoveCategory(false)
    }

    const openCategoryMedia = (status) => {
        setIsCategoryMediaOpen(status)
        if (props.isPopup) {
            props.setShowSelectButton(status)
        }
    }

    useEffect(() => {
        if (window.innerWidth >= 992) {
            setIsCategoryMediaOpen(false)
        }
    }, [])

    return (
        <WebsiteFontColor>
            <div className='container'>
                {!props.isPopup && !isCategoryMediaOpen ?
                    <BackButton classes='row m-1 mt-5' link='/admin/reporting' text='Reporting' />
                    :
                    <></>
                }
                <MainContainer
                    content_color={content_color}
                    loading={props.isLoading}
                    content_font_color={content_font_color}
                    border_color={card_header_border_color}
                    site_font_color={site_font_color}
                    id='media-page'
                    isPopup={props.isPopup}
                    className='my-3 my-lg-5'
                    borderTop={width >= 992 ? true : false}
                    borderBottom={width < 992 ? true : false}
                >
                    {(width < 992 && isCategoryMediaOpen) ?
                        <div className='d-flex align-items-center p-0 m-0 mt-5'>
                            <i className='fa fa-chevron-left'
                                onClick={() => openCategoryMedia(false)}
                                aria-hidden='true'>
                            </i>
                            <h4 className='mt-0 mb-0 p-0 ml-3' onClick={() => openCategoryMedia(false)}>
                                {categoryName}
                            </h4>
                        </div>
                        :
                        !props.isPopup &&
                        <h3>Content Manager</h3>
                    }
                    <div className='row'>
                        <div className='col col-12 col-lg-3 p-0'>
                            <div className={isCategoryMediaOpen === false && width >= 992 && classNames('row outline', { 'ml-lg-3': props.isPopup })}>
                                {
                                    isCategoryMediaOpen === false &&
                                    <div className={width < 992 ? 'd-flex px-0' : 'd-flex p-3'}>
                                        <input className='form-control m-0' onChange={handleInputChange} placeholder='Search category' />
                                        {width < 992 && <Button style={{ paddingTop: '4px', paddingBottom: '4px' }} loading={props.isLoading} classes='m-0 ml-3' onClick={() => props.handleAddOrUpdateCategory('add_category')}>
                                            <i className='fas fa-plus'></i>
                                        </Button>}
                                    </div>
                                }

                                {width >= 992 &&
                                    <div className='px-3 pb-3 m-0'>
                                        <Button style={{ paddingTop: '4px', paddingBottom: '4px' }} loading={props.isLoading} classes='float-right m-0' onClick={() => props.handleAddOrUpdateCategory('add_category')}>
                                            <i className='fas fa-plus'></i>&nbsp;Add Category
                                        </Button>
                                    </div>}
                                {props.isCategoryLoading
                                    ?
                                    <div className='d-flex justify-content-center align-items-center my-5 pb-5'>
                                        <Loader loading={props.isLoading} size={10}></Loader>
                                    </div>
                                    :
                                    category.length > 0 && width < 992
                                        ?
                                        isCategoryMediaOpen === false &&
                                        <div className='pb-5' style={{ zIndex: 100 }}>
                                            {
                                                filteredCategory.map((categoryList) => (
                                                    categoryList.is_deleted !== true &&
                                                    <div
                                                        className={classNames('category col-12 p-0')}
                                                        key={categoryList._id}
                                                    >
                                                        <SelectCategoryDiv
                                                            onClick={() => {
                                                                openCategoryMedia(true)
                                                                props.updateSelectedCategory(categoryList._id)
                                                                if (props.media.filter.activeCategory !== categoryList._id) {
                                                                    props.getAllMediaByType(props.activeTab, categoryList._id)
                                                                }
                                                                setIsDropdownOpen(false)
                                                                setCategoryName(categoryList.name)
                                                            }}
                                                            className='w-100 h-100'
                                                        >
                                                            <h4 className='m-0 fw-bold'>{categoryList.name}</h4>
                                                            {props.renderPhotoVideoCounts(categoryList, props.isPopup)}
                                                        </SelectCategoryDiv>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        :
                                        filteredCategory.map((categoryList, index) => (
                                            categoryList.is_deleted !== true &&
                                            <div
                                                className={classNames('category col-12 p-0',
                                                    {
                                                        'active': categoryList._id === props.media.filter.activeCategory,
                                                        'disabled': props.isLoading
                                                    }
                                                )}
                                                key={categoryList._id}
                                            >
                                                <SelectCategoryDiv
                                                    onClick={() => {
                                                        props.updateSelectedCategory(categoryList._id)
                                                        if (props.media.filter.activeCategory !== categoryList._id) {
                                                            props.getAllMediaByType(props.activeTab, categoryList._id)
                                                        }
                                                        setIsDropdownOpen(false)
                                                    }}
                                                    className='w-100 h-100'
                                                >
                                                    <h4 className='m-0 fw-bold'>{categoryList.name}</h4>
                                                    {props.renderPhotoVideoCounts(categoryList, props.isPopup)}
                                                </SelectCategoryDiv>
                                                {(categoryList.name !== 'All Media' && categoryList.name !== 'Preview') &&
                                                    <Dropdown className='dropdown-action'>
                                                        <DropdownButton
                                                            className='btn'
                                                            onClick={() => {
                                                                toggleDropdown(index)
                                                                props.updateSelectedCategory(categoryList._id)
                                                                if (props.media.filter.activeCategory !== categoryList._id) {
                                                                    props.getAllMediaByType(props.activeTab, categoryList._id)
                                                                }
                                                            }}
                                                            site_font_color={site_font_color}
                                                        >
                                                            <i className='fas fa-ellipsis-v'></i>
                                                        </DropdownButton>
                                                        {(isDropdownOpen && index === dropDownIndex) &&
                                                            <DropDownMenu
                                                                className='dropdown-menu show'
                                                                aria-label={index}
                                                            >
                                                                <DropdownActionLink
                                                                    className='dropdown-item'
                                                                    onClick={() => {
                                                                        props.handleAddOrUpdateCategory('update_category', categoryList._id, categoryList.name)
                                                                        setIsDropdownOpen(false)
                                                                    }}
                                                                >
                                                                    Edit
                                                                </DropdownActionLink>
                                                                <DropdownActionLink
                                                                    className='dropdown-item'
                                                                    onClick={() => {
                                                                        props.handleDeleteCategory(categoryList._id, categoryList.name)
                                                                        setIsDropdownOpen(false)
                                                                    }}
                                                                >
                                                                    Delete
                                                                </DropdownActionLink>
                                                            </DropDownMenu>
                                                        }
                                                    </Dropdown>
                                                }
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        {
                            isCategoryMediaOpen && width < 992 ?
                                <div className='col col-12 col-lg-9'>
                                    <div className='row'>
                                        {!props.isPopup && <MediaUpload />}
                                        {/* <div className='px-0 pb-5 px-lg-5'>
                                        <input className='form-control' placeholder='Search media tag' />
                                    </div> */}
                                        <Tabs className='col-12 pb-5'
                                            content_color={content_color}
                                            content_font_color={content_font_color}
                                            button_shadow_color={button_shadow_color}
                                        >
                                            <div className={classNames('btn-group main-tabs d-flex flex-row', { 'mt-5 mt-lg-0': props.isPopup })}>
                                                <button
                                                    type='button'
                                                    disabled={props.isLoading || props.isMediaLoadingOnScroll}
                                                    onClick={() => { props.handleChangeType('all'); setIsDropdownOpen(false) }}
                                                    className={classNames('btn mx-0 mt-0', {
                                                        'btn-primary': props.activeTab === 'all',
                                                        'btn-outline-primary': props.activeTab !== 'all'
                                                    })}
                                                >
                                                    All
                                                </button>
                                                <button
                                                    type='button'
                                                    disabled={props.isLoading || props.isMediaLoadingOnScroll}
                                                    onClick={() => { props.handleChangeType('photo'); setIsDropdownOpen(false) }}
                                                    className={classNames('btn mx-0 mt-0', {
                                                        'btn-primary': props.activeTab === 'photo',
                                                        'btn-outline-primary': props.activeTab !== 'photo'
                                                    })}
                                                >
                                                    Images
                                                </button>
                                                <button
                                                    type='button'
                                                    disabled={props.isLoading || props.isMediaLoadingOnScroll}
                                                    onClick={() => { props.handleChangeType('video'); setIsDropdownOpen(false) }}
                                                    className={classNames('btn mx-0 mt-0', {
                                                        'btn-primary': props.activeTab === 'video',
                                                        'btn-outline-primary': props.activeTab !== 'video'
                                                    })}
                                                >
                                                    Videos
                                                </button>
                                            </div>
                                            <div
                                                className='d-flex justify-content-end'
                                                style={{
                                                    minHeight: '30px',
                                                    marginTop: '15px',
                                                    marginBottom: '-6px'
                                                }}
                                            >
                                                {
                                                    props.selectedIds.length > 0 && errorHandle && <StyledDiv
                                                        onClick={() => props.setShowCategoryPopup(true)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            alignContent: 'center'
                                                        }}
                                                        disableDiv={props.isLoading || props.isMediaLoadingOnScroll}
                                                    >
                                                        <i
                                                            className='fas fa-folder-plus'
                                                            style={{
                                                                fontSize: '24px',
                                                                color: site_font_color,
                                                                paddingRight: '25px'
                                                            }}
                                                        >
                                                        </i>
                                                    </StyledDiv>
                                                }
                                                {
                                                    props.selectedIds.length > 0 && <StyledDiv
                                                        onClick={() => { props.deleteMedia() }}
                                                        style={{
                                                            cursor: 'pointer',
                                                            alignContent: 'center'
                                                        }}
                                                        disableDiv={props.isLoading || props.isMediaLoadingOnScroll}
                                                    >
                                                        <i
                                                            className='fas fa-trash'
                                                            style={{
                                                                fontSize: '24px',
                                                                color: site_font_color,
                                                                paddingRight: '25px'
                                                            }}
                                                        ></i>
                                                    </StyledDiv>
                                                }
                                                <StyledDiv
                                                    onClick={() => {
                                                        props.getAllMediaByType(props.activeTab, props.media.filter.activeCategory, true)
                                                        props.setSelectedIds([])
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        alignContent: 'center'
                                                    }}
                                                    disableDiv={props.isLoading || props.isMediaLoadingOnScroll}
                                                >
                                                    <i
                                                        className='fas fa-redo-alt'
                                                        style={{
                                                            fontSize: '24px',
                                                            color: site_font_color
                                                        }}
                                                    >
                                                    </i>
                                                </StyledDiv>
                                            </div>
                                        </Tabs>

                                        {props.isLoading
                                            ?
                                            <div className='d-flex justify-content-center align-items-center my-5 pb-5'>
                                                <Loader loading={props.isLoading} size={10}></Loader>
                                            </div>
                                            :
                                            <>
                                                {
                                                    media.length === 0 && isCategoryMediaOpen
                                                        ?
                                                        <h4 className='w-100 d-flex justify-content-center align-items-center'>
                                                            No Media Available
                                                        </h4>
                                                        :
                                                        <>
                                                            <div className='grid-container p-0'>
                                                                {media.map(media => <MediaContainer
                                                                    isSuperAdmin={props.auth.isSuperAdmin}
                                                                    key={media._id}
                                                                    media={media}
                                                                    mediaList={props.mediaList}
                                                                    requestFrom={props.isPopup ? 'popup' : 'content-manager'}
                                                                    setMediaList={(mediaId) => props.isPopup ? props.onSelection(mediaId) : null}
                                                                    selectedMedia={props.isPopup ? props.prevSelectedMedia : props.selectedIds}
                                                                />)}
                                                            </div>
                                                            <div className='d-flex justify-content-center align-items-center mt-3'>
                                                                <Loader loading={props.isMediaLoadingOnScroll} size={10} />
                                                            </div>
                                                        </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                :
                                <div className='col col-12 col-lg-9'>
                                    <div className='row'>
                                        {/* <div className='px-0 pb-5 px-lg-5'>
                                            <input className='form-control' placeholder='Search media tag' />
                                        </div> */}
                                        {width >= 992 &&
                                            <>
                                                {!props.isPopup && <MediaUpload />}
                                                <Tabs className='col-12 pb-5'
                                                    content_color={content_color}
                                                    content_font_color={content_font_color}
                                                    button_shadow_color={button_shadow_color}
                                                >
                                                    <div className={classNames('btn-group main-tabs', { 'mt-5 mt-lg-0': props.isPopup })}>
                                                        <button
                                                            type='button'
                                                            disabled={props.isLoading || props.isMediaLoadingOnScroll}
                                                            onClick={() => { props.handleChangeType('all'); setIsDropdownOpen(false) }}
                                                            className={classNames('btn mx-0 mt-0', {
                                                                'btn-primary': props.activeTab === 'all',
                                                                'btn-outline-primary': props.activeTab !== 'all'
                                                            })}
                                                        >
                                                            All
                                                        </button>
                                                        <button
                                                            type='button'
                                                            disabled={props.isLoading || props.isMediaLoadingOnScroll}
                                                            onClick={() => { props.handleChangeType('photo'); setIsDropdownOpen(false) }}
                                                            className={classNames('btn mx-0 mt-0', {
                                                                'btn-primary': props.activeTab === 'photo',
                                                                'btn-outline-primary': props.activeTab !== 'photo'
                                                            })}
                                                        >
                                                            Images
                                                        </button>
                                                        <button
                                                            type='button'
                                                            disabled={props.isLoading || props.isMediaLoadingOnScroll}
                                                            onClick={() => { props.handleChangeType('video'); setIsDropdownOpen(false) }}
                                                            className={classNames('btn mx-0 mt-0', {
                                                                'btn-primary': props.activeTab === 'video',
                                                                'btn-outline-primary': props.activeTab !== 'video'
                                                            })}
                                                        >
                                                            Videos
                                                        </button>
                                                    </div>
                                                    <div
                                                        className='d-flex justify-content-end pr-5 mt-3'
                                                        style={{
                                                            minHeight: '30px'
                                                        }}
                                                    >
                                                        {
                                                            props.selectedIds.length > 0 && errorHandle && <StyledDiv
                                                                onClick={() => props.setShowCategoryPopup(true)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    alignContent: 'center'
                                                                }}
                                                                disableDiv={props.isLoading || props.isMediaLoadingOnScroll}
                                                            >
                                                                <i
                                                                    className='fas fa-folder-plus'
                                                                    style={{
                                                                        fontSize: '24px',
                                                                        color: site_font_color,
                                                                        paddingRight: '25px'
                                                                    }}
                                                                >
                                                                </i>
                                                            </StyledDiv>
                                                        }
                                                        {
                                                            props.selectedIds.length > 0 && <StyledDiv
                                                                onClick={() => { props.deleteMedia() }}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    alignContent: 'center'
                                                                }}
                                                                disableDiv={props.isLoading || props.isMediaLoadingOnScroll}
                                                            >
                                                                <i
                                                                    className='fas fa-trash'
                                                                    style={{
                                                                        fontSize: '24px',
                                                                        color: site_font_color,
                                                                        paddingRight: '25px'
                                                                    }}
                                                                ></i>
                                                            </StyledDiv>
                                                        }
                                                        <StyledDiv
                                                            onClick={() => {
                                                                props.getAllMediaByType(props.activeTab, props.media.filter.activeCategory, true)
                                                                props.setSelectedIds([])
                                                            }}
                                                            style={{
                                                                cursor: 'pointer',
                                                                alignContent: 'center'
                                                            }}
                                                            disableDiv={props.isLoading || props.isMediaLoadingOnScroll}
                                                        >
                                                            <i
                                                                className='fas fa-redo-alt'
                                                                style={{
                                                                    fontSize: '24px',
                                                                    color: site_font_color
                                                                }}
                                                            >
                                                            </i>
                                                        </StyledDiv>
                                                    </div>
                                                </Tabs>
                                            </>
                                        }

                                        {props.isLoading && width > 992
                                            ?
                                            <div className='d-flex justify-content-center align-items-center my-5 pb-5'>
                                                <Loader loading={props.isLoading} size={10}></Loader>
                                            </div>
                                            :
                                            <>
                                                {
                                                    media.length === 0 && width >= 992
                                                        ?
                                                        <h4 className='w-100 d-flex justify-content-center align-items-center'>
                                                            No Media Available
                                                        </h4>
                                                        :
                                                        width >= 992 &&
                                                        <>
                                                            <div className='grid-container px-5'>
                                                                {media.map(media => <MediaContainer
                                                                    isSuperAdmin={props.auth.isSuperAdmin}
                                                                    key={media._id}
                                                                    media={media}
                                                                    mediaList={props.mediaList}
                                                                    requestFrom={props.isPopup ? 'popup' : 'content-manager'}
                                                                    setMediaList={(mediaId) => props.isPopup ? props.onSelection(mediaId) : null}
                                                                    selectedMedia={props.isPopup ? props.prevSelectedMedia : props.selectedIds}
                                                                />)}
                                                            </div>
                                                            <div className='d-flex justify-content-center align-items-center mt-3'>
                                                                <Loader loading={props.isMediaLoadingOnScroll} size={10} />
                                                            </div>
                                                        </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                    {
                        props.showAddCategoryPopup && <ModalPopUp
                            classes='modal-md'
                            title={props.addOrUpdateCategory === 'add_category' ? 'Add Category' : 'Update Category'}
                            showCloseBtn={false}
                            modalBodyMaxHeight='30vh'
                            requestFrom='category'
                        >
                            <form
                                autoComplete='off'
                                onSubmit={handleSubmit(props.handleCategory)}
                            >
                                <div className='form-row'>
                                    <div className='form-group col-12 mb-0'>
                                        <TextFieldGroup
                                            label='Enter category name'
                                            type='text'
                                            name='category'
                                            id='category'
                                            onChange={(e) => {
                                                props.setCategoryName(e.target.value)
                                                props.setErrorText('')
                                            }}
                                            value={props.categoryName}
                                            maxLength={30}
                                            error={props.categoryName.length > 30 ? 'Category name cannot be more than 30 characters' : props.errorText}
                                            disabled={props.isDisableCategory}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-row-reverse'>
                                    <Button
                                        type='submit'
                                        classes='m-0 px-5'
                                        onClick={props.handleCategory}
                                        loading={props.isDisableCategory || (props.categoryName && props.categoryName.length > 30)}
                                    >
                                        {props.isDisableCategory && <span className='spinner-border spinner-border-sm mr-2'></span>}
                                        {props.addOrUpdateCategory === 'add_category' ? 'Add' : 'Update'}
                                    </Button>
                                    <CancelButton
                                        className='btn'
                                        type='button'
                                        btnColor={site_font_color}
                                        btnBorderColor={content_color}
                                        onClick={props.hideCategoryPopup}
                                        disabled={props.isDisableCategory}
                                    >
                                        Cancel
                                    </CancelButton>
                                </div>
                            </form>
                        </ModalPopUp>
                    }
                    {
                        props.showCategoryPopup &&
                        <ModalPopUp
                            classes='modal-md'
                            title='Category'
                            handleClose={handleClose}
                            modalBodyMaxHeight='40vh'
                            closeBtnFontSize='initial'
                            isLoading={categoryPopupLoading}
                        >
                            <MediaCategoryPopup
                                renderPhotoVideoCounts={props.renderPhotoVideoCounts}
                                selectedIds={props.selectedIds}
                                newCategoryId={props.newCategoryId}
                                setNewCategoryId={() => props.setNewCategoryId('')}
                                setShowCategoryPopup={props.setShowCategoryPopup}
                                handleAddOrUpdateCategory={() => props.handleAddOrUpdateCategory('add_category')}
                                setIsMoveCategory={(status) => props.setIsMoveCategory(status)}
                                setSelectedIds={props.setSelectedIds}
                                setIsOpenCategory={(status) => props.setIsOpenCategory(status)}
                                activeCategoryId={props.activeCategory}
                                setCategoryPopupLoading={setCategoryPopupLoading}
                            />
                        </ModalPopUp>
                    }
                </MainContainer>
            </div>
        </WebsiteFontColor>
    )
}

MediaCommonContent.propTypes = {
    auth: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    selectedMedia: PropTypes.array,
    isSuperAdmin: PropTypes.bool,
    requestFrom: PropTypes.string,
    setMediaList: PropTypes.func,
    mediaList: PropTypes.func,
    showAddCategoryPopup: PropTypes.bool,
    isPopup: PropTypes.bool,
    onSelection: PropTypes.func,
    isLoading: PropTypes.bool,
    handleCategory: PropTypes.func.isRequired,
    addOrUpdateCategory: PropTypes.string.isRequired,
    renderPhotoVideoCounts: PropTypes.func,
    isDisableCategory: PropTypes.bool,
    isMediaLoadingOnScroll: PropTypes.bool,
    handleChangeType: PropTypes.func.isRequired,
    handleAddOrUpdateCategory: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
    isCategoryLoading: PropTypes.bool,
    hideCategoryPopup: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    selectedIds: PropTypes.array,
    selectedCategory: PropTypes.object,
    setActiveTab: PropTypes.func.isRequired,
    setNewCategory: PropTypes.func.isRequired,
    categoryId: PropTypes.string.isRequired,
    setCategoryName: PropTypes.func.isRequired,
    setErrorText: PropTypes.func.isRequired,
    categoryName: PropTypes.string,
    setSelectedIds: PropTypes.func.isRequired,
    handleDeleteCategory: PropTypes.func.isRequired,
    setShowMediaDialogue: PropTypes.func,
    showMediaDialogue: PropTypes.bool,
    errorText: PropTypes.string,
    updateSelectedCategory: PropTypes.func.isRequired,
    setIsOpenCategory: PropTypes.func,
    setNewCategoryId: PropTypes.string,
    setIsMoveCategory: PropTypes.func,
    setShowCategoryPopup: PropTypes.bool,
    showCategoryPopup: PropTypes.bool,
    newCategoryId: PropTypes.string,
    prevSelectedMedia: PropTypes.array.isRequired,
    setShowSelectButton: PropTypes.func,
    getAllMediaByType: PropTypes.func.isRequired,
    activeCategory: PropTypes.string.isRequired,
    setIsError: PropTypes.func.isRequired,
    isError: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    promotion: state.promotion,
    media: state.media
})

export default connect(
    mapStateToProps,
    {
        updateSelectedCategory
    }
)(withRouter((MediaCommonContent)))

