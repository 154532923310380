import * as React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import moment from 'moment'
import PropTypes from 'prop-types'
import OverlayContainer from './../Layouts/OverlayStyle'
import BlogGalleryImage from './../Layouts/BlogGalleryImages'
import FullScreenModelPopUpDialog from './../Layouts/FullScreenModelPopUpDialog'
import BlogMedia from './BlogMedia'
import ReviewBlog from './ReviewBlog'
import _ from 'lodash'
import { connect } from 'react-redux'
import { addOrDeleteFeedLike } from '../../actions/blogActions'
import ModalPopUp from '../Layouts/ModalPopUp'
import FeedTagPopup from './FeedTagPopup'
import AddFeedTagDialog from '../app-settings/FeedTagAddDialog'
import { getCloudFrontAssetsUrl } from '../../utils/assets'

const useStyles = makeStyles(() => ({
    colorBackgr: {
        display: 'grid',
        height: '250px',
        backgroundColor: '0xff0000',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lockBackgr: {
        position: 'relative'
    },
    lockAsideImg: {
        position: 'absolute',
        top: 'calc(50% - 25px)',
        left: 'calc(15% - 25px)',
        width: '50px',
        height: '50px'
    },
    lockTopImg: {
        // position: 'absolute',
        // top: `calc(50% - 25px)`,
        // left: `calc(50% - 25px)`,
        width: '50px',
        height: '50px',
        padding: '5px',
        borderRadius: '100px',
        WebkitMaskSize: '50px 40px',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        backgroundColor: '#e91e63',
        mask: `url(${getCloudFrontAssetsUrl('blog/lock.svg')})`,
        WebkitMask: `url(${getCloudFrontAssetsUrl('blog/lock.svg')})`,
        margin: 'auto'
    },
    dollarTopImg: {
        width: '50px',
        height: '50px',
        padding: '5px',
        borderRadius: '100px'
    },
    lockTxt: {
        position: 'absolute',
        top: 'calc(50% + 30px)',
        left: 'calc(50% - 100px)',
        textAlign: 'center'
    },
    container: {
        position: 'relative',
        color: 'white'
    },
    centered: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textDecoration: 'none',
        marginTop: '40px',
        color: '#000000'
    },
    lockBackgrNewGallery: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}))

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '767px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1850px',
    desktopL: '2560px'
}

const BlurContentText = styled.div`
    top: calc(50% - 42px);
    left: 0;
    right: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    div:nth-child(1) {
        width: fit-content;
        width: -moz-fit-content;
        min-width: 50px;
        background-color: ${props => props.lockIconColor};
    }
`

const LockedContentText = styled.div`
    position: absolute;
    text-align: center;
    width: 100%;
    top: calc(50% - 25px);
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
`

const UnlockButtonDiv = styled.div`
    bottom: ${props => props.marginBottom};
    left: 0;
    right: 0;
    position: absolute;
    text-align: center;
    width: 90%;
    margin: auto;

    button {
        font-size: ${props => props.buttonFontSize[3]} !important;
    }
    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        button {
            font-size: ${props => props.buttonFontSize[0]} !important;
        }
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        button {
            font-size: ${props => props.buttonFontSize[1]} !important;
        }
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        button {
            font-size: ${props => props.buttonFontSize[2]} !important;
        }
    }
`

const DropdownButton = styled.button`
    box-shadow: none;
    border-radius: 20px !important;

    &:hover {
        box-shadow: none;
        background-color: #c7c7c7;
        border-radius: 20px;
    }
`

const CardColor = styled.div`
    background-color: ${props => props.cardColor} !important;

    .card-header {
        border-bottom: 1px solid ${props => props.sitesFontColor};
    }
`

const IconColor = styled.i`
    color: ${props => props.sitesFontColor};
`

const BlurTitle = styled.h4`
    color: transparent;
    text-shadow: 0 0 ${props => props.postCaptionBlurIntensity}px ${props => props.sitesFontColor};
`

const NormalImages = styled.img`
    cursor: pointer;
    max-height: 80vh;
    min-width: 100%;
    object-fit: contain;
`

const VideoIcon = styled.div`
    position: absolute;
    top: calc(48.5%);
    left: calc(47.5%);
    cursor: pointer;

    .video-button-span {
        font-size: 30px;
        color: rgb(211, 211, 211);
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
    }

    .play-icon {
        font-size: 30px;
        position: absolute;
        border-radius: 51px;
        left: -5px;
        top: -5px;
    }
`

const BlogContent = styled.div`
    padding: 0 1.5rem;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        padding: 0;
        height: auto;
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        padding: 0;
        height: auto;
    }
`

const GalleryMediaCounts = styled.div`
    font-size: 16px;
    color: #000000;
    text-align: center;
    position: ${props => props.position};
    ${props => props.topBottomPosition};
    ${props => props.leftRightPosition};
    background-color: transparent !important;

    > span { 
        background-color: #ddddddaa;
        border-radius: 5px;
        padding: 0 5px;
        display: inline-block;
    }

    span > span {
        margin-right: 5px;
    }

    i {
        margin: ${props => props.mt} 2px 7px 7px;
    }
`

const BlogMediaInformation = styled.div`
    padding: 5px 0;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.laptop}) {
        padding: 5px 10px;
    }
`

const LikeAndCommentIcons = styled.div`
    @media (min-width: ${size.mobileS}) and (max-width: ${size.laptop}) {
        margin-top: 0.75rem !important;
    }
`

function BlogCard({
    id,
    format,
    lock,
    locked,
    blur,
    description,
    index,
    avatarUrl,
    authorName,
    date,
    blogType,
    contentURL = '',
    openDlgBlog,
    thumbnailUrl,
    deletePost,
    isAdmin,
    isAuthenticated,
    processing,
    openEditBlogDialog,
    blurUrl,
    isBlurLoading,
    refreshBlurImage,
    isModel,
    role,
    rotateImageLeft,
    rotateImageRight,
    openDropdown,
    closeDropdown,
    isDropdownOpen,
    dropdownIndex,
    isLocked,
    amount,
    unlockContent,
    isPaymentProcessing,
    contentColor,
    contentFontColor,
    createVideoProcessingRequest,
    isPostLocked,
    enableWatermark,
    userId,
    cardBackgroundColor,
    siteFontColor,
    captionBlur,
    postCaptionBlurIntensity,
    userMembership,
    galleryImages,
    blurGalleryImages,
    error,
    openReuseBlogDialogBox,
    lockIconColor,
    subscribeText,
    promotionPercentage,
    lineThroughColor,
    content_length,
    unlockButtonFontSize,
    thumbnailUrlGallery,
    contentLeftForProcessing,
    isPreviewEnable,
    contentCount,
    show_content_length,
    blogSubType,
    is_published,
    gallery_process_error_index,
    showTimestamp,
    media,
    media_preview,
    auth,
    history,
    total_comments,
    total_likes,
    user_feed_like,
    addOrDeleteFeedLike,
    feed_tags,
    onGetBlogs
}) {
    const getVideoLength = (length) => {
        if (length < 60) {
            return `The video is ${length} seconds.`
        }
        let minutes = Math.floor(length / 60)
        let seconds = length - minutes * 60
        if (minutes === 1) {
            if (seconds === 0 || seconds === 1) {
                return `The video is ${minutes} minute ${seconds} second.`
            } else {
                return `The video is ${minutes} minute ${seconds} seconds.`
            }
        } else {
            if (seconds === 0 || seconds === 1) {
                return `The video is ${minutes} minutes ${seconds} second.`
            } else {
                return `The video is ${minutes} minutes ${seconds} seconds.`
            }
        }
    }

    const [isDialogOpen, setIsDiaLogOpen] = React.useState(false)
    const [isReviewBlog, setIsReviewBlog] = React.useState(false)
    const [url, setUrl] = React.useState('')
    const [type, setType] = React.useState('')
    const [isFeedCommentEnabled, setIsFeedCommentEnabled] = React.useState(false)
    const [isFeedLikeEnabled, setIsFeedLikeEnabled] = React.useState(false)
    const { is_feed_comment_enabled, comment_visibility, is_feed_like_enabled, like_visibility, feed_tags_enable, show_media_count_in_exclusive_content, font_family, enable_like_count_for_private_locked_post, enable_comment_count_for_private_locked_post } = auth.appSettings
    const [showFeedTag, setShowFeedTag] = React.useState(false)
    const [feedTag, setFeedTag] = React.useState({
        name: '',
        slug: ''
    })
    const [blogId, setBlogId] = React.useState('')
    const [showAddNewTag, setShowAddNewTag] = React.useState(false)
    const [addTagStatus, setAddTagStatus] = React.useState(false)
    const [enableLike, setEnableLike] = React.useState(true)

    React.useEffect(() => {
        if (is_feed_comment_enabled === true) {
            checkFeedCommentAndLikeIsEnabled(is_feed_comment_enabled, comment_visibility, setIsFeedCommentEnabled)
        }

        if (is_feed_like_enabled === true) {
            checkFeedCommentAndLikeIsEnabled(is_feed_like_enabled, like_visibility, setIsFeedLikeEnabled)
        }

    }, [auth.isAuthenticated])

    const checkFeedCommentAndLikeIsEnabled = (is_setting_enabled, visibility, update_setting) => {
        const is_user_login = _.get(auth, 'isAuthenticated', false)
        const ccbillSubscriptionStatus = _.get(auth, 'user.ccbillSubscriptionStatus', '0')
        const registered_and_subscribers = ['0', '1', '2']
        const subscribers = ['1', '2']

        if (error !== true && processing === false && is_setting_enabled === true) {
            if (is_user_login === false) {
                // if user not
                if (visibility === 'everyone') {
                    update_setting(true)
                } else {
                    update_setting(false)
                }
            } else {
                // if user login
                if (visibility === 'everyone') {
                    update_setting(true)
                }

                if (visibility === 'registered_and_subscribers' && registered_and_subscribers.includes(ccbillSubscriptionStatus)) {
                    update_setting(true)
                }

                if (visibility === 'subscribers' && subscribers.includes(ccbillSubscriptionStatus)) {
                    update_setting(true)
                }

                if (isAdmin === true) {
                    update_setting(true)
                }
            }
        } else {
            update_setting(false)
        }
    }

    const onReviewBlogModalOpen = () => {
        document.querySelector('body').style.overflow = 'hidden'
        setIsReviewBlog(true)
    }

    const onReviewBlogModalClose = () => {
        document.querySelector('body').style.overflow = 'visible'
        setIsReviewBlog(false)
    }

    const openFullScreenDlgBlog = (media) => {
        setUrl(media.url)
        setType(media.content_type)
        setIsDiaLogOpen(!isDialogOpen)
    }

    // Set gallery index for rotating image or refreshing blur image
    const [galleryImageIndex, setGalleryImageIndex] = React.useState(0)
    const classes = useStyles()
    let showBlurCaption = captionBlur

    const getDiscountPrice = (amount) => {
        return Math.ceil(amount * (100 - promotionPercentage) / 100)
    }

    const getGalleryPhotoAndVideoCount = (isLocked = false, position = 'absolute') => {
        const photo = _.get(contentCount, 'photo', 0)
        const video = _.get(contentCount, 'video', 0)

        let photoCount = photo
        let videoCount = video
        if ((role === 'user' || role === undefined || role === 'proxy_user') && !show_media_count_in_exclusive_content) {
            photoCount = ''
            videoCount = ''
        }

        const { watermark_position } = auth.appSettings
        const topBottomPosition = (watermark_position === 'bottom-left' || watermark_position === 'bottom-right' || isLocked === true) ? 'top: 5px;' : 'bottom: 5px;'
        let mediaLeftRightPosition
        if (position === 'absolute') {
            mediaLeftRightPosition = (isLocked === true) ? 'right: auto; top: auto; bottom: 60px; left: 50%; transform: translateX(-50%);' : 'left: 0px; right: 0px; margin: 5px auto 0px;'
        } else {
            mediaLeftRightPosition = 'margin: 0px auto;'
        }

        return <GalleryMediaCounts contentColor={contentColor} mt={font_family === 'Myriad Pro Regular' ? '7px' : '8px'} fontColor={contentFontColor} topBottomPosition={topBottomPosition} leftRightPosition={mediaLeftRightPosition} position={position}>
            <span style={{ fontSize: isLocked ? '1.1rem' : '' }}>
                {photo > 0 && video > 0 &&
                    <><i className='fas fa-image' /> {photoCount} | <i className='fas fa-video' /> <span>{videoCount}</span></>
                }
                {photo > 0 && video === 0 && <><i className='fas fa-image' /> <span>{photoCount}</span></>}
                {photo === 0 && video > 0 && <><i className='fas fa-video' /> <span>{videoCount}</span></>}
            </span>
        </GalleryMediaCounts>
    }

    if (isAdmin === true || userMembership === true) {
        showBlurCaption = false
    }

    let blogContent
    let redirectUrl = isAuthenticated ? '/membership' : '/register'
    let enableComment = false

    let image

    let subscribe_text = subscribeText ? subscribeText : 'Please Subscribe to See Content'

    const getPreviewButton = (data, isPaymentProcessing, contentColor, contentFontColor) => {
        return <button className='btn btn-block btn-sm mt-3 px-1' onClick={() => openFullScreenDlgBlog(data)} disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
            PREVIEW
        </button>
    }

    if (enableWatermark) {
        image = <OverlayContainer userId={userId}>
            <NormalImages className='mb-2' src={contentURL} alt='' draggable={false} onClick={() => openDlgBlog(index)} />
        </OverlayContainer>
    } else {
        image = <NormalImages className='mb-2' src={contentURL} alt='' draggable={false} onClick={() => openDlgBlog(index)} />
    }

    let video
    if (enableWatermark) {
        video = <OverlayContainer userId={userId}>
            <video key={contentURL} poster={thumbnailUrl} style={{ maxHeight: '80vh', maxWidth: '100%' }} onClick={() => openDlgBlog(index)}>
                <source src={contentURL}></source>
            </video>
            <VideoIcon>
                <span className='video-button-span'>
                    <i className='fas fa-play-circle play-icon' />
                </span>
            </VideoIcon>
        </OverlayContainer>
    } else {
        video = <>
            <video key={contentURL} poster={thumbnailUrl} style={{ maxHeight: '80vh', maxWidth: '100%' }} onClick={() => openDlgBlog(index)}>
                <source src={contentURL}></source>
            </video>
            <VideoIcon>
                <span className='video-button-span'>
                    <i className='fas fa-play-circle play-icon' />
                </span>
            </VideoIcon>
        </>
    }

    if (error === true) {
        if (blogType === 'video') {
            blogContent =
                <div style={{ textAlign: 'center' }}>
                    Error: There was a problem with this video. Please try again.
                    <button className='btn btn-block' onClick={onReviewBlogModalOpen} style={{ backgroundColor: contentColor, color: contentFontColor }}>Review And Post</button>
                </div>
        } else {
            blogContent =
                <div style={{ textAlign: 'center' }}>
                    Error: There is problem in processing one or multiple content.
                    <button className='btn btn-block' onClick={onReviewBlogModalOpen} style={{ backgroundColor: contentColor, color: contentFontColor }}>Review And Post</button>
                </div>
        }
    } else if (processing === true) {
        blogContent =
            <div style={{ textAlign: 'center', padding: '5px' }}>
                This post is under process.
                <br />
                It will be visible once processing is completed.
            </div>
    } else if (lock) {
        if (blur) {
            if (blurUrl) {
                blogContent = <Link className={classes.lockBackgr} to={redirectUrl} style={{ display: 'block' }}>
                    <div className='text-center' style={{ overflow: 'hidden', width: '100%', minWidth: '245px' }}>
                        <img key={id} draggable={false} src={blurUrl} alt='' style={{ maxHeight: '80vh' }} />
                    </div>
                    <BlurContentText
                        lockIconColor={lockIconColor}
                    >
                        <div style={{ backgroundColor: 'white', width: 'fit-content', margin: 'auto', borderRadius: '25px' }}>
                            <div draggable={false} className={classes.lockTopImg}></div>
                        </div>
                        <div className='mt-3' style={{ color: '#000000', textShadow: '#fff 1px 0 10px' }}>{subscribe_text}</div>
                        {getGalleryPhotoAndVideoCount(true, 'relative')}
                    </BlurContentText>
                </Link>
            } else {
                blogContent = <Link className={classes.lockBackgr} to={redirectUrl} style={{ display: 'block' }}>
                    <div className='text-center' style={{ overflow: 'hidden', width: '100%', minWidth: '245px' }}>
                        <img key={id} draggable={false} src={blogType === 'photo' ? contentURL : thumbnailUrl} style={{ filter: 'blur(15px)', maxHeight: '80vh' }} alt='' />
                    </div>
                    <BlurContentText
                        lockIconColor={lockIconColor}
                    >
                        <div style={{ backgroundColor: 'white', width: 'fit-content', margin: 'auto', borderRadius: '25px' }}>
                            <div draggable={false} className={classes.lockTopImg}></div>
                        </div>
                        <div className='mt-3' style={{ color: '#000000', textShadow: '#fff 1px 0 10px' }}>{subscribe_text}</div>
                        {getGalleryPhotoAndVideoCount(true, 'relative')}
                    </BlurContentText>
                </Link>
            }
        } else {
            blogContent = <Link className={classes.lockBackgr} to={redirectUrl} style={{ display: 'block' }}>
                <div className='card-img-top' style={{ width: '100%', minWidth: '245px' }}>
                    <img key={id} draggable={false} className='card-img-top' src={getCloudFrontAssetsUrl('blog/lockback.jpg')} alt='Lock Background' style={{ height: 'calc(60vh - 20px)' }} />
                </div>
                <BlurContentText
                    lockIconColor={lockIconColor}
                >
                    <div style={{ backgroundColor: 'white', width: 'fit-content', margin: 'auto', borderRadius: '25px' }}>
                        <div draggable={false} className={classes.lockTopImg}></div>
                    </div>
                    <div className='mt-3' style={{ color: '#000000' }}>{subscribe_text}</div>
                    <div className='position-relative'>
                        {getGalleryPhotoAndVideoCount(true, 'relative')}
                    </div>
                </BlurContentText>
            </Link>
        }
    } else if (isLocked) {
        blogContent =
            <div className={classes.lockBackgr} style={{ display: 'block' }}>
                <div className='text-center' style={{ overflow: 'hidden', paddingBottom: '10px', position: 'relative' }}>
                    {blur ?
                        <img key={id} draggable={false} src={blurUrl} alt='' style={{ maxHeight: '80vh' }} />
                        :
                        <img key={id} draggable={false} className='card-img-top' src={getCloudFrontAssetsUrl('blog/lockback.jpg')} alt='Lock Background' />
                    }
                    <UnlockButtonDiv marginBottom={'20px'} buttonFontSize={unlockButtonFontSize}>
                        {isPaymentProcessing ?
                            <button className='btn btn-block btn-sm' disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
                                Payment Processing
                            </button> :
                            <>
                                {promotionPercentage === 0 ?
                                    <button className='btn btn-block' onClick={() => unlockContent(id, amount, blogType, description, false)} disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
                                        UNLOCK THIS {blogType} FOR ${amount}
                                    </button> :
                                    <button className='btn btn-block px-1' onClick={() => unlockContent(id, getDiscountPrice(amount), blogType, description, true)} disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
                                        UNLOCK THIS {blogType} FOR <span style={{ textDecoration: 'line-through', textDecorationColor: lineThroughColor, textDecorationThickness: '2px' }}>${amount}</span> ${getDiscountPrice(amount)}
                                    </button>
                                }
                            </>}
                    </UnlockButtonDiv>
                    {!blur && isPreviewEnable === false &&
                        <LockedContentText>
                            <i className='fas fa-dollar-sign fa-3x' style={{ color: lockIconColor }}></i>
                        </LockedContentText>
                    }
                </div>
                {content_length !== undefined && content_length !== 0 && show_content_length === true &&
                    <>
                        <span className='text-center' style={{ fontSize: '0.9em' }}>
                            {getVideoLength(content_length)}
                        </span>
                        <br />
                    </>
                }
                {promotionPercentage === 0 ?
                    <span className='text-center' style={{ fontSize: '0.9em' }}>
                        {auth.user.default_payment_method === 'crypto_currency' ?
                            <>
                                ${amount} will be debited from wallet balance.
                            </>
                            :
                            <>
                                ${amount} will be charged to the payment method you used previously on the website.
                            </>
                        }
                    </span>
                    :
                    <span className='text-center' style={{ fontSize: '0.9em' }}>
                        {auth.user.default_payment_method === 'crypto_currency' ?
                            <>
                                ${getDiscountPrice(amount)} will be debited from wallet balance.
                            </>
                            :
                            <>
                                ${getDiscountPrice(amount)} will be charged to the payment method you used previously on the website.
                            </>
                        }
                    </span>

                }
            </div>
    } else {
        blogContent =
            <div className='text-center'>
                {blogType === 'photo' ?
                    image
                    : blogType === 'video' ?
                        video
                        : null
                }
            </div>
    }

    if (error !== true && blogType === 'gallery' && processing !== true) {
        if (lock) {
            if (blur) {
                blogContent = <Link className={classes.lockBackgr} to={redirectUrl} style={{ display: 'block' }}>
                    <div className='text-center' style={{ overflow: 'hidden', width: '100%', minWidth: '245px' }}>
                        <BlogGalleryImage is_published={is_published} gallery_process_error_index={gallery_process_error_index} openDlgBlog={openDlgBlog} isPreviewEnable={isPreviewEnable} contentLeftForProcessing={contentLeftForProcessing} galleryImages={blurGalleryImages} thumbnailUrlGallery={thumbnailUrlGallery} isLocked={lock} setGalleryImageIndex={setGalleryImageIndex} i={index} />
                    </div>
                    <BlurContentText
                        lockIconColor={lockIconColor}
                    >
                        <div style={{ backgroundColor: 'white', width: 'fit-content', margin: 'auto', borderRadius: '25px' }}>
                            <div draggable={false} className={classes.lockTopImg}></div>
                        </div>
                        <div className='mt-3' style={{ color: '#000000', textShadow: '#fff 1px 0 10px' }}>{subscribe_text}</div>
                        {getGalleryPhotoAndVideoCount(true, 'relative')}
                    </BlurContentText>
                </Link>
            }
        } else if (isLocked) {
            blogContent =
                <div className={classes.lockBackgr} style={{ display: 'block' }}>
                    <div className='text-center' style={{ overflow: 'hidden', position: 'relative' }}>
                        {blur ?
                            <BlogGalleryImage is_published={is_published} gallery_process_error_index={gallery_process_error_index} openDlgBlog={openDlgBlog} isPreviewEnable={isPreviewEnable} contentLeftForProcessing={contentLeftForProcessing} galleryImages={blurGalleryImages} thumbnailUrlGallery={thumbnailUrlGallery} isLocked={isLocked} i={index} setGalleryImageIndex={setGalleryImageIndex} />
                            :
                            <img key={id} draggable={false} className='card-img-top' src={getCloudFrontAssetsUrl('blog/lockback.jpg')} alt='Lock Background' />
                        }
                        <UnlockButtonDiv marginBottom='10px' buttonFontSize={unlockButtonFontSize}>
                            {isPaymentProcessing ?
                                <button className='btn btn-block btn-sm' disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
                                    Payment Processing
                                </button> :
                                <>
                                    {promotionPercentage === 0 ?
                                        <button className='btn btn-block btn-sm' onClick={() => unlockContent(id, amount, blogSubType, description, false)} disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
                                            UNLOCK THIS {blogType} FOR ${amount} <br />
                                        </button> :
                                        <button className='btn btn-block btn-sm px-1' onClick={() => unlockContent(id, getDiscountPrice(amount), blogSubType, description, true)} disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
                                            UNLOCK THIS {blogType} FOR <span style={{ textDecoration: 'line-through', textDecorationColor: lineThroughColor, textDecorationThickness: '2px' }}>${amount}</span> ${getDiscountPrice(amount)} <br />
                                        </button>
                                    }
                                    {isDialogOpen === true &&
                                        <FullScreenModelPopUpDialog
                                            url={url}
                                            handleClose={() => { openFullScreenDlgBlog('') }}
                                            type={type}
                                        />
                                    }
                                </>}
                        </UnlockButtonDiv>
                        {!blur && isPreviewEnable === false &&
                            <LockedContentText>
                                <i className='fas fa-dollar-sign fa-3x' style={{ color: lockIconColor }}></i>
                            </LockedContentText>
                        }
                    </div>
                    {getGalleryPhotoAndVideoCount(true)}
                    {promotionPercentage === 0 ?
                        <span className='d-flex' style={{ fontSize: '0.9em' }}>
                            {auth.user.default_payment_method === 'crypto_currency' ?
                                <>
                                    ${amount} will be debited from wallet balance.
                                </>
                                :
                                <>
                                    ${amount} will be charged to the payment method you used previously on the website.
                                </>
                            }
                        </span>
                        :
                        <span className='d-flex' style={{ fontSize: '0.9em' }}>
                            {auth.user.default_payment_method === 'crypto_currency' ?
                                <>
                                    ${getDiscountPrice(amount)} will be debited from wallet balance.
                                </>
                                :
                                <>
                                    ${getDiscountPrice(amount)} will be charged to the payment method you used previously on the website.
                                </>
                            }
                        </span>
                    }
                </div>
        } else {
            if (contentLeftForProcessing > 0) {
                blogContent = <BlogGalleryImage is_published={is_published} gallery_process_error_index={gallery_process_error_index} contentLeftForProcessing={contentLeftForProcessing} galleryImages={galleryImages} thumbnailUrlGallery={thumbnailUrlGallery} isLocked={false} openDlgBlog={openDlgBlog} isPreviewEnable={isPreviewEnable} i={index} setGalleryImageIndex={setGalleryImageIndex} />
            } else {
                blogContent = <OverlayContainer userId={userId}>
                    <BlogGalleryImage is_published={is_published} gallery_process_error_index={gallery_process_error_index} contentLeftForProcessing={contentLeftForProcessing} galleryImages={galleryImages} thumbnailUrlGallery={thumbnailUrlGallery} isLocked={false} openDlgBlog={openDlgBlog} isPreviewEnable={isPreviewEnable} i={index} setGalleryImageIndex={setGalleryImageIndex} />
                    {getGalleryPhotoAndVideoCount(false)}
                </OverlayContainer>
            }
        }
    }

    // New Structure for Blog Gallery UI
    if (error !== true && processing !== true) {
        if (lock) {
            if (blur) {
                blogContent = <Link className={classes.lockBackgrNewGallery} to={redirectUrl} style={{ textDecoration: 'none' }}>
                    <div className='text-center' style={{ overflow: 'hidden', width: '100%', minWidth: '245px' }}>
                        <BlogMedia
                            media={media}
                            isLocked={lock}
                            setCurrentMediaIndex={setGalleryImageIndex}
                            isPreviewEnable={isPreviewEnable}
                            is_published={is_published}
                            openDlgBlog={openDlgBlog}
                            i={index}
                            contentLeftForProcessing={contentLeftForProcessing}
                        />
                    </div>
                    <BlurContentText
                        lockIconColor={lockIconColor}
                    >
                        <div style={{ backgroundColor: 'white', width: 'fit-content', margin: 'auto', borderRadius: '25px' }}>
                            <div draggable={false} className={classes.lockTopImg}></div>
                        </div>
                        <div className='mt-3' style={{ color: '#000000', textShadow: '#fff 1px 0 10px' }}>{subscribe_text}</div>
                        <div className='position-relative'>
                            {getGalleryPhotoAndVideoCount(true, 'relative')}
                        </div>
                    </BlurContentText>
                    {media_preview !== undefined && media_preview.length > 0 &&
                        getPreviewButton(media_preview[0], isPaymentProcessing, contentColor, contentFontColor)
                    }
                </Link>
            }
        } else if (isLocked) {
            blogContent = <div className={classes.lockBackgrNewGallery}>
                <div className='text-center' style={{ overflow: 'hidden', position: 'relative' }}>
                    {blur ?
                        <BlogMedia
                            media={media}
                            isLocked={isLocked}
                            setCurrentMediaIndex={setGalleryImageIndex}
                            isPreviewEnable={isPreviewEnable}
                            is_published={is_published}
                            openDlgBlog={openDlgBlog}
                            i={index}
                            contentLeftForProcessing={contentLeftForProcessing}
                        />
                        :
                        <img key={id} draggable={false} className='card-img-top' src={getCloudFrontAssetsUrl('blog/lockback.jpg')} alt='Lock Background' style={{ height: 'calc(60vh - 20px)' }} />
                    }
                    <UnlockButtonDiv marginBottom='10px' buttonFontSize={unlockButtonFontSize}>
                        {isPaymentProcessing ?
                            <button className='btn btn-block btn-sm' disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
                                Payment Processing
                            </button> :
                            <>
                                {promotionPercentage === 0 ?
                                    <button className='btn btn-block btn-sm' onClick={() => unlockContent(id, amount, blogSubType, description, false)} disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
                                        UNLOCK THIS {blogType} FOR ${amount} <br />
                                    </button> :
                                    <button className='btn btn-block btn-sm px-1' onClick={() => unlockContent(id, getDiscountPrice(amount), blogSubType, description, true)} disabled={isPaymentProcessing} style={{ backgroundColor: contentColor, color: contentFontColor }}>
                                        UNLOCK THIS {blogType} FOR <span style={{ textDecoration: 'line-through', textDecorationColor: lineThroughColor, textDecorationThickness: '2px' }}>${amount}</span> ${getDiscountPrice(amount)} <br />
                                    </button>
                                }
                                <div className='position-relative'>
                                    {getGalleryPhotoAndVideoCount(true)}
                                </div>
                                {media_preview !== undefined && media_preview.length > 0 &&
                                    getPreviewButton(media_preview[0], isPaymentProcessing, contentColor, contentFontColor)
                                }
                                {isDialogOpen === true &&
                                    <FullScreenModelPopUpDialog
                                        url={url}
                                        handleClose={() => { openFullScreenDlgBlog('') }}
                                        type={type}
                                    />
                                }
                            </>}
                    </UnlockButtonDiv>
                    {!blur && isPreviewEnable === false &&
                        <LockedContentText>
                            <i className='fas fa-dollar-sign fa-3x' style={{ color: lockIconColor }}></i>
                        </LockedContentText>
                    }
                </div>
                <BlogMediaInformation>
                    {(show_content_length === true && blogType === 'video') &&
                        <span className='mb-2 d-flex' style={{ fontSize: '0.9em', padding: '0' }}>
                            {getVideoLength(content_length)}
                        </span>
                    }
                    {promotionPercentage === 0 ?
                        <span className='d-flex' style={{ fontSize: '0.9em', padding: '0' }}>
                            {auth.user.default_payment_method === 'crypto_currency' ?
                                <>
                                    ${amount} will be debited from wallet balance.
                                </>
                                :
                                <>
                                    ${amount} will be charged to the payment method you used previously on the website.
                                </>
                            }
                        </span>
                        :
                        <span className='d-flex' style={{ fontSize: '0.9em', padding: '0' }}>
                            {auth.user.default_payment_method === 'crypto_currency' ?
                                <>
                                    ${getDiscountPrice(amount)} will be debited from wallet balance.
                                </>
                                :
                                <>
                                    ${getDiscountPrice(amount)} will be charged to the payment method you used previously on the website.
                                </>
                            }
                        </span>
                    }
                </BlogMediaInformation>
            </div>
        } else {
            if (contentLeftForProcessing > 0) {
                blogContent = <BlogMedia
                    media={media}
                    isLocked={false}
                    setCurrentMediaIndex={setGalleryImageIndex}
                    isPreviewEnable={isPreviewEnable}
                    is_published={is_published}
                    openDlgBlog={openDlgBlog}
                    i={index}
                    contentLeftForProcessing={contentLeftForProcessing}
                />
            } else {
                blogContent = <div className='mb-2'>
                    <OverlayContainer userId={userId}>
                        <BlogMedia
                            media={media}
                            isLocked={false}
                            setCurrentMediaIndex={setGalleryImageIndex}
                            isPreviewEnable={isPreviewEnable}
                            is_published={is_published}
                            openDlgBlog={openDlgBlog}
                            i={index}
                            contentLeftForProcessing={contentLeftForProcessing}
                        />
                        {getGalleryPhotoAndVideoCount(false)}
                    </OverlayContainer>
                    {media_preview !== undefined && media_preview.length > 0 &&
                        getPreviewButton(media_preview[0], isPaymentProcessing, contentColor, contentFontColor)
                    }
                </div>
                enableComment = true
            }
        }
    }

    const galleryAmount = (promotionPercentage, amount, lineThroughColor) => {
        return <div className='d-block ml-1' style={{ fontSize: '15px' }}>
            {promotionPercentage === 0 ?
                <span> ${amount} </span>
                :
                <>
                    <span style={{ textDecoration: 'line-through', textDecorationColor: lineThroughColor, textDecorationThickness: '2px' }}>
                        ${amount}
                    </span> ${getDiscountPrice(amount)}
                </>
            }
        </div>
    }

    const goToPostDetailPage = (enableComment) => {
        const ccbillSubscriptionStatus = _.get(auth, 'user.ccbillSubscriptionStatus', '0')
        if ((ccbillSubscriptionStatus === '1' || ccbillSubscriptionStatus === '2') && enableComment === false) {
            return false
        }

        enableComment === true ? history.push(`/feed/${id}`) : history.push(redirectUrl)
    }

    const openFeedTagPopup = (id) => {
        setBlogId(id)
        setShowFeedTag(true)
    }

    const likeOrDislikeFeed = async () => {
        if (auth.isAuthenticated === false) {
            return false
        }

        const ccbillSubscriptionStatus = _.get(auth, 'user.ccbillSubscriptionStatus', '0')
        if ((ccbillSubscriptionStatus === '1' || ccbillSubscriptionStatus === '2') && enableComment === false) {
            return false
        }

        if (enableComment === false) {
            history.push(redirectUrl)
            return
        }

        const userLike = user_feed_like === 0 ? 1 : 0
        let likeCount = total_likes
        likeCount = user_feed_like === 0 ? likeCount + 1 : likeCount - 1
        let data
        if (auth.user.role === 'proxy_user') {
            setEnableLike(false)
            data = {}
        } else {
            data = {
                id: id,
                is_like: userLike,
                total_likes: likeCount
            }
        }
        await addOrDeleteFeedLike(data)
        setEnableLike(true)
    }

    const openDropDownMenu = (index) => (
        openDropdown(index)
    )
    const onShowFeedTag = () => {
        setShowFeedTag(false)
        onGetBlogs()
        closeDropdown()
    }

    const AddNewTag = (tag) => {
        setShowAddNewTag(true)
        setFeedTag(tag)
        setShowFeedTag(false)
    }

    const hideAddNewTagDialog = () => {
        setShowAddNewTag(false)
        setShowFeedTag(true)
    }

    const addNewTag = () => {
        setAddTagStatus(true)
    }

    return (
        <div className='col-12 col-lg-4 mb-5' id={`feed_${id}`}>
            <CardColor className='card h-100' cardColor={cardBackgroundColor} sitesFontColor={siteFontColor}>
                <div className={'card-body pb-0'}>
                    <div style={{ paddingTop: '0px !important', display: 'flex', alignItems: 'center' }}>
                        <img
                            src={avatarUrl}
                            className='img-fluid'
                            alt=''
                            style={{ height: '50px', borderRadius: '25px' }}
                            draggable={false}
                        />
                        <span className='pl-2 w-auto'>
                            {authorName}<br />
                            {!isAdmin && showTimestamp === false ? '' : `${moment(date).fromNow()}`}
                        </span>
                        {isAdmin && isAuthenticated ? <div className='row ml-auto'>
                            {isPostLocked ? <button className='btn w-auto d-flex align-items-center' style={{ boxShadow: 'none' }} disabled={true}><IconColor className='fas fa-dollar-sign' sitesFontColor={siteFontColor}></IconColor></button> :
                                locked ? <button className='btn w-auto' style={{ boxShadow: 'none' }} disabled={true}><IconColor className='fas fa-lock' sitesFontColor={siteFontColor}></IconColor></button> :
                                    <button className='btn w-auto' style={{ boxShadow: 'none' }} disabled={true}><IconColor className='fas fa-globe-americas' sitesFontColor={siteFontColor}></IconColor></button>
                            }
                            {role === 'live_stream_manager' ?
                                <></>
                                :
                                <div className='dropdown w-auto' id={index}>
                                    {isDropdownOpen && index === dropdownIndex ? <DropdownButton className='btn' onClick={() => closeDropdown()}>
                                        <IconColor className='fas fa-chevron-up' sitesFontColor={siteFontColor}></IconColor>
                                    </DropdownButton> : <DropdownButton className='btn' onClick={() => openDropDownMenu(index)}>
                                        <IconColor className='fas fa-chevron-down' sitesFontColor={siteFontColor}></IconColor>
                                    </DropdownButton>}
                                    {isDropdownOpen && index === dropdownIndex ?
                                        isBlurLoading ? <div className=' dropdown-menu show text-center' aria-labelledby={index} style={{ right: '0', left: 'auto' }}>
                                            <div className='spinner-border' role='status' style={{ color: '#000000' }}>
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                        </div> :
                                            <div className='dropdown-menu show' aria-labelledby={index} style={{ right: '0', left: 'auto', zIndex: '1' }}>
                                                {error !== true && processing === false && <>
                                                    <button className='dropdown-item' style={{ boxShadow: 'none' }} onClick={() => openEditBlogDialog(id, description, captionBlur, isPostLocked, locked, media_preview, media, feed_tags)}>
                                                        <i className='fas fa-edit'></i>
                                                        &nbsp;&nbsp;&nbsp; Edit Post
                                                    </button>
                                                    {feed_tags_enable &&
                                                        <button className='dropdown-item' style={{ boxShadow: 'none' }} onClick={() => openFeedTagPopup(id)}>
                                                            <i className='fas fa-plus'></i>
                                                            &nbsp;&nbsp;&nbsp; Add / remove Tags
                                                        </button>
                                                    }
                                                    {isModel === false && role !== 'content_manager' && blur ?
                                                        <button className='dropdown-item' onClick={() => refreshBlurImage(id, format)} disabled={isBlurLoading}>
                                                            <i className='fas fa-sync'></i>
                                                            &nbsp;&nbsp;&nbsp; Refresh Blur Image
                                                        </button> : null}
                                                    {((blogType === 'photo' || blogType === 'gallery') && media[galleryImageIndex].content_type === 'photo') ?
                                                        <button className='dropdown-item' onClick={() => rotateImageLeft(id, galleryImageIndex, format)} disabled={isBlurLoading}>
                                                            <i className='fas fa-undo'></i>
                                                            &nbsp;&nbsp;&nbsp; Rotate Image Left
                                                        </button> :
                                                        null}
                                                    {((blogType === 'photo' || blogType === 'gallery') && media[galleryImageIndex].content_type === 'photo') ?
                                                        <button className='dropdown-item' onClick={() => rotateImageRight(id, galleryImageIndex, format)} disabled={isBlurLoading}>
                                                            <i className='fas fa-redo-alt'></i>
                                                            &nbsp;&nbsp;&nbsp; Rotate Image Right
                                                        </button> :
                                                        null}
                                                    {isPostLocked === true ?
                                                        <button className='dropdown-item' style={{ boxShadow: 'none' }} onClick={() => openReuseBlogDialogBox(id)}>
                                                            <i className='fas fa-paper-plane'></i>
                                                            &nbsp;&nbsp;&nbsp; Reuse Post
                                                        </button>
                                                        : null
                                                    }
                                                </>
                                                }
                                                <button className='dropdown-item' style={{ boxShadow: 'none' }} onClick={() => {
                                                    deletePost(id)
                                                }}>
                                                    <i className='fas fa-trash'></i>
                                                    &nbsp;&nbsp;&nbsp; Delete Post
                                                </button>
                                                {isModel === false && role !== 'content_manager' && blogType === 'video' && processing === true ?
                                                    <button className='dropdown-item' onClick={() => createVideoProcessingRequest(id, blur, contentURL)} disabled={isBlurLoading}>
                                                        <i className='fas fa-sync'></i>
                                                        &nbsp;&nbsp;&nbsp; Send Video Process Request
                                                    </button> : null}
                                            </div> : null}
                                </div>}
                        </div> : <></>}
                    </div>
                    <div style={{ paddingTop: '20px' }}>
                        {showBlurCaption ?
                            <BlurTitle sitesFontColor={siteFontColor} postCaptionBlurIntensity={postCaptionBlurIntensity}>
                                {description}
                            </BlurTitle>
                            :
                            <h4>{description}</h4>
                        }
                        {isAdmin && isAuthenticated && isPostLocked && role !== 'live_stream_manager' && galleryAmount(promotionPercentage, amount, lineThroughColor)}
                    </div>
                </div>
                {showFeedTag === true &&
                    <ModalPopUp classes='modal-md pb-0' title='SELECT TAGS' showCloseBtn={true} handleClose={() => setShowFeedTag(false)} fromChatList={true} closeBtnFontSize='25px' modalHeight='38em' modalBodyMaxHeight='30vh'>
                        <FeedTagPopup
                            isAddTag={true}
                            onShowFeedTag={onShowFeedTag}
                            showAddNewTag={AddNewTag}
                            newTagStatus={addTagStatus}
                            feedTags={feed_tags}
                            blogId={blogId}>
                        </FeedTagPopup>
                    </ModalPopUp>
                }
                {
                    showAddNewTag === true &&
                    <AddFeedTagDialog
                        isAddNewTag={feedTag.id ? false : true}
                        blogId={blogId}
                        addNewTag={addNewTag}
                        feedTagList={hideAddNewTagDialog}
                        feedTags={feedTag}
                        hideAddNewTag={hideAddNewTagDialog}
                    ></AddFeedTagDialog>
                }
                <BlogContent>{blogContent}</BlogContent>
                {role === 'live_stream_manager' ?
                    <></>
                    :
                    (isFeedCommentEnabled === true || isFeedLikeEnabled === true) &&
                    <LikeAndCommentIcons className='card-body py-0 mb-2 d-flex'>
                        {isFeedLikeEnabled === true &&
                            <span className='d-flex align-items-center mr-3'
                                style={{
                                    cursor: 'pointer',
                                    pointerEvents: enableLike === false ? 'none' : '',
                                    opacity: enableLike === false ? '0.4' : '1.0'
                                }}
                                onClick={() => likeOrDislikeFeed()}>
                                {(isAuthenticated === false || user_feed_like === 0) &&
                                    <IconColor
                                        className='fas fa-light fa-heart mr-2'
                                        aria-hidden="true"
                                        sitesFontColor={siteFontColor}
                                        style={{
                                            fontSize: '22px',
                                            fontWeight: 'normal',
                                            opacity: enableComment === false ? '0.4' : '1'
                                        }}
                                    ></IconColor>
                                }
                                {user_feed_like !== 0 &&
                                    <IconColor
                                        className='fas fa-solid fa-heart mr-2'
                                        sitesFontColor={siteFontColor}
                                        style={{
                                            fontSize: '22px'
                                        }}
                                    ></IconColor>
                                }
                                {(enable_like_count_for_private_locked_post || enableComment)
                                    ? `${total_likes || 0} Like${(total_likes === 1) ? '' : 's'}`
                                    : ''}
                            </span>
                        }
                        {isFeedCommentEnabled === true &&
                            <span onClick={() => goToPostDetailPage(enableComment)}
                                style={{
                                    cursor: 'pointer'
                                }} className='d-flex align-items-center'>
                                <IconColor
                                    className='fas fa-light fa-comment mr-2'
                                    sitesFontColor={siteFontColor}
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: 'normal',
                                        opacity: enableComment === false ? '0.4' : '1'
                                    }}
                                ></IconColor> {(enable_comment_count_for_private_locked_post || enableComment)
                                    ? `${total_comments || 0} Comment${(total_comments === 1) ? '' : 's'}`
                                    : ''}
                            </span>
                        }
                    </LikeAndCommentIcons>
                }
            </CardColor >
            {
                isReviewBlog ?
                    <ReviewBlog
                        blog_id={id}
                        onClose={onReviewBlogModalClose}
                    />
                    : null
            }
            {
                isDialogOpen === true &&
                <FullScreenModelPopUpDialog
                    url={url}
                    handleClose={() => { openFullScreenDlgBlog('') }}
                    type={type}
                />
            }
        </div >

    )
}

BlogCard.propTypes = {
    auth: PropTypes.object.isRequired,
    id: PropTypes.string,
    lock: PropTypes.bool,
    locked: PropTypes.bool,
    blur: PropTypes.bool,
    description: PropTypes.string,
    index: PropTypes.number,
    avatarUrl: PropTypes.string,
    imgAlt: PropTypes.string,
    authorName: PropTypes.string,
    date: PropTypes.string,
    blogType: PropTypes.string,
    contentURL: PropTypes.string,
    profileHref: PropTypes.string,
    openDlgBlog: PropTypes.func.isRequired,
    thumbnailUrl: PropTypes.string,
    deletePost: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    processing: PropTypes.bool,
    openEditBlogDialog: PropTypes.func.isRequired,
    blurUrl: PropTypes.string,
    isBlurLoading: PropTypes.bool,
    refreshBlurImage: PropTypes.func,
    isModel: PropTypes.bool,
    role: PropTypes.string,
    rotateImageLeft: PropTypes.func,
    rotateImageRight: PropTypes.func,
    openDropdown: PropTypes.func,
    closeDropdown: PropTypes.func,
    isDropdownOpen: PropTypes.bool,
    dropdownIndex: PropTypes.number,
    isLocked: PropTypes.bool,
    amount: PropTypes.string,
    unlockContent: PropTypes.func,
    isPaymentProcessing: PropTypes.bool,
    contentColor: PropTypes.string,
    contentFontColor: PropTypes.string,
    createVideoProcessingRequest: PropTypes.func,
    isPostLocked: PropTypes.bool,
    enableWatermark: PropTypes.bool,
    userId: PropTypes.string,
    cardBackgroundColor: PropTypes.string,
    siteFontColor: PropTypes.string,
    captionBlur: PropTypes.bool,
    postCaptionBlurIntensity: PropTypes.number,
    userMembership: PropTypes.bool,
    galleryImages: PropTypes.array,
    blurGalleryImages: PropTypes.array,
    error: PropTypes.bool,
    openReuseBlogDialogBox: PropTypes.func,
    lockIconColor: PropTypes.string,
    subscribeText: PropTypes.string,
    promotionPercentage: PropTypes.number.isRequired,
    lineThroughColor: PropTypes.string,
    content_length: PropTypes.number,
    unlockButtonFontSize: PropTypes.array,
    thumbnailUrlGallery: PropTypes.array,
    contentCount: PropTypes.object,
    contentLeftForProcessing: PropTypes.number,
    isPreviewEnable: PropTypes.bool,
    previewEnableImages: PropTypes.array,
    show_content_length: PropTypes.bool,
    blogSubType: PropTypes.string,
    is_published: PropTypes.bool,
    gallery_process_error_index: PropTypes.array,
    showTimestamp: PropTypes.bool,
    media: PropTypes.array,
    media_preview: PropTypes.array,
    history: PropTypes.object.isRequired,
    total_comments: PropTypes.number,
    total_likes: PropTypes.number,
    addOrDeleteFeedLike: PropTypes.func.isRequired,
    user_feed_like: PropTypes.number,
    feed_tags: PropTypes.array,
    onGetBlogs: PropTypes.func,
    format: PropTypes.string
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps,
    { addOrDeleteFeedLike }
)(withRouter(BlogCard))
