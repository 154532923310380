import {
    PAYMENT_PROCESSING,
    PAYMENT_COMPLETED,
    BLOG_UNLOCK_PAYMENT_PROCESSING,
    BLOG_UNLOCK_PAYMENT_COMPLETED
} from '../actions/types'

const initialState = {
    isLoading: false,
    isBlogPaymentLoading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYMENT_PROCESSING:
            return {
                ...state,
                isLoading: true
            }
        case PAYMENT_COMPLETED:
            return {
                ...state,
                isLoading: false
            }
        case BLOG_UNLOCK_PAYMENT_PROCESSING:
            return {
                ...state,
                isBlogPaymentLoading: true
            }
        case BLOG_UNLOCK_PAYMENT_COMPLETED:
            return {
                ...state,
                isBlogPaymentLoading: false
            }
        default:
            return state
    }
}
