import { OPEN_COPY_TO_CLIPBOARD_TOAST, CLOSE_COPY_TO_CLIPBOARD_TOAST } from '../actions/types'

const initialState = {
    isCopyToClipboardToastOpen: false,
    toastType: 'copy'
}

export default function (state = initialState, action) {
    switch (action.type) {
        case OPEN_COPY_TO_CLIPBOARD_TOAST:
            return {
                ...state,
                isCopyToClipboardToastOpen: true,
                toastType: action.payload
            }
        case CLOSE_COPY_TO_CLIPBOARD_TOAST:
            return {
                ...state,
                isCopyToClipboardToastOpen: false
            }
        default:
            return state
    }
}
