import {
    SET_PUSH_NOTIFICATION_PROMPT,
    ONE_SIGNAL_INITIALIZED
} from '../actions/types'

const initialState = {
    setNotificationPrompt: false,
    isOneSignalInitialized: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case SET_PUSH_NOTIFICATION_PROMPT:
            return {
                ...state,
                setNotificationPrompt: action.payload
            }
        case ONE_SIGNAL_INITIALIZED:
            return {
                ...state,
                isOneSignalInitialized: action.payload
            }
        default:
            return state
    }
}
