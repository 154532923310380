import axios from 'axios'
import { getAppBaseUrl } from './api'
import _ from 'lodash'
import { UPDATE_USER_SUBSCRIPTION_STATUS, UPDATE_USER_REBILL_FAILED_STATUS, UPDATE_USER_SUBSCRIPTION_PAYMENT_GATEWAY } from './../actions/types'

const BASE_URL = getAppBaseUrl()

/**
 * @description New user add card and subscription.
 * @param {object} data user address and card details.
 * @param {function} callback success or fail messages.
 */
export const newUserSubscription = (data, callback) => () => {
    const url = BASE_URL + '/api/ccbill/subscribe_user'

    axios.post(url, data)
        .then(res => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', error.response.data.errors.generalMessage)
            callback(errorMessage)
        })
}

/**
 * @description update user's subscription status client side
 * @returns {object} client side update user subscription status type
 */
export const updateUserSubscriptionStatus = () => {
    return {
        type: UPDATE_USER_SUBSCRIPTION_STATUS
    }
}

/**
 * @description If user already added card then subscribe using that card
 * @param {object} data subscription data for card
 * @param {function} callback success or fail messages.
 */
export const userSubscriptionWithCard = (data, callback) => () => {
    const url = BASE_URL + '/api/ccbill/subscribe-with-card'

    axios.post(url, data)
        .then(res => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.errors.generalMessage', error.response.data.message)
            callback(errorMessage)
        })
}

/**
 * @description get country name from IP address.
 * @param {function} callback country name.
 */
export const getCountryFromIP = (callback) => () => {
    const url = BASE_URL + '/api/ccbill/get-country-from-ip'

    axios.get(url)
        .then(res => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.message', error.response.data.message)
            callback(errorMessage)
        })
}

/**
 * @description If user already added card then subscribe using that card
 * @param {function} callback success or fail messages.
 */
export const userResubscriptionWithCard = (data, callback) => () => {
    const url = BASE_URL + '/api/ccbill/resubscribe-with-card'

    axios.post(url, data)
        .then(res => {
            callback(res.data)
        }).catch((error) => {
            const errorMessage = _.get(error, 'response.data.errors.generalMessage', error.response.data.message)
            callback(errorMessage)
        })
}

/**
 * @description update user's subscription status client side
 * @returns {object} client side update user subscription status type
 */
export const updateRebillFailedStatus = () => {
    return {
        type: UPDATE_USER_REBILL_FAILED_STATUS
    }
}

/**
 * @description update user's subscription payment gateway
 * @returns {object} client side update user subscription status type
 */
export const updateUserSubscriptionPaymentGateway = (data) => {
    return {
        type: UPDATE_USER_SUBSCRIPTION_PAYMENT_GATEWAY,
        payload: data
    }
}