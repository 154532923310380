const CLOUDFRONT_URL = 'https://d3cn8z1d1ca676.cloudfront.net/'

const getCloudFrontAssetsUrl = (s3_assets_path) => {
    return `${CLOUDFRONT_URL}${s3_assets_path}`
}

const socialMediaSvgIcons = () => {
    return {
        instagram: 'social-icons/svg/instagram.svg',
        tiktok: 'social-icons/svg/tiktok.svg',
        www: 'social-icons/svg/www-2.svg',
        twitter: 'social-icons/svg/twitter.svg',
        twitch: 'social-icons/svg/twitch.svg',
        discord: 'social-icons/svg/discord.svg',
        facebook: 'social-icons/svg/facebook.svg',
        snapchat: 'social-icons/svg/snapchat.svg',
        youtube: 'social-icons/svg/youtube.svg',
        amazon: 'social-icons/svg/amazon.svg',
        brush_stroke: 'social-icons/svg/brush-stroke.svg'
    }
}

export {
    getCloudFrontAssetsUrl,
    socialMediaSvgIcons
}
