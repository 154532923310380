import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Button
} from '@material-ui/core'
import PropTypes from 'prop-types'
import WebsiteFontColor from './WebsiteFontColor'
import AlarmIcon from '@material-ui/icons/Alarm'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import CreatableSelect from 'react-select/creatable'
import { getBlogDataById, postReuseBlogWithNewDesign, getAllBlogs, setLoading } from '../../actions/blogActions'
import moment from 'moment'
import _ from 'lodash'
import { isIOS } from 'react-device-detect'
import { setSweetAlert } from './../../actions/sweetAlertActions'
import { getFileExtension, sortArrayById } from '../../utils/common'
import FullScreenModelPopUpDialog from './FullScreenModelPopUpDialog'
import HelperModelForPreview from '../gallery/HelperModelForPreview'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import MediaSelectionPopup from '../media/MediaSelectionPopup'

const DatePickerDiv = styled.div`
    .react-datepicker__input-time-container .react-datepicker-time__input-container
    .react-datepicker-time__input input {
        width: auto;
    }
    .react-datepicker-wrapper {
        width:100%
    }
    .react-datepicker-input {
        text-align: center
    }
`

const HelperButton = styled.button`
border-radius: 25px;
border: none;
font-size: 20px;
vertical-align: middle;
`
const MarkAsPreviewButton = styled.div`
    color:${props => props.site_font_color} !important;
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: ${props => props.site_font_color} !important;
    }
    .custom-control-input:checked~.custom-control-label::before {
        border-color: ${props => props.site_font_color} !important;
    }
`

const DropZoneSection = styled.section`
    width: 100%;
    height: fit-content;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 12px;
    border-color: ${props => props.site_font_color};
`

const PlusButton = styled.label`
    background-color: ${props => props.content_color};
    color: ${props => props.content_font_color};
    height: 100%;
    border-radius: 5px;
    cursor: ${props => props.disabled === false ? 'pointer' : 'inherit'};
    display: flex;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
`

const ColumnDiv = styled.div`
    width: 100%;
    margin-bottom: ${props => props.isUploading === true ? '50px' : '20px'};
    
    .positioning {
        display: none !important;
    }

    .form-group {
        position: absolute;
        bottom: -24px;
    }

    .progress-span {
        position: absolute;
        bottom: -44px;
        width: 88%;
    }
    .progress {
        border-radius: 5px;
    }

    .progress-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media(max-width: 767px) {
        .positioning {
            display: inline-block !important;
            width: 20%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }

        .positioning i {
            color: ${props => props.site_font_color};
            border-radius: 50%;
            width: 29px;
            height: 29px;
        }
    }
`

const CloseButton = styled.button`
    right: 5px; 
    top: 0;
    position: absolute; 
    border-radius: 82%; 
    width: 24px;
    background-color: ${props => props.content_color} !important; 
    color: ${props => props.content_font_color} !important;
    cursor: pointer;

    :before {
        font-size: medium;
    }

    @media(max-width: 767px) {
        right: ${props => props.isRearrangeModeEnable === true ? '20%' : '13%'};
    }
`

const VideoDiv = styled.div`
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 5px;

    video{
        object-fit: cover;
        border-radius: 5px;
    }
    
    .progress {
        border-radius: 5px;
    }

    @media (min-width:991px) and (max-width:1280px) {

        video{
            max-width: 153px;
        }
    }
    @media (min-width:767px) and (max-width:991px) {

        video{
            max-width: 183px;
        }
    }
    
    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block;
        video{
            max-width: 100%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }
    }
`

const OverLayDiv = styled.div`
    width: 100%;
    height: ${props => props.type === 'video' ? '150px' : '100%'};
    max-width: ${props => props.type === 'video' ? '210px' : '100%'};
    position: absolute;
    background-color: #FFFFFF4F;
    z-index: 4;
    padding: 0 20px;
    border-radius: 5px;
    display: ${props => props.isRearrangeModeEnable === true ? 'block' : 'none'};
    left: ${props => props.type === 'video' ? '3px' : '0px'};

    @media (min-width:991px) and (max-width:1280px) {
        left: ${props => props.type === 'video' ? '5px' : '0px'};
        max-width: ${props => props.type === 'video' ? '153px' : '100%'};
    }
    @media (min-width:767px) and (max-width:991px) {
        left: ${props => props.type === 'video' ? '5px' : '0px'};
        max-width: ${props => props.type === 'video' ? '183px' : '100%'};        
    }
    @media (max-width:767px) {
        left: 0px !important;
        max-width: 100% !important;
    }
`

const GalleryIcon = styled.div`
    position: absolute;
    top: calc(45.5%);
    left: calc(47.5%);
    cursor: pointer;

    .video-button-span {
        font-size: 30px;
        color: rgb(211, 211, 211);
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
    }

    .play-icon {
        font-size: 30px;
        position: absolute;
        border-radius: 51px;
        left: -5px;
        top: -5px;
    }
`

const DivData = styled.div`
    background-image: ${props => props.imageUrl};
    height:150px;
    background-position:center;
    background-repeat:no-repeat;
    background-size: cover;
    background-color: #000000;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    width: inherit;

    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block !important;
        margin-bottom: 1%;
    }
`

const ReorderButton = styled.div`
    color:${props => props.site_font_color} !important;
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: ${props => props.content_color} !important;
    }
    .custom-control-input:checked~.custom-control-label::before {
        border-color: ${props => props.content_color} !important;
    }
`
function NewReusePost(props) {
    const appSettings = props.auth.appSettings
    const [description, updateDescription] = useState('')
    const [isLoaded, updateIsLoaded] = useState(false)
    const [captionBlur, updateCaptionBlur] = useState(false)
    const [isSchedule, updateIsSchedule] = useState(false)
    const [amount, updateAmount] = useState('0')
    const [privateBlur, updatePrivateBlur] = useState(false)
    const [schedulePostTime, updateSchedulePostTime] = useState(new Date().setDate(new Date().getDate() + 1))
    const [schedulePostHour, updateSchedulePostHour] = useState((new Date().getHours() + 24) % 12 || 12)
    const [schedulePostMin, updateSchedulePostMin] = useState(new Date().getMinutes())
    const [schedulePostTimeFormat, updateSchedulePostTimeFormat] = useState(new Date().getHours() > 12 ? 'PM' : 'AM')
    const [isReuse, updateIsReuse] = useState(false)
    const [helperModelOpen, updateHelperModelOpen] = useState(false)
    const [media, setMedia] = useState([])
    const [mediaPreview, setMediaPreview] = useState([])
    const [isRearrangeModeEnable, setIsRearrangeModeEnable] = useState(false)
    const [dragId, setDragId] = useState('')
    const [isPreviewModeEnable, setIsPreviewModeEnable] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [type, setType] = useState('')
    const [selectedPreviewIndex, setSelectedPreviewIndex] = useState('')
    const [keepSameDateForBlog, setKeepSameDateForBlog] = useState(false)
    const [blogDate, setBlogDate] = useState('')
    const [helperPreviewModelOpen, setHelperPreviewModelOpen] = useState(false)
    const [feedTags, setFeedTag] = useState([])
    const [feedTagErrorText, setFeedTagErrorText] = useState('')
    const [showMediaPreviewSelectionPopup, setShowMediaPreviewSelectionPopup] = useState(false)
    const [showMediaSelectionPopup, setShowMediaSelectionPopup] = useState(false)
    const [selectedMediaIds, setSelectedMediaIds] = useState([])
    const [selectedPreviewMediaIds, setSelectedPreviewMediaIds] = useState([])

    useEffect(() => {
        props.getBlogDataById(props.editBlogId, (response) => {
            if (response) {
                const blog = response.data
                updateDescription(blog.description)
                updateCaptionBlur(blog.captionBlur)
                updateAmount(blog.amount)
                updatePrivateBlur(blog.privateBlur)
                setMedia(blog.media)
                setSelectedMediaIds(blog.media.filter(item => item._id).map(item => item._id))
                setBlogDate(blog.date)
                const media_preview = _.get(blog, 'media_preview', [])
                setMediaPreview(media_preview)
                if (media_preview.length > 0) {
                    setIsPreviewModeEnable(true)
                }
                const feed_tags = _.get(blog, 'feed_tags', undefined)
                if (feed_tags !== undefined) {
                    let tags = []
                    for (let tag of feed_tags) {
                        let tagValue = appSettings.feed_tags.find(arr => arr._id === tag.id)
                        if (tagValue) {
                            let label = tagValue.name
                            let value = tagValue._id
                            tags.push({ label, value })
                        }
                    }
                    setFeedTag(tags)
                }
                if (new Date() < new Date(blog.date)) {
                    updateIsSchedule(true)
                    updateSchedulePostTime(new Date(blog.date))
                    updateSchedulePostHour((new Date(blog.date).getHours() + 24) % 12 || 12)
                    updateSchedulePostMin(new Date(blog.date).getMinutes())
                    updateSchedulePostTimeFormat(new Date(blog.date).getHours() > 12 ? 'PM' : 'AM')
                }
            } else {
                alert('Something went wrong')
                props.handleReuseBlogDialogClose()
            }
            updateIsLoaded(true)
        })
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        updateIsReuse(true)
        let amountInt = parseInt(amount, 10)
        let tipsMinimumAmount = parseInt(appSettings.tips_minimum_amount, 10)
        let tipsMaximumAmount = parseInt(appSettings.tips_maximum_amount, 10)

        if (!((tipsMinimumAmount <= amountInt) && (amountInt <= tipsMaximumAmount))) {
            alert(`Choose an amount between ${tipsMinimumAmount} and ${tipsMaximumAmount}`)
            updateIsReuse(false)
            return
        }

        if (media.length === 0) {
            updateIsReuse(false)
            return props.setSweetAlert({ description: 'Please Select Media' })
        }

        for (let index = 0; index < media.length; index++) {
            const element = media[index]
            if (element.is_new_file === true) {
                let fileExtension = getFileExtension(element.selectedFile.name)
                fileExtension = fileExtension.toUpperCase()
                let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG', 'MP4', 'MOV']
                if (!acceptedFileExtensions.includes(fileExtension)) {
                    updateIsReuse(false)
                    return props.setSweetAlert({ description: `Image Number ${index + 1} media format is not supported.` })
                }
            }
        }

        for (let index = 0; index < mediaPreview.length; index++) {
            const element = mediaPreview[index]
            if (element.is_new_file === true && element.is_selected_from_original_file !== true) {
                let fileExtension = getFileExtension(element.selectedFile.name)
                fileExtension = fileExtension.toUpperCase()
                let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG', 'MP4', 'MOV']
                if (!acceptedFileExtensions.includes(fileExtension)) {
                    updateIsReuse(false)
                    return props.setSweetAlert({ description: 'Preview media format is not supported.' })
                }
            }
        }

        let schedulePostTimes = new Date()
        if (isSchedule) {
            schedulePostTimes = new Date(schedulePostTime)
            let hourWithFormat = schedulePostHour + '' + schedulePostTimeFormat
            let hour = moment(hourWithFormat, ['HHA']).format('HH')
            schedulePostTimes.setHours(hour)
            schedulePostTimes.setMinutes(schedulePostMin)
        } else if (keepSameDateForBlog === true) {
            schedulePostTimes = blogDate
        }

        let galleryArray = [...media]
        const newFilesArray = galleryArray.filter((file) => file.is_new_file === true)

        let mediaPreviewArray = [...mediaPreview]
        const previewFilesArray = mediaPreviewArray.filter((file) => file.is_new_file === true)

        let object = {
            _id: props.editBlogId,
            description: description,
            privateBlur: privateBlur,
            captionBlur: captionBlur,
            amount: amount,
            date: schedulePostTimes,
            contentLeftForProcessing: newFilesArray.length + previewFilesArray.length,
            media: [],
            media_preview: [],
            feed_tags: feedTags,
            media_item: []
        }

        for (let index = 0; index < mediaPreview.length; index++) {
            const element = mediaPreview[index]
            if (element.is_new_file !== true || element.is_selected_from_original_file === true) {
                object.media_preview_item = element._id
                object.media_preview.push({ _id: element._id, content_type: element.content_type, url: element.url })
            } else {
                object.media_preview_item = element.selectedFile._id
                object.media_preview.push({ _id: element.selectedFile._id, content_type: element.selectedFile.type.includes('image') ? 'photo' : 'video', url: element.selectedFile.path })
            }
        }

        for (let index = 0; index < media.length; index++) {
            const element = media[index]
            if (element.is_new_file === true) {
                object.media_item.push(element.selectedFile._id)
                object.media.push({ _id: element.selectedFile._id, content_type: element.selectedFile.type.includes('image') ? 'photo' : 'video', url: element.selectedFile.path })
            } else {
                object.media_item.push(element._id)
                object.media.push({ _id: element._id, content_type: element.content_type, url: element.url })
            }
        }

        props.postReuseBlogWithNewDesign(object, (response) => {
            if (response.success === 1) {
                props.setLoading(true)
                props.handleReuseBlogDialogClose()
                const requestFrom = _.get(props, 'requestFrom', '')
                if (requestFrom === 'lockedExclusive') {
                    props.refreshData(response.data.reuseBlog.udid)
                }
                return props.setSweetAlert({ description: response.message })
            }
            let message = _.get(response, 'response.data.reuseBlog.message', 'There was a problem in reuse post.')
            updateIsReuse(false)
            return alert(message)
        })
    }

    let hourArray = new Array(12).fill(0)
    let minArray = new Array(60).fill(0)

    const handleDeletePhoto = (index, name) => {
        setSelectedPreviewIndex('')
        if (name === 'preview') {
            setMediaPreview([])
            setSelectedPreviewMediaIds([])
        } else {
            const copyMedia = [...media]
            const updatedFilesArray = [
                ...copyMedia.slice(0, index),
                ...copyMedia.slice(index + 1)
            ]
            setMedia(updatedFilesArray)
            const selectedMedia = updatedFilesArray.filter(item => {
            if (item) {
                if (item._id) {
                    return true
                } else if (item.selectedFile && item.selectedFile._id) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }).map(item => item._id || item.selectedFile._id)
            setSelectedMediaIds(selectedMedia)
        }
    }

    const handleDrag = (index) => {
        if (isRearrangeModeEnable === true) {
            setDragId(index)
        }
    }

    const handleDrop = (index) => {
        if (isRearrangeModeEnable === true && dragId !== '') {
            const copyNewFiles = [...media]
            const dragFile = copyNewFiles[dragId]
            copyNewFiles.splice(dragId, 1)
            copyNewFiles.splice(index, 0, dragFile)
            setMedia(copyNewFiles)
            setDragId('')
        }
        setDragId('')
    }

    const galleryFiles = (files, deletedIdsIndex = []) => {
        const existFilesCount = media.length
        const uploadFileCount = 45 - existFilesCount < files.length ? 45 - existFilesCount : files.length
        const totalFilesCount = files.length + existFilesCount
        for (let index = 0; index < uploadFileCount; index++) {
            const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
            if (!acceptExtension.includes(files[index].type)) {
                return props.setSweetAlert({ description: 'Media format is not supported.' })
            }
        }

        let newUploadedFiles = []
        for (let index = 0; index < uploadFileCount; index++) {
            if (files[index]) {
                const newFileName = files[index].name
                const newFileSize = files[index].size

                let findRecord = _.findIndex(media, function (n) {
                    return (n.is_new_file === true && n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                })
                if (findRecord === -1) {
                    let newFile = {
                        selectedFile: files[index],
                        renderFile: files[index].format === 'modern' ? (files[index].type.includes('video') ? files[index].path : files[index].small_thumb) : URL.createObjectURL(files[index]),
                        is_new_file: true
                    }
                    newUploadedFiles.push(newFile)
                }
            }
        }

        let copyNewFiles = [...media]
        if (deletedIdsIndex.length > 0) {
            // Delete unselected files/media
            copyNewFiles = copyNewFiles.filter((item, i) => !deletedIdsIndex.includes(i))
        }
        const existingMediaIds = media.filter(item => item._id).map(item => item._id)
        // Filter duplicate files/media
        newUploadedFiles = newUploadedFiles.filter(item => !existingMediaIds.includes(item.selectedFile._id))
        if (newUploadedFiles.length > 0) {
            copyNewFiles = copyNewFiles.concat(newUploadedFiles)
            setMedia(copyNewFiles)
        } else {
            setMedia(copyNewFiles)
        }

        if (totalFilesCount > 45) {
            props.setSweetAlert({ description: `You have selected ${totalFilesCount} images, maximum 45 images allowed.` })
        }
    }

    const previewFiles = (files) => {
        if (files.length > 1) {
            return props.setSweetAlert({ description: 'You can not add more than one preview.' })
        }
        const file = files[0]
        const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
        if (!acceptExtension.includes(file.type)) {
            return props.setSweetAlert({ description: 'Media format is not supported.' })
        }

        if (file) {
            let newFile = {
                selectedFile: file,
                renderFile: file.format === 'modern' ? (file.type.includes('video') ? file.path : file.small_thumb) : URL.createObjectURL(file),
                is_new_file: true
            }
            setMediaPreview([newFile])
        }
    }

    const getPlusButton = (name) => {
        return <>
            <div className='col-12 col-md-4 mt-3 mb-2'
                style={{
                    height: '150px',
                    marginBottom: '3%',
                    maxWidth: '150px',
                    outlineColor: 'transparent'
                }}
            >
                <PlusButton
                    htmlFor={name}
                    content_color={appSettings.content_color}
                    fullWidth={true}
                    className='align-items-center justify-content-center'
                    content_font_color={appSettings.content_font_color}
                    disabled={isReuse}
                    button_shadow_color={appSettings.button_shadow_color}
                    onClick={() => {
                        if (!isReuse) {
                            name === 'original' ? setShowMediaSelectionPopup(true) : setShowMediaPreviewSelectionPopup(true)
                        }
                    }}
                >
                    <i className="fas fa-plus" aria-hidden="true"></i>
                </PlusButton>
            </div>
        </>
    }

    const reArrangeImage = (dragIndex, dropIndex) => {
        let copyNewFiles = [...media]
        const draggedImage = copyNewFiles[dragIndex]
        copyNewFiles[dragIndex] = copyNewFiles[dropIndex]
        copyNewFiles[dropIndex] = draggedImage
        setMedia(copyNewFiles)
    }

    const onMarkAsPreview = (previewMedia, index) => {
        if (previewMedia.is_process === false) {
            previewMedia.is_selected_from_original_file = true
        }
        setMediaPreview([previewMedia])
        setSelectedPreviewIndex(index)
    }

    const previewContent = (name) => {
        const filesArray = name === 'preview' ? mediaPreview : media
        return <>
            {filesArray.map((item, i) => {
                let url = 'url(' + item.renderFile + ')'
                let file = item.renderFile
                if (item.url) {
                    file = item.url
                    url = 'url(' + item.url + ')'
                }
                const fileType = _.get(item, 'selectedFile.type', item.content_type)
                const fileSrc = _.get(item, 'renderFile', item.url)

                return (
                    <ColumnDiv isUploading={isReuse} content_color={appSettings.content_color} site_font_color={appSettings.site_font_color} key={i} className='col-12 col-md-4 d-flex justify-content-center align-items-center flex-column mt-3'>
                        {['video/quicktime', 'video/mp4', 'video'].includes(fileType) ?
                            <>
                                <CloseButton
                                    className='align-items-center close d-flex justify-content-center mr-4 mt-2'
                                    content_color={appSettings.content_color}
                                    content_font_color={appSettings.content_font_color}
                                    type={'video'}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleDeletePhoto(i, name)
                                    }}
                                    disabled={isReuse}
                                    style={{ zIndex: '5' }}
                                    isRearrangeModeEnable={isRearrangeModeEnable}
                                />
                                <VideoDiv
                                    onClick={() => { openDialog(file, 'video') }}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragStart={() => {
                                        handleDrag(i)
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault()
                                        handleDrop(i)
                                    }}
                                    draggable={isRearrangeModeEnable && name === 'original' ? true : false}
                                >
                                    <OverLayDiv type='video' isRearrangeModeEnable={isRearrangeModeEnable} />
                                    {isIOS ?
                                        <video src={fileSrc} poster={getCloudFrontAssetsUrl('images/no-preview-video.png')} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black' }} />
                                        :
                                        <video src={fileSrc} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black' }} />
                                    }
                                    <GalleryIcon>
                                        <span className='video-button-span'>
                                            <i className='fas fa-play-circle play-icon' />
                                        </span>
                                    </GalleryIcon>
                                </VideoDiv>
                                {isRearrangeModeEnable === true && name === 'original' &&
                                    <div className='positioning text-dark d-inline'>
                                        <i className='fas fa-arrow-up fa-2x' style={{ display: i === 0 && 'none', marginTop: i === (media.length - 1) ? '3rem' : '2rem' }} onClick={(e) => {
                                            e.preventDefault()
                                            reArrangeImage(i, i - 1)
                                        }}></i>{i > 0 && <br></br>}
                                        <i className='fas fa-arrow-down fa-2x' style={{ display: i === (media.length - 1) && 'none', marginTop: i === 0 ? '3rem' : (i < (media.length - 1) && i > 0) ? '1rem' : '2rem' }} onClick={(e) => {
                                            e.preventDefault()
                                            reArrangeImage(i, i + 1)
                                        }}></i>
                                    </div>
                                }
                                {name === 'original' && isPreviewModeEnable && !isRearrangeModeEnable &&
                                    <div className='form-check mt-2'>
                                        <input className='form-check-input' type='checkbox' checked={i === selectedPreviewIndex} name='media_preview' onChange={() => onMarkAsPreview(item, i)} id={`media_preview${i}`} disabled={isReuse} />
                                        <label className='form-check-label' htmlFor={`media_preview${i}`}>
                                            Set as Preview
                                        </label>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <CloseButton
                                    className='align-items-center close d-flex justify-content-center mr-3 mt-2'
                                    content_color={appSettings.content_color}
                                    content_font_color={appSettings.content_font_color}
                                    type={'image'}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleDeletePhoto(i, name)
                                    }}
                                    disabled={isReuse}
                                    style={{ zIndex: '5' }}
                                    isRearrangeModeEnable={isRearrangeModeEnable}
                                />
                                <DivData
                                    onClick={() => { openDialog(file, 'photo') }}
                                    imageUrl={url}
                                    draggable={isRearrangeModeEnable && name === 'original' ? true : false}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragStart={() => {
                                        handleDrag(i)
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault()
                                        handleDrop(i)
                                    }}
                                >
                                    <OverLayDiv type='image' isRearrangeModeEnable={isRearrangeModeEnable} />
                                </DivData>
                                {isRearrangeModeEnable === true && name === 'original' &&
                                    <div className='positioning text-dark d-inline'>
                                        <i className='fas fa-arrow-up fa-2x' style={{ display: i === 0 && 'none', marginTop: i === (media.length - 1) ? '3rem' : '2rem' }} onClick={(e) => {
                                            e.preventDefault()
                                            reArrangeImage(i, i - 1)
                                        }}></i>{i > 0 && <br></br>}
                                        <i className='fas fa-arrow-down fa-2x' style={{ display: i === (media.length - 1) && 'none', marginTop: i === 0 ? '3rem' : (i < (media.length - 1) && i > 0) ? '1rem' : '2rem' }} onClick={(e) => {
                                            e.preventDefault()
                                            reArrangeImage(i, i + 1)
                                        }}></i>
                                    </div>
                                }
                                {name === 'original' && isPreviewModeEnable && !isRearrangeModeEnable &&
                                    <div className='form-check mt-2'>
                                        <input className='form-check-input' type='checkbox' checked={i === selectedPreviewIndex} name='media_preview' onChange={() => onMarkAsPreview(item, i)} id={`media_preview${i}`} disabled={isReuse} />
                                        <label className='form-check-label' htmlFor={`media_preview${i}`}>
                                            Set as Preview
                                        </label>
                                    </div>
                                }
                            </>
                        }
                    </ColumnDiv>
                )
            })
            }
        </>
    }

    const onChangePreviewMode = () => {
        if (isPreviewModeEnable === true) {
            setMediaPreview([])
        }
        setSelectedPreviewIndex('')
        setIsPreviewModeEnable(!isPreviewModeEnable)
    }

    const openDialog = (url, type) => {
        if (isLoaded === false) {
            return
        }
        if (!isDialogOpen === true) {
            document.querySelector('body').style.overflow = 'hidden'
        } else {
            document.querySelector('body').style.overflow = 'visible'
        }
        setUrl(url)
        setType(type)
        setIsDialogOpen(!isDialogOpen)
    }

    const onChangeFeedTags = (feedTags) => {
        setFeedTagErrorText('')
        let selectedTags = []
        for (const tag of feedTags) {
            const slug = tag.value.trim().toLowerCase()
            const isNew = _.get(tag, '__isNew__', false)
            if (slug === '') {
                setFeedTagErrorText('Tag not allow empty')
            } else if (isNew) {
                const isValid = props.auth.appSettings.feed_tags.find(item => item.slug === slug)
                if (isValid !== undefined) {
                    setFeedTagErrorText('Tag already added')
                } else {
                    selectedTags.push(tag)
                }
            } else {
                selectedTags.push(tag)
            }
        }
        setFeedTag(selectedTags)
    }

    const onSelection = (selectedMedia) => {
        const name = showMediaSelectionPopup ? 'original' : 'preview'
        let files = showMediaSelectionPopup ? media : mediaPreview
        files = files.filter(file => {
            if (file) {
                if (file._id) {
                    return true
                } else if (file.selectedFile && file.selectedFile._id) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }).map(file => file._id || file.selectedFile._id)

        const deletedIdsIndex = []
        if (showMediaSelectionPopup) {
            for (let i = 0; i < files.length; i++) {
                if (!selectedMedia.includes(files[i])) {
                    deletedIdsIndex.push(i)
                }
            }
        }

        if (name === 'original') {
            setShowMediaSelectionPopup(false)
            setSelectedMediaIds(selectedMedia)
        } else {
            setShowMediaPreviewSelectionPopup(false)
            setSelectedPreviewMediaIds(selectedMedia)
            if (selectedMedia.length > 1) {
                setSelectedPreviewMediaIds([])
                props.setSweetAlert({ description: 'You can not add more than one preview.' })
                return
            }
        }

        const { allMedia } = props.mediaState
        const savedMedia = allMedia.filter(item => selectedMedia.includes(item._id))
        const sortedMedia = sortArrayById(savedMedia, selectedMedia)
        if (sortedMedia.length > 0) {
            const selectedFiles = sortedMedia.map(item => {
                return {
                    _id: item._id,
                    path: item.path,
                    small_thumb: item.small_thumb,
                    format: 'modern',
                    type: item.type === 'photo' ? 'image/jpeg' : 'video/mp4',
                    size: item.size,
                    name: item._id + (item.type === 'photo' ? '.jpeg' : '.mp4')
                }
            })
            if (name === 'original') {
                galleryFiles(selectedFiles, deletedIdsIndex)
            } else {
                previewFiles(selectedFiles)
            }
        }
    }

    return (
        <WebsiteFontColor>
            {isDialogOpen === true &&
                <FullScreenModelPopUpDialog
                    url={url}
                    handleClose={() => { openDialog('', '') }}
                    type={type}
                    showWatermark={false}
                />
            }
            <div className='modal fade show' role='dialog' style={{ display: isDialogOpen ? 'none' : 'block', backgroundColor: '#00000080' }}>
                <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg' role='document'>
                    <div className='modal-content' style={{ backgroundColor: appSettings.card_background_color }}>
                        <div className='modal-header align-items-center'>
                            <h5 className='modal-title'>Reuse Post</h5><HelperButton onClick={(e) => {
                                e.preventDefault()
                                updateHelperModelOpen(true)
                            }} style={{ backgroundColor: 'transparent', color: appSettings.content_color }}>
                                <i className='fas fa-question-circle'></i>
                            </HelperButton>
                            <button className='close' onClick={props.handleReuseBlogDialogClose} style={{ color: appSettings.site_font_color, opacity: 1 }} disabled={isReuse} />
                        </div>
                        <div className='modal-body'>
                            <div className='container'>
                                {isLoaded === false ?
                                    <div className='text-center'>
                                        <div className='spinner-border' role='status' style={{ color: appSettings.content_color }}>
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <form autoComplete='off'>
                                        <div className='form-row'>
                                            <div className='form-group col'>
                                                <textarea
                                                    id='description'
                                                    name='description'
                                                    className='form-control'
                                                    value={description}
                                                    onChange={(e) => updateDescription(e.target.value)}
                                                    disabled={isReuse}
                                                />
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <DropZoneSection content_color={appSettings.content_color} site_font_color={appSettings.site_font_color} className=''>
                                                <h4 style={{ marginBottom: '0%', fontWeight: 400 }}>Choose from Content Manager</h4>
                                                <div className='row d-flex justify-content-center'>
                                                    {previewContent('original')}
                                                    {isRearrangeModeEnable === false && <>
                                                        {getPlusButton('original')}
                                                    </>}
                                                </div>
                                                {media.length > 1 &&
                                                    <ReorderButton content_color={appSettings.content_color} site_font_color={appSettings.site_font_color} className='ml-3' style={{ textAlign: 'left' }}>
                                                        <div className="custom-control custom-switch">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="REORDER_MODE"
                                                                name='ReorderMode'
                                                                onChange={() => {
                                                                    setIsRearrangeModeEnable(!isRearrangeModeEnable)
                                                                    setSelectedPreviewIndex('')
                                                                }}
                                                                checked={isRearrangeModeEnable}
                                                                disabled={isReuse}
                                                            />
                                                            <label className="custom-control-label" htmlFor="REORDER_MODE">Reorder</label>
                                                        </div>
                                                    </ReorderButton>
                                                }
                                                {media.length > 0 &&
                                                    <div className='row'>
                                                        <div className='col-12 px-5' style={{ textAlign: 'left' }}>
                                                            <MarkAsPreviewButton content_color={appSettings.content_color} site_font_color={appSettings.site_font_color}>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="PREVIEW_MODE_REUSE"
                                                                        name='PreviewMode'
                                                                        onChange={onChangePreviewMode}
                                                                        checked={isPreviewModeEnable}
                                                                        disabled={isReuse}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="PREVIEW_MODE_REUSE">Add Preview</label>
                                                                </div>
                                                                <HelperButton className='mx-1'
                                                                    disabled={isReuse}
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setHelperPreviewModelOpen(true)
                                                                    }} style={{ backgroundColor: 'transparent', color: appSettings.content_color }}>
                                                                    <i className='fas fa-question-circle'></i>
                                                                </HelperButton>
                                                            </MarkAsPreviewButton>
                                                        </div>
                                                    </div>
                                                }
                                            </DropZoneSection>
                                        </div>
                                        {isPreviewModeEnable === true && media.length > 0 &&
                                            <div className='mt-2 text-center'>
                                                <DropZoneSection content_color={appSettings.content_color} site_font_color={appSettings.site_font_color} className='my-3'>
                                                    <h4 style={{ color: appSettings.site_font_color, marginBottom: '0%' }}>Preview</h4>
                                                    <div className='row d-flex justify-content-center'>
                                                        {previewContent('preview')}
                                                        {mediaPreview.length === 0 &&
                                                            getPlusButton('preview')
                                                        }
                                                    </div>
                                                </DropZoneSection>
                                            </div>
                                        }
                                        <div className='row mt-3 mb-5'>
                                            <div className='col-12'>
                                                {isSchedule ?
                                                    <>
                                                        <label style={{ display: 'flex', alignItems: 'center', margin: '0', paddingTop: '10px' }}>Date</label>
                                                        <div className='ml-1'>
                                                            <DatePickerDiv>
                                                                <DatePicker
                                                                    selected={schedulePostTime}
                                                                    onChange={date => updateSchedulePostTime(date)}
                                                                    minDate={new Date()}
                                                                    className={'form-control react-datepicker-input'}
                                                                    dateFormat='MMMM d, yyyy'
                                                                    disabled={isReuse}
                                                                />
                                                            </DatePickerDiv>
                                                        </div>
                                                        <div className='whole-time-box pt-2'>
                                                            <label style={{ display: 'flex', alignItems: 'center', margin: '0' }}>Time</label>
                                                            <div className='schedule-box' style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}>
                                                                <select name='schedulePostHour'
                                                                    value={schedulePostHour}
                                                                    onChange={(e) => updateSchedulePostHour(e.target.value)}
                                                                    className='form-control ml-1 form-select'
                                                                    style={{ textAlignLast: 'center' }}
                                                                    disabled={isReuse}
                                                                >
                                                                    {hourArray.map((d, i) => {
                                                                        return <option key={'hour' + i} value={i + 1}>{i + 1}</option>
                                                                    })
                                                                    }
                                                                </select>
                                                                <select name='schedulePostMin'
                                                                    value={schedulePostMin}
                                                                    onChange={(e) => updateSchedulePostMin(e.target.value)}
                                                                    className='form-control ml-1 form-select'
                                                                    style={{ textAlignLast: 'center' }}
                                                                    disabled={isReuse}
                                                                >
                                                                    {minArray.map((d, i) => {
                                                                        return <option key={'min' + i} value={i}>{i < 10 ? '0' + (i) : i}</option>
                                                                    })
                                                                    }
                                                                </select>
                                                                <select name='schedulePostTimeFormat'
                                                                    value={schedulePostTimeFormat}
                                                                    onChange={(e) => updateSchedulePostTimeFormat(e.target.value)}
                                                                    className='form-control ml-1 form-select'
                                                                    style={{ textAlignLast: 'center' }}
                                                                    disabled={isReuse}
                                                                >
                                                                    <option value='AM'>AM</option>
                                                                    <option value='PM'>PM</option>
                                                                </select>
                                                            </div>
                                                            <div className='mt-3'>
                                                                <button className='btn' onClick={() => updateIsSchedule(false)}
                                                                    style={{ backgroundColor: appSettings.content_color, color: appSettings.content_font_color }}
                                                                    disabled={isReuse}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <Button
                                                        onClick={() => updateIsSchedule(true)}
                                                        style={{
                                                            textTransform: 'none',
                                                            color: appSettings.content_font_color,
                                                            boxShadow: `0 5px 15px 0 ${appSettings.button_shadow_color}44, 0 4px 15px 0 ${appSettings.button_shadow_color}44`,
                                                            backgroundColor: appSettings.content_color
                                                        }}
                                                        disabled={isReuse}
                                                    >
                                                        <AlarmIcon style={{ fontSize: '30px' }} />
                                                        &nbsp;Schedule Post
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                        <div className='form-row'>
                                            <div className='col'>
                                                <>
                                                    <label>Blur Photo/Video</label>
                                                    <div className='form-group'>
                                                        <div className='from-check form-check-inline'>
                                                            <label className='form-check-label' htmlFor='REUSE_PHOTO_BLUR'>
                                                                <input
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    value={true}
                                                                    checked={privateBlur === true}
                                                                    onChange={() => updatePrivateBlur(true)}
                                                                    name='privateBlur'
                                                                    id='REUSE_PHOTO_BLUR'
                                                                    disabled={isReuse}
                                                                />
                                                                Enable Blur
                                                            </label>
                                                        </div>
                                                        <div className='from-check form-check-inline'>
                                                            <label className='form-check-label' htmlFor='REUSE_PHOTO_NOT_BLUR'>
                                                                <input
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    value={false}
                                                                    checked={privateBlur === false}
                                                                    onChange={() => updatePrivateBlur(false)}
                                                                    name='privateBlur'
                                                                    id='REUSE_PHOTO_NOT_BLUR'
                                                                    disabled={isReuse}
                                                                />
                                                                Disable Blur
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                                <label>Blur Caption</label>
                                                <div className='form-group'>
                                                    <div className='from-check form-check-inline'>
                                                        <label className='form-check-label' htmlFor='enableCaption'>
                                                            <input
                                                                className='form-check-input'
                                                                type='radio'
                                                                value={true}
                                                                checked={captionBlur === true}
                                                                onChange={() => updateCaptionBlur(true)}
                                                                name='captionBlur'
                                                                id='enableCaption'
                                                                disabled={isReuse}
                                                            />
                                                            Enable Blur
                                                        </label>
                                                    </div>
                                                    <div className='from-check form-check-inline'>
                                                        <label className='form-check-label' htmlFor='disableCaption'>
                                                            <input
                                                                className='form-check-input'
                                                                type='radio'
                                                                value={false}
                                                                checked={captionBlur === false}
                                                                onChange={() => updateCaptionBlur(false)}
                                                                name='captionBlur'
                                                                id='disableCaption'
                                                                disabled={isReuse}
                                                            />
                                                            Disable Blur
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-row'>
                                            <div className='form-group col col-md-6 col-lg-6'>
                                                <label htmlFor='amount'>Amount</label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    id='amount'
                                                    name='amount'
                                                    value={amount}
                                                    onChange={(e) => updateAmount(e.target.value)}
                                                    min={appSettings.tips_minimum_amount}
                                                    max={appSettings.tips_maximum_amount}
                                                    disabled={isReuse}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-row'>
                                            <div className='col'>
                                                {appSettings.feed_tags_enable && <div className='form-row'>
                                                    <div className='form-group col-md-12 col-lg-6'>
                                                        <label htmlFor='feed-tag'>Tag</label>
                                                        <CreatableSelect
                                                            className='text-dark'
                                                            isMulti={true}
                                                            options={props.blog.tagList}
                                                            value={feedTags}
                                                            onChange={(tags) => onChangeFeedTags(tags)}
                                                            noOptionsMessage={() => 'No Tags Available'}
                                                            isDisabled={isReuse}
                                                        />
                                                        {feedTagErrorText !== '' && <div className='invalid-feedback d-block'>{feedTagErrorText}</div>}
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className='form-check form-check-inline'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='date-check'
                                                    defaultChecked={keepSameDateForBlog}
                                                    onChange={(e) => setKeepSameDateForBlog(e.target.checked)}
                                                />
                                                <label className='form-check-label' htmlFor='date-check'>
                                                    Keep date the same and don't bring it to the top.
                                                </label>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <button
                                                    className='btn btn-block'
                                                    style={{
                                                        backgroundColor: appSettings.content_color,
                                                        color: appSettings.content_font_color,
                                                        boxShadow: `0 5px 15px 0 ${appSettings.button_shadow_color}44, 0 4px 15px 0 ${appSettings.button_shadow_color}44`
                                                    }}
                                                    onClick={(e) => {
                                                        handleSubmit(e)
                                                    }}
                                                    disabled={isReuse}
                                                >
                                                    {isReuse && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                                    Reuse Post
                                                </button>
                                            </div>
                                        </div><br />
                                        <span>Note: This action will reuse this as a NEW Post to those who have NOT PURCHASED this post. Those who have already purchased will not see this post refreshed.</span>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {helperModelOpen === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header' style={{ backgroundColor: appSettings.card_background_color }}>
                                <h5 className='modal-title'>Reuse Post </h5>
                                <button className='close' onClick={(e) => {
                                    e.preventDefault()
                                    updateHelperModelOpen(false)
                                }} style={{ color: appSettings.site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body' style={{ backgroundColor: appSettings.card_background_color }}>
                                <p>The REUSE post feature allows you to repurpose old posts from the Exclusive Content feed. This is a great idea on days when you don’t have any content to post. We find that it’s best to use this feature on posts that are at least a month old and if possible, use it for posts that are 2-3 months old. Subscribers who have previously purchased the post do not see the new post.</p>
                                <p>When using REUSE POST we suggest you make a few modifications to the caption and minor adjustments to the price. This way if the subscriber has viewed the caption in the past, they won’t recognize it as an old post. This is also your opportunity to adjust the caption with keywords and emojis associated with posts that have sold well in the past.</p>
                                <p>All reused posts are grouped together with older versions of the same post in reporting. </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {helperPreviewModelOpen === true &&
                <HelperModelForPreview setHelperModelOpen={setHelperPreviewModelOpen} />
            }
            {
                (showMediaPreviewSelectionPopup || showMediaSelectionPopup) &&
                <MediaSelectionPopup
                    title={showMediaPreviewSelectionPopup ? 'Select Preview' : 'Select Media'}
                    onClose={() => { showMediaSelectionPopup ? setShowMediaSelectionPopup(false) : setShowMediaPreviewSelectionPopup(false) }}
                    prevSelectedMedia={showMediaSelectionPopup ? selectedMediaIds : selectedPreviewMediaIds}
                    onSelection={onSelection}
                />
            }
        </WebsiteFontColor>
    )
}

NewReusePost.propTypes = {
    auth: PropTypes.object.isRequired,
    mediaState: PropTypes.object.isRequired,
    handleReuseBlogDialogClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    editBlogId: PropTypes.string.isRequired,
    getBlogDataById: PropTypes.func.isRequired,
    blog: PropTypes.object.isRequired,
    postReuseBlogWithNewDesign: PropTypes.func.isRequired,
    getAllBlogs: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    refreshData: PropTypes.func,
    requestFrom: PropTypes.string,
    setSweetAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog,
    mediaState: state.media
})

export default connect(
    mapStateToProps, { getBlogDataById, postReuseBlogWithNewDesign, getAllBlogs, setLoading, setSweetAlert }
)(withRouter(NewReusePost))
