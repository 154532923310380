import axios from 'axios'
import {
    PAYMENT_COMPLETED,
    PAYMENT_PROCESSING,
    UPDATE_BLOG_DATA,
    BLOG_UNLOCK_PAYMENT_PROCESSING,
    BLOG_UNLOCK_PAYMENT_COMPLETED
} from './types'
import { getAppBaseUrl } from './api'
import { openPhotoIdModal, awaitGetUserDetails } from './authActions'
import { refreshMessageCount } from './chatActions'
import store from './../store'
import { googleAnalyticsTrackEvent, setPromotionGoogleAnalyticsEvent } from '../utils/GoogleAnalyticsEvent'
import _ from 'lodash'
import { getSingleMessage } from './chatActions'
import { updateRebillFailedStatus } from './ccbillSubscriptionActions'
import { setSweetAlert } from './sweetAlertActions'

const BASE_URL = getAppBaseUrl()

export const newPayment = (data, userId) => dispatch => {
    const url = (userId !== undefined) ? BASE_URL + '/api/ccbill/pay' : BASE_URL + '/api/ccbill/pay-guest'
    dispatch(paymentProcessing())

    axios.post(url, data)
        .then(res => {
            if (res.data.failureCode) {
                dispatch(paymentResponseRecieved())

                if (res.data.failureCode === '-23' || res.data.failureCode === '-24') {
                    dispatch(openPhotoIdModal())
                    return
                }
                return alert(res.data.error)
            }

            if (res.data.error) {
                dispatch(paymentResponseRecieved())
                return alert(res.data.error)
            }

            if (res.data.accepted === true) {

                if (data.action === 'tips') {
                    let transactionId = res.data.transactionId
                    if (userId !== undefined) {
                        transactionId = transactionId + '-' + userId
                    }
                    // For now its fixed 'menu' because there is no option for tip in live video or chat
                    let productSKU = 'menu'
                    // Set google analytics event for tip
                    googleAnalyticsTrackEvent('purchase', transactionId, data.amount, productSKU, 'tip', '')
                }
                if (data.action === 'subscription' && data.promotion_id !== false) {
                    setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
                }
                dispatch(paymentResponseRecieved())
                return alert(res.data.message)
            }

            if (res.data.action) {
                window.location.replace(res.data.url)
            }
        })
}

export const tipFromChat = (data, userId, callback) => dispatch => {
    const url = BASE_URL + '/api/ccbill/tip_from_chat_charge_by_previous'
    dispatch(paymentProcessing())

    axios.post(url, data)
        .then(res => {
            if (res.data.failureCode) {
                if (res.data.failureCode === '-23' || res.data.failureCode === '-24') {
                    dispatch(openPhotoIdModal())
                }
            }

            if (res.data.accepted === true) {
                if (data.action === 'tips') {
                    let transactionId = res.data.transactionId + '-' + userId
                    // Todo - Discuss productsku for google event
                    let productSKU = 'chat'
                    let product = 'tip'
                    if (data.tipFrom === 'go live stream') {
                        product = 'go live tip'
                        productSKU = data.tipFrom
                    }
                    // Set google analytics event for tip
                    googleAnalyticsTrackEvent('purchase', transactionId, data.amount, productSKU, product, '')
                }
            }
            dispatch(paymentResponseRecieved())
            callback(res)
        })
}

// Cancel Subscription
export const cancelSubscription = (data = { cancellation_reason: '' }) => async (dispatch) => {
    try {
        const res = await axios.post(BASE_URL + '/api/ccbill/cancel_subscription', data)

        const payload = {
            description: 'There was a problem cancelling your subscription. Please try again after an hour.'
        }
        if (res.data.cancelled === true) {
            payload.description = 'Subscription Cancelled'
            updateRebillFailedStatus()
            await dispatch(awaitGetUserDetails(false))
        }
        dispatch(setSweetAlert(payload))
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        const payload = {
            description: errorMessage
        }
        return dispatch(setSweetAlert(payload))
    }
}

// Charge By Previous only for chat
export const chargeByPrevious = (data) => dispatch => {
    dispatch(paymentProcessing())

    axios.post(BASE_URL + '/api/ccbill/charge_by_previous', data)
        .then(res => {
            // Get the latest version of the message
            dispatch(getSingleMessage(res.data.messageObject))

            if (res.data.failureCode) {
                if (res.data.failureCode === '-23' || res.data.failureCode === '-24') {
                    dispatch(openPhotoIdModal())
                    return
                }
                return alert(res.data.error)
            }

            if (res.data.alreadyCharged) {
                return alert('Content already unlocked.')
            }

            if (res.data.error) {
                return alert(res.data.error)
            }

            if (res.data.accepted === false) {
                return alert('Could not unlock content.')
            }

            if (res.data.accepted === true) {
                // Fetch data from store
                const state = store.getState()
                let userId = _.get(state, 'auth.user._id', false)
                let transactionId = userId ? res.data.transactionId + '-' + userId : res.data.transactionId

                // Set google analytics event for unlock chat
                googleAnalyticsTrackEvent('purchase', transactionId, data.amount, data.productSKU, data.productName, data.productCategory)

                if (data.action === 'chat' && data.promotion_id !== false) {
                    setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
                }
                return alert(res.data.message)
            }

            if (res.data.action) {
                window.location.replace(res.data.url)
            }
        })
}

// Charge By Previous only for blog
export const blogChargeByPrevious = (data) => dispatch => {
    dispatch(blogUnlockPaymentProcessing())

    axios.post(BASE_URL + '/api/ccbill/blog_charge_by_previous', data)
        .then(res => {
            dispatch(blogUnlockPaymentResponseRecieved())

            if (res.data.failureCode) {
                if (res.data.failureCode === '-23' || res.data.failureCode === '-24') {
                    dispatch(openPhotoIdModal())
                    return
                }
                return alert(res.data.error)
            }

            if (res.data.alreadyCharged) {
                return alert('Content already unlocked.')
            }

            if (res.data.error) {
                return alert(res.data.error)
            }

            if (res.data.accepted === false) {
                return alert('Could not unlock content.')
            }

            if (res.data.accepted === true) {
                dispatch({ type: UPDATE_BLOG_DATA, payload: res.data.blogData })
                const isRePost = _.get(res, 'data.blogData.udid', '').length > 1
                // Fetch data from store
                const state = store.getState()
                let userId = _.get(state, 'auth.user._id', false)
                let transactionId = userId ? res.data.transactionId + '-' + userId : res.data.transactionId
                let productSKU = data.blogType
                let productCategory = 'unlock feed'
                if (productSKU === 'photo') {
                    productSKU = 'image'
                }
                if (isRePost === true) {
                    productCategory = 'unlock reuse feed'
                }
                // Set google analytics event for unlock feed
                googleAnalyticsTrackEvent('purchase', transactionId, data.amount, productSKU, productCategory, data.title)

                if (data.is_promotion_applied === true && data.promotion_id !== false) {
                    setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
                }

                return alert(res.data.message)
            }

            if (res.data.action) {
                window.location.replace(res.data.url)
            }
        })
}

// Charge By Previous only for chat booking
export const chatBookingChargeByPrevious = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/ccbill/chat_booking_charge_by_previous', data)
        .then(res => {
            callback(res.data)
        }).catch(error => {
            if (error.response.data.errors.failureCode === '-23' || error.response.data.errors.failureCode === '-24') {
                dispatch(openPhotoIdModal())
                callback()
                return
            }
            const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
            alert(errorMessage)
            callback()
        })
}

// Payment flow only for shop
export const shopPayment = (data, history) => dispatch => {
    dispatch(paymentProcessing())

    axios.post(BASE_URL + '/api/ccbill/shop_payment', data)
        .then(res => {
            dispatch(paymentResponseRecieved())

            if (res.data.failureCode) {
                if (res.data.failureCode === '-23' || res.data.failureCode === '-24') {
                    dispatch(openPhotoIdModal())
                    return
                }
                return alert(res.data.error)
            }

            if (res.data.alreadyCharged) {
                return alert('Content already unlocked.')
            }

            if (res.data.error) {
                return alert(res.data.error)
            }

            if (res.data.accepted === false) {
                return alert('Could not unlock content.')
            }

            if (res.data.accepted === true) {
                alert(res.data.message)
                dispatch(refreshMessageCount())
                return history.push('/')
            }

            if (res.data.action) {
                window.location.replace(res.data.url)
            }
        })
}

export const getUserDataFromEmail = (data, callback) => () => {
    axios.post(BASE_URL + '/api/users/get_users_data_from_email', data)
        .then(res => {
            callback(res.data)
        })
}

export const generateSubscriptionURL = (data, callback) => () => {
    axios.post(BASE_URL + '/api/generate_subscription_url/generate_subscription_url', data)
        .then(res => {
            callback(res.data)
        })
}

export const paymentProcessing = () => {
    return {
        type: PAYMENT_PROCESSING
    }
}

export const paymentResponseRecieved = () => {
    return {
        type: PAYMENT_COMPLETED
    }
}

export const blogUnlockPaymentProcessing = () => {
    return {
        type: BLOG_UNLOCK_PAYMENT_PROCESSING
    }
}

export const blogUnlockPaymentResponseRecieved = () => {
    return {
        type: BLOG_UNLOCK_PAYMENT_COMPLETED
    }
}
