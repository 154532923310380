import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { togglePaymentModal } from './../../actions/utilityActions'
import ModalPopUp from '../Layouts/ModalPopUp'
import Button from '../Layouts/Button'
import _ from 'lodash'
import { cancelStickyIoSubscription } from './../../actions/stickyIoActions'
import { cancelUserSubscription } from './../../actions/hybridPaymentAction'
import { cancelSubscription } from './../../actions/ccbillActions'
import { setGoogleAnalyticsEvent } from '../../utils/GoogleAnalyticsEvent'
import { setSweetAlert } from './../../actions/sweetAlertActions'
import CancelSubscriptionReasonDialog from '../Layouts/CancelSubscriptionReasonDialog'
import moment from 'moment'

function RebillSubscriptionPopup(props) {
    const excludedPath = ['/subscribe', '/profile/add-new-payment-method']
    const showModel = !excludedPath.includes(props.history.location.pathname)
    const [showRebillModal, setShowRebillModal] = useState(showModel)
    const [isLoading, setIsLoading] = useState(false)
    const { cardAuthorizedByAllGateway, payment_gateway, next_billing_date, role } = props.auth.user
    const [showCancelSubscriptionReasonModal, setShowCancelSubscriptionReasonModal] = useState(false)
    const { is_cancel_subscription_reason_enabled, returning_recurring_membership_price } = props.auth.appSettings

    if (showRebillModal === true) {
        setGoogleAnalyticsEvent('show', 'rebill_fail_popup', 'rebill failed')
    }

    let message = 'Your recent rebill for subscription failed. Please enter your existing card information again or try a new card.'
    if (cardAuthorizedByAllGateway === true) {
        message = 'Your recent rebill for subscription failed. Your existing card is not working, Please add new card.'
    }
    if (payment_gateway === 'forumpay') {
        message = `Your subscription renewal of $${returning_recurring_membership_price} is due as of ${moment(next_billing_date).format('MM/DD/YYYY')}. Please press renew subscription to fund your wallet in order to pay for your renewal`
    }

    const onCancelSubscription = async () => {
        if (is_cancel_subscription_reason_enabled === true) {
            setShowCancelSubscriptionReasonModal(true)
            return false
        }

        const confirmation = window.confirm('Are you sure you want to cancel your subscription?')
        if (confirmation === false) {
            return false
        }
        setGoogleAnalyticsEvent('button_click', 'rebill_fail_popup', 'cancel subscription')
        setIsLoading(true)
        const payment_api_version = _.get(props.auth.appSettings, 'payment_api_version', 'v1')
        if (payment_api_version === 'v2') {
            await props.cancelUserSubscription()
            setIsLoading(false)
            return true
        }

        if (props.auth.appSettings.is_sticky_io_enabled === true) {
            props.cancelStickyIoSubscription()
            setIsLoading(false)
            return true
        }

        props.cancelSubscription()
        setIsLoading(false)
        return true
    }

    const gotToSubscription = () => {
        setGoogleAnalyticsEvent('button_click', 'rebill_fail_popup', 'renew subscription')
        props.history.push('/subscribe')
    }

    const onClose = () => {
        setShowCancelSubscriptionReasonModal(false)
    }

    return (
        <>
            {showRebillModal === true &&
                <ModalPopUp
                    closeBtnFontSize={20}
                    title='I want you back!'
                    handleClose={() => { setShowRebillModal(false) }}
                    showCloseBtn={role === 'proxy_user' ? true : false}
                >
                    <>
                        <p>{message}</p>
                        <div className='modal-button row d-flex justify-content-center'>
                            <Button
                                loading={isLoading}
                                type='button'
                                onClick={onCancelSubscription}
                                classes='btn btn-danger text-white col-6 col-md-auto'
                            >Cancel Subscription</Button>
                            <Button
                                loading={false}
                                type='button'
                                classes='btn ms-md-3 col-6 col-md-auto ml-md-6'
                                onClick={gotToSubscription}
                            >Renew Subscription</Button>
                        </div>
                    </>
                </ModalPopUp>}
            {showCancelSubscriptionReasonModal === true && <CancelSubscriptionReasonDialog onClose={onClose} />}
        </>
    )
}

RebillSubscriptionPopup.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    togglePaymentModal: PropTypes.func.isRequired,
    cancelSubscription: PropTypes.func.isRequired,
    cancelStickyIoSubscription: PropTypes.func.isRequired,
    cancelUserSubscription: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, {
    togglePaymentModal,
    cancelSubscription,
    cancelStickyIoSubscription,
    cancelUserSubscription,
    setSweetAlert
})(withRouter(RebillSubscriptionPopup))
