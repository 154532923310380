import React, { useEffect, useState, useMemo, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
    getCryptoCurrencyList,
    startNewPayment,
    checkTransactionForAddFund,
    getTransactionData,
    addFund,
    updateWalletAmount,
    purchaseFromWallet,
    generatePaymentId
} from '../../actions/cryptoPaymentActions'
import { updateUserSubscriptionStatus } from '../../actions/ccbillSubscriptionActions'
import Loader from '../Layouts/Loader'
import styled from 'styled-components'
import { openCopyToClipboardToast } from './../../actions/copyToClipboardAction'
import { updateDefaultPaymentMethod } from '../../actions/paymentMethodAction'
import { googleAnalyticsTrackEvent } from '../../utils/GoogleAnalyticsEvent'
import Button from '../Layouts/Button'
import { getUserDetails } from './../../actions/authActions'
import { setSweetAlert } from './../../actions/sweetAlertActions'
import { useDispatch } from 'react-redux'
import { TOGGLE_CHAT_TIP_POPUP } from '../../actions/types'
import { v4 as uuidv4 } from 'uuid'

const IconColor = styled.i`
    color: #495057;
`
const LoaderStyle = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 1;
    visibility: ${props => props.show ? 'visible' : 'hidden'};
`

const Layer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
`

function CryptoForm(props) {

    const {
        card_background_color,
        enable_forumpay_payment_live_mode,
        content_color
    } = props.auth.appSettings
    const { last_used_crypto_currency } = props.auth.user

    const [cryptoCurrencies, setCryptoCurrencies] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState('')
    const [loading, setLoading] = useState(true)
    const [amount, setAmount] = useState(0)
    const [qrCodeUrl, setQrCodeUrl] = useState('')
    const [forumUrl, setForumUrl] = useState('')
    const [paymentAddress, setPaymentAddress] = useState('')
    const [isPaymentStarted, setIsPaymentStarted] = useState(false)
    const [waitTime, setWaitTime] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [isTransactionProceed, setIsTransactionProceed] = useState(false)
    const [posId, setPosId] = useState('')
    const [paymentId, setPaymentId] = useState('')
    const [paymentNotices, setPaymentNotices] = useState([])

    const currencyRef = useRef(null)
    const transactionIdRef = useRef(transactionId)
    const isTransactionProceedRef = useRef(isTransactionProceed)
    const paymentAddressRef = useRef(paymentAddress)
    const posIdRef = useRef(posId)
    const paymentIdRef = useRef(paymentId)
    const selectedCurrencyRef = useRef(selectedCurrency)
    const isCallApiRef = useRef(false)
    const generatePaymentIdApiRef = useRef(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isPaymentStarted) {
            const interval = setInterval(() => {
                checkTransaction()
            }, 5000)

            return () => {
                clearInterval(interval)
            }
        } else if (selectedCurrencyRef.current === '') {
            setLoading(true)
            getCryptoCurrencyListData()
        }

        if (generatePaymentIdApiRef.current === false && isPaymentStarted === false) {
            const generatePaymentIdInterval = setInterval(() => {
                generatePaymentID()
            }, 5000)

            return () => {
                clearInterval(generatePaymentIdInterval)
            }
        }
    }, [isPaymentStarted])

    useEffect(() => {
        if (isPaymentStarted === false || selectedCurrencyRef.current === '') {
            transactionIdRef.current = ''
            generatePaymentID()
        }
    }, [selectedCurrency, isPaymentStarted])

    const copyToClipboard = (text, type) => {
        if (type === 'amount') {
            window.CryptoPaymentStats.onAmountCopy()
        }
        if (type === 'payment_address') {
            window.CryptoPaymentStats.onAddressCopy()
        }
        navigator.clipboard.writeText(text)
        props.openCopyToClipboardToast()
    }

    const checkTransaction = async () => {
        if (transactionIdRef.current !== '' && isTransactionProceedRef.current !== true && isCallApiRef.current === false) {
            setLoading(true)
            const data = {
                pos_id: posIdRef.current,
                currency: selectedCurrencyRef.current,
                address: paymentAddressRef.current,
                payment_id: paymentIdRef.current,
                transaction_id: transactionIdRef.current
            }
            isCallApiRef.current = true
            const res = await props.checkTransactionForAddFund(data)
            isCallApiRef.current = false
            if (res) {
                setLoading(false)
                if (res.data.is_payment_confirmed === false) {
                    return
                } else {
                    const payload = {
                        payment_method: 'crypto_currency'
                    }
                    props.updateDefaultPaymentMethod(payload)
                    props.getUserDetails(() => {
                        setIsTransactionProceed(true)
                        isTransactionProceedRef.current = true
                        window.CryptoPaymentStats.beforeClose()
                        props.onCompleteTransaction(res.data.wallet_balance)
                    }, false)
                }
            } else {
                setLoading(false)
                setIsTransactionProceed(true)
                isTransactionProceedRef.current = true
                props.setSweetAlert({ description: 'Payment failed.' })
                window.CryptoPaymentStats.beforeClose()
                props.onCompleteTransaction(false)
            }
        }
    }

    const getCryptoCurrencyListData = async () => {
        const res = await props.getCryptoCurrencyList(props.requestFrom)
        if (res) {
            setCryptoCurrencies(res.data)
            setSelectedCurrency(last_used_crypto_currency)
            selectedCurrencyRef.current = last_used_crypto_currency
        } else {
            if (props.hideCryptoFormStatus === 'blog' || props.hideCryptoFormStatus === 'chat' ||
                props.hideCryptoFormStatus === 'tips' || props.hideCryptoFormStatus === 'add_fund') {
                props.onHideAddFundsForm()
            }
            if (props.hideCryptoFormStatus === 'tips') {
                dispatch({ type: TOGGLE_CHAT_TIP_POPUP, payload: false })
            }
            props.onChangeAmount()
        }
        setLoading(false)
    }

    const currencyOptions = useMemo(() => {
        const options = cryptoCurrencies.map((currency) => {
            return <option key={currency.currency} value={currency.currency}>{currency.description}</option>
        })
        return options
    }, [cryptoCurrencies])

    const onStartNewPayment = async () => {
        if (selectedCurrency.current !== '') {
            setLoading(true)
            setIsPaymentStarted(true)
            const data = {
                currency: selectedCurrencyRef.current,
                transaction_type: 'add_fund',
                amount: props.amount.toString(),
                payment_for: 'add_fund',
                payment_id: paymentIdRef.current
            }
            // data.amount = props.amount
            // const url = 'send-tip'
            // data.action = 'tip'
            // data.tipFrom = 'menu'
            // data.isAnonymousTip = 'false'
            // data.url = url

            const res = await props.addFund(data)
            if (res.success === 1) {
                const { paymentData, pos_id } = res.data
                const { amount, qr_img, qr, address, wait_time, reference_no, payment_id, stats_token, currency, notices } = paymentData
                setAmount(amount)
                setQrCodeUrl(qr_img)
                setForumUrl(qr)
                setPaymentAddress(address)
                setWaitTime(wait_time)
                setTransactionId(reference_no)
                setPosId(pos_id)
                setPaymentNotices(notices)
                transactionIdRef.current = reference_no
                paymentAddressRef.current = address
                posIdRef.current = pos_id
                paymentIdRef.current = payment_id

                window.CryptoPaymentStats.setToken(stats_token)
                googleAnalyticsTrackEvent('add_fund', pos_id, amount, 'CRYPTO', 'add_fund', currency)
            } else {
                props.setSweetAlert({ description: res.message })
            }
        }
    }

    const generatePaymentID = async () => {
        if (selectedCurrencyRef.current !== '' && generatePaymentIdApiRef.current === false) {
            generatePaymentIdApiRef.current = true
            setLoading(true)
            const data = {
                currency: selectedCurrencyRef.current,
                amount: props.amount.toString()
            }
            const res = await props.generatePaymentId(data)
            if (res.success === 1) {
                setAmount(res.data.amount)
                setPaymentId(res.data.payment_id)
                paymentIdRef.current = res.data.payment_id
                setLoading(false)
                generatePaymentIdApiRef.current = false
            } else {
                const payload = {
                    description: res.message
                }
                props.setSweetAlert(payload)
                props.onCompleteTransaction(false)
            }
        }
    }

    return <div style={{ background: card_background_color }}>
        <div>
            <div>
                <h4 className='m-0'>Transaction Amount: ${props.amount} USD</h4>
                <p
                    className='m-0'
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => props.onChangeAmount()}
                >(change amount)</p>
            </div>
            <LoaderStyle show={(loading || currencyOptions.length === 0)}>
                <Layer />
                <Loader loading={loading} />
            </LoaderStyle>
            <div>
                <p>
                    {isPaymentStarted === true ?
                        <>
                            Use the wallet QR code below or copy and paste the coin amount and wallet address.
                        </>
                        :
                        <>
                            Please select a cryptocurrency from the options below.
                        </>
                    }
                </p>
            </div>
            <div className='form-group'>
                <select
                    className='form-select'
                    value={selectedCurrency}
                    onChange={(e) => {
                        setSelectedCurrency(e.target.value)
                        selectedCurrencyRef.current = e.target.value
                        generatePaymentID()
                    }}
                    ref={currencyRef}
                    disabled={isPaymentStarted}
                >
                    {currencyOptions}
                </select>
            </div>
            <div className='form-group'>
                <div className='input-group'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text form-control'>{selectedCurrency}</span>
                    </div>
                    <input
                        type='text'
                        className='form-control'
                        value={amount}
                        disabled
                    />
                    <div className='input-group-append' style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(amount, 'amount')}>
                        <span className='input-group-text form-control'><IconColor className='fas fa-copy'></IconColor></span>
                    </div>
                </div>
            </div>
            {isPaymentStarted === true ?
                <>
                    <div className='text-center'>
                        <div className='form-group'>
                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text form-control'>Address</span>
                                </div>
                                <input
                                    type='text'
                                    className='form-control'
                                    value={paymentAddress}
                                    disabled
                                />
                                <div className='input-group-append' style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(paymentAddress, 'payment_address')}>
                                    <span className='input-group-text form-control'><IconColor className='fas fa-copy'></IconColor></span>
                                </div>
                            </div>
                        </div>
                        {
                            paymentNotices.length > 0 &&
                            <div className='text-left mt-5'>
                                {paymentNotices.map((notice) => <div key={uuidv4()} className="alert alert-danger">{notice.code}&#x3a; {notice.message}</div>)}
                            </div>
                        }
                        <div className='form-group'>
                            <img
                                src={qrCodeUrl}
                                width='200px'
                                alt=''
                                onLoad='window.CryptoPaymentStats.onQRCodeLoad(true)'
                                onError='window.CryptoPaymentStats.onQRCodeLoad(false)'
                            />
                        </div>
                        <div className='form-group'>
                            <small>Expected time to confirm: {waitTime}.</small>
                            <small>Please do not close this tab.</small>
                        </div>
                        {enable_forumpay_payment_live_mode === false &&
                            <a target='_blank' rel='noopener noreferrer' href={forumUrl} >Click here for payment</a>
                        }
                    </div>
                    <div className='text-left mt-5 p-2'
                        style={{ border: `1px solid ${content_color}` }}
                    >
                        <div className='row'>
                            <div className='col-1'>
                                <i className='fas fa-info-circle'></i>
                            </div>
                            <div className='col-10'>
                                You will have 15 minutes from the time you select your cryptocurrency to
                                complete your transaction. If you are unable to complete your transaction, you
                                can start over again. All orders are final.
                            </div>
                        </div>
                    </div>
                </>
                :
                <Button
                    type='button'
                    onClick={onStartNewPayment}
                    classes={'px-4'}
                    style={{ fontSize: '16px' }}
                    loading={loading}
                >
                    Start Crypto Payment
                </Button>
            }
        </div>
    </div>
}

CryptoForm.propTypes = {
    auth: PropTypes.object.isRequired,
    getCryptoCurrencyList: PropTypes.func.isRequired,
    startNewPayment: PropTypes.func.isRequired,
    checkTransactionForAddFund: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    updateUserSubscriptionStatus: PropTypes.func.isRequired,
    openCopyToClipboardToast: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    contentId: PropTypes.string,
    promotionId: PropTypes.string,
    isPromotionApplied: PropTypes.string,
    getTransactionData: PropTypes.func.isRequired,
    onCompleteTransaction: PropTypes.func,
    amount: PropTypes.string,
    tipFrom: PropTypes.string,
    isAnonymousTip: PropTypes.string,
    updateDefaultPaymentMethod: PropTypes.func.isRequired,
    onChangeAmount: PropTypes.func.isRequired,
    addFund: PropTypes.func.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    purchaseFromWallet: PropTypes.func.isRequired,
    transactionAmount: PropTypes.string.isRequired,
    generatePaymentId: PropTypes.func.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    hideCryptoFormStatus: PropTypes.func,
    onHideCryptoForm: PropTypes.func,
    onHideAddFundsForm: PropTypes.func
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, {
    getCryptoCurrencyList,
    startNewPayment,
    checkTransactionForAddFund,
    updateUserSubscriptionStatus,
    openCopyToClipboardToast,
    getTransactionData,
    updateDefaultPaymentMethod,
    addFund,
    updateWalletAmount,
    purchaseFromWallet,
    generatePaymentId,
    getUserDetails,
    setSweetAlert
})(withRouter(CryptoForm))
