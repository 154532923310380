import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import { cancelSubscription, subscription } from '../../actions/MigrateSubscriptionSettingsActions'
import { migrateUserSubscription, updateUserCCbillSubscriptionStatus } from '../../actions/hybridPaymentAction'
import _ from 'lodash'
import CancelSubscriptionReasonDialog from '../Layouts/CancelSubscriptionReasonDialog'
import HTMLDiv from '../Layouts/htmlDivStyledComponent'
import { purchaseFromWallet, updateWalletAmount } from '../../actions/cryptoPaymentActions'
import { hideMigrationPopup } from '../../actions/authActions'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import AddFundForm from '../crypto/AddFundForm'

function MigrationSubscriptionModal(props) {
    const {
        content_color,
        content_font_color,
        card_background_color,
        migrate_subscription,
        returning_recurring_membership_price,
        is_cancel_subscription_reason_enabled,
        site_font_color
    } = props.auth.appSettings
    const { showMigrationPopup, default_payment_method, wallet_amount, role } = props.auth.user
    const [isLoading, setIsLoading] = useState(false)
    const [closeMigrationPopup, setCloseMigrationPopup] = useState(true)
    const [showCancelSubscriptionReasonModal, setShowCancelSubscriptionReasonModal] = useState(false)
    const [remainingAmount, setRemainingAmount] = useState(0)
    const [showAddFundPopup, setShowAddFundPopup] = useState(false)

    const onSubscription = async (updatedWalletBalance, showConfirmationAlert = true) => {
        setIsLoading(true)
        let userWalletAmount = wallet_amount
        if (_.isNumber(updatedWalletBalance) === true) {
            userWalletAmount = updatedWalletBalance
        }
        let confirmationMessage = `Please Confirm Your Purchase of $${returning_recurring_membership_price}.`
        if (default_payment_method === 'crypto_currency') {
            confirmationMessage += `You currently have $${userWalletAmount} in your wallet. Amount will be debited from your wallet balance.`
        }
        let confirmation
        const payment_api_version = _.get(props.auth.appSettings, 'payment_api_version', 'v1')
        if (payment_api_version === 'v1') {
            props.subscription(() => {
                setIsLoading(false)
            })
        } else {
            if (default_payment_method === 'crypto_currency') {
                if (userWalletAmount >= returning_recurring_membership_price) {
                    if (showConfirmationAlert === false) {
                        confirmation = true
                    } else {
                        confirmation = window.confirm(confirmationMessage)
                    }
                    if (confirmation === true) {
                        const data = {
                            payment_for: 'migration'
                        }
                        const response = await props.purchaseFromWallet(data)
                        const isSuccess = _.get(response, 'success', 0)
                        if (isSuccess === 1) {
                            const description = _.get(response, 'message', 'Thank you for subscribing')
                            let payload = { description }
                            props.setSweetAlert(payload)
                            props.updateWalletAmount(response.data.wallet_balance)
                            props.hideMigrationPopup()
                        } else {
                            const error = _.get(response, 'message', false)
                            const isCancelled = _.get(response, 'errors.cancelled', false)
                            const errorMessage = error !== false ? error : response
                            let payload = { description: errorMessage }
                            props.setSweetAlert(payload)
                            if (isCancelled === true) {
                                props.hideMigrationPopup()
                                props.updateUserCCbillSubscriptionStatus()
                            }
                        }
                    }
                } else {
                    if (wallet_amount !== 0.00) {
                        setRemainingAmount(Math.ceil(returning_recurring_membership_price - wallet_amount))
                    }
                    setShowAddFundPopup(true)
                }
            } else {
                confirmation = window.confirm(confirmationMessage)
                if (confirmation === true) {
                    await props.migrateUserSubscription()
                }
            }
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (showMigrationPopup === true) {
            document.querySelector('body').style.overflow = 'hidden'
        }
    }, [showMigrationPopup, isLoading])

    const onCancelSubscription = () => {
        if (is_cancel_subscription_reason_enabled === true) {
            setShowCancelSubscriptionReasonModal(true)
            return false
        }
        setIsLoading(true)
        props.cancelSubscription(() => {
            setIsLoading(false)
        })
    }

    const onClose = () => {
        setShowCancelSubscriptionReasonModal(false)
    }

    const hideMigrationPopup = () => {

        setCloseMigrationPopup(false)
        document.querySelector('body').style.overflow = 'visible'
    }

    return (
        <WebsiteFontColor>
            {(showMigrationPopup && closeMigrationPopup) ? <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#000000ef' }}>
                <div className='modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable' role='document'>
                    <div className='modal-content'>
                        <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                            {role === 'proxy_user' && <button className='close' onClick={hideMigrationPopup} style={{ color: site_font_color, opacity: 1 }} />}
                            <HTMLDiv dangerouslySetInnerHTML={{ __html: migrate_subscription.message_content }}>
                            </HTMLDiv>
                            <div className='row justify-content-center mt-6'>
                                <button type='button' className='col-6 col-md-auto btn' onClick={onSubscription} style={{ backgroundColor: content_color, color: content_font_color }} disabled={isLoading}>
                                    {isLoading === true && <span className='spinner-border spinner-border-sm mr-2'></span>}
                                    Subscribe for ${returning_recurring_membership_price}
                                </button>
                                <button type='button' className='col-6 btn-danger col-md-auto btn ml-md-6' onClick={onCancelSubscription} disabled={isLoading}>
                                    {isLoading === true && <span className='spinner-border spinner-border-sm mr-2'></span>}
                                    Cancel Subscription
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
            {showCancelSubscriptionReasonModal === true && <CancelSubscriptionReasonDialog onClose={onClose} requestFrom='migration' />}
            {showAddFundPopup === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                            <div className='modal-header align-items-center'>
                                <button className='close' onClick={() => {
                                    setShowAddFundPopup(false)
                                }} style={{ color: site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <AddFundForm
                                        type='subscription'
                                        transactionAmount={Number(returning_recurring_membership_price)}
                                        remainAmount={remainingAmount}
                                        onCompleteTransaction={
                                            (updatedBalance) => {
                                                setShowAddFundPopup(false)
                                                if (updatedBalance) {
                                                    onSubscription(updatedBalance, false)
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </WebsiteFontColor>
    )
}

MigrationSubscriptionModal.propTypes = {
    auth: PropTypes.object.isRequired,
    cancelSubscription: PropTypes.func.isRequired,
    subscription: PropTypes.func.isRequired,
    migrateUserSubscription: PropTypes.func.isRequired,
    purchaseFromWallet: PropTypes.func.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    hideMigrationPopup: PropTypes.func.isRequired,
    updateUserCCbillSubscriptionStatus: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, {
    cancelSubscription,
    subscription,
    migrateUserSubscription,
    purchaseFromWallet,
    updateWalletAmount,
    setSweetAlert,
    hideMigrationPopup,
    updateUserCCbillSubscriptionStatus
})(withRouter((MigrationSubscriptionModal)))
