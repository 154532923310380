import {
    TOGGLE_SIDEBAR,
    TOGGLE_NEW_PAYMENT_MODAL,
    TOGGLE_MISCELLANEOUS_POP_UP,
    UNSET_NOTIFICATION_POPUP,
    TOGGLE_NOTIFICATION_POPUP,
    TOGGLE_PAYMENT_MODAL,
    TOGGLE_ANNOUNCEMENT_BANNER,
    DISPLAY_MST_TIME_FOR_NOTIFICATION,
    CLOSE_PROMOTION_BANNER,
    CLOSE_RESUBSCRIPTION_BANNER,
    IS_PROGRESSIVE_WEB_APP_POPUP_VISIBLE,
    PROGRESSIVE_WEB_APP_POPUP_SHOW_AGAIN,
    SET_UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_POPUP_VISIBILITY
} from '../actions/types'

const initialState = {
    isSideBarOpen: false,
    isNewPaymentModalOpen: false,
    isMiscellaneousPopUpOpen: true,
    showNotificationPopup: false,
    showPaymentModel: false,
    paymentCascade: {},
    paymentRequest: {},
    isAnnouncementBannerOpen: true,
    showDateAndTime: false,
    isPromotionBannerOpen: true,
    isResubscriptionBannerOpen: true,
    isProgressiveWebAppPopupVisible: false,
    progressiveWebAppPopupShowAgain: true,
    isUniversalLoginDifferentPasswordPopupVisible: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                isSideBarOpen: !state.isSideBarOpen
            }
        case TOGGLE_NEW_PAYMENT_MODAL:
            return {
                ...state,
                isNewPaymentModalOpen: !state.isNewPaymentModalOpen
            }
        case TOGGLE_MISCELLANEOUS_POP_UP:
            return {
                ...state,
                isMiscellaneousPopUpOpen: !state.isMiscellaneousPopUpOpen
            }
        case UNSET_NOTIFICATION_POPUP:
            return {
                ...state,
                showNotificationPopup: false
            }
        case TOGGLE_NOTIFICATION_POPUP:
            return {
                ...state,
                showNotificationPopup: !state.showNotificationPopup
            }
        case DISPLAY_MST_TIME_FOR_NOTIFICATION:
            return {
                ...state,
                showDateAndTime: !state.showDateAndTime
            }
        case TOGGLE_PAYMENT_MODAL:
            return {
                ...state,
                showPaymentModel: !state.showPaymentModel,
                paymentCascade: action.payload.paymentCascade,
                paymentRequest: action.payload.paymentRequest
            }
        case TOGGLE_ANNOUNCEMENT_BANNER:
            return {
                ...state,
                isAnnouncementBannerOpen: action.payload
            }
        case CLOSE_PROMOTION_BANNER:
            return {
                ...state,
                isPromotionBannerOpen: action.payload
            }
        case CLOSE_RESUBSCRIPTION_BANNER:
            return {
                ...state,
                isResubscriptionBannerOpen: action.payload
            }
        case IS_PROGRESSIVE_WEB_APP_POPUP_VISIBLE:
            return {
                ...state,
                isProgressiveWebAppPopupVisible: action.payload
            }
        case PROGRESSIVE_WEB_APP_POPUP_SHOW_AGAIN:
            return {
                ...state,
                progressiveWebAppPopupShowAgain: action.payload
            }
        case SET_UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_POPUP_VISIBILITY:
            return {
                ...state,
                isUniversalLoginDifferentPasswordPopupVisible: action.payload
            }
        default:
            return state
    }
}
