import {
    IS_LOADING_SIGN_UP_BUTTON,
    REGISTER_USER_INFO
} from '../actions/types'

const initialState = {
    isLoading: false,
    registerUserInfo: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case IS_LOADING_SIGN_UP_BUTTON: {
            return {
                ...state,
                isLoading: action.payload
            }
        }
        case REGISTER_USER_INFO: {
            return {
                ...state,
                registerUserInfo: action.payload
            }
        }
        default:
            return state
    }
}
