import {
    SET_SOCKET_CONNECTED,
    SET_SOCKET_DISCONNECTED
} from '../actions/types'

const initialState = {
    isSocketConnected: false,
    isSocketInitialyConnected: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SOCKET_CONNECTED:
            return {
                ...state,
                isSocketConnected: true,
                isSocketInitialyConnected: true
            }
        case SET_SOCKET_DISCONNECTED:
            return {
                ...state,
                isSocketConnected: false
            }
        default:
            return state
    }
}
