import axios from 'axios'
import _ from 'lodash'
import { getAppBaseUrl } from './api'
import { setSweetAlert } from './sweetAlertActions'
import { getAppSettings } from './appSettingsActions'
import { getUserDetails } from './authActions'
import { setUserDataToOneSignal } from './OneSignalAction'

const BASE_URL = getAppBaseUrl()

/**
 * Create one signal account
 *
 * @param {Object} data Request data to be sent to server
 * @param {*} callback Return to the UI for success or error
 */
export const createOneSignalAccount = () => async (dispatch) => {
    const url = `${BASE_URL}/api/notifications/create-one-signal-account`
    try {
        const res = await axios.get(url)
        if (res.data.success === 1) {
            dispatch(getAppSettings(() => { }))
            dispatch(setSweetAlert({ description: res.data.message }))
            return res.data.data
        } else {
            dispatch(setSweetAlert({ description: 'Error while create one signal account' }))
        }
        return false
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Error while creating one signal account.')
        dispatch(setSweetAlert({ description: errorMessage }))
        return false
    }
}

export const saveWebNotificationData = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/notifications/save-web-push-notification-settings`
    try {
        const res = await axios.post(url, data)
        if (res.data.success === 1) {
            dispatch(getAppSettings(() => { }))
            dispatch(setSweetAlert({ description: res.data.message }))
            return res.data.data
        } else {
            dispatch(setSweetAlert({ description: 'Error while save web notification settings.' }))
            return false
        }
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Error while save web notification settings.')
        dispatch(setSweetAlert({ description: errorMessage }))
        return false
    }
}

export const saveUserNotificationPreferences = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/notifications/save-user-notification-preferences`
    try {
        const res = await axios.post(url, data)
        if (res.data.success === 1) {
            dispatch(getUserDetails(() => {
                dispatch(setUserDataToOneSignal())
            }))
            return res.data
        } else {
            dispatch(setSweetAlert({ description: 'Error while save web notification settings.' }))
            return res.data
        }
    } catch (error) {
        return error.response.data
    }
}
