/**
 * Get file extension
 * @param {string} fileName  file name
 * @returns {string} file extension
 */
const getFileExtension = (fileName) => {
    let items = fileName.split(/\.(?=[^.]+$)/)
    if (items.length === 2) {
        return items[1]
    }
    return ''
}

/**
 * Get file name and extension
 * @param {string} fileName  file name
 * @returns {Array<string>} file name and extension
 */
const getFileNameAndExtension = (fileName) => {
    return fileName.split(/\.(?=[^.]+$)/)
}

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '767px',
    tabletL: '991px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1850px',
    desktopL: '2560px'
}

/**
 * Sort array of object by given array of _id
 * @param {Array} arrayObject  array of objects
 * @returns {Array} idArray array of _ids
 */
function sortArrayById(arrayObject, idArray) {
    const idMap = new Map(idArray.map((id, index) => [id, index])) // Create a map for quick access to indices
    return arrayObject.sort((a, b) => {
        const indexA = idMap.get(a._id) // Get the index of the first object's _id
        const indexB = idMap.get(b._id) // Get the index of the second object's _id
        return indexA - indexB // Sort by index
    })
}

export { getFileExtension, getFileNameAndExtension, size, sortArrayById }
