import axios from 'axios'
import { getAppBaseUrl } from './api'
import _ from 'lodash'
import {
    UPDATE_BLOG_DATA,
    BLOG_UNLOCK_PAYMENT_PROCESSING,
    BLOG_UNLOCK_PAYMENT_COMPLETED,
    PAYMENT_PROCESSING,
    PAYMENT_COMPLETED,
    SET_USER_SUBSCRIPTION_TO_0
} from './types'
import store from './../store'
import { googleAnalyticsTrackEvent, setPromotionGoogleAnalyticsEvent, setGoogleAnalyticsEvent } from '../utils/GoogleAnalyticsEvent'
import { updateMessageAction } from './chatActions'
import { togglePaymentModal } from './utilityActions'
import { generateRecaptchaToken, checkForRecaptchaStatus, RECAPTCHA_ACTION_SUBSCRIPTION, RECAPTCHA_ACTION_ADD_CARD } from '../utils/reCaptcha'
import { updateRebillFailedStatus } from './ccbillSubscriptionActions'
import { setSweetAlert } from './sweetAlertActions'
import { awaitGetUserDetails, hideMigrationPopup } from './authActions'
import { showPushNotificationPrompt } from '../actions/OneSignalAction'
import { getAllBlogs, setLoading } from './blogActions'
const BASE_URL = getAppBaseUrl()

/**
 * @description New User Subscription.
 * @param {object} data user address and card details.
 */
export const subscriptionPayment = (data) => async () => {
    const url = `${BASE_URL}/api/v2/purchase/subscription`
    try {
        const headers = await generateRecaptchaToken(RECAPTCHA_ACTION_SUBSCRIPTION)

        const response = await axios.post(url, data, { headers })
        return response.data
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        const responseCode = _.get(error, 'response.status', 400)
        setGoogleAnalyticsEvent('subscription_with_new_card', 'error', errorMessage)
        if (responseCode === 430) {
            const checkCaptchaSetting = await checkForRecaptchaStatus()
            if (checkCaptchaSetting === false) {
                window.location.reload()
                return
            }
            return errorMessage
        }
        return errorMessage
    }
}

/**
 * @description New User Subscription.
 * @param {object} data user address and card details.
 */
export const subscriptionPaymentByCard = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/v2/purchase/subscription-with-card`
    try {
        const response = await axios.post(url, data)
        dispatch(showPushNotificationPrompt())
        dispatch(setLoading(true))
        dispatch(getAllBlogs(false, { pageNum: 1 }, () => { }))
        return response.data
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        setGoogleAnalyticsEvent('subscription_with_old_card', 'error', errorMessage)
        return errorMessage
    }
}

export const blogUnlockPaymentProcessing = () => {
    return { type: BLOG_UNLOCK_PAYMENT_PROCESSING }
}

export const blogUnlockPaymentResponseReceived = () => {
    return { type: BLOG_UNLOCK_PAYMENT_COMPLETED }
}

export const paymentProcessing = () => {
    return { type: PAYMENT_PROCESSING }
}

export const updateBlogData = (data) => {
    return { type: UPDATE_BLOG_DATA, payload: data }
}

/**
 * @description Blog Content Purchase.
 * @param {object} data user address and card details.
 */
export const blogPurchasePayment = (data) => async (dispatch) => {
    dispatch(blogUnlockPaymentProcessing())

    const url = `${BASE_URL}/api/v2/purchase/blog-content-purchase`
    try {
        const res = await axios.post(url, data)
        const response = res.data

        dispatch(blogUnlockPaymentResponseReceived())
        if (response.data.success === 0) {
            alert(response.data.message)
            return response.data
        }

        dispatch(updateBlogData(response.data.blogData))

        const isRePost = _.get(res, 'data.blogData.udid', '').length > 1
        // Fetch data from store
        const state = store.getState()
        const userId = _.get(state, 'auth.user._id', false)
        const transactionId = userId ? `${response.data.transactionId}-${userId}` : response.data.transactionId
        const productSKU = (data.blogType === 'photo') ? 'image' : data.blogType
        const productCategory = isRePost === true ? 'unlock reuse feed' : 'unlock feed'
        // Set google analytics event for unlock feed
        googleAnalyticsTrackEvent('purchase', transactionId, data.amount, productSKU, productCategory, data.title)

        if (data.is_promotion_applied === true && data.promotion_id !== false) {
            setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
        }
        alert(response.data.message)
        return response
    } catch (error) {
        dispatch(blogUnlockPaymentResponseReceived())
        const errorData = _.get(error, 'response.data.errors', {})
        const allowCascade = _.get(error, 'response.data.errors.allow_cascade', false)
        if (allowCascade === false) {
            const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
            alert(errorMessage)
            return errorData
        }
        alert('Problem in card authorization please re-enter your card details or add new card.')
        const payload = {
            paymentCascade: {
                error_from: errorData.error_from,
                transaction_id: errorData.transactionId
            },
            paymentRequest: data
        }
        dispatch(togglePaymentModal(payload))
        return errorData
    }
}

/**
 * Chat Purchase
 * @param {object} data request data
 */
export const chatPurchasePayment = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/v2/purchase/chat-content-purchase`
    dispatch(paymentProcessing())

    try {
        const res = await axios.post(url, data)
        const response = res.data
        dispatch(paymentResponseReceived())
        dispatch(updateMessageAction(response.data.messageObject))
        if (response.success === 0) {
            dispatch(setSweetAlert({ description: response.data.message }))
            return response.data
        }

        // Fetch data from store
        const state = store.getState()
        const userId = _.get(state, 'auth.user._id', false)
        const transactionId = userId !== undefined ? `${response.data.transactionId}-${userId}` : response.data.transactionId

        // Set google analytics event for unlock chat
        googleAnalyticsTrackEvent('purchase', transactionId, data.amount, data.productSKU, data.productName, data.productCategory)

        if (data.action === 'chat' && data.promotion_id !== false) {
            setPromotionGoogleAnalyticsEvent(data.promotion_id, data.ribbon_text)
        }
        dispatch(setSweetAlert({ description: response.data.message }))
        return response
    } catch (error) {
        dispatch(paymentResponseReceived())
        const errorData = _.get(error, 'response.data.errors', {})
        const allowCascade = _.get(error, 'response.data.errors.allow_cascade', false)
        if (allowCascade === false) {
            const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
            dispatch(setSweetAlert({ description: errorMessage }))
            return errorData
        }
        dispatch(setSweetAlert({ description: 'Problem in card authorization please re-enter your card details or add new card.' }))
        const payload = {
            paymentCascade: {
                error_from: errorData.error_from,
                transaction_id: errorData.transactionId
            },
            paymentRequest: data
        }
        dispatch(togglePaymentModal(payload))
        return errorData
    }
}

/**
 * Tip Payment
 * @param {object} data request object
 * @param {string} userId user id
 */
export const TipPayment = (data, userId, history = '') => async (dispatch) => {
    const url = `${BASE_URL}/api/v2/purchase/send-tip`
    dispatch(paymentProcessing())

    try {
        const res = await axios.post(url, data)
        const response = res.data
        const transactionId = userId !== undefined ? `${res.data.transactionId}-${userId}` : res.data.transactionId
        const productSKU = data.tipFrom
        const product = data.tipFrom === 'go live stream' ? 'go live tip' : 'tip'
        // Set google analytics event for tip
        googleAnalyticsTrackEvent('purchase', transactionId, data.amount, productSKU, product, '')
        dispatch(paymentResponseReceived())
        if (response.success === 0) {
            dispatch(setSweetAlert({ description: response.data.message }))
            return response.data
        }
        dispatch(setSweetAlert({ description: response.data.message }))
        return response
    } catch (error) {
        dispatch(paymentResponseReceived())
        const errorResponseStatus = _.get(error, 'response.data.status', {})

        if (errorResponseStatus === 401 && history !== '') {
            return history.push('/register')
        }
        const errorData = _.get(error, 'response.data.errors', {})
        const allowCascade = _.get(error, 'response.data.errors.allow_cascade', false)
        if (allowCascade === false) {
            const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
            dispatch(setSweetAlert({ description: errorMessage }))
            return errorData
        }
        dispatch(setSweetAlert({ description: 'Problem in card authorization please re-enter your card details or add new card.' }))
        const payload = {
            paymentCascade: {
                error_from: errorData.error_from,
                transaction_id: errorData.transactionId
            },
            paymentRequest: data
        }
        dispatch(togglePaymentModal(payload))
        return errorData
    }
}

export const paymentResponseReceived = () => {
    return { type: PAYMENT_COMPLETED }
}

/**
 * @description cancel subscription
 *
 * @param {object} data Cancellation reason data
 */
export const cancelUserSubscription = (data = { cancellation_reason: '' }) => async (dispatch) => {
    const url = `${BASE_URL}/api/v2/subscription/cancel-subscription`
    try {
        const res = await axios.post(url, data)
        const response = res.data
        const payload = {
            description: 'There was a problem cancelling your subscription. Please try again after an hour.'
        }
        if (response.data.cancelled === true) {
            payload.description = 'Subscription Cancelled'
            updateRebillFailedStatus()
            await dispatch(awaitGetUserDetails(false))
        }
        dispatch(setSweetAlert(payload))
        return
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        return
    }
}

/**
 * @description Add New Card.
 * @param {object} data user address and card details.
 */
export const addNewCardAction = (data) => async () => {
    const url = `${BASE_URL}/api/v2/purchase/add-new-card`
    try {
        const headers = await generateRecaptchaToken(RECAPTCHA_ACTION_ADD_CARD)

        const response = await axios.post(url, data, { headers })
        return response.data
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        const responseCode = _.get(error, 'response.status', 400)
        setGoogleAnalyticsEvent('add_new_card', 'error', errorMessage)
        if (responseCode === 430) {
            const checkCaptchaSetting = await checkForRecaptchaStatus()
            if (checkCaptchaSetting === false) {
                window.location.reload()
                return
            }
            return errorMessage
        }
        return errorMessage
    }
}

/**
 * @description Resubscribe User
 * @param {object} data data
 */
export const userResubscription = (data) => async (dispatch) => {
    const url = BASE_URL + '/api/v2/purchase/resubscribe-with-card'
    try {
        const response = await axios.post(url, data)
        dispatch(setLoading(true))
        dispatch(getAllBlogs(false, { pageNum: 1 }, () => { }))
        return response.data
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        return errorMessage
    }
}

/**
 * @description Migrate subscriptions.
 */
export const migrateUserSubscription = () => async (dispatch) => {
    try {
        const res = await axios.post(BASE_URL + '/api/v2/purchase/migrate-subscription')
        if (res.data.data.accepted === true) {
            const { transactionId, amount, product_sku, product_name, product_category } = res.data.data
            googleAnalyticsTrackEvent('purchase', transactionId, amount, product_sku, product_name, product_category)
            setGoogleAnalyticsEvent('purchase', 'v2 payment', `migrate subscription ${product_sku}`)
        } else {
            if (res.data.data.ccbill_subscription_status === '0') {
                dispatch(updateUserCCbillSubscriptionStatus())
            }
        }

        const is_cascade = _.get(res.data.is_cascade, 'is_cascade', false)
        const description = is_cascade === true ? `${res.data.message} \n Redirecting to subscription page. Please re-enter your card details or add new card.` : res.data.message
        const onConfirmUrl = is_cascade === true ? '/subscribe' : ''
        let payload = { description, onConfirmUrl }
        dispatch(setSweetAlert(payload))
        dispatch(hideMigrationPopup())
        return
    } catch (error) {
        const is_cascade = _.get(error.response.data.errors, 'is_cascade', false)
        const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
        const description = is_cascade === true ? `${errorMessage} \n Redirecting to subscription page. Please re-enter your card details or add new card.` : errorMessage
        const onConfirmUrl = is_cascade === true ? '/subscribe' : ''
        let payload = { description, onConfirmUrl }
        const ccbill_subscription_status = _.get(error.response.data.errors, 'ccbill_subscription_status', '2')
        if (ccbill_subscription_status === '0') {
            dispatch(updateUserCCbillSubscriptionStatus())
        }

        const is_subscription_cancelled = _.get(error, 'response.data.errors.cancelled', false)
        if (is_subscription_cancelled === true) {
            dispatch(hideMigrationPopup())
        }
        dispatch(setSweetAlert(payload))
        return
    }
}

export const updateUserCCbillSubscriptionStatus = () => {
    return {
        type: SET_USER_SUBSCRIPTION_TO_0
    }
}
