import {
    SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS,
    IS_SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS_LOADING,
    IS_SUBSCRIPTION_EMAIL_NOTIFICATION_BUTTON_LOADING,
    IS_PAGINATION_ON_EXCLUSIVE_CONTENT_SETTING_LOADING,
    IS_PAGINATION_ON_EXCLUSIVE_CONTENT_BUTTON_LOADING,
    IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED,
    IS_REDIS_CACHE_SETTING_LOADING,
    IS_REDIS_CACHE_ENABLED,
    IS_REDIS_CACHE_BUTTON_LOADING
} from '../actions/types'

const initialState = {
    subscriptionEmailNotification: '',
    isSubscriptionEmailNotificationSettingsLoading: true,
    isSubscriptionEmailNotificationButtonLoading: false,
    isPaginationOnExclusiveContentEnabled: false,
    isPaginationOnExclusiveContentSettingLoading: true,
    isPaginationOnExclusiveContentButtonLoading: false,
    isRedisCacheSettingLoading: true,
    isRedisCacheButtonLoading: false,
    isRedisCacheEnabled: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case IS_SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS_LOADING:
            return {
                ...state,
                isSubscriptionEmailNotificationSettingsLoading: action.payload
            }
        case IS_SUBSCRIPTION_EMAIL_NOTIFICATION_BUTTON_LOADING:
            return {
                ...state,
                isSubscriptionEmailNotificationButtonLoading: action.payload
            }
        case SUBSCRIPTION_EMAIL_NOTIFICATION_SETTINGS:
            return {
                ...state,
                subscriptionEmailNotification: action.payload
            }
        case IS_PAGINATION_ON_EXCLUSIVE_CONTENT_SETTING_LOADING:
            return {
                ...state,
                isPaginationOnExclusiveContentSettingLoading: action.payload
            }
        case IS_PAGINATION_ON_EXCLUSIVE_CONTENT_BUTTON_LOADING:
            return {
                ...state,
                isPaginationOnExclusiveContentButtonLoading: action.payload
            }
        case IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED:
            return {
                ...state,
                isPaginationOnExclusiveContentEnabled: action.payload
            }
        case IS_REDIS_CACHE_SETTING_LOADING:
            return {
                ...state,
                isRedisCacheSettingLoading: action.payload
            }
        case IS_REDIS_CACHE_ENABLED:
            return {
                ...state,
                isRedisCacheEnabled: action.payload
            }
        case IS_REDIS_CACHE_BUTTON_LOADING:
            return {
                ...state,
                isRedisCacheButtonLoading: action.payload
            }
        default:
            return state
    }
}
