import {
    SET_CHAT_TIMER,
    REMOVE_CHAT_TIMER
} from '../actions/types'

const initialState = {
    numberOfLiveChatUser: 0,
    chatTimeRemaining: null,
    liveChatUserId: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CHAT_TIMER:
            return {
                numberOfLiveChatUser: action.payload.numberOfLiveChatUser,
                chatTimeRemaining: action.payload.chatTimeRemaining,
                liveChatUserId: action.payload.liveChatUserId
            }
        case REMOVE_CHAT_TIMER:
            return {
                numberOfLiveChatUser: 0,
                chatTimeRemaining: null,
                liveChatUserId: null
            }
        default:
            return state
    }
}
