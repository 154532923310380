import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import { getCloudFrontAssetsUrl } from '../../utils/assets'

const Title = styled.div`
    font-size: 23px;
    font-weight: bold;
    text-align: center;
`

class AgeModalPage extends Component {
    constructor() {
        super()
        this.state = {
            isDialogOpen: localStorage.getItem('age') === null ? true : false
        }

        this.closeDialogBlog = this.closeDialogBlog.bind(this)
    }

    closeDialogBlog() {
        this.setState({ isDialogOpen: false })
        localStorage.setItem('age', true)
    }

    render() {
        const { model_name, content_color, model_id, show_age_modal, content_font_color, card_background_color } = this.props.auth.appSettings
        const profilePic = (this.props.chat.userProfileInfo[model_id])
            ? this.props.chat.userProfileInfo[model_id].profile
            : getCloudFrontAssetsUrl('faces/avatar.png')
        const modalConditions = this.state.isDialogOpen && this.props.auth.isAuthenticated === false && show_age_modal

        return (
            <WebsiteFontColor>
                {modalConditions ? <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#000000ef' }}>
                    <div className='modal-dialog modal-dialog-centered' role='document'>
                        <div className='modal-content'>
                            <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                <Title>You must be 18+ to view this content</Title>
                                <div className='text-center mt-5 mb-5'>
                                    <img
                                        src={profilePic}
                                        className='img-fluid'
                                        alt=''
                                        style={{ height: '60px', borderRadius: '30px' }}
                                        draggable={false}
                                    />
                                </div>
                                <p className='text-center'><b>{model_name}</b> is posting content you must be 18+ to view. Are you 18 years of age or older?</p>
                                <button type='button' className='btn btn-block btn-pill' onClick={this.closeDialogBlog} style={{ backgroundColor: content_color, color: content_font_color }}>
                                    Yes, I am 18 or older
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
            </WebsiteFontColor>
        )
    }
}

AgeModalPage.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat
})

export default connect(
    mapStateToProps
)(withRouter((AgeModalPage)))
