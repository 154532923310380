import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import {
    SET_CURRENT_USER,
    USER_LOADING,
    PROFILE_PHOTO_UPLOADING,
    CHAT_USER_PROFILE_INFO,
    SET_IS_PROFILE_READY,
    PHOTO_ID_UPLOADING,
    PHOTO_ID_UPLOADED,
    OPEN_PHOTO_ID_MODAL,
    CLOSE_PHOTO_ID_MODAL,
    GET_ERRORS,
    ADD_PAY_PER_MESSAGE_CREDIT,
    REMOVE_PAY_PER_MESSAGE_CREDIT,
    REMOVE_RESUBSCRIPTION_OFFER,
    HIDE_MIGRATION_SUBSCRIPTION_POP,
    RESET_PROMOTION,
    RESET_UNREAD_COUNT,
    LOGOUT_USER_LOADER,
    SET_PUSH_NOTIFICATION_PROMPT,
    IS_LOADING_LOGIN_BUTTON,
    IS_LOADING_SIGN_UP_BUTTON,
    REGISTER_USER_INFO,
    LOGIN_USER_INFO,
    SET_RESET_PASSWORD_PAGE_LOADER,
    SET_IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED
} from './types'
import { getAppBaseUrl } from './api'
import _ from 'lodash'
import { refreshMessageCount } from './chatActions'
import { googleAnalyticsTrackEvent } from '../utils/GoogleAnalyticsEvent'
import { getAllBlogs, resetCurrentPage, restoreFirstPageBlogs } from './blogActions'
import { getInfluencerHelpPopupData, resetHelpNotification } from './appSettingsActions'
import { toggleAnnouncementBanner, sendMobileInfoToServices } from './utilityActions'
import { generateRecaptchaToken, checkForRecaptchaStatus, RECAPTCHA_ACTION_REGISTER, RECAPTCHA_ACTION_LOGIN } from '../utils/reCaptcha'
import { setSweetAlert } from './sweetAlertActions'
import { removeUserDataFromOneSignal, showPushNotificationPrompt } from './OneSignalAction'
import { getUserInfluencerHelpPopupData, resetUserHelpNotification } from './userInfluencerHelpAction'
import {
    handleShowUniversalAddAccountPopup,
    handleHideUniversalAddAccountPopup,
    showUniversalForgotPasswordPopup,
    showOldUserMergeAccountPopup,
    hideOldUserMergeAccountPopup,
    showDifferentPasswordSitePopup,
    universalLoginDifferentPasswordSite
} from './universalLoginAction'
import store from './../store'
import { LOGIN, ADD_ACCOUNT, REGISTER, MERGE_ACCOUNT } from '../utils/universalLoginConstant'
import { removeConfirmSweetAlert } from './sweetAlertActions'
import { updateWalletAmount } from './cryptoPaymentActions'
import { updateDefaultPaymentMethod } from './paymentMethodAction'
const BASE_URL = getAppBaseUrl()

// Register User
export const registerUser = (userData, requestFrom, history) => async (dispatch) => {
    try {
        const headers = await generateRecaptchaToken(RECAPTCHA_ACTION_REGISTER)
        const res = await axios.post(BASE_URL + '/api/users/register', userData, { headers })

        const state = store.getState()

        const isPaginationEnabled = _.get(state.auth.appSettings, 'is_pagination_on_exclusive_content_enabled', false)
        if (isPaginationEnabled) {
            // set pagination page to 1 and restore blogs of first page without calling API
            dispatch(restoreFirstPageBlogs())
        }

        // isUniversalLoginEnabled - check universal login setting is enabled or not
        const isUniversalLoginEnabled = _.get(state.auth.appSettings, 'is_universal_login_enabled', false)

        // store universal login add account website list and show universal add account popup
        if (isUniversalLoginEnabled) {
            const showMergePopup = _.get(res, 'data.data.showMergePopup', false)
            if (showMergePopup) {
                dispatch(handleShowUniversalAddAccountPopup(res, userData, REGISTER))
                return
            }
            const showForgotPasswordPopup = _.get(res, 'data.data.showForgotPasswordPopup', false)
            if (showForgotPasswordPopup) {
                // show forgot password popup
                dispatch(showUniversalForgotPasswordPopup(res, userData.email))
                return
            }
            const showOldUserMergePopup = _.get(res, 'data.data.showOldUserMergePopup', false)
            if (showOldUserMergePopup) {
                // show old user merge account popup
                dispatch(showOldUserMergeAccountPopup(res, userData, REGISTER))
                return
            }
        }

        // Set token to localStorage
        const { token, userId, url } = res.data.data
        localStorage.setItem('AuthToken', token)
        // Set token to Auth header
        setAuthToken(token)

        let transactionId = `0-${userId}`
        // Set google analytics event for registration
        googleAnalyticsTrackEvent('purchase', transactionId, '0', 'registration', 'registration', 'registration')

        const action = _.get(userData, 'action', '')
        dispatch(getCurrentUserDetails(history, REGISTER, action, requestFrom, url))
    } catch (error) {
        dispatch(handleErrorMessage(error))
    }
}

// Login - get user token
export const loginUser = (userData, history, requestFrom = '') => async (dispatch) => {
    try {
        const headers = await generateRecaptchaToken(RECAPTCHA_ACTION_LOGIN)
        const res = await axios.post(BASE_URL + '/api/users/login', userData, { headers })
        const state = store.getState()

        // isUniversalLoginEnabled - check universal login setting is enabled or not
        const isUniversalLoginEnabled = _.get(state.auth.appSettings, 'is_universal_login_enabled', false)

        // store universal login add account website list and show universal add account popup
        if (isUniversalLoginEnabled) {
            const showMergePopup = _.get(res, 'data.data.showMergePopup', false)
            if (showMergePopup) {
                dispatch(handleShowUniversalAddAccountPopup(res, userData, LOGIN))
                return
            }
            const showForgotPasswordPopup = _.get(res, 'data.data.showForgotPasswordPopup', false)
            if (showForgotPasswordPopup) {
                // show forgot password popup
                dispatch(showUniversalForgotPasswordPopup(res, userData.email))
                return
            }
            const showOldUserMergePopup = _.get(res, 'data.data.showOldUserMergePopup', false)
            if (showOldUserMergePopup) {
                // show old user merge account popup
                dispatch(showOldUserMergeAccountPopup(res, userData, LOGIN))
                return
            }
        }
        // Save to localStorage

        // Set token to localStorage
        const { token, url } = res.data.data
        localStorage.setItem('AuthToken', token)
        // Set token to Auth header
        setAuthToken(token)
        const isPaginationEnabled = _.get(state.auth.appSettings, 'is_pagination_on_exclusive_content_enabled', false)
        if (isPaginationEnabled) {
            // set pagination page to 1 and restore blogs of first page without calling API
            dispatch(restoreFirstPageBlogs())
        }
        const action = _.get(userData, 'action', '')
        dispatch(getCurrentUserDetails(history, LOGIN, action, requestFrom, url))
    } catch (err) {
        if (err.response.data.status === 429) {
            let errorMessage = _.get(err.response, 'data.message', 'You sent too many requests. Please wait a while then try again')
            dispatch(setErrors(err.response.data))
            dispatch(setSweetAlert({ description: errorMessage }))
            return
        }
        if (err.response.data.status === 430) {
            dispatch(setErrors(err.response.data))
            const checkCaptchaSetting = checkForRecaptchaStatus()
            if (checkCaptchaSetting === false) {
                window.location.reload()
            }
            return
        }
        if (err.response.data.errors.errorCode === 'ERR_10001') {
            let errorMessage = _.get(err.response, 'data.message', 'Invalid request') + ' (<a href="/contact-us" target="_blank">Contact Us</a>) (ERR_10001).'
            dispatch(setErrors(err.response.data))
            dispatch(setSweetAlert({ description: errorMessage }))
            return
        }

        dispatch(setErrors(err.response.data))
        let errorMessage = _.get(err.response, 'data.message', 'Invalid email or password.')
        dispatch(setSweetAlert({ description: errorMessage }))
    }
}

/**
 * @description handle error message while get error from register
 * @param {object} err error response
 * @returns return error response
 */
export const handleErrorMessage = (err) => (dispatch) => {
    let errorMessage = ''
    let errors = {}
    const errorResponse = _.get(err.response, 'data.errors', {})

    if (!_.isEmpty(errorResponse)) {
        errors = errorResponse
        _.forEach(errorResponse, function (value) {
            errorMessage += value + '\n'
        })
    }

    if (err.response.data.status === 429) {
        errorMessage = _.get(err.response, 'data.message', 'You sent too many requests. Please wait a while then try again')
        errors = err.response.data
    }

    if (err.response.data.status === 430) {
        dispatch(setErrors(err.response.data))
        const checkCaptchaSetting = checkForRecaptchaStatus()
        if (checkCaptchaSetting === false) {
            window.location.reload()
        }
        return
    }

    if (_.isEmpty(errorResponse)) {
        errorMessage = _.get(err.response, 'data.message', 'Invalid request')
        errors = err.response.data
    }

    if (err.response.data.errors.errorCode === 'ERR_10001') {
        errorMessage = _.get(err.response, 'data.message', 'Invalid request') + ' (<a href="/contact-us" target="_blank">Contact Us</a>) (ERR_10001).'
        errors = err.response.data
    }

    dispatch(setErrors(errors))
    dispatch(setSweetAlert({ description: errorMessage }))
    return
}

/**
 *
 * @description get current login or register user details
 * @param {object} history for redirect user
 * @param {string} from register or login
 * @param {string} action check for add user account
 * @param {string} requestFrom requestFrom newRegisterPage, ccbill or sticky.io while user register
 * @param {string} redirectUrl user redirect url while user register
 * @returns return after get user details
 */
export const getCurrentUserDetails = (history, from, action, requestFrom = '', redirectUrl = '') => (dispatch) => {
    const state = store.getState()

    dispatch(getUserDetails(() => {
        // isUniversalLoginEnabled - check universal login setting is enabled or not
        const isUniversalLoginEnabled = _.get(state.auth.appSettings, 'is_universal_login_enabled', false)
        // stop signup button loading while get user details
        if (isUniversalLoginEnabled) {
            // call if action is add account
            if (action === ADD_ACCOUNT) {
                // from - register or login
                // requestFrom - check request from ccbill, new register page,
                // redirectUrl - url pass from register
                dispatch(handleHideUniversalAddAccountPopup(history, from, requestFrom, redirectUrl))
            }

            if (action === MERGE_ACCOUNT) {
                dispatch(hideOldUserMergeAccountPopup(from))
            }
        }

        const differentPasswordSite = _.get(state, 'universalLogin.differentPasswordSiteList', [])
        if (from === REGISTER) {
            dispatch(signUpButtonLoading(false))
            // redirect user after register or add account from login page
            dispatch(redirectRegisterUser(history, requestFrom, redirectUrl))
            // show different password popup if exist different password site while register new user and merge account
            if (differentPasswordSite.length > 0) {
                dispatch(showDifferentPasswordSitePopup(true))
            }
        } else if (from === LOGIN && ['', MERGE_ACCOUNT].includes(action)) {
            dispatch(loginButtonLoading(false))
            const currentRoute = localStorage.getItem('routeBeforeLogin') || ''
            if (currentRoute !== '' && currentRoute !== undefined) {
                const actualRoute = currentRoute.split('/')
                const routeToken = actualRoute[actualRoute.length - 1]
                const verificationSource = actualRoute[actualRoute.length - 2]
                const routeBeforeLogin = '/' + verificationSource + '/' + routeToken
                const redirectRoute = routeBeforeLogin === '' ? '/' : routeBeforeLogin
                history.push(redirectRoute)
            } else {
                // get value if user is add while reset their password
                const isNewUser = localStorage.getItem('newUser')
                if (isNewUser) {
                    // remove item after new user is redirect subscribe page
                    localStorage.removeItem('newUser')
                    history.push('/subscribe')
                } else {
                    history.push('/')
                }
                // show different password popup while user merge and user site with different password
                if (differentPasswordSite.length > 0) {
                    dispatch(showDifferentPasswordSitePopup(true))
                }
            }
        }
    }, true))
    dispatch(removePromotionOffer())
    return
}

/**
 *
 * @description common function for redirect user after register or add account from login page
 * @param {void} history for redirect user
 * @param {string} requestFrom requestFrom newRegisterPage, ccbill or sticky.io while user register
 * @param {string} redirectUrl user redirect url while user register
 * @returns return after user redirect
 */
export const redirectRegisterUser = (history, requestFrom, redirectUrl) => () => {
    if (requestFrom === 'newRegisterPage') {
        return window.location.replace(redirectUrl)
    } else if (requestFrom === 'ccbillRestApi' || requestFrom === 'stickyIo') {
        history.push('/subscribe')
        return
    }
    history.push('/membership')
}

// Admin Log as User
export const loginAsProxyUser = (userData, history) => async (dispatch) => {
    try {
        const res = await axios.post(`${BASE_URL}/api/users/login-as-proxy-user`, userData)
        const { token } = res.data.data

        localStorage.setItem('ProxyUserToken', token)
        setAuthToken(token)
        dispatch(getUserInfluencerHelpPopupData(() => { }))
        dispatch(removePromotionOffer())
        dispatch(getUserDetails(() => {
            const data = {
                pageNum: 1
            }
            dispatch(getAllBlogs(false, data, () => { }))
            history.push('/')
            dispatch({ type: RESET_UNREAD_COUNT, payload: 0 })
        }, false))
        return true
    } catch (err) {
        let errorMessage
        let payload
        if (err.response.status === 429) {
            errorMessage = _.get(err.response, 'data.message', 'You sent too many requests. Please wait a while then try again')
            dispatch(setErrors(err.response.data))
            payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return false
        }
        dispatch(setErrors(err.response.data))
        errorMessage = _.get(err.response, 'data.message', 'Invalid User Id')
        payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        return false
    }
}

// Forgot Password
export const forgotPassword = (data) => async(dispatch) => {
    try {
        await axios.post(BASE_URL + '/api/users/forgot_password', data)
        const payload = {
            description: 'A password reset link was just emailed to you.'
        }
        const requestFrom = _.get(data, 'requestFrom', '')
        if (requestFrom === 'forgot_password') {
            payload.onConfirmUrl = '/login'
        }
        dispatch(setSweetAlert(payload))
    } catch (error) {
        if (error.response.status === 429) {
            let errorMessage = _.get(error.response, 'data.message', 'You sent too many requests. Please wait a while then try again')
            dispatch(setErrors(error.response.data))
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
            return
        }
        const errorRes = _.get(error.response, 'data.message', 'Error in sent forgot password link')
        const payload = {
            description: errorRes
        }
        dispatch(setSweetAlert(payload))
    }
}

// Check Token
export const checkToken = (token) => async(dispatch) => {
    try {
        await axios.post(BASE_URL + `/api/users/check_token/${token}`)
        dispatch(resetPasswordPageLoader(false))
    } catch (error) {
        const message = _.get(error, 'response.data.message', 'Invalid token.')
        const payload = {
            description: message,
            onConfirmUrl: '/'
        }
        dispatch(setSweetAlert(payload))
    }
}

/**
 * @description change user password
 * @param {object} data user password
 * @returns error or success response
 */
export const resetPassword = (data) => async (dispatch) => {
    try {
        const res = await axios.post(BASE_URL + '/api/users/reset_password', data)
        let message = 'Password updated successfully.'
        if (res.data.data.userUniversalAccountCount > 1) {
            message = 'Password updated successfully.\n\nPlease use the same password for all McCandless Group websites.'
        }
        const isNewUser = _.get(res, 'data.data.newUser', false)
        const userDetails = _.get(res, 'data.data.user', {})

        if (_.isEmpty(userDetails) === false) {
            dispatch(updateWalletAmount(userDetails.wallet_amount))
            dispatch(updateDefaultPaymentMethod({ payment_method: userDetails.default_payment_method }))
        }
        if (isNewUser) {
            // set value when new user add from reset password
            localStorage.setItem('newUser', isNewUser)
        }

        const payload = {
            description: message,
            onConfirmUrl: '/login'
        }

        // update user token while old user change password when reset password link send from homepage
        const token = _.get(res, 'data.data.token', '')
        if (!_.isEmpty(token)) {
            localStorage.setItem('AuthToken', token)
            setAuthToken(token)
            payload.onConfirmUrl = '/'
        }

        // remove confirmation sweet alert after reset password
        dispatch(removeConfirmSweetAlert())
        dispatch(setSweetAlert(payload))
    } catch (error) {
        // remove confirmation sweet alert after reset password
        dispatch(removeConfirmSweetAlert())
        const message = _.get(error, 'response.data.message', 'There was a problem in change password.')
        const payload = {
            description: message
        }
        dispatch(setSweetAlert(payload))
    }
}

// Change Profile Details
export const changeProfileDetails = (data) => async(dispatch) => {
    dispatch(profilePhotoUploading())
    try {
        await axios.post(BASE_URL + '/api/users/change_profile_details', data)
        dispatch(getUserDetails())
        dispatch(setSweetAlert({ description: 'Profile Details Updated Successfully.' }))
    } catch (err) {
        const message = _.get(err, 'response.data.message', 'There was a problem in edit profile.')
        dispatch(getUserDetails())
        dispatch(setSweetAlert({ description: message }))
    }
}

/**
 * @description update user password
 * @param {object} data user password
 * @returns error or success response
 */
export const changeOldPassword = (data) => async(dispatch) => {
    try {
        const res = await axios.post(BASE_URL + '/api/users/change_old_password', data)
        if (res.data.data.action === false) {
            const errorMessage = _.get(res, 'data.message', 'Invalid data')
            const payload = {
                description: errorMessage
            }
            return dispatch(setSweetAlert(payload))
        }

        const token = _.get(res, 'data.data.token', '')
        if (_.isEmpty(token)) {
            dispatch(setSweetAlert({ description: 'Invalid token.' }))
            dispatch(logoutUser())
            return
        }

        localStorage.setItem('AuthToken', token)
        // Set token to Auth header
        setAuthToken(token)
        let message = 'Password updated successfully.'
        if (res.data.data.userUniversalAccountCount > 1) {
            message = 'Password updated successfully.\n\nPlease use the same password for all McCandless Group websites.'
        }
        dispatch(setSweetAlert({ description: message }))
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'There was an error while change password.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
    }
}

// Get current user details
export const getUserDetails = (callback, isRequestFromLoginOrSignupAPI = false) => dispatch => {
    if (!localStorage.AuthToken) {
        dispatch(setIsProfileReadyAction())
        if (callback) {
            callback()
        }
        return
    }

    axios.get(BASE_URL + '/api/users/me?is_request_from_login_or_signup_api=' + isRequestFromLoginOrSignupAPI)
        .then(result => {
            // IMPORTANT: If you need to add any new functionality add this in the function below
            dispatch(updateUserDetails(result, isRequestFromLoginOrSignupAPI))
            if (callback) {
                callback()
            }
        }).catch(error => {
            // IMPORTANT: If you need to add any new functionality add this in the function below
            dispatch(handleUserUpdateError(error))
            if (callback) {
                callback()
            }
        })
}

export const resendChangeEmailRequest = () => dispatch => {
    axios.post(BASE_URL + '/api/change-email-settings/resend-change-email-request', {})
        .then(() => {
            dispatch(getUserDetails())
            alert('Resend change Email Request Successfully')
        })
        .catch(err => {
            const message = _.get(err, 'response.data.message', 'There was a problem in resend change email request.')
            alert(message)
        })
}

export const dismissChangeEmailRequest = () => dispatch => {
    axios.post(BASE_URL + '/api/change-email-settings/dismiss-change-email-request', {})
        .then(() => {
            dispatch(getUserDetails())
            const payload = {
                description: 'Your change email request has been cancelled, if you want to change email then please request again.'
            }
            dispatch(setSweetAlert(payload))
        })
        .catch(err => {
            const message = _.get(err, 'response.data.message', 'There was a problem in dismiss change email request.')
            dispatch(setSweetAlert({ description: message }))
        })
}

export const getModelDetails = () => dispatch => {
    axios.get(BASE_URL + '/api/users/get_model_details')
        .then(res => {
            dispatch(modelProfileInfo(res.data))
        })
}

// Upload Photo ID
export const uploadPhotoId = (data) => dispatch => {
    dispatch(photoIdUploading())
    axios
        .post(BASE_URL + '/api/users/upload_photo_id', data)
        .then(() => {
            dispatch(photoIdUploaded())
            dispatch(getUserDetails())
            dispatch(closePhotoIdModal())
            return alert('Photo ID uploaded. Your transaction limit has now been increased.')
        })
        .catch(err => alert(err))
}

// Get current user details
export const awaitGetUserDetails = (isRequestFromLoginOrSignupAPI = false) => async (dispatch) => {
    if (!localStorage.AuthToken) {
        dispatch(setIsProfileReadyAction())
        return
    }

    try {
        const result = await axios.get(BASE_URL + '/api/users/me?is_request_from_login_or_signup_api=' + isRequestFromLoginOrSignupAPI)

        // IMPORTANT: If you need to add any new functionality add this in the function below
        dispatch(updateUserDetails(result, isRequestFromLoginOrSignupAPI))
        return
    } catch (error) {
        // IMPORTANT: If you need to add any new functionality add this in the function below
        dispatch(handleUserUpdateError(error))
        return
    }
}

/**
 * @description Update user details after /me API call is completed
 * @param {object} result Response data
 * @param {boolean} isRequestFromLoginOrSignupAPI Is API request from login or sign up
 */
const updateUserDetails = (result, isRequestFromLoginOrSignupAPI) => dispatch => {
    dispatch(setCurrentUser(result.data))
    dispatch(setIsProfileReadyAction())
    dispatch(refreshMessageCount())
    if (result.data.ccbillSubscriptionStatus === '2') {
        dispatch(toggleAnnouncementBanner(true))
    }
    if (result.data.isAdmin === true && isRequestFromLoginOrSignupAPI === true) {
        dispatch(getInfluencerHelpPopupData(() => { }))
    }

    if (result.data.isAdmin === false && isRequestFromLoginOrSignupAPI === true) {
        dispatch(getUserInfluencerHelpPopupData(() => { }))
    }

    const differentPasswordSiteList = _.get(result, 'data.siteListOfDifferentPassword', [])
    if (differentPasswordSiteList.length > 0) {
        const differentPasswordPopupReminderCount = localStorage.getItem('differentPasswordPopupReminderCount')
        if (!differentPasswordPopupReminderCount) {
            localStorage.setItem('differentPasswordPopupReminderCount', 0)
            dispatch(showDifferentPasswordSitePopup(true))
        } else {
            if ([0, 12, 23, 31, 45].includes(Number(differentPasswordPopupReminderCount))) dispatch(showDifferentPasswordSitePopup(true))
        }

        dispatch(universalLoginDifferentPasswordSite(differentPasswordSiteList))
    }

    const data = {
        is_running_from_pwa: window.matchMedia('(display-mode: standalone)').matches === true,
        user_id: result.data._id,
        user_agent: window.navigator.userAgent,
        ccbill_subscription_status: result.data.ccbillSubscriptionStatus
    }
    dispatch(sendMobileInfoToServices(data))
    dispatch(showPushNotificationPrompt())
}

/**
 * @description Handle error response in /me API
 * @param {object} error Error response data
 */
const handleUserUpdateError = (error) => dispatch => {
    if (error.response && error.response.status === 401) {
        dispatch(logoutUser())
    }
    dispatch(setIsProfileReadyAction())
}

// Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

// Set logged in user
export const setIsProfileReadyAction = () => {
    return {
        type: SET_IS_PROFILE_READY
    }
}

// User loading
export const setUserLoading = () => {
    return {
        type: USER_LOADING
    }
}

// Log user out
export const logoutUser = (history) => dispatch => {
    localStorage.removeItem('differentPasswordPopupReminderCount')
    dispatch(universalLoginDifferentPasswordSite([]))

    const proxyUserToken = localStorage.getItem('ProxyUserToken')
    if (proxyUserToken !== undefined && proxyUserToken !== null) {
        // Remove token from local storage
        localStorage.removeItem('ProxyUserToken')
        setAuthToken(localStorage.getItem('AuthToken'))
        dispatch({ type: LOGOUT_USER_LOADER, payload: true })
        dispatch(resetUserHelpNotification())
        dispatch(getUserDetails(() => {
            history.push('/admin/transactions')
        }, false))
    } else {
        dispatch(resetHelpNotification())
        dispatch(resetUserHelpNotification())
        removeUserDataFromOneSignal()
        // Remove token from local storage
        localStorage.removeItem('AuthToken')
        localStorage.removeItem('showEmailNotificationPrompt')
        // Remove auth header for future requests
        setAuthToken(false)
        // Set current user to empty object {} which will set isAuthenticated to false
        dispatch(setCurrentUser({}))
        const data = {
            pageNum: 1
        }
        dispatch(getAllBlogs(false, data, () => { }))
        dispatch(resetCurrentPage())
        dispatch(removeOffer())
        dispatch(removePromotionOffer())
    }
}

export const profilePhotoUploading = () => {
    return {
        type: PROFILE_PHOTO_UPLOADING
    }
}

export const modelProfileInfo = (data) => {
    return {
        type: CHAT_USER_PROFILE_INFO,
        payload: data
    }
}

export const photoIdUploading = () => {
    return {
        type: PHOTO_ID_UPLOADING
    }
}

export const photoIdUploaded = () => {
    return {
        type: PHOTO_ID_UPLOADED
    }
}

export const openPhotoIdModal = () => {
    return {
        type: OPEN_PHOTO_ID_MODAL
    }
}

export const closePhotoIdModal = () => {
    return {
        type: CLOSE_PHOTO_ID_MODAL
    }
}

export const setErrors = (data) => {
    return {
        type: GET_ERRORS,
        payload: data
    }
}

// Add Pay per message credit
export const addPayPerMessageCredit = () => {
    return {
        type: ADD_PAY_PER_MESSAGE_CREDIT
    }
}

// Remove Pay per message credit
export const removePayPerMessageCredit = () => {
    return {
        type: REMOVE_PAY_PER_MESSAGE_CREDIT
    }
}

// Remove resubscription Offer data
export const removeOffer = () => {
    return {
        type: REMOVE_RESUBSCRIPTION_OFFER
    }
}

export const hideMigrationPopup = () => {
    document.querySelector('body').style.overflow = 'visible'
    return {
        type: HIDE_MIGRATION_SUBSCRIPTION_POP
    }
}

// Remove promotion Offer data
export const removePromotionOffer = () => {
    return {
        type: RESET_PROMOTION
    }
}

export const setPushNotificationPrompt = (data) => {
    if (data) {
        document.querySelector('body').style.overflow = 'hidden'
    } else {
        document.querySelector('body').style.overflow = 'visible'
    }
    return {
        type: SET_PUSH_NOTIFICATION_PROMPT,
        payload: data
    }
}

/**
 * cancel complimentary subscription
 */
export const cancelComplimentarySubscription = () => async (dispatch) => {
    const url = `${BASE_URL}/api/users/cancel_complimentary_subscription`
    let alertMessage = ''
    try {
        const res = await axios.post(url, {})
        await dispatch(awaitGetUserDetails(false))
        dispatch(getAllBlogs(false, { pageNum: 1 }, () => { }))
        alertMessage = res.data.message
    } catch (error) {
        alertMessage = _.get(error, 'response.data.message', 'Error in cancel subscription')
    } finally {
        dispatch(setSweetAlert({ description: alertMessage }))
    }
}

/**
 * @description manage loading on sign in button login page
 * @param {boolean} data true or false
 */
export const signUpButtonLoading = (data) => {
    return {
        type: IS_LOADING_SIGN_UP_BUTTON,
        payload: data
    }
}

/**
 * @description manage loading on sign in button register page
 * @param {boolean} data true or false
 */
export const loginButtonLoading = (data) => {
    return {
        type: IS_LOADING_LOGIN_BUTTON,
        payload: data
    }
}

/**
 * @description store register user info while show universal login merge popup
 * @param {object} data register user info
 */
export const setRegisterUserInfo = (data) => {
    return {
        type: REGISTER_USER_INFO,
        payload: data
    }
}

/**
 * @description store login user info while show universal login merge popup
 * @param {object} data login user info
 */
export const setLoginUserInfo = (data) => {
    return {
        type: LOGIN_USER_INFO,
        payload: data
    }
}

export const resetPasswordPageLoader = (data) => {
    return {
        type: SET_RESET_PASSWORD_PAGE_LOADER,
        payload: data
    }
}

export const setIsExclusiveContentPaginationEnabled = (data) => {
    return {
        type: SET_IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED,
        payload: data
    }
}
