import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash'
import { getAllTheme3Settings } from '../../actions/appSettingsActions'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import FooterPage from '../Layouts/FooterLayout'
import Loader from './../Layouts/Loader'
import PromotionNotificationBar from '../Layouts/PromotionNotificationBar'
import AnnouncementBanner from '../Layouts/AnnouncementBanner'
import { getCloudFrontAssetsUrl, socialMediaSvgIcons } from '../../utils/assets'

const LandingPageMainDiv = styled.div`
background-color: ${props => props.landingPageBackgroundColor ? props.landingPageBackgroundColor : 'transparent'};
`

const VideoBanner = styled.section`
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: -6px;
    video {
        min-height: 100vh;
        max-height: 100vh;
        object-fit: cover;
        overflow: hidden;
        max-width: 100%;
        min-width: 100%;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }

    .btn-mute-unmute{
        position: absolute;
        right: 10px;
        bottom: 0;
    }

    .btn-mute-unmute i {
        font-size: 2.5rem;
    }

    .homepage_banner_image {
        width: 100%;
        position: ${props => props.videoBannerEnabled === true ? 'absolute' : 'relative'};
        height: ${props => props.fullScreenMobileBanner === true ? '100vh' : '90vh'};
        background-image: ${props => props.videoBannerEnabled ? '' : `url(${props.bannerImage})`};
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;
        top: ${props => props.videoBannerEnabled === true ? '0' : 'unset'};
        bottom: ${props => props.videoBannerEnabled === true ? '0' : 'unset'};
        left: ${props => props.videoBannerEnabled === true ? '0' : 'unset'};
        right: ${props => props.videoBannerEnabled === true ? '0' : 'unset'};
        margin: ${props => props.videoBannerEnabled === true ? 'auto' : 'unset'};
    }

    .homepage_banner_image .bottom-content {
        position: absolute;
        bottom: 40px;
        display: block;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
    }

    .homepage_banner_top_links.social_icons {
        right: auto;
        left: 60px;
    }

    /* bottom content */
    .homepage_banner_image h1 {
        color: ${props => props.bannerModelNameColor};
        font-size: 140px;
        line-height: 1.6;
    }

    .btn-custom-light {
        background: transparent;
        color: ${props => props.bannerSubscribeNowTextColor} !important;
        border: 3px solid ${props => props.bannerSubscribeNowTextColor} !important;
        transition: ease-out 0.5s;
        font-weight: bold;
        border-radius: 0;
        font-size: 1.25rem;
        padding: 15px 30px;
        font-family: 'Baron Neue';
        letter-spacing: 5px;
    }

    /* top content */
    .homepage_banner_top_links {
        position: absolute;
        width: auto;
        right: 60px;
        top: 60px;
        vertical-align: middle;
    }
    .homepage_banner_top_links a {
        color: ${props => props.bannerLoginSignUpColor};
        font-size: 28px;
        margin-right: 20px;
    }
    .homepage_banner_top_links a:last-child{
        margin-right: 0;
    }
    .login-input-button{
        width: fit-content;
        border: none !important;
        color: ${props => props.newInputLoginColor} !important;
    }

    @media only screen and (max-width: 1350px) and (min-width: 1300px) {
        .homepage_banner_image .bottom-content h1 {
            // font-size: 130px;
            width: 100%;
        }
        .homepage_banner_image .bottom-content .right-content {
            padding-right: 40px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    @media only screen and (max-width: 1300px) and (min-width: 1024px) {
        .homepage_banner_image .bottom-content{
            bottom: 30px;
        }
        .homepage_banner_image .bottom-content h1 {
            font-size: 112px;
            width: 100%;
            padding-right: 310px;
        }
        .homepage_banner_image .bottom-content .right-content {
            padding-right: 40px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    @media (max-width: 1023px) and (min-width: 1000px){
        .homepage_banner_image h1 {
            font-size: 102px;
        }
    }

    @media only screen and (max-width: 999px) and (min-width: 768px){
        .homepage_banner_image .bottom-content{
            bottom: 20px;
        }
        .homepage_banner_image .bottom-content .right-content {
            position: absolute;
            right: 30px;
            bottom: 10px;
            padding: 0;
        }
        .homepage_banner_image h1 {
            font-size: 92px;
        }
        .homepage_banner_top_links{
            right: 20px;
            top: 20px;
        }
        .homepage_banner_top_links.social_icons {
            left: 20px;
            top: 20px;
        }
        .homepage_banner_top_links a {
            color: ${props => props.bannerLoginSignUpColor};
            font-size: 24px;
            margin-right: 20px;
        }
        .homepage_banner_image {
            height: unset;
            padding: 250px 0px;
        }
    }

    @media only screen and (max-width: 767px) and (min-width: 426px){
        .homepage_banner_image .bottom-content .right-content{
            display: none;
        }
        .homepage_banner_image {
            height: ${props => props.fullScreenMobileBanner === true ? '100vh' : 'unset'};
            padding: 180px 0px;
        }
        .homepage_banner_image{
            text-align: center;
            margin-top: -60px;
        }
        .homepage_banner_image .bottom-content{
            bottom: 0px;
        }
        .homepage_banner_image h1 {
            font-size: 72px;
        }
        .homepage_banner_top_links{
            right: 15px;
            top: 15px;
        }
        .homepage_banner_top_links a{
            font-size: 24px;
            margin-right: 10px;
        }
        .btn-custom-dark {
            background: transparent;
            color: ${props => props.section2SubscribeNowTextColor};
            border: 3px solid ${props => props.section2SubscribeNowTextColor} !important;
            -webkit-transition: ease-out 0.5s;
            transition: ease-out 0.5s;
            font-weight: bold;
            border-radius: 0;
            font-size: 1.25rem;
            padding: 15px 30px;
            font-family: 'Baron Neue';
            -webkit-letter-spacing: 5px;
            -moz-letter-spacing: 5px;
            -ms-letter-spacing: 5px;
            letter-spacing: 5px;
        }
    }
    @media only screen and (max-width: 425px) and (min-width: 318px) {
        .homepage_banner_image h1 {
            font-size: 50px;
        }
        .homepage_banner_image {
            height: ${props => props.fullScreenMobileBanner === true ? '100vh' : 'unset'};
            padding: 85px 0px;
        }
        .homepage_banner_image{
            text-align: center;
            margin-top: -60px;
        }
        .btn-custom-light, .btn-custom-dark{
            font-size: small;
        }
        .btn-custom-dark {
            background: transparent;
            color: ${props => props.section2SubscribeNowTextColor};
            border: 3px solid ${props => props.section2SubscribeNowTextColor} !important;
            -webkit-transition: ease-out 0.5s;
            transition: ease-out 0.5s;
            font-weight: bold;
            border-radius: 0;
            font-size: 1.25rem;
            padding: 15px 30px;
            font-family: 'Baron Neue';
            -webkit-letter-spacing: 5px;
            -moz-letter-spacing: 5px;
            -ms-letter-spacing: 5px;
            letter-spacing: 5px;
        }
    }
`


const TopBanner = styled.section`
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    
    video {
        min-height: 100vh;
        max-height: 100vh;
        object-fit: cover;
        overflow: hidden;
        max-width: 100%;
        min-width: 100%;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }

    .btn-mute-unmute{
        position: absolute;
        right: 10px;
        bottom: 0;
    }

    .btn-mute-unmute i {
        font-size: 2.5rem;
    }

    .homepage_banner_image {
        width: 100%;
        position: ${props => props.videoBannerEnabled === true ? 'absolute' : 'relative'};
        height: ${props => props.fullScreenMobileBanner === true ? '100vh' : '90vh'};
        background-image: ${props => props.videoBannerEnabled ? '' : `url(${props.bannerImage})`};
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;
        top: ${props => props.videoBannerEnabled === true ? '0' : 'unset'};
        bottom: ${props => props.videoBannerEnabled === true ? '0' : 'unset'};
        left: ${props => props.videoBannerEnabled === true ? '0' : 'unset'};
        right: ${props => props.videoBannerEnabled === true ? '0' : 'unset'};
        margin: ${props => props.videoBannerEnabled === true ? 'auto' : 'unset'};
    }

    .homepage_banner_image .bottom-content {
        position: absolute;
        bottom: 40px;
        display: block;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
    }

    .homepage_banner_top_links.social_icons {
        right: auto;
        left: 60px;
    }

    /* bottom content */
    .homepage_banner_image h1 {
        color: ${props => props.bannerModelNameColor};
        font-size: 140px;
        line-height: 1.6;
    }

    .btn-custom-light {
        background: transparent;
        color: ${props => props.bannerSubscribeNowTextColor} !important;
        border: 3px solid ${props => props.bannerSubscribeNowTextColor} !important;
        transition: ease-out 0.5s;
        font-weight: bold;
        border-radius: 0;
        font-size: 1.25rem;
        padding: 15px 30px;
        font-family: 'Baron Neue';
        letter-spacing: 5px;
    }

    .homepage_banner_image .bottom-content .right-content {
        float: right;
        width: auto;
        padding-right: 40px;
        position: absolute;
        right: 30px;
        bottom: 10px;
    }
    .right-content.mobile-show{
        display: none;
    }
    .homepage_banner_image .bottom-content .right-content .homepage_banner_subscribe {
        font-size: 28px;
        color: ${props => props.bannerSubscribeNowTextColor};
        border: 2px solid ${props => props.bannerSubscribeNowTextColor};
        padding: 5px 40px;
        border-radius: 10px;
        display: inline-block;
        margin-top: 18px;
        text-decoration-line: none;
    }
    .homepage_banner_image .bottom-content .right-content .banner_social_icon a {
        display: inline-block;
        width: 45px;
        height: 45px;
        border: 2px solid ${props => props.bannerSocialMediaIconsAndBorderColor};
        border-radius: 100px;
        color: ${props => props.bannerSocialMediaIconsAndBorderColor};
        font-size: 24px;
        text-align: center;
        margin-right: 7px;
        margin-top: 18px;
        line-height: 2.8rem;
    }
    .homepage_banner_image .bottom-content .right-content .banner_social_icon a:last-child{
        margin-right: 0px;
    }
    .homepage_banner_image .bottom-content .right-content .banner_social_icon a img {
        width: 100%;
        padding: 0 12px 4px 12px;
        vertical-align: middle;
    }

    .social_icons .banner_social_icon a {
        margin: 0 5px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        justify-content: center;
        display: inline-flex;
        align-items: center;
    }
    .social_icons .banner_social_icon a i {
        color: #000;
    }

    /* top content */
    .homepage_banner_top_links {
        position: absolute;
        width: auto;
        right: 60px;
        top: 60px;
        vertical-align: middle;
    }
    .homepage_banner_top_links a {
        color: ${props => props.bannerLoginSignUpColor};
        font-size: 28px;
        margin-right: 20px;
    }
    .homepage_banner_top_links a:last-child{
        margin-right: 0;
    }
    .login-input-button{
        width: fit-content;
        border: none !important;
        color: ${props => props.newInputLoginColor} !important;
    }

    .instagram-svg{
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.instagram)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    .tiktok-svg{
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.tiktok)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    .website-svg{
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.www)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    .twitter-svg{
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.twitter)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    .twitch-svg{
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.twitch)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    .discord-svg {
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.discord)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    .facebook-svg{
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.facebook)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    .snapchat-svg{
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.snapchat)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    .youtube-svg{
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.youtube)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    .amazon-svg{
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.amazon)});
        width: 40px;
        height: 40px;
        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        background-color: ${props => props.bannerSocialMediaIconColor};
    }

    @media only screen and (max-width: 1350px) and (min-width: 1300px) {
        .homepage_banner_image .bottom-content h1 {
            // font-size: 130px;
            width: 100%;
        }
        .homepage_banner_image .bottom-content .right-content {
            padding-right: 40px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    @media only screen and (max-width: 1300px) and (min-width: 1025px) {
        .homepage_banner_image .bottom-content{
            bottom: 30px;
        }
        .homepage_banner_image .bottom-content h1 {
            font-size: 112px;
            width: 100%;
            padding-right: 310px;
        }
        .homepage_banner_image .bottom-content .right-content {
            padding-right: 40px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    @media only screen and (max-width: 1024px) and (min-width: 768px){
        .homepage_banner_image .bottom-content{
            bottom: 20px;
        }
        .homepage_banner_image .bottom-content .right-content {
            position: absolute;
            right: 30px;
            bottom: 10px;
            padding: 0;
        }
        .homepage_banner_image h1 {
            font-size: 92px;
        }
        .homepage_banner_top_links{
            right: 20px;
            top: 20px;
        }
        .homepage_banner_top_links.social_icons {
            left: 20px;
            top: 20px;
        }
        .homepage_banner_top_links a {
            color: ${props => props.bannerLoginSignUpColor};
            font-size: 24px;
            margin-right: 20px;
        }
        .homepage_banner_image {
            height: unset;
            padding: 250px 0px;
            background-position-x: ${props => props.backgroundPosition};
            background-position-y: center;
        }
    }

    @media only screen and (max-width: 767px) and (min-width: 480px){
        .homepage_banner_image .bottom-content .right-content{
            display: none;
        }
        .homepage_banner_image {
            height: ${props => props.fullScreenMobileBanner === true ? '100vh' : 'unset'}
            padding: 180px 0px;
            background-position-x: ${props => props.backgroundPosition};
            background-position-y: center;
        }
        .mobile-hide{
            display: none;
        }
        .mobile-show {
            display: block !important;
            position: ${props => props.videoBannerEnabled === true ? 'absolute' : 'unset'};
            width: ${props => props.videoBannerEnabled === true ? '100%' : 'auto'};
            z-index: ${props => props.videoBannerEnabled === true ? '2' : '1'};
        }
        .right-content.mobile-show {
            display: block;
            padding: 0;
            width: 100%;
            text-align: center;
            margin-top: 20px;
        }
        .right-content.mobile-show .homepage_banner_subscribe {
            border: 2px solid ${props => props.bannerSubscribeNowBgColorMobile};
            padding: 5px 40px;
            display: inline-block;
            margin-top: 18px;
            color: ${props => props.bannerSubscribeNowTextColorMobile};
            background-color: ${props => props.bannerSubscribeNowBgColorMobile};
            font-size: 18px;
            width: 80%;
            border-radius: 10px;
        }
        .right-content .banner_social_icon a {
            display: inline-block;
            width: 45px;
            height: 45px;
            border: 2px solid ${props => props.bannerSocialMediaIconsAndBorderColorMobile};
            border-radius: 100px;
            color: ${props => props.bannerSocialMediaIconsAndBorderColorMobile};
            font-size: 24px;
            text-align: center;
            margin-right: 7px;
            margin-top: 18px;
            line-height: 44px;
        }
        .right-content .banner_social_icon a:last-child{
            margin-right: 0px;
        }
        .right-content .banner_social_icon a img {
            width: 100%;
            padding: 0 12px 4px 12px;
            vertical-align: middle;
        }
        .right-content .banner_social_icon a img{
            padding: 0 10px 4px 10px;
        }

        .homepage_banner_image{
            text-align: center;
            margin-top: -60px;
        }
        .homepage_banner_image .bottom-content{
            bottom: 0px;
        }
        .homepage_banner_image h1 {
            font-size: 72px;
        }
        .homepage_banner_top_links{
            right: 15px;
            top: 15px;
        }
        .homepage_banner_top_links a{
            font-size: 24px;
            margin-right: 10px;
        }
        button.navbar-toggler.collapsed{
            z-index: 2;
            color: ${props => props.bannerNavbarButtonColorClosed};
        }
        .collapse.show {
            height: 100vh;
        }
        .social-icons {
            position: absolute;
            width: unset;
            bottom: 100px;
            left: 0;
            right: 0;
            display: inline-block;
            margin: auto !important;
            text-align: center;
        }
        .access-links {
            text-align: center;
        }
        .access-links li {
            text-align: center;
            display: block;
            font-size: 34px;
        }
        .access-links a.nav-link {
            color: black !important;
        }
        li.showinmenu {
            display: block !important;
        }
        .showinmenu p span {
            color: ${props => props.bannerModelLastNameColorSideMenu};
        }
        .showinmenu p {
            color: ${props => props.bannerModelFirstNameColorSideMenu};
            font-family: ${props => props.bannerModelNameFontFamily};
            font-size: xxx-large;
            font-weight: 400;
        }
        
        .social-icons li {
            background-color: ${props => props.bannerSocialMediaTextColorMobile};
            border-radius: 50px;
            margin: 0 5px;
            width: fit-content;
            margin: 2px;
            display: inline-block;
        }
        .social-icons li {
            background-color: ${props => props.bannerSocialMediaTextColorMobile};
            width: 40px;
            height: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px;
        }
        .social-icons li a {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            object-fit: cover;
            padding: 9px 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(103 137 138) !important;
            display: inline-flex;
        }
        .social-icons a {
            line-height: 40px;
            margin: 0 4px;
            width: 40px;
            height: 40px;
            background: ${props => props.bannerSocialMediaIconColorMobile};
            font-size: 40px;
            border-radius: 50%;
            display: inline-flex;
        }
        .access-links li.nav-item {
            margin-top: 20px;
        }
        .access-links li.nav-item a{
            color: #000;
        }
        .access-links .btn-custom-dark {
            color: ${props => props.bannerSubscribeNowTextColorSideMenuMobile} !important;
            border: 2px solid ${props => props.bannerSubscribeNowTextColorSideMenuMobile} !important;
        }
        .btn-custom-dark {
            background: transparent;
            color: ${props => props.section2SubscribeNowTextColor};
            border: 3px solid ${props => props.section2SubscribeNowTextColor} !important;
            -webkit-transition: ease-out 0.5s;
            transition: ease-out 0.5s;
            font-weight: bold;
            border-radius: 0;
            font-size: 1.25rem;
            padding: 15px 30px;
            font-family: 'Baron Neue';
            -webkit-letter-spacing: 5px;
            -moz-letter-spacing: 5px;
            -ms-letter-spacing: 5px;
            letter-spacing: 5px;
        }
    }
    @media only screen and (max-width: 480px) and (min-width: 318px) {
        .homepage_banner_image h1 {
            font-size: 50px;
        }
        .access-links .btn-custom-dark {
            color: ${props => props.bannerSubscribeNowTextColorSideMenuMobile} !important;
            border: 2px solid ${props => props.bannerSubscribeNowTextColorSideMenuMobile} !important;
        }
        .homepage_banner_image {
            background-image: ${props => props.videoBannerEnabled ? '' : `url(${props.bannerImageForMobile})`};
            height: ${props => props.fullScreenMobileBanner === true ? '100vh' : 'unset'};
            padding: 85px 0px;
            background-position-x: ${props => props.backgroundPosition};
            background-position-y: center;
        }
        .mobile-hide{
            display: none;
        }
        .mobile-show {
            display: block !important;
            position: ${props => props.videoBannerEnabled === true ? 'absolute' : 'unset'};
            width: ${props => props.videoBannerEnabled === true ? '100%' : 'auto'};
            z-index: ${props => props.videoBannerEnabled === true ? '2' : '1'};
        }
        .homepage_banner_image{
            text-align: center;
            margin-top: -60px;
        }
        button.navbar-toggler.collapsed{
            z-index: 2;
            color: ${props => props.bannerNavbarButtonColorClosed};
        }
        .collapse.show {
            height: 100vh;
        }
        .social-icons {
            position: absolute;
            width: unset;
            bottom: 100px;
            left: 0;
            right: 0;
            display: inline-block;
            margin: auto !important;
            text-align: center;
        }
        .access-links {
            text-align: center;
        }
        .access-links li {
            text-align: center;
            display: block;
            font-size: 25px;
        }
        .access-links a.nav-link {
            color: black !important;
        }
        li.showinmenu {
            display: block !important;
        }
        
        .showinmenu p span {
            color: ${props => props.bannerModelLastNameColorSideMenu};;
        }

        .showinmenu p {
            color: ${props => props.bannerModelFirstNameColorSideMenu};
            font-family: ${props => props.bannerModelNameFontFamily};
            font-size: 2.4rem;
            font-weight: 400;
        }
        
        .social-icons li {
            background-color: #fff;
            border-radius: 50px;
            margin: 0 5px;
            width: fit-content;
            margin: 2px;
            display: inline-block;
        }
        .social-icons li {
            background-color: ${props => props.bannerSocialMediaTextColorMobile};
            width: 40px;
            height: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px;
        }
        .social-icons li a {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            object-fit: cover;
            padding: 9px 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(103 137 138) !important;
            display: inline-flex;
        }
        .social-icons a {
            line-height: 40px;
            margin: 0 4px;
            width: 40px;
            height: 40px;
            background: ${props => props.bannerSocialMediaIconColorMobile};
            font-size: 40px;
            border-radius: 50%;
            display: inline-flex;
        }
        .access-links li.nav-item {
            margin-top: 20px;
        }
        .access-links li.nav-item a{
            color: #000;
        }
        .btn-custom-light, .btn-custom-dark{
            font-size: small;
        }
        .btn-custom-dark {
            background: transparent;
            color: ${props => props.section2SubscribeNowTextColor};
            border: 3px solid ${props => props.section2SubscribeNowTextColor} !important;
            -webkit-transition: ease-out 0.5s;
            transition: ease-out 0.5s;
            font-weight: bold;
            border-radius: 0;
            font-size: 1.25rem;
            padding: 15px 30px;
            font-family: 'Baron Neue';
            -webkit-letter-spacing: 5px;
            -moz-letter-spacing: 5px;
            -ms-letter-spacing: 5px;
            letter-spacing: 5px;
        }
    }
`

const MainWrapper = styled.section`
    width: 100%;
    padding: 0px;

    body {
        font-family: 'Nobile';
        font-weight: 100;
    }

    .landing-section h1 {
        font-size: 112px;
        color: #f4cedd;
        font-family: ${props => props.bannerModelNameFontFamily};
    }

    .landing-section h1 span {
        color: #fff;
    }

    .btn-custom-dark {
        background: transparent;
        color: ${props => props.section2SubscribeNowTextColor};
        border: 3px solid ${props => props.section2SubscribeNowTextColor} !important;
        -webkit-transition: ease-out 0.5s;
        -webkit-transition: ease-out 0.5s;
        transition: ease-out 0.5s;
        font-weight: bold;
        border-radius: 0;
        font-size: 1.25rem;
        padding: 15px 30px;
        font-family: 'Baron Neue';
        -webkit-letter-spacing: 5px;
        -moz-letter-spacing: 5px;
        -ms-letter-spacing: 5px;
        -webkit-letter-spacing: 5px;
        -moz-letter-spacing: 5px;
        -ms-letter-spacing: 5px;
        letter-spacing: 5px;
    }

    .btn-custom-dark:hover {
        background: transparent;
        color: ${props => props.section2SubscribeNowTextColor};
        transition: ease-in 0.5s;
    }

    .btn-custom-light {
        border: 3px solid white;
        background: transparent;
        color: white;
        transition: ease-out 0.5s;
        font-weight: bold;
        border-radius: 0;
        letter-spacing: 5px;
        font-family: 'Baron Neue';
    }

    .btn-custom-light:hover {
        background: transparent;
        color: white;
        transition: ease-in 0.5s;
    }

    .img-model-round {
        width: 75vh;
        height: 75vh;
        /* border: 2px solid red; */
        border-radius: 50%;
        border-left: none;
        border-top: none;
        border-bottom: none;
        padding-right: 20px;
        padding-bottom: 20px;
        object-fit: cover;
        object-position: center;
    }

    .right-section .img-model-round {
        border: none;
        border-radius: 0px;
        height: auto;
        width: 100%;
        padding: 0;
        margin-top: -20px;
        margin-left: 20px;
        margin-bottom: 20px;
        object-fit: cover;
    }

    .box {
        border: 3px solid ${props => props.section2ImageBorder};
        max-width: 604px;
        margin: auto;
    }

    .box1 {
        border: 3px solid ${props => props.section1ImageBorderColor};
        border-radius: 100%;
        width: 75vh;
        height: 75vh;
        border-left: none;
        border-top: none;
        margin: auto;
    }
    .title {
        position: relative;
        margin: 25px 0px 10px;
    }
    .highlight {
        background-repeat: no-repeat;
        padding: 25px 0;
        background-size: cover;
        -webkit-mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.brush_stroke)});
        mask-image: url(${props => getCloudFrontAssetsUrl(props.socialIcons.brush_stroke)});
        background-color: ${props => props.section1TitleBackgroundColor};
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        -webkit-mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        top: calc(50% - 52.5px);
        margin: 0px;
    }

    h2.brushed-h2 {
        text-align: center;
        font-size: 3rem;
        font-family:  ${props => props.titleFontStyle};
        color: ${props => props.section1TitleColor};
        z-index: 1;
        position: relative;
        max-width: 640px;
        width: 100%;
        margin-inline: auto;
    }
    .right-section h2.brushed-h2 {
        text-align: center;
        font-size: 3rem;
        font-family:  ${props => props.titleFontStyle};
        color: ${props => props.section2TitleColor};
    }

    h2.highlight,
    .last-section h2 {
        font-size: 50px;
        font-family:  ${props => props.titleFontStyle};
        font-weight: bold;
        text-align: center;
        border: none;
        color: ${props => props.section1TitleColor};
    }
    .right-section  h2.brushed-h2 {
        color: ${props => props.section2TitleColor};
        font-family:  ${props => props.titleFontStyle};
    }
    ,right-section p {
        color: ${props => props.section2DescriptionColor};
    }
    .right-section .highlight {
        background-color: ${props => props.section2TitleBackgroundColor};
    }
    .right-section .btn-custom-dark{
        color: ${props => props.section2SubscribeNowTextColor} !important;
        border-color: ${props => props.section2SubscribeNowTextColor} !important;
    }
    .last-section h2{
        color: ${props => props.section3TitleColor};
    }
    .last-section p{
        color: ${props => props.section3DescriptionColor} !important;
    }
    .last-section .btn-custom-light{
        color: ${props => props.section3SubscribeNowColor};
        border-color: ${props => props.section3SubscribeNowColor};
    }
    .left-section {
        border: ${props => props.section1BorderColor};
    }
    .left-section p,
    .right-section p,
    .last-section p {
        font-size: large;
        padding-left: 30px;
        padding-right: 30px;
        font-family: ${props => props.descriptionFontStyle};
    }
    .left-section p {
        color: ${props => props.section1DescriptionColor};
    }

    .grid-layout {
        margin: auto;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .grid-container {
        display: grid;
        padding-top: 20px;
        position: inherit;
        width: inherit;
        justify-content: inherit;
        grid-template-columns: 23% 25% 25% 23%;
        grid-template-rows: 25% 25% 25% 25%;
        grid-template-areas:
            'img1 img2 img3 img3'
            'img1 img2 img3 img3'
            'img4 img4 img5 img6'
            'img4 img4 img5 img6';
        object-fit: cover;
        grid-gap: 0;
        grid-gap: 5px;
    }

    .grid-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .item-a {
        grid-area: img1;
    }

    .item-b {
        grid-area: img2;
    }

    .item-c {
        grid-area: img3;
    }

    .item-d {
        grid-area: img4;
    }

    .item-e {
        grid-area: img5;
    }

    .item-f {
        grid-area: img6;
    }

    .left-section, .right-section {
        height: 100vh
    }

    .last-section p {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        max-width: 70%;
    }

    .landing-section {
        background: url('./ALEXA-DELLANOS_01.jpg');
        background-size: cover;
        background-position-x: center;
        margin-top: -100px;
        height: 100vh;
    }


    .right-section {
        background: url(${props => props.section2BackgroundImage});
        background-size: cover;
        background-position-x: center;
    }
    @media(max-width: 1280px) {
        .right-section-wrapper {
            flex-direction: column-reverse;
        }
    }

    .last-section {
        background: url(${props => props.section3BackgroundImage});
        background-size: cover;
        background-position-x: center;
        height: 94vh;
        margin-top: 25px;
        padding: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .social-icons li {
        background-color: #fff;
        border-radius: 50px;
        margin: 0 5px;
    }

    .access-links li {
        font-size: 34px;
        font-variant: all-petite-caps;
        font-weight: bold;
        margin: auto 10px;
        color: white;
    }

    .access-links a.nav-link {
        color: #fff !important;
        font-family: ${props => props.bannerLoginSignUpFontFamily};
        font-weight: 100;
    }

    .bar-container {
        display: inline-block;
        cursor: pointer;
    }

    .bar1,
    .bar2,
    .bar3 {
        width: 35px;
        height: 5px;
        background-color: #fff;
        margin: 6px 0;
        transition: 0.4s;
    }

    .change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-9px, 6px);
        transform: rotate(-45deg) translate(-9px, 6px);
        background-color: #000 !important;
    }

    .change .bar2 {
        opacity: 0;
    }

    .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-9px, -7px);
        transform: rotate(45deg) translate(-9px, -7px);
        background-color: #000 !important;
    }

    .navbar-toggler {
        border: none !important;
    }

    .navbar-toggler:focus {
        text-decoration: none;
        border: none !important;
        outline: none;
    }

    .social-icons li a {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        object-fit: cover;
        padding: 9px 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(103 137 138) !important;
    }

    .svg-icon {
        fill: #fff;
    }

    .svg-icon:hover {
        fill: white;
    }
    .last-section .row.h-100.justify-content-center.align-items-center{
        border: 5px solid ${props => props.section3BorderColor};
    }

    @media (min-width:991px) and (max-width:1440px) {

        .img-model-round,
        .box1 {
            width: 50vh;
            height: 50vh;
        }
    }

    @media (max-width: 1201px) and (min-width: 1081px){
        .right-section {
            height: unset;
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    @media (min-width: 1080px) {
        .last-section h2{
            font-size: 80px;
            margin-bottom: 30px;
        }
        .left-section p, .right-section p, .last-section p{
            font-size: x-large;
            width: 90%
            margin-bottom: 20px;
        }
        .right-section p {
            padding-right: 0px;
            margin-right: 0;
            width: 100%;
            margin-bottom: 50px;
        }
        .left-section p {
            width: 100%;
        }
    }

    @media (min-width:991px) and (max-width:1080px)  {
        .last-section {
            height: fit-content;
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .right-section {
            height: unset;
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    @media (max-width:991px) {
        .highlight {
            background-size: 100% 100%;
            text-align: center !important;
        }

        h2.highlight,
        .last-section h2 {
            padding-left: unset;
        }

        .last-section {
            height: unset;
        }

        .landing-section {
            margin-top: -115px;
            height: unset;
            padding: 200px 0px;
        }
        .left-section, .right-section {
            height: unset;
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    @media (max-width:768px) {

        .img-model-round,
        .box1 {
            max-width: 80vw;
            max-height: 80vw;
            margin: auto;
        }
        .right-section .img-model-round{
            max-height: fit-content;
        }
        .highlight {
            background-size: 100% 100%;
            text-align: center !important;
        }

        h2.highlight,
        .last-section h2 {
            padding-left: unset;
        }

        .right-section .img-model-round {
            max-width: 100%;
        }

        .landing-section h1 {
            font-size: 72px;
        }

        .left-section p,
        .right-section p,
        .last-section p {
            font-size: x-large;
            padding-left: unset;
            padding-right: unset;
        }

        .last-section p {
            text-align: center;
            margin: 0 auto;
            width: 100%;
            max-width: 80%;
        }
    }

    @media(max-width:767px) {
        .landing-section {
            margin-top: -68px;
        }

        ul.social-icons {
            display: block;
            margin: 0 auto;
            width: -webkit-fill-available;
            text-align: center;
        }

        .social-icons li {
            background-color: #fff;
            border-radius: 50px;
            margin: 0 5px;
            width: fit-content;
            margin: 2px;
            display: inline-block;
        }

        .main-header .custom-nav {
            color: #000 !important;
        }

        .change .custom-nav {
            background: #fff !important;
        }

        .change .social-icons li {
            background-color: #f4cedd;
        }

        .change .social-icons svg {
            color: #000;
        }

        .change .social-icons {
            position: absolute;
            width: unset;
            bottom: 40px;
            left: 0;
            right: 0;
        }

        .left-section p,
        .right-section p,
        .last-section p {
            font-size: large;
            padding-left: unset;
            padding-right: unset;
        }

        .svg-icon {
            fill: #000;
            max-width: 32px;
        }

        .change .access-links a.nav-link {
            color: black !important;
        }

        .change .navbar-collapse.collapse.show {
            height: 93vh;
        }

        .change .access-links {
            text-align: center;
        }

        .change li.showinmenu {
            display: block !important;
        }

        .showinmenu p {
            color: ${props => props.bannerModelFirstNameColorSideMenu};
            font-family: ${props => props.bannerModelNameFontFamily};
            font-size: xxx-large;
            font-weight: 400;
        }

        .last-section {
            height: unset;
        }


        .change li#svg-icon-id {
            background-color: #f4cedd !important;
        }

        .landing-section {
            padding: 180px 0px;
        }
        .right-section .row.h-100.justify-content-center.align-items-center {
            padding-right: 20px;
            padding-left: 20px;
        }

    }
    @media (max-width: 666px) and (min-width: 476px){
        .col-xl-7.text-center.mt-5{
            padding: 0px 45px;
        }
    }
    @media (max-width: 475px) {

        .btn-custom-light,
        .btn-custom-dark {
            width: 90%;
        }

        .landing-section {
            padding: 80px 0px;
        }
        .col-xl-7.text-center.mt-5{
            padding: 0px 45px;
        }
        .last-section p {
            max-width: 100%;
        }
        h2.brushed-h2, .right-section h2.brushed-h2 {
            font-size: 2.5rem;
            bottom: 0;
            display: inline-flex;
            height: 110px;
            justify-content: center;
            align-items: center;
        }
        .right-section .btn.btn-lg, .last-section .btn.btn-lg {
            padding: 15px 5px;
            width: 90%;
            white-space: normal;
        }
        .left-section .img-model-round, .left-section .box1 {
            max-width: 85vw;
            max-height: 85vw;
        }
    }

    @media (max-width:425px) {

        .img-model-round,
        .box1 {
            max-width: 30vh;
            max-height: 30vh;
        }
        .btn-custom-dark, .btn-custom-light {
            font-size: medium;
        }
        .highlight {
            background-size: 100% 95%;
        }

        .right-section .img-model-round {
            max-width: 100%;
        }

        .landing-section h1 {
            font-size: 50px;
        }

        .grid-container {
            grid-template-columns: 49% 49%;
            grid-template-rows: 25% 25% 25% 25%;
            grid-template-areas:
                'img1 img2'
                'img3 img3'
                'img5 img6'
                'img4 img4';
        }

        .last-section {
            padding: 15px 10px;
        }
        .img-model-round {
            padding-right: 10px;
            padding-bottom: 10px;
        }
        h2.highlight, .last-section h2{
            font-size: 40px;
            padding-bottom: 25px;
        }
        .last-section h2{
            margin-bottom: 0px;
        }
        .last-section .btn.btn-lg {
            padding: 15px 0px;
        }
    }
    @media (max-width: 400px){
        .left-section .img-model-round, .left-section .box1 {
            max-width: 80VW;
            max-height: 80VW;
        }
    }
`

class LandingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            videoIsMuted: false,
            width: window.innerWidth,
            isLoading: true
        }
        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.videoMuteUnMute = this.videoMuteUnMute.bind(this)
        this.toggleMute = this.toggleMute.bind(this)
        this.updateDimensions = this.updateDimensions.bind(this)

    }

    updateDimensions() {
        this.setState({ width: window.innerWidth })
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions)
        this.props.getAllTheme3Settings(() => {
            this.setState({ isLoading: false })
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    toggleCollapse() {
        this.setState({ isOpen: !this.state.isOpen })
    }

    toggleMute() {

        let video = document.getElementById('myVideo')
        this.setState({ videoIsMuted: !this.state.videoIsMuted })
        video.muted = !video.muted
    }

    videoMuteUnMute() {

        return (
            <>
                {this.state.videoIsMuted === false ?
                    <button className='btn btn-sm-lg shadow-none text-white btn-mute-unmute' onClick={this.toggleMute} > <i className='fas fa-volume-off fa-5x'></i></button>
                    :
                    <button className='btn btn-sm-lg shadow-none text-white btn-mute-unmute' onClick={this.toggleMute} > <i className='fas fa-volume-up fa-2x'></i></button>
                }
            </>
        )
    }

    render() {
        const theme3Settings = _.get(this.props.auth.theme3Settings, 'theme3Settings', '')
        const {
            theme_2_instagram_url,
            theme_2_twitter_url,
            theme_2_facebook_url,
            theme_2_tiktok_url,
            theme_2_twitch_url,
            theme_2_discord_url,
            theme_2_snapchat_url,
            theme_2_website_url,
            theme_2_youtube_url,
            theme_2_amazon_wishlist_url,
            log_in_navbar_name,
            sign_up_navbar_name,
            promotion_settings
        } = this.props.auth.appSettings

        const ribbonPosition = _.get(promotion_settings, 'subscription_promotion_ribbon_position', 'above menu')
        const titleFontStyle = {
            fontFamily: theme3Settings.model_name_font,
            fontWeight: '100',
            color: theme3Settings.banner_model_first_name_color
        }
        const newTitleFontStyle = {
            fontFamily: theme3Settings.theme_1_banner_model_name_font,
            fontWeight: '100',
            color: theme3Settings.theme_1_banner_model_name_color,
            marginBottom: '0'
        }

        const titleFontStyleMobile = {
            fontFamily: theme3Settings.model_name_font,
            fontWeight: '100',
            color: theme3Settings.banner_model_first_name_color_side_menu
        }

        const titleFontStyleSpan = {
            fontFamily: theme3Settings.model_name_font,
            fontWeight: '100',
            color: theme3Settings.banner_model_last_name_color,
            display: 'inline-block'
        }

        const accessLinkFontStyle = {
            fontFamily: theme3Settings.login_signup_font,
            color: theme3Settings.banner_login_signup_color
        }

        const accessLinkFontColorMobile = {
            fontFamily: theme3Settings.login_signup_font,
            color: theme3Settings.banner_login_signup_color_mobile
        }

        const navbarIconColor = {
            color: this.state.isOpen === true ? theme3Settings.side_menu_button_color_open : theme3Settings.side_menu_button_color_closed
        }

        const navbarBackgroundColor = {
            backgroundColor: this.state.isOpen === true ? theme3Settings.side_menu_background_color : 'transparent'
        }

        let redirectUrl = '/register'
        let showInstagramIcon = (theme_2_instagram_url === '' || theme_2_instagram_url === undefined) ? false : true
        let showTwitterIcon = (theme_2_twitter_url === '' || theme_2_twitter_url === undefined) ? false : true
        let showSnapchatIcon = (theme_2_snapchat_url === '' || theme_2_snapchat_url === undefined) ? false : true
        let showFacebookIcon = (theme_2_facebook_url === '' || theme_2_facebook_url === undefined) ? false : true
        let showYoutubeIcon = (theme_2_youtube_url === '' || theme_2_youtube_url === undefined) ? false : true
        let showWebsiteIcon = (theme_2_website_url === '' || theme_2_website_url === undefined) ? false : true
        let showTiktokIcon = (theme_2_tiktok_url === '' || theme_2_tiktok_url === undefined) ? false : true
        let showAmazonIcon = (theme_2_amazon_wishlist_url === '' || theme_2_amazon_wishlist_url === undefined) ? false : true
        let showTwitchIcon = (theme_2_twitch_url === '' || theme_2_twitch_url === undefined) ? false : true
        let showDiscordIcon = (theme_2_discord_url === '' || theme_2_discord_url === undefined) ? false : true

        const [firstName, ...lastName] = _.isEmpty(theme3Settings) ? '' : theme3Settings.banner_model_name.split(' ')
        const fullLastName = lastName.join(' ')

        const mainWidth = this.state.width

        let videoUrl = theme3Settings.desktop_video_banner_url

        if (mainWidth < 999 || theme3Settings.desktop_video_banner_url === '') {
            videoUrl = theme3Settings.video_banner_url
        }
        if (theme3Settings.video_banner_url === '' && theme3Settings.desktop_video_banner_url !== '') {
            videoUrl = theme3Settings.desktop_video_banner_url
        }
        if (this.state.isLoading === true) {
            return <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
                <Loader loading={true} size={10} />
            </div>
        }

        let theme3MobileUrl = theme3Settings.banner_image_for_mobile
        if (_.isEmpty(theme3MobileUrl)) {
            theme3MobileUrl = theme3Settings.banner_image
        }

        const socialMediaIcons = socialMediaSvgIcons()

        return (
            <WebsiteFontColor>
                <LandingPageMainDiv landingPageBackgroundColor={theme3Settings.landing_page_background_color}>
                    <AnnouncementBanner />
                    {(ribbonPosition === 'above menu' || ribbonPosition === 'above hero') ?
                        <PromotionNotificationBar /> : <></>
                    }
                    {theme3Settings.enable_theme_1_banner_section === true ?
                        <VideoBanner bannerSubscribeNowTextColor={theme3Settings.theme_1_banner_subscribe_now_text_color}
                            bannerModelNameFontFamily={theme3Settings.theme_1_banner_model_name_font}
                            fullScreenMobileBanner={theme3Settings.enable_banner_section_full_mobile}
                            videoBannerEnabled={theme3Settings.enable_theme_1_banner_section}
                            newInputLoginColor={theme3Settings.theme_1_banner_login_text_color}
                        >
                            {theme3Settings.enable_theme_1_banner_section === true ?
                                <>
                                    <video id='myVideo' src={theme3Settings.theme_1_video_banner_url} type='mp4' playsInline autoPlay loop muted />
                                </>
                                : null}
                            <div className='homepage_banner_image'>
                                {theme3Settings.enable_video_banner === true ?
                                    this.videoMuteUnMute()
                                    : null}
                                <div className='container h-100'>
                                    <div className='row h-100 justify-content-center align-items-end align-items-md-center'>
                                        <div className='col-12 text-center'>
                                            <h1 style={newTitleFontStyle}>{theme3Settings.theme_1_banner_model_name}</h1>
                                            <Link className='btn btn-sm-lg btn-custom-light shadow-none text-lowercase' to={redirectUrl}>
                                                {theme3Settings.theme_1_banner_subscribe_now_text}
                                            </Link>
                                            <Link className='btn login-input-button btn-custom-light btn-sm-lg d-block m-auto shadow-none text-lowercase' to='/login'>
                                                {theme3Settings.theme_1_banner_login_text}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </VideoBanner> : null}
                    {theme3Settings.enable_banner_section === true ?
                        <TopBanner bannerImage={theme3Settings.banner_image}
                            bannerSubscribeNowTextColor={theme3Settings.banner_subscribe_now_text_color}
                            bannerSocialMediaIconColor={theme3Settings.banner_social_media_icon_color}
                            bannerSocialMediaIconColorMobile={theme3Settings.banner_social_media_icon_color_mobile}
                            bannerSocialMediaTextColorMobile={theme3Settings.banner_social_media_text_color_mobile}
                            bannerSubscribeNowTextColorSideMenuMobile={theme3Settings.banner_subscribe_now_text_color_side_menu_mobile}
                            bannerModelFirstNameColorSideMenu={theme3Settings.banner_model_first_name_color_side_menu}
                            bannerModelLastNameColorSideMenu={theme3Settings.banner_model_last_name_color_side_menu}
                            bannerLoginSignUpColor={theme3Settings.banner_login_signup_color}
                            bannerSocialMediaIconsAndBorderColor={theme3Settings.banner_social_media_icon_and_border_color}
                            bannerSocialMediaIconsAndBorderColorMobile={theme3Settings.banner_social_media_icon_and_border_color_mobile}
                            bannerModelNameFontFamily={theme3Settings.model_name_font}
                            bannerLoginSignUpFontFamily={theme3Settings.login_signup_font}
                            bannerNavbarButtonColorClosed={theme3Settings.side_menu_button_color_closed}
                            bannerNavbarButtonColorOpen={theme3Settings.side_menu_button_color_open}
                            bannerNavbarBackgroundColor={theme3Settings.side_menu_background_color}
                            fullScreenMobileBanner={theme3Settings.enable_banner_section_full_mobile}
                            videoBannerEnabled={theme3Settings.enable_video_banner}
                            backgroundPosition={theme3Settings.mobile_banner_background_position}
                            bannerImageForMobile={theme3MobileUrl}
                            socialIcons={socialMediaIcons}
                        >
                            <div className='mobile-show d-none'>
                                <nav className='navbar shadow-none' style={navbarBackgroundColor}>
                                    <button className='navbar-toggler collapsed' style={navbarIconColor} type='button' data-toggle='collapse' onClick={this.toggleCollapse}>
                                        {this.state.isOpen === true ?
                                            <i className='fas fa-times fa-2x'></i>
                                            : <i className='fas fa-bars fa-2x'></i>
                                        }
                                    </button>
                                    <div className={this.state.isOpen ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'} id='navbarsExampleDefault'>
                                        <ul className='navbar-nav mr-auto mt-2 mt-lg-0 social-icons'>
                                            {showInstagramIcon ? <li><a href={theme_2_instagram_url} target='_blank' rel='noopener noreferrer' className='instagram-svg'>
                                            </a> </li> : <></>}
                                            {showTiktokIcon ? <li><a href={theme_2_tiktok_url} target='_blank' rel='noopener noreferrer' className='tiktok-svg'>
                                            </a></li> : <></>}
                                            {showFacebookIcon ? <li><a href={theme_2_facebook_url} target='_blank' rel='noopener noreferrer' className='facebook-svg'>
                                            </a></li> : <></>}
                                            {showTwitterIcon ? <li><a href={theme_2_twitter_url} target='_blank' rel='noopener noreferrer' className='twitter-svg'>
                                            </a></li> : <></>}
                                            {showTwitchIcon ? <li><a href={theme_2_twitch_url} target='_blank' rel='noopener noreferrer' className='twitch-svg'>
                                            </a></li> : <></>}
                                            {showDiscordIcon ? <li><a href={theme_2_discord_url} target='_blank' rel='noopener noreferrer' className='discord-svg'>
                                            </a></li> : <></>}
                                            {showSnapchatIcon ? <li><a href={theme_2_snapchat_url} target='_blank' rel='noopener noreferrer' className='snapchat-svg'>
                                            </a></li> : <></>}
                                            {showYoutubeIcon ? <li><a href={theme_2_youtube_url} target='_blank' rel='noopener noreferrer' className='youtube-svg'>
                                            </a></li> : <></>}
                                            {showWebsiteIcon ? <li style={{ height: '10px' }}><a href={theme_2_website_url} target='_blank' rel='noopener noreferrer' className='website-svg'>
                                            </a></li> : <></>}
                                            {showAmazonIcon ? <li><a href={theme_2_amazon_wishlist_url} target='_blank' rel='noopener noreferrer' className='amazon-svg'>
                                            </a></li> : <></>}
                                        </ul>
                                        <ul className='navbar-nav my-2 my-lg-0 access-links'>
                                            <li className='showinmenu d-none'>
                                                <p style={titleFontStyleMobile}>{firstName}&nbsp;
                                                    <span style={{ display: 'inline-block' }}>{fullLastName}</span>
                                                </p>
                                            </li>
                                            <li className='nav-item'>
                                                <Link className='mx-5' to='/login' style={accessLinkFontColorMobile}>{log_in_navbar_name}</Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link className='mx-5' to='/register' style={accessLinkFontColorMobile}>{sign_up_navbar_name}</Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link className='btn btn-sm-lg btn-custom-dark shadow-none text-lowercase' to={redirectUrl}>
                                                    {theme3Settings.banner_subscribe_now_text}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            {theme3Settings.enable_video_banner === true ?
                                <>
                                    <video id='myVideo' src={videoUrl} type='mp4' playsInline autoPlay loop muted />
                                </>
                                : null}
                            <div className='homepage_banner_image'>
                                {theme3Settings.enable_video_banner === true ?
                                    this.videoMuteUnMute()
                                    : null}
                                <div className='container h-100'>
                                    <div className='row h-100 justify-content-center align-items-center'>
                                        <div className='col-12 text-center'>
                                            <h1 style={titleFontStyle}>{firstName}&nbsp;<span style={titleFontStyleSpan}>{fullLastName}</span></h1>
                                            <Link className='btn btn-sm-lg  btn-custom-light shadow-none text-lowercase' to={redirectUrl}>
                                                {theme3Settings.banner_subscribe_now_text}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='homepage_banner_top_links social_icons mobile-hide'>
                                        <div className='banner_social_icon'>
                                            {showInstagramIcon ? <a href={theme_2_instagram_url} target='_blank' rel='noopener noreferrer' className='instagram-svg'>
                                            </a> : <></>}
                                            {showTiktokIcon ? <a href={theme_2_tiktok_url} target='_blank' rel='noopener noreferrer' className='tiktok-svg'>
                                            </a> : <></>}
                                            {showFacebookIcon ? <a href={theme_2_facebook_url} target='_blank' rel='noopener noreferrer' className='facebook-svg'>
                                            </a> : <></>}
                                            {showTwitterIcon ? <a href={theme_2_twitter_url} target='_blank' rel='noopener noreferrer' className='twitter-svg'>
                                            </a> : <></>}
                                            {showTwitchIcon ? <a href={theme_2_twitch_url} target='_blank' rel='noopener noreferrer' className='twitch-svg'>
                                            </a> : <></>}
                                            {showDiscordIcon ? <a href={theme_2_discord_url} target='_blank' rel='noopener noreferrer' className='discord-svg'>
                                            </a> : <></>}
                                            {showSnapchatIcon ? <a href={theme_2_snapchat_url} target='_blank' rel='noopener noreferrer' className='snapchat-svg'>
                                            </a> : <></>}
                                            {showYoutubeIcon ? <a href={theme_2_youtube_url} target='_blank' rel='noopener noreferrer' className='youtube-svg'>
                                            </a> : <></>}
                                            {showWebsiteIcon ? <a href={theme_2_website_url} target='_blank' rel='noopener noreferrer' className='website-svg'>
                                            </a> : <></>}
                                            {showAmazonIcon ? <a href={theme_2_amazon_wishlist_url} target='_blank' rel='noopener noreferrer' className='amazon-svg'>
                                            </a> : <></>}
                                        </div>
                                    </div>
                                    <div className='homepage_banner_top_links mobile-hide'>
                                        <Link className='mx-5' to='/login' style={accessLinkFontStyle}>{log_in_navbar_name}</Link>
                                        <Link className='mx-5' to='/register' style={accessLinkFontStyle}>{sign_up_navbar_name}</Link>
                                    </div>
                                </div>
                            </div>
                        </TopBanner> : null}
                    {ribbonPosition === 'below hero' &&
                        <PromotionNotificationBar />
                    }
                    <MainWrapper
                        section1ImageBorderColor={theme3Settings.section_1_image_border_color}
                        section1TitleColor={theme3Settings.section_1_title_color}
                        section1TitleBackgroundColor={theme3Settings.section_1_title_background_color}
                        section1DescriptionColor={theme3Settings.section_1_description_color}
                        section2BackgroundImage={theme3Settings.section_2_background_image}
                        section2ImageBorder={theme3Settings.section_2_image_border_color}
                        section2TitleColor={theme3Settings.section_2_title_color}
                        section2TitleBackgroundColor={theme3Settings.section_2_title_background_color}
                        section2DescriptionColor={theme3Settings.section_2_description_color}
                        section2SubscribeNowButtonText={theme3Settings.section_2_subscribe_now_text}
                        section2SubscribeNowTextColor={theme3Settings.section_2_subscribe_now_text_color}
                        section3BackgroundImage={theme3Settings.section_3_image}
                        section3BorderColor={theme3Settings.section_3_border_color}
                        section3TitleColor={theme3Settings.section_3_title_color}
                        section3DescriptionColor={theme3Settings.section_3_description_color}
                        section3SubscribeNowColor={theme3Settings.section_3_subscribe_now_text_color}
                        titleFontStyle={theme3Settings.title_font}
                        descriptionFontStyle={theme3Settings.description_font}
                        socialIcons={socialMediaIcons}
                        section1BorderColor={theme3Settings.section_1_border_color}
                    >
                        {theme3Settings.enable_section_1 === true ?
                            <div className='left-section'>
                                <div className='container-fluid h-100 px-lg-5'>
                                    <div className='row h-100 justify-content-center align-items-center'>
                                        <div className='col-xl-6 text-center mt-2'>
                                            <div className='box1'>
                                                <img src={theme3Settings.section_1_image} alt='section_1_image' className='img-model-round' />
                                            </div>
                                        </div>
                                        <div className='col-xl-6 mt-2'>
                                            <div className='title'>
                                                <h2 className='highlight'>&nbsp;</h2>
                                                <h2 className='brushed-h2'>{theme3Settings.section_1_title}</h2>
                                            </div>
                                            <p style={{ whiteSpace: 'pre-wrap', textAlign: theme3Settings.section_1_description_alignment }}>{theme3Settings.section_1_description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                        {theme3Settings.enable_section_2 === true ?
                            <div className='right-section'>
                                <div className='container-fluid h-100 px-lg-5'>
                                    <div className='row h-100 justify-content-center align-items-center right-section-wrapper'>
                                        <div className='col-xl-6 text-center mt-2'>
                                            <div className='title'>
                                                <h2 className='highlight'>&nbsp;</h2>
                                                <h2 className='brushed-h2'>{theme3Settings.section_2_title}</h2>
                                            </div>
                                            <p style={{ textAlign: theme3Settings.section_2_description_alignment, whiteSpace: 'pre-wrap' }}>{theme3Settings.section_2_description}</p>
                                            <Link className='btn btn-lg  btn-custom-dark shadow-none text-lowercase' to={redirectUrl}>
                                                {theme3Settings.section_2_subscribe_now_text}
                                            </Link>
                                        </div>
                                        <div className='col-xl-6 text-center mt-5'>
                                            <div className='box'>
                                                <img src={theme3Settings.section_2_image} alt='section_2_image' className='img-model-round' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                        {theme3Settings.enable_grid_section === true ?
                            <div className='grid-layout'>
                                <div className='grid-container'>
                                    <div className='item-a'><img src={theme3Settings.grid_section_image_1} alt='grid_section_image_1' /></div>
                                    <div className='item-b'><img src={theme3Settings.grid_section_image_2} alt='grid_section_image_2' /></div>
                                    <div className='item-c'><img src={theme3Settings.grid_section_image_3} alt='grid_section_image_3' /></div>
                                    <div className='item-d'><img src={theme3Settings.grid_section_image_4} alt='grid_section_image_4' /></div>
                                    <div className='item-e'><img src={theme3Settings.grid_section_image_5} alt='grid_section_image_5' /></div>
                                    <div className='item-f'><img src={theme3Settings.grid_section_image_6} alt='grid_section_image_6' /></div>
                                </div>
                            </div> : null}
                        {theme3Settings.enable_section_3 === true ?
                            <div className='last-section'>
                                <div className='container-fluid' style={{ height: '100%' }}>
                                    <div className='row h-100 justify-content-center align-items-center'>
                                        <div className='col-12 text-center py-4'>
                                            <h2>{theme3Settings.section_3_title}</h2>
                                            <p style={{ textAlign: theme3Settings.section_3_description_alignment, whiteSpace: 'pre-wrap' }}>{theme3Settings.section_3_description}</p>
                                            <Link className='btn btn-lg  btn-custom-light mt-4 shadow-none text-lowercase' to={redirectUrl}>
                                                {theme3Settings.section_3_subscribe_now_text}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                    </MainWrapper>
                    <FooterPage></FooterPage>
                </LandingPageMainDiv>
            </WebsiteFontColor>
        )
    }
}

LandingPage.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getAllTheme3Settings: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    admin: state.admin
})

export default connect(
    mapStateToProps,
    { getAllTheme3Settings, getCloudFrontAssetsUrl, socialMediaSvgIcons }
)(LandingPage)
