import React  from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

const CardColor = styled.div`
    background-color: ${props => props.cardColor} !important;

    .card-header {
        border-bottom: 1px solid ${props => props.cardHeaderBorderColor};
    }
`

CardRow.propTypes = {
    title: PropTypes.string.isRequired,
    className : PropTypes.string,
    children: PropTypes.node,
    auth: PropTypes.object.isRequired
}

function CardRow (props) {
    const { card_background_color, card_header_border_color } = props.auth.appSettings

    return <div className={classNames('row', props.className )}>
        <div className='col-12'>
            <CardColor className='card' cardColor={card_background_color} cardHeaderBorderColor={card_header_border_color}>
                {props.title ? <div className='card-header'>
                    {props.title}
                </div> : <></>}
                <div className='card-body'>
                    {props.children}
                </div>
            </CardColor>
        </div>
    </div>
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(withRouter(CardRow))
