import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import WebsiteFontColor from './WebsiteFontColor'
import { toggleNewPaymentModal } from '../../actions/utilityActions'
import { getAppBaseUrl } from './../../actions/api'
const BASE_URL = getAppBaseUrl()

class NewPaymentModal extends Component {
    constructor() {
        super()

        this.redirectToCCBill = this.redirectToCCBill.bind(this)
    }

    redirectToCCBill() {
        axios.get(BASE_URL + '/api/ccbill/set_payment_method').then(res => {
            if (res.data.action) {
                window.location.replace(res.data.url)
            }
        })
    }

    render() {
        const { content_color, content_font_color, card_background_color } = this.props.auth.appSettings
        const modalConditions = this.props.utility.isNewPaymentModalOpen

        return (
            <WebsiteFontColor>
                {modalConditions ? <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#000000ef' }}>
                    <div className='modal-dialog modal-dialog-centered' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                <h5 className='modal-title'>Set Up a Payment Method</h5>
                                <button
                                    className='close'
                                    onClick={() => this.props.toggleNewPaymentModal()}
                                    style={{ color: content_font_color }}
                                />
                            </div>
                            <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                <p className='text-center'>
                                    You have not set a payment method. Please set a payment method to continue.
                                    You will be redirected to another site to set up your payment method.
                                </p>
                                <button
                                    type='button'
                                    className='btn btn-block btn-pill'
                                    onClick={this.redirectToCCBill}
                                    style={{ backgroundColor: content_color, color: content_font_color }}
                                >
                                    Set Payment Method
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
            </WebsiteFontColor>
        )
    }
}

NewPaymentModal.propTypes = {
    auth: PropTypes.object.isRequired,
    utility: PropTypes.object.isRequired,
    toggleNewPaymentModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    utility: state.utility
})

export default connect(
    mapStateToProps,
    { toggleNewPaymentModal }
)(withRouter((NewPaymentModal)))
