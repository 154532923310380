import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getAppSettings, getLinkPageData, getAllCustomMenuLinks, getInfluencerHelpPopupData } from './../actions/appSettingsActions'
import { getUserInfluencerHelpPopupData } from './../actions/userInfluencerHelpAction'
import { getModelDetails, getUserDetails } from './../actions/authActions'

const MyraidProFont = styled.div`
    font-family: ${props => props.fontFamily};
`

class Startup extends Component {
    constructor() {
        super()

        this.state = {
            isBackgroundSet: false
        }
    }

    componentDidMount() {
        this.props.getUserDetails(() => {
            this.props.getAppSettings(() => {
                if (this.props.auth.appSettings.influencer_help_enabled === true && this.props.auth.isAdmin === true && this.props.auth.isSupport === false) {
                    this.props.getInfluencerHelpPopupData(() => {
                    })
                }
                if (this.props.auth.appSettings.user_influencer_help_enabled === true && this.props.auth.user.isAdmin === false) {
                    this.props.getUserInfluencerHelpPopupData(() => {
                    })
                }
            })
        })
        this.props.getModelDetails()
        this.props.getAllCustomMenuLinks(() => { })
        this.props.getLinkPageData()
    }

    componentDidUpdate() {
        if (this.props.auth.isReady === true && this.state.isBackgroundSet === false) {
            this.setFavicon()
            this.setBackground()
            this.setState({ isBackgroundSet: true })
        }
    }

    setFavicon() {
        const { model_id } = this.props.auth.appSettings
        if (this.props.chat.userProfileInfo[model_id] !== undefined) {
            let favicon = document.getElementById('favicon')
            favicon.href = this.props.chat.userProfileInfo[model_id].profile
        }
    }

    setBackground() {
        const { use_background_image, theme_background_image, site_background_color } = this.props.auth.appSettings

        if (use_background_image && theme_background_image !== '') {
            document.body.style = `background: url(${theme_background_image}); background-size: 'contain';`
        } else {
            document.body.style = `background: ${site_background_color};`
        }
    }

    render() {
        const { font_family } = this.props.auth.appSettings

        if (this.props.auth.isBlocked) {
            return (
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h3><center>This website is blocked in your area.</center></h3>
                        </div>
                    </div>
                </div>
            )
        }

        return this.props.auth.isReady && this.props.auth.isProfileReady && this.props.socket.isSocketInitialyConnected === true
            ? <MyraidProFont fontFamily={font_family}>{this.props.children}</MyraidProFont>
            : (<></>)
    }
}

Startup.propTypes = {
    auth: PropTypes.object,
    chat: PropTypes.object,
    children: PropTypes.object,
    getAppSettings: PropTypes.func.isRequired,
    getModelDetails: PropTypes.func.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    getAllCustomMenuLinks: PropTypes.func.isRequired,
    getInfluencerHelpPopupData: PropTypes.func.isRequired,
    socket: PropTypes.object,
    getLinkPageData: PropTypes.func.isRequired,
    getUserInfluencerHelpPopupData: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    chat: state.chat,
    socket: state.socket
})

export default connect(
    mapStateToProps,
    {
        getAppSettings,
        getModelDetails,
        getUserDetails,
        getAllCustomMenuLinks,
        getInfluencerHelpPopupData,
        getLinkPageData,
        getUserInfluencerHelpPopupData
    }
)(Startup)
