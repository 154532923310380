import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
    galleryAddFile,
    galleryRemoveFile,
    galleryReset,
    dragDropImage,
    galleryAddFileInPreviewFile,
    galleryRemoveFileInPreviewFile,
    previewGalleryReset,
    setIsPreviewModeEnable
} from '../../actions/galleryActions'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import _ from 'lodash'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import FullScreenModelPopUpDialog from '../Layouts/FullScreenModelPopUpDialog'
import { isIOS, isIPad13 } from 'react-device-detect'
import { FILE_SIZE_LIMIT_IN_BYTE } from '../../utils/constant'
import HelperModelForPreview from './HelperModelForPreview'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import MediaSelectionPopup from '../media/MediaSelectionPopup'
import { sortArrayById } from '../../utils/common'
import CreatableSelect from 'react-select/creatable'
import { getAllCategory } from '../../actions/mediaAction'

const DropZoneSection = styled.section`
    width: 100%;
    height: fit-content;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 12px;
    border-color: ${props => props.site_font_color};

    .category-label {
        color: ${props => props.site_font_color};
    }
`

const CloseButton = styled.button`
    right: 5px; 
    top: 0;
    position: absolute; 
    border-radius: 82%; 
    width: 24px;
    background-color: ${props => props.content_color} !important; 
    color: ${props => props.content_font_color} !important;
    cursor: pointer;

    :before {
        font-size: medium;
    }

    @media(max-width: 767px) {
        right: ${props => (props.isRearrangeModeEnable === true && props.name === 'original') ? '20%' : '13%'};
    }
`

const PlusButton = styled.label`
    background-color: ${props => props.content_color};
    color: ${props => props.content_font_color};
    height: 100%;
    border-radius: 5px;
    cursor: ${props => props.disabled === false ? 'pointer' : 'inherit'};
    display: flex;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;

    :hover {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }
`

const DivData = styled.div`
    background-image: ${props => props.imageUrl};
    height:150px;
    background-position:center;
    background-repeat:no-repeat;
    background-size: cover;
    background-color: #000000;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    width: inherit;

    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block !important;
        margin-bottom: 1%;
    }

`

const ColumnDiv = styled.div`
    width: 100%;
    margin-bottom: ${props => props.isUploading === true ? '50px' : '20px'};
    
    .positioning {
        display: none !important;
    }

    .form-group {
        position: absolute;
        bottom: -24px;
    }

    .progress-span {
        position: absolute;
        bottom: -44px;
        width: 88%;
    }

    .progress {
        border-radius: 5px;
    }

    .progress-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media(max-width: 767px) {
        .positioning {
            display: inline-block !important;
            width: 20%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }

        .positioning i {
            color: ${props => props.site_font_color};
            border-radius: 50%;
            width: 29px;
            height: 29px;
        }

        .progress-span {
            width: 92%;
        }
    }
`

const VideoDiv = styled.div`
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 5px;

    video {
        max-width: 210px;
        object-fit: cover;
        border-radius: 5px;
    }
    
    .progress {
        border-radius: 5px;
    }
    
    @media (min-width:991px) and (max-width:1280px) {

        video {
            max-width: 153px;
        }
    }
    @media (min-width:767px) and (max-width:991px) {

        video {
            max-width: 183px;
        }
    }
    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block;
        video {
            max-width: 100%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }
    }
`

const GalleryIcon = styled.div`
    position: absolute;
    top: calc(45.5%);
    left: calc(47.5%);
    cursor: pointer;

    .video-button-span {
        font-size: 30px;
        color: rgb(211, 211, 211);
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
    }

    .play-icon {
        font-size: 30px;
        position: absolute;
        border-radius: 51px;
        left: -5px;
        top: -5px;
    }
`
const ReorderButton = styled.div`
color:${props => props.site_font_color} !important;
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${props => props.site_font_color} !important;
}
.custom-control-input:checked~.custom-control-label::before {
    border-color: ${props => props.site_font_color} !important;
}
`

const MarkAsPreviewButton = styled.div`
display: flex;
align-items: center;
margin-bottom: 2px;
color:${props => props.site_font_color} !important;
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${props => props.site_font_color} !important;
}
.custom-control-input:checked~.custom-control-label::before {
    border-color: ${props => props.site_font_color} !important;
}
`

const OverLayDiv = styled.div`
    width: 100%;
    height: ${props => props.type === 'video' ? '150px' : '100%'};
    max-width: ${props => props.type === 'video' ? '210px' : '100%'};
    position: absolute;
    background-color: #FFFFFF4F;
    z-index: 4;
    padding: 0 20px;
    border-radius: 5px;
    display: ${props => props.isRearrangeModeEnable === true ? 'block' : 'none'};
    left: ${props => props.type === 'video' ? '3px' : '0px'};

    @media (min-width:991px) and (max-width:1280px) {
        left: ${props => props.type === 'video' ? '5px' : '0px'};
        max-width: ${props => props.type === 'video' ? '153px' : '100%'};
    }
    @media (min-width:767px) and (max-width:991px) {
        left: ${props => props.type === 'video' ? '5px' : '0px'};
        max-width: ${props => props.type === 'video' ? '183px' : '100%'};        
    }
    @media (max-width:767px) {
        left: 0px !important;
        max-width: 100% !important;
    }
`

const HelperButton = styled.button`
    border-radius: 25px;
    border: none;
    font-size: 20px;
    vertical-align: middle;
`

const NewGalleryMultiImageModel = (props) => {

    const { content_color, site_font_color, button_shadow_color } = props.auth.appSettings
    const { selectFromContentManager } = props
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [type, setType] = useState('')
    const [dragId, setDragId] = useState()
    const [isRearrangeModeEnableForFirstTab, setIsRearrangeModeEnableForFirstTab] = useState(false)
    const [isRearrangeModeEnableForSecondTab, setIsRearrangeModeEnableForSecondTab] = useState(false)
    const [isRearrangeModeEnable, setIsRearrangeModeEnable] = useState(selectFromContentManager ? isRearrangeModeEnableForFirstTab : isRearrangeModeEnableForSecondTab)
    const [helperModelOpen, setHelperModelOpen] = useState(false)
    const [showMediaSelectionPopup, setShowMediaSelectionPopup] = useState(false)
    const [showMediaPreviewSelectionPopup, setShowMediaPreviewSelectionPopup] = useState(false)
    const [selectedMediaIds, setSelectedMediaIds] = useState([])
    const [selectedPreviewMediaIds, setSelectedPreviewMediaIds] = useState([])
    const { category } = props.media
    const [categoryOptions, setCategoryOptions] = useState([])

    useEffect(() => {
        props.galleryReset()
        props.previewGalleryReset()
    }, [])

    useEffect(() => {
        const isRearrangeModeEnable = selectFromContentManager ? isRearrangeModeEnableForFirstTab : isRearrangeModeEnableForSecondTab
        setIsRearrangeModeEnable(isRearrangeModeEnable)
    }, [selectFromContentManager, isRearrangeModeEnableForFirstTab, isRearrangeModeEnableForSecondTab])

    useEffect(() => {
        if (category.length === 0) {
            props.getAllCategory()
        }
    }, [])

    useEffect(() => {
        const newCategoryOptions = category.filter(item => (item.slug !== 'all media' && item.slug !== 'preview'))
            .map(item => {
                return { value: item._id, label: item.name }
            })
        setCategoryOptions(newCategoryOptions)
    }, [category])

    const clearData = () => {
        setIsDialogOpen(false)
        setUrl('')
        setType('')
        setDragId('')
        setIsRearrangeModeEnableForFirstTab(false)
        setIsRearrangeModeEnableForSecondTab(false)
    }

    const handleGalleryChange = (e, name) => {
        const files = e.target.files
        if (name === 'preview') {
            if (files.length > 1) {
                props.previewGalleryReset()
                return props.setSweetAlert({ description: 'You can not add more than one preview.' })
            } else {
                const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
                if (!acceptExtension.includes(files[0].type)) {
                    return props.setSweetAlert({ description: 'Media format is not supported.' })
                }
                handleAddFile(files[0], name)
            }
        } else {
            galleryFiles(files, name)
        }
    }

    const galleryFilesFromOriginal = (files) => {
        if (selectFromContentManager || props.isUploading) {
            return
        }
        galleryFiles(files, 'original')
    }

    const galleryFilesFromPreview = (files) => {
        if (selectFromContentManager || props.isUploading) {
            return
        }
        if (props.gallery.previewFiles.length === 0) {
            galleryFiles([files[0]], 'preview')
        } else {
            props.previewGalleryReset()
            return props.setSweetAlert({ description: 'You can not add more than one preview.' })
        }
    }

    const galleryFiles = (files, name) => {
        if (isRearrangeModeEnable !== true || name === 'preview') {
            const existFilesCount = props.gallery.files.length
            const uploadFileCount = 45 - existFilesCount < files.length ? 45 - existFilesCount : files.length
            const totalFilesCount = files.length + existFilesCount
            let type = 'gallery'
            for (let index = 0; index < uploadFileCount; index++) {
                const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
                if (!acceptExtension.includes(files[index].type)) {
                    return props.setSweetAlert({ description: 'Media format is not supported.' })
                }
                if (totalFilesCount === 1) {
                    if (['video/mp4', 'video/quicktime'].includes(files[index].type)) {
                        type = 'video'
                    } else if (['image/jpeg', 'image/jpeg', 'image/png'].includes(files[index].type)) {
                        type = 'photo'
                    }
                }
            }

            for (let index = 0; index < uploadFileCount; index++) {
                const fileSize = _.get(files[index], 'size', -1)
                if (fileSize !== -1 && fileSize > FILE_SIZE_LIMIT_IN_BYTE) {
                    props.setSweetAlert({ description: `File ${files[index].name} size is too large.` })
                } else {
                    handleAddFile(files[index], name)
                }
            }
            if (totalFilesCount > 45) {
                props.setSweetAlert({ description: `You have selected ${totalFilesCount} images, maximum 45 images allowed.` })
            } else {
                props.setPostType(type)
            }
        }
    }

    const handleAddFile = (file, name) => {
        if (file) {
            const newFileName = file.name
            const newFileSize = file.size
            const galleries = (name === 'original') ? props.gallery.files : props.gallery.previewFiles
            let findRecord = -1
            if (name === 'original') {
                findRecord = _.findIndex(galleries, function (n) {
                    return (n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                })
            }
            if (findRecord === -1) {
                let newFile = {
                    selectedFile: file,
                    renderFile: file.format === 'modern' ? file.path : URL.createObjectURL(file)
                }
                if (name === 'original') {
                    props.galleryAddFile(newFile)
                } else {
                    props.galleryAddFileInPreviewFile(newFile)
                }
            }
        }
    }

    const handleDeletePhoto = (index, name) => {
        let filesLength = files.length
        if (name === 'original') {
            if (filesLength === 2) {
                selectFromContentManager
                    ? setIsRearrangeModeEnableForFirstTab(false)
                    : setIsRearrangeModeEnableForSecondTab(false)
            }
            filesLength -= 1
            props.galleryRemoveFile(index)
        } else {
            props.galleryRemoveFileInPreviewFile(index)
        }
        if (filesLength < 1) {
            if (isPreviewModeEnable) {
                props.setIsPreviewModeEnable()
            }
            props.previewGalleryReset()
        }
    }

    const { classes } = props
    const { files, uploadProgress, previewFiles, isPreviewModeEnable } = props.gallery

    const openDialog = (url, type) => {
        if (props.disableButtons === true) {
            return
        }
        if (!isDialogOpen === true) {
            document.querySelector('body').style.overflow = 'hidden'
        } else {
            document.querySelector('body').style.overflow = 'visible'
        }
        setUrl(url)
        setType(type)
        setIsDialogOpen(!isDialogOpen)
    }

    /** handle drag and drop code */
    const handleDrag = (index) => {
        if (isRearrangeModeEnable === true) {
            setDragId(index)
        }
    }

    const handleDrop = (index) => {
        if (isRearrangeModeEnable === true && dragId !== '') {
            const dragFile = files[dragId]
            files.splice(dragId, 1)
            files.splice(index, 0, dragFile)

            props.dragDropImage(files)
            if (props.previewIndex !== '') {
                if (Number(props.previewIndex) === Number(dragId)) {
                    props.setIndex(index)
                } else if (Number(props.previewIndex) === Number(index)) {
                    props.setIndex(dragId)
                }
            }
            setDragId('')
        }
        setDragId('')
    }

    const reArrangeImage = (dragIndex, dropIndex) => {
        const draggedImage = files[dragIndex]
        files[dragIndex] = files[dropIndex]
        files[dropIndex] = draggedImage
        props.dragDropImage(files)
        if (props.previewIndex !== '') {
            if (Number(props.previewIndex) === Number(dragId)) {
                props.setIndex(dropIndex)
            } else if (Number(props.previewIndex) === Number(dropIndex)) {
                props.setIndex(dragId)
            }
        }
    }

    const uploadProgressInMb = (galleryIndex, progressNumber, type) => {
        let actualFileSize = 0
        let actualFileUploaded = '0'
        const selectedFile = type === 'original' ? files[galleryIndex].selectedFile : previewFiles[galleryIndex].selectedFile
        actualFileSize = (_.get(selectedFile, 'size', 0) / 1024) / 1024
        actualFileUploaded = ((actualFileSize * progressNumber) / 100).toFixed(2)

        return <div className='progress-text' style={{ color: props.auth.appSettings.site_font_color }}>Uploading {actualFileUploaded} MB Of {actualFileSize.toFixed(2)} MB</div>
    }

    const getGalleryProgress = (index, name) => {
        const progress = _.get(uploadProgress, 'progress', '')
        const progressIndex = _.get(uploadProgress, 'index', '')
        const progressNumber = _.get(uploadProgress, 'progressNumber', '')
        const type = _.get(uploadProgress, 'type', '')
        const processingIndex = props.gallery.processingIndex
        const processedIndex = props.gallery.processedIndex

        if (processingIndex !== -1 && processingIndex === index) {
            return <span style={{ color: props.auth.appSettings.site_font_color }} className='progress-span'>Processing</span>
        }
        if (progress !== '' && progress !== '100%' && progressIndex === index) {
            return <span className='progress-span mt-1'>
                <div className='progress'>
                    <div
                        className='progress-bar progress-bar-striped progress-bar-animated'
                        role='progressbar'
                        style={{ width: progress, backgroundColor: props.content_color, color: props.content_font_color }}
                    >{progress}</div>
                </div>
                {uploadProgressInMb(progressIndex, progressNumber, type)}
            </span>
        }
        if (name !== 'preview' && processedIndex && processedIndex.includes(index)) {
            return <span style={{ color: props.auth.appSettings.site_font_color }} className='progress-span'>Uploaded</span>
        }
        return <></>
    }

    const previewContent = (name) => {
        let array = name === 'original' ? files : previewFiles

        return <div className='row justify-content-center'>
            {
                array.map((item, i) => {
                    const { format, small_thumb } = item.selectedFile
                    let url = format === 'modern' ? small_thumb : item.renderFile
                    url = `url(${url})`
                    return (
                        <ColumnDiv isUploading={props.disableButtons} content_color={content_color} site_font_color={site_font_color} key={i} className='align-items-center col-12 col-md-4 d-flex justify-content-center mt-3'>
                            {['video/quicktime', 'video/mp4'].includes(item.selectedFile.type) ?
                                <>
                                    <CloseButton
                                        className='align-items-center close d-flex justify-content-center mr-4 mt-2'
                                        content_color={props.content_color}
                                        content_font_color={props.content_font_color}
                                        type={'video'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            handleDeletePhoto(i, name)
                                        }}
                                        disabled={props.disableButtons}
                                        style={{ zIndex: '5' }}
                                        isRearrangeModeEnable={isRearrangeModeEnable}
                                        name={name}
                                    />
                                    <VideoDiv
                                        className='d-flex justify-content-center align-items-center'
                                        onClick={() => { openDialog(item.renderFile, 'video') }}
                                        draggable={isRearrangeModeEnable === true && name === 'original' ? true : false}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDragStart={() => {
                                            handleDrag(i)
                                        }}
                                        onDrop={(e) => {
                                            e.preventDefault()
                                            handleDrop(i)
                                        }}
                                    >
                                        <OverLayDiv type='video' isRearrangeModeEnable={isRearrangeModeEnable} />
                                        {
                                            format === 'modern'
                                                ?
                                                <img
                                                    src={small_thumb}
                                                    id='uploaded-video'
                                                    style={{
                                                        objectFit: 'cover',
                                                        height: '150px',
                                                        width: '100%',
                                                        position: 'relative',
                                                        backgroundColor: 'black'
                                                    }}
                                                />
                                                : isIOS ?
                                                    <video
                                                        src={item.renderFile}
                                                        poster={getCloudFrontAssetsUrl('images/no-preview-video.png')}
                                                        id='uploaded-video'
                                                        style={{
                                                            height: '150px',
                                                            width: '100%',
                                                            position: 'relative',
                                                            backgroundColor: 'black'
                                                        }}
                                                    />
                                                    :
                                                    <video
                                                        src={item.renderFile}
                                                        id='uploaded-video'
                                                        style={{
                                                            height: '150px',
                                                            width: '100%',
                                                            position: 'relative',
                                                            backgroundColor: 'black'
                                                        }}
                                                    />
                                        }
                                        <GalleryIcon>
                                            <span className='video-button-span'>
                                                <i className='fas fa-play-circle play-icon' />
                                            </span>
                                        </GalleryIcon>
                                    </VideoDiv>
                                    {isRearrangeModeEnable === true && name === 'original' &&
                                        <div className='positioning text-dark d-inline'>
                                            <i
                                                className='fas fa-arrow-up fa-2x'
                                                style={{ display: i === 0 && 'none' }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    reArrangeImage(i, i - 1)
                                                }}
                                            ></i>{i > 0 && <br></br>}
                                            <i
                                                className='fas fa-arrow-down fa-2x'
                                                style={{
                                                    display: i === (files.length - 1) && 'none',
                                                    marginTop: i === 0 ? '0px' : '1rem'
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    reArrangeImage(i, i + 1)
                                                }}
                                            ></i>
                                        </div>
                                    }
                                    {((name === uploadProgress.type || props.gallery.processingIndex !== -1) && props.disableButtons) && getGalleryProgress(i, name)}
                                </>
                                :
                                <>
                                    <CloseButton
                                        className='align-items-center close d-flex justify-content-center mr-3 mt-2'
                                        content_color={props.content_color}
                                        content_font_color={props.content_font_color}
                                        type={'image'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            handleDeletePhoto(i, name)
                                        }}
                                        disabled={props.disableButtons}
                                        style={{ zIndex: '5' }}
                                        isRearrangeModeEnable={isRearrangeModeEnable}
                                        name={name}
                                    />
                                    <DivData
                                        imageUrl={url}
                                        onClick={() => { openDialog(item.renderFile, 'photo') }}
                                        draggable={isRearrangeModeEnable === true && name === 'original' ? true : false}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDragStart={() => {
                                            handleDrag(i)
                                        }}
                                        onDrop={(e) => {
                                            e.preventDefault()
                                            handleDrop(i)
                                        }}
                                    >
                                        <OverLayDiv type='image' isRearrangeModeEnable={isRearrangeModeEnable} />
                                    </DivData>
                                    {isRearrangeModeEnable === true && name === 'original' &&
                                        <div className='positioning text-dark d-inline'>
                                            <i className='fas fa-arrow-up fa-2x' style={{ display: i === 0 && 'none' }} onClick={(e) => {
                                                e.preventDefault()
                                                reArrangeImage(i, i - 1)
                                            }}></i>{i > 0 && <br></br>}
                                            <i className='fas fa-arrow-down fa-2x' style={{ display: i === (files.length - 1) && 'none', marginTop: i === 0 ? '0px' : '1rem' }} onClick={(e) => {
                                                e.preventDefault()
                                                reArrangeImage(i, i + 1)
                                            }}></i>
                                        </div>
                                    }
                                    {((name === uploadProgress.type || props.gallery.processingIndex !== -1) && props.disableButtons) && getGalleryProgress(i, name)}
                                </>
                            }
                        </ColumnDiv>
                    )
                })
            }
            {files.length < 45 && files.length > 0 && isRearrangeModeEnable === false && name !== 'preview' ?
                getPlusButton(name)
                : null
            }
        </div >
    }

    const getPlusButton = (name) => {
        let id = `button-gallery-${name}`
        const { files, previewFiles } = props.gallery
        return <>
            <div className='col-12 col-md-4 mt-3 mb-5'
                style={{
                    height: '150px',
                    marginBottom: '3%',
                    margin: ((name === 'original' && files.length === 0) || (name !== 'original' && previewFiles.length === 0)) ? 'auto' : '0%',
                    maxWidth: '150px',
                    outlineColor: 'transparent'
                }}
                name={name}
            >
                <PlusButton
                    htmlFor={id}
                    content_color={props.content_color}
                    fullWidth={true}
                    className='align-items-center justify-content-center'
                    content_font_color={props.content_font_color}
                    disabled={props.disableButtons}
                    name={name}
                    button_shadow_color={button_shadow_color}
                    onClick={() => {
                        if (selectFromContentManager) {
                            document.body.style.overflow = 'hidden'
                            if (name === 'original') {
                                setShowMediaSelectionPopup(true)
                            } else {
                                setShowMediaPreviewSelectionPopup(true)
                            }
                        }
                    }}
                >
                    {!selectFromContentManager &&
                        <input
                            accept='image/png,image/jpg,image/jpeg,video/mp4,video/quicktime,mp4/*'
                            className={classes.upload_input}
                            id={id}
                            onChange={(e) => handleGalleryChange(e, name)}
                            style={{ display: 'none' }}
                            type='file'
                            disabled={props.disableButtons}
                            multiple={true}
                            name={name}
                        />
                    }
                    <i className='fas fa-plus' aria-hidden='true'></i>
                </PlusButton>
            </div >
        </>
    }
    const isDropZoneVisible = isRearrangeModeEnable === true ? true : false

    const handleMedia = (selectedMediaIds, name) => {

        if (name == 'preview') {
            setShowMediaPreviewSelectionPopup(false)
            setSelectedPreviewMediaIds(selectedMediaIds)
            if (selectedMediaIds.length > 1) {
                props.previewGalleryReset()
                props.setSweetAlert({ description: 'You can not add more than one preview.' })
                return
            }
        } else {
            setShowMediaSelectionPopup(false)
            setSelectedMediaIds(selectedMediaIds)
        }

        const savedMedia = props.media.allMedia.filter(item => selectedMediaIds.includes(item._id))
        const sortedMedia = sortArrayById(savedMedia, selectedMediaIds)
        if (sortedMedia.length > 0) {
            const selectedFiles = sortedMedia.map(item => {
                return {
                    _id: item._id,
                    path: item.path,
                    small_thumb: item.small_thumb,
                    format: 'modern',
                    type: item.type === 'photo' ? 'image/jpeg' : 'video/mp4',
                    size: item.size,
                    name: item._id + (item.type === 'photo' ? '.jpeg' : '.mp4')
                }
            })
            galleryFiles(selectedFiles, name)
        }
    }

    const onSelection = (media) => {
        let files = showMediaSelectionPopup ? props.gallery.files : props.gallery.previewFiles
        files = files.map(file => file.selectedFile._id)
        const deletedIdsIndex = []
        const name = showMediaSelectionPopup ? 'original' : 'preview'

        for (let i = 0; i < files.length; i++) {
            if (!media.includes(files[i])) {
                deletedIdsIndex.push(i)
            }
        }
        for (let i = deletedIdsIndex.length - 1; i >= 0; i--) {
            handleDeletePhoto(deletedIdsIndex[i], name)
        }

        handleMedia(media, name)
    }

    useEffect(() => {
        const selectedMediaIds = props.gallery.files.map(file => file.selectedFile._id)
        setSelectedMediaIds(selectedMediaIds)
    }, [props.gallery.files])

    useEffect(() => {
        const selectedPreviewMediaIds = props.gallery.previewFiles.map(file => file.selectedFile._id)
        setSelectedPreviewMediaIds(selectedPreviewMediaIds)
    }, [props.gallery.previewFiles])

    return <>
        {isDialogOpen === true &&
            <FullScreenModelPopUpDialog
                url={url}
                handleClose={() => { openDialog('', '') }}
                type={type}
                showWatermark={false}
            />
        }
        <Dropzone multiple={true} onDrop={galleryFilesFromOriginal} noDrag={isDropZoneVisible}>
            {({ getRootProps }) => (
                <DropZoneSection content_color={content_color} site_font_color={site_font_color}>
                    <div {...getRootProps()} style={{ height: '100%', textAlign: 'center', color: '#ECECEC', outline: '0px' }}>
                        {<h4 style={{ color: site_font_color, marginBottom: '0%' }}>{selectFromContentManager ? 'Choose from Content Manager' : 'Upload Media'}</h4>}
                        {previewContent('original')}
                        {files.length === 0 &&
                            getPlusButton('original')
                        }

                        <div className='row align-items-center'>
                            <div className='col-12 col-md-6 px-5 mt-1 text-left'>
                                {files.length > 1 && !isIPad13 &&
                                    <ReorderButton content_color={content_color} site_font_color={site_font_color}>
                                        <div className='custom-control custom-switch'>
                                            <input
                                                type='checkbox'
                                                className='custom-control-input'
                                                id='REORDER_MODE'
                                                name='ReorderMode'
                                                onChange={() => {
                                                    selectFromContentManager
                                                        ? setIsRearrangeModeEnableForFirstTab(!isRearrangeModeEnableForFirstTab)
                                                        : setIsRearrangeModeEnableForSecondTab(!isRearrangeModeEnableForSecondTab)
                                                }}
                                                checked={isRearrangeModeEnable}
                                                disabled={props.disableButtons}
                                            />
                                            <label className='custom-control-label' htmlFor='REORDER_MODE'>Reorder</label>
                                        </div>
                                    </ReorderButton>
                                }
                                {props.isLocked === 'Locked' &&
                                    <MarkAsPreviewButton content_color={content_color} site_font_color={site_font_color}>
                                        <div className='custom-control custom-switch'>
                                            <input
                                                type='checkbox'
                                                className='custom-control-input'
                                                id='PREVIEW_MODE'
                                                name='PreviewMode'
                                                onChange={() => {
                                                    props.setIsPreviewModeEnable()
                                                }}
                                                checked={isPreviewModeEnable}
                                                disabled={props.disableButtons}
                                            />
                                            <label className='custom-control-label' htmlFor='PREVIEW_MODE'>Add Preview</label>
                                        </div>
                                        <HelperButton className='mx-1'
                                            disabled={props.disableButtons}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setHelperModelOpen(true)
                                            }} style={{ backgroundColor: 'transparent', color: content_color }}>
                                            <i className='fas fa-question-circle'></i>
                                        </HelperButton>
                                    </MarkAsPreviewButton>
                                }
                            </div>
                            {(!props.selectFromContentManager) &&
                                <div className='col-12 col-md-6 mt-1 mt-md-0 text-left' >
                                    <CreatableSelect
                                        className='text-dark'
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 10 })
                                        }}
                                        isMulti={false}
                                        value={props.selectedCategoryOption}
                                        options={categoryOptions}
                                        onChange={(tags) => props.setSelectedCategoryOption(tags)}
                                        noOptionsMessage={() => 'No Category Available'}
                                        isDisabled={props.disableButtons}
                                        isClearable={true}
                                        placeholder='Select Category'
                                    />
                                </div>
                            }
                        </div>
                        {props.disableButtons === true && clearData()}
                    </div>
                </DropZoneSection>
            )}
        </Dropzone>
        {isPreviewModeEnable === true && props.isLocked === 'Locked' &&
            <div className='mt-2'>
                <Dropzone multiple={true} onDrop={galleryFilesFromPreview} noDrag={isDropZoneVisible}>
                    {({ getRootProps }) => (
                        <DropZoneSection content_color={content_color}>
                            <div {...getRootProps()} style={{ height: '100%', textAlign: 'center', color: '#ECECEC', outline: '0px' }}>
                                <h4 style={{ color: site_font_color, marginBottom: '0%' }}>Preview</h4>
                                {previewContent('preview')}
                                {previewFiles.length === 0 &&
                                    getPlusButton('preview')
                                }
                                {props.disableButtons === true && clearData()}
                            </div>
                        </DropZoneSection>
                    )}
                </Dropzone>
            </div>
        }
        {helperModelOpen === true &&
            <HelperModelForPreview setHelperModelOpen={setHelperModelOpen} />
        }
        {
            (showMediaSelectionPopup || showMediaPreviewSelectionPopup) &&
            <MediaSelectionPopup
                title={showMediaPreviewSelectionPopup ? 'Select Preview' : 'Select Media'}
                onClose={() => { showMediaSelectionPopup ? setShowMediaSelectionPopup(false) : setShowMediaPreviewSelectionPopup(false) }}
                prevSelectedMedia={showMediaSelectionPopup ? selectedMediaIds : selectedPreviewMediaIds}
                onSelection={onSelection}
            />
        }
    </>
}

NewGalleryMultiImageModel.propTypes = {
    auth: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    content_color: PropTypes.string,
    content_font_color: PropTypes.string,
    classes: PropTypes.object.isRequired,
    galleryAddFile: PropTypes.func.isRequired,
    galleryRemoveFile: PropTypes.func.isRequired,
    galleryReset: PropTypes.func.isRequired,
    gallery: PropTypes.shape({
        files: PropTypes.array.isRequired,
        uploadProgress: PropTypes.object,
        previewFiles: PropTypes.array,
        isPreviewModeEnable: PropTypes.bool.isRequired,
        processingIndex: PropTypes.string.isRequired,
        processedIndex: PropTypes.array.isRequired
    }),
    disableButtons: PropTypes.bool.isRequired,
    setIndex: PropTypes.func.isRequired,
    previewIndex: PropTypes.string,
    isLocked: PropTypes.string,
    setSweetAlert: PropTypes.func,
    dragDropImage: PropTypes.func,
    setPostType: PropTypes.func,
    galleryAddFileInPreviewFile: PropTypes.func.isRequired,
    galleryRemoveFileInPreviewFile: PropTypes.func.isRequired,
    previewGalleryReset: PropTypes.func.isRequired,
    setIsPreviewModeEnable: PropTypes.func.isRequired,
    selectFromContentManager: PropTypes.bool,
    isUploading: PropTypes.bool,
    getAllCategory: PropTypes.func.isRequired,
    selectedCategoryOption: PropTypes.any,
    setSelectedCategoryOption: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    media: state.media,
    content_color: state.auth.appSettings.content_color,
    content_font_color: state.auth.appSettings.content_font_color,
    gallery: state.gallery
})

export default connect(
    mapStateToProps,
    {
        galleryAddFile,
        galleryRemoveFile,
        galleryReset,
        setSweetAlert,
        dragDropImage,
        galleryAddFileInPreviewFile,
        galleryRemoveFileInPreviewFile,
        previewGalleryReset,
        setIsPreviewModeEnable,
        getAllCategory
    }
)((NewGalleryMultiImageModel))
