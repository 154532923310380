import axios from 'axios'
import { getAppBaseUrl } from './api'
import { ADD_USER_INFLUENCER_HELP_POPUP,
    USER_INFLUENCER_HELP_POPUP_HIDE,
    ADD_USER_INFLUENCER_HELP_LIST,
    ADD_USER_INFLUENCER_HELP_DETAIL,
    USER_INFLUENCER_HELP_RESUME_API_CALL,
    UPDATE_USER_INFLUENCER_HELP_LIST,
    UPDATE_USER_INFLUENCER_HELP_LIST_NOTIFICATION,
    ADD_USER_INFLUENCER_HELP_LIST_NOTIFICATION,
    SET_USER_HELP_NOTIFICATION_UNREAD_COUNT,
    RESET_USER_HELP_NOTIFICATION,
    SET_USER_NOTIFICATION_FILTER,
    SET_USER_HELP_TAG_FILTER,
    SET_USER_HELP_TAGS } from './types'
const BASE_URL = getAppBaseUrl()

export const getUserInfluencerHelpPopupData = () => dispatch => {
    axios.get(BASE_URL + '/user-help/popup-with-notification-count')
        .then(res => {
            dispatch(addUserInfluencerHelpPopupData(res.data.data))
        }).catch((error) => {
            console.log(error)
        })
}

export const addUserInfluencerHelpPopupData = (data) => {
    return {
        type: ADD_USER_INFLUENCER_HELP_POPUP,
        payload: data
    }
}

export const setUserHelpDataAsRead = (id) => dispatch => {
    axios.get(BASE_URL + `/user-help/read/${id}`)
        .then(res => {
            dispatch(setUserHelpNotificationUnreadCount(res.data.data))
        })
}

export const userInfluencerPopupHide = () => {
    return {
        type: USER_INFLUENCER_HELP_POPUP_HIDE,
        payload: {}
    }
}


export const setUserHelpNotificationUnreadCount = (data) => {
    return {
        type: SET_USER_HELP_NOTIFICATION_UNREAD_COUNT,
        payload: data
    }
}


export const getUserInfluencerHelpData = (filter, callback) => dispatch => {
    axios.post(BASE_URL + '/user-help/list', filter)
        .then(res => {
            const object = {
                currentPage: res.data.data.currentPage,
                totalPages: res.data.data.totalPages
            }
            if (filter.pageNum === 1) {
                dispatch(addUserInfluencerHelpData(res.data.data))
                dispatch(setUserHelpTags(res.data.data.helpTagsData))
                object.helpTags = res.data.data.helpTagsData
            } else {
                dispatch(updateUserInfluencerHelpData(res.data.data))
            }
            return callback(object)
        }).catch(() => {
            const object = {
                currentPage: 1,
                totalPages: 0
            }
            return callback(object)
        })
}


export const addUserInfluencerHelpData = (data) => {
    return {
        type: ADD_USER_INFLUENCER_HELP_LIST,
        payload: data
    }
}

export const updateUserInfluencerHelpData = (data) => {
    return {
        type: UPDATE_USER_INFLUENCER_HELP_LIST,
        payload: data
    }
}

export const getUserInfluencerHelpDetailData = (helpId, callback) => dispatch => {
    axios.get(BASE_URL + `/user-help/details/${helpId}`)
        .then(res => {
            dispatch(addUserInfluencerHelpDetailData(res.data.data))
            return callback()
        }).catch((error) => {
            console.log(error)
            return callback()
        })
}

export const addUserInfluencerHelpDetailData = (data) => {
    return {
        type: ADD_USER_INFLUENCER_HELP_DETAIL,
        payload: data
    }
}

export const userInfluencerHelpResumeAPICall = () => {
    return {
        type: USER_INFLUENCER_HELP_RESUME_API_CALL
    }
}

export const getUserInfluencerHelpNotificationData = (body, callback) => dispatch => {
    axios.post(BASE_URL + '/user-help/list/notifications', body)
        .then(res => {
            if (body.pageNum === 1) {
                dispatch(addUserInfluencerHelpNotificationData(res.data.data))
            } else {
                dispatch(updateUserInfluencerHelpNotificationData(res.data.data))
            }
            const object = {
                currentPage: res.data.data.currentPage,
                totalPages: res.data.data.totalPages,
                isChangeUnreadToAll: res.data.data.isChangeUnreadToAll
            }
            if (res.data.data.isChangeUnreadToAll === true) {
                dispatch(setUserNotificationFilter('all'))
            }
            return callback(object)
        }).catch((error) => {
            const object = {
                currentPage: 1,
                totalPages: 0
            }
            console.log(error)
            return callback(object)
        })
}

export const addUserInfluencerHelpNotificationData = (data) => {
    return {
        type: ADD_USER_INFLUENCER_HELP_LIST_NOTIFICATION,
        payload: data
    }
}

export const updateUserInfluencerHelpNotificationData = (data) => {
    return {
        type: UPDATE_USER_INFLUENCER_HELP_LIST_NOTIFICATION,
        payload: data
    }
}

export const setUserNotificationFilter = (type) => {
    return {
        type: SET_USER_NOTIFICATION_FILTER,
        payload: type
    }
}

export const resetUserHelpNotification = () => {
    return {
        type: RESET_USER_HELP_NOTIFICATION
    }
}

// this will not call in this file
export const setUserHelpTagFilter = (type) => {
    return {
        type: SET_USER_HELP_TAG_FILTER,
        payload: type
    }
}

export const setUserHelpTags = (type) => {
    return {
        type: SET_USER_HELP_TAGS,
        payload: type
    }
}
