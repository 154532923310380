import axios from 'axios'
import {
    SET_CHAT_TIMER,
    REMOVE_CHAT_TIMER
} from './types'
import { getAppBaseUrl } from './api'

const BASE_URL = getAppBaseUrl()

// Get User List
export const getChatBookingTimerData = (data) => dispatch => {
    axios.post(BASE_URL + '/api/chat/get_next_meeting_time', data)
        .then(res => {
            dispatch(setChatTimer(res.data))
        })
}

export const setChatTimer = (data) => {
    return {
        type: SET_CHAT_TIMER,
        payload: data
    }
}

export const removeChatTimer = (data) => {
    return {
        type: REMOVE_CHAT_TIMER,
        payload: data
    }
}
