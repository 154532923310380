import OneSignalReact from 'react-onesignal'
import _ from 'lodash'
import { SET_PUSH_NOTIFICATION_PROMPT, ONE_SIGNAL_INITIALIZED } from './types'
import store from './../store'

export const initOneSignal = () => async (dispatch) => {
    try {
        const state = store.getState()
        const { pushNotification, auth } = state
        const { isOneSignalInitialized } = pushNotification
        const { appSettings } = auth
        const { one_signal, web_push_notification_enabled } = appSettings
        const { app_id, safari_web_id } = one_signal

        if (_.isEmpty(app_id) || _.isEmpty(safari_web_id) || !web_push_notification_enabled || isOneSignalInitialized) {
            return
        }


        let initData = {
            welcomeNotification: {
                disable: true
            },
            appId: app_id,
            safari_web_id: safari_web_id,
            autoResubscribe: true,
            notificationClickHandlerAction: 'navigate',
            notificationClickHandlerMatch: 'origin'
        }

        if (window.location.hostname === 'localhost') {
            initData.allowLocalhostAsSecureOrigin = true
        }
        await OneSignalReact.init(initData)
        dispatch({ type: ONE_SIGNAL_INITIALIZED, payload: true })
        dispatch(setUserDataToOneSignal())
        dispatch(showPushNotificationPrompt())
    } catch (error) {
        dispatch({ type: ONE_SIGNAL_INITIALIZED, payload: false })
        console.log(error)
    }
}

export const setUserDataToOneSignal = () => async () => {
    const state = store.getState()
    const { pushNotification, auth } = state
    const { isOneSignalInitialized } = pushNotification
    const { user, isAdmin, isAuthenticated } = auth
    const userId = _.get(user, '_id', false)

    if (!isOneSignalInitialized || !isAuthenticated) {
        return
    }

    const userIdList = localStorage.getItem('hide_notification_prompt') ? JSON.parse(localStorage.getItem('hide_notification_prompt')) : {}
    const permission = await getNotificationPermission()

    if (permission === 'granted' && Object.keys(userIdList).includes(userId) && userIdList[userId]) {
        setExternalUserId(userId)
        if (!isAdmin) {
            SetTagsToOneSignal(user)
        }
    }
}

export const showPushNotificationPrompt = () => async (dispatch) => {
    const state = store.getState()
    const { isOneSignalInitialized } = state.pushNotification
    const { appSettings, user, isAdmin, isAuthenticated } = state.auth
    const { web_push_notification_enabled } = appSettings
    const subscriptionStatus = _.get(user, 'ccbillSubscriptionStatus', '0')
    const userId = _.get(user, '_id', false)
    if (!isOneSignalInitialized || !isAuthenticated || !web_push_notification_enabled || ['proxy_user', 'support'].includes(state.auth.user.role) || !(isAdmin || ['1', '2'].includes(subscriptionStatus))) {
        return
    }

    const permission = await getNotificationPermission()
    const userIdList = localStorage.getItem('hide_notification_prompt') ? JSON.parse(localStorage.getItem('hide_notification_prompt')) : {}

    if (['default', 'granted'].includes(permission) && Object.keys(userIdList).includes(userId) === false && (isAdmin || ['1', '2'].includes(subscriptionStatus)) && user.role !== 'live_stream_manager') {
        document.querySelector('body').style.overflow = 'hidden'
        dispatch({ type: SET_PUSH_NOTIFICATION_PROMPT, payload: true })
    }
}

const preferencesLetters = {
    content: 'A',
    offers: 'B',
    live_stream: 'C',
    messages: 'D',
    mass_messages: 'E'
}

export const SetTagsToOneSignal = (user) => {
    let preferences1String = ''
    let preferences2String = ''

    const content = _.get(user, 'web_notification_preferences.content', false)
    const offers = _.get(user, 'web_notification_preferences.offers', false)
    const live_stream = _.get(user, 'web_notification_preferences.live_stream', false)
    const messages = _.get(user, 'web_notification_preferences.messages', false)
    const mass_messages = _.get(user, 'web_notification_preferences.mass_messages', false)

    preferences1String += content ? preferencesLetters.content : '0'
    preferences1String += offers ? preferencesLetters.offers : '0'
    preferences1String += live_stream ? preferencesLetters.live_stream : '0'
    preferences1String += messages ? preferencesLetters.messages : '0'

    preferences2String += mass_messages ? preferencesLetters.mass_messages : '0'
    preferences2String += '000'

    const tagData = {
        'preferences1': preferences1String,
        'preferences2': preferences2String
    }
    OneSignalReact.sendTags(tagData)
    return
}

export const setExternalUserId = (userId) => {
    OneSignalReact.setExternalUserId(userId)
}

export const showNativePrompt = () => {
    OneSignalReact.showNativePrompt()
}

export const getNotificationPermission = async () => {
    const permission = await OneSignalReact.getNotificationPermission()
    return permission
}

export const removeUserDataFromOneSignal = () => {
    OneSignalReact.removeExternalUserId()
    OneSignalReact.deleteTags(['preferences1', 'preferences2'])
}
