import {
    SET_RESUBSCRIPTION_OFFER,
    REMOVE_RESUBSCRIPTION_OFFER
} from '../actions/types'

const initialState = {
    isCheckOffer: false,
    isUserEligibleForOffer: false,
    offer: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_RESUBSCRIPTION_OFFER:
            return {
                ...state,
                isCheckOffer: true,
                isUserEligibleForOffer: action.payload.isUserEligibleForOffer,
                offer: action.payload.offer
            }
        case REMOVE_RESUBSCRIPTION_OFFER:
            return {
                isCheckOffer: false,
                isUserEligibleForOffer: false,
                offer: {}
            }
        default:
            return state
    }
}
