import axios from 'axios'
import _ from 'lodash'

const setAuthToken = token => {
    if (token) {
        const proxyUserToken = localStorage.getItem('ProxyUserToken')
        // Apply authorization token to every request if logged in
        axios.defaults.headers.common['Authorization'] = token
        if (!_.isEmpty(proxyUserToken)) {
            //Apply authorization token to proxy user is logged in 
            axios.defaults.headers.common['Authorization'] = proxyUserToken
        }
    } else {
        // Delete auth header
        delete axios.defaults.headers.common['Authorization']
    }
}

export default setAuthToken
