import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import ModalPopUp from '../Layouts/ModalPopUp'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { universalLoginDifferentPasswordSite, showDifferentPasswordSitePopup } from '../../actions/universalLoginAction'
import { forgotPassword, loginButtonLoading } from '../../actions/authActions'
import { setConfirmSweetAlert, setAlertLoader, removeConfirmSweetAlert } from '../../actions/sweetAlertActions'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'
import Button from '../Layouts/Button'
import { setUniversalLoginDifferentPasswordPopupVisibility } from '../../actions/utilityActions'

const WebsiteListDiv = styled.div`
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
`

const ButtonDiv = styled.div`
    button:focus {
        box-shadow: none;
    }

    @media(max-width: 768px) {
        .left-button,
        .right-button {
            width: 100%;
        }
        .left-button {
            margin-bottom: 10px !important;
        }
    }
`

const ShowWebsiteButtonSpan = styled.div` 
    color: ${props => props.siteFontColor};
    text-decoration: ${props => `underline ${props.siteFontColor}`} !important;
    cursor: pointer;

    button:focus {
        box-shadow: none;
    }

    i {
        font-size: 13px;
    }
`

const WebsiteLink = styled.a`
    color: ${props => props.siteFontColor};
    text-decoration: underline;

    :hover {
        color: ${props => props.siteFontColor};
    }
`

function UniversalLoginDifferentPasswordPopup(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [showWebsiteList, setShowWebsiteList] = useState(false)
    const { site_font_color } = props.auth.appSettings
    const {
        forgotPassword,
        setConfirmSweetAlert,
        setAlertLoader,
        removeConfirmSweetAlert,
        universalLogin,
        showDifferentPasswordSitePopup,
        utility
    } = props
    const { differentPasswordSiteList } = universalLogin
    const { email } = props.login.loginUserInfo
    let showPopup = !utility.isProgressiveWebAppPopupVisible

    const remindMeLater = () => {
        props.setUniversalLoginDifferentPasswordPopupVisibility(false)
        let reminderCount = Number(localStorage.getItem('differentPasswordPopupReminderCount'))
        if (reminderCount >= 45) {
            localStorage.setItem('differentPasswordPopupReminderCount', 1)
        } else {
            localStorage.setItem('differentPasswordPopupReminderCount', reminderCount + 1)
        }
        showDifferentPasswordSitePopup(false)
    }

    const showConfirmationMessage = () => {
        setShowAlert(true)
        setConfirmSweetAlert({ description: 'Are you sure you want to reset your password?' })
    }

    const sendForgotPasswordLink = async () => {
        props.setUniversalLoginDifferentPasswordPopupVisibility(false)
        let userEmail = email
        if (_.isEmpty(email)) {
            userEmail = props.auth.user.email
        }
        setIsLoading(true)
        const data = {
            email: userEmail,
            requestFrom: 'oldUser'
        }
        await forgotPassword(data)
        removeConfirmSweetAlert()
        showDifferentPasswordSitePopup(false)
        setIsLoading(false)
    }

    const domainLink = (domain, index) => {
        const url = `https://${domain}`
        return (
            <WebsiteLink
                siteFontColor={site_font_color}
                className='px-0'
                href={url} index={index}
                rel='noopener noreferrer'
                target='_blank'
            >
                {domain}
            </WebsiteLink>
        )
    }

    useEffect(() => {
        props.setUniversalLoginDifferentPasswordPopupVisibility(true)
    }, [])

    return (
        showPopup ? <>
            <ModalPopUp classes='modal-md pb-0' modalMaxHeight='30em' showHeader={false} showCloseBtn={false}>
                <p className='mb-2'>
                    We&apos;ve found that your email is being used across multiple McCandless Group website{differentPasswordSiteList.length > 1 && 's'}, but your password is different.
                    Click <strong>SEND RESET PASSWORD MAIL</strong> to reset your password to use the same credentials across all websites.
                </p>
                <p>
                    Please click <strong>REMIND ME LATER</strong> to continue without changing your password.
                </p>
                {differentPasswordSiteList.length > 0 && showWebsiteList === false &&
                    <ShowWebsiteButtonSpan
                        siteFontColor={site_font_color}
                        onClick={() => { setShowWebsiteList(!showWebsiteList) }}
                    >
                        Show all website{differentPasswordSiteList.length > 1 && 's'}
                        <i className='fas fa-chevron-down pl-1'></i>
                    </ShowWebsiteButtonSpan>
                }
                {differentPasswordSiteList.length > 0 && showWebsiteList &&
                    <WebsiteListDiv>
                        {differentPasswordSiteList.map((item, index) => (
                            <div key={index}>
                                {domainLink(item, index)}
                            </div>
                        ))}
                    </WebsiteListDiv>
                }
                <ButtonDiv className='pt-4'>
                    <Button
                        classes='btn float-left m-0 left-button'
                        loading={isLoading}
                        onClick={remindMeLater}
                        type='button'
                    >
                        Remind Me Later
                    </Button>
                    <Button
                        classes='btn float-right m-0 right-button'
                        loading={isLoading}
                        onClick={showConfirmationMessage}
                        type='button'
                    >
                        Send Reset Password Mail
                    </Button>
                </ButtonDiv>
            </ModalPopUp>
            {showAlert &&
                <ConfirmSweetAlert
                    onConfirm={() => { setAlertLoader(true); sendForgotPasswordLink() }}
                    onCancel={() => setShowAlert(false)}
                />
            }
        </> : <></>
    )
}

UniversalLoginDifferentPasswordPopup.propTypes = {
    auth: PropTypes.object.isRequired,
    universalLogin: PropTypes.object.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    setAlertLoader: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    utility: PropTypes.object.isRequired,
    removeConfirmSweetAlert: PropTypes.func.isRequired,
    universalLoginDifferentPasswordSite: PropTypes.func.isRequired,
    showDifferentPasswordSitePopup: PropTypes.func.isRequired,
    loginButtonLoading: PropTypes.func,
    login: PropTypes.object.isRequired,
    setUniversalLoginDifferentPasswordPopupVisibility: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    universalLogin: state.universalLogin,
    utility: state.utility,
    login: state.login
})

export default connect(
    mapStateToProps,
    {
        forgotPassword,
        setConfirmSweetAlert,
        setAlertLoader,
        removeConfirmSweetAlert,
        universalLoginDifferentPasswordSite,
        showDifferentPasswordSitePopup,
        loginButtonLoading,
        setUniversalLoginDifferentPasswordPopupVisibility
    }
)(withRouter(UniversalLoginDifferentPasswordPopup))
