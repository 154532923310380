import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { toggleAnnouncementBanner } from '../../actions/utilityActions'

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '767px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1850px',
    desktopL: '2560px'
}

const PromoTextDiv = styled.div`
    font-size: ${props => props.promoFontSize[3]};
    font-family: ${props => props.promoFontFamily};
    color: ${props => props.ribbonColor};
    padding-bottom: ${props => props.ribbonBottomPadding}px;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        font-size: ${props => props.promoFontSize[0]};
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        font-size: ${props => props.promoFontSize[1]};
    }

    @media (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
        font-size: ${props => props.promoFontSize[2]};
    }
`

function AnnouncementBannerLayout(props) {
    const { content_color, content_font_color, announcement_banner_settings } = props.auth.appSettings

    const backgroundColor = _.get(announcement_banner_settings, 'ribbon_background_color', content_font_color)
    const ribbonPaddingTop = _.get(announcement_banner_settings, 'ribbon_padding_top', '0')
    const ribbonPaddingBottom = _.get(announcement_banner_settings, 'ribbon_padding_bottom', '0')
    const promoFontColor = _.get(announcement_banner_settings, 'promo_text_color', content_color)
    let promoFontSize = _.get(announcement_banner_settings, 'promo_text_font_size', '25px|25px|30px|30px')
    const promoFontFamily = _.get(announcement_banner_settings, 'promo_text_font', 'inherit')

    promoFontSize = promoFontSize.split('|')
    if (promoFontSize.length !== 4) {
        promoFontSize = ['25px', '25px', '30px', '30px']
    }

    return (
        <>
            {props.utility.isAnnouncementBannerOpen &&
                <Animated animationIn={announcement_banner_settings.ribbon_animation} animationInDelay={announcement_banner_settings.animation_delay} animationInDuration={announcement_banner_settings.animation_duration} isVisible={true}>
                    <div style={{ width: '100%', background: backgroundColor, justifyContent: 'center', alignContent: 'center', paddingTop: ribbonPaddingTop + 'px', paddingBottom: ribbonPaddingBottom + 'px' }}>
                        <div className='float-right'>
                            <button className='close mr-2' style={{ color: '#ffffff', opacity: '1.0', fontSize: '1.3em' }} onClick={() => props.toggleAnnouncementBanner(false)}></button>
                        </div>
                        <div className='text-center mt-2' style={{ fontSize: '25px', wordBreak: 'break-word', padding: '5px 20px' }}>
                            <PromoTextDiv ribbonColor={promoFontColor} promoFontSize={promoFontSize} promoFontFamily={promoFontFamily}>{props.banner_text}</PromoTextDiv>
                        </div>
                    </div>
                </Animated>
            }
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    utility: state.utility
})

AnnouncementBannerLayout.propTypes = {
    auth: PropTypes.object.isRequired,
    utility: PropTypes.object.isRequired,
    toggleAnnouncementBanner: PropTypes.func.isRequired,
    banner_text: PropTypes.string
}

export default connect(mapStateToProps, { toggleAnnouncementBanner })(withRouter(AnnouncementBannerLayout))
