import {
    UNIVERSAL_LOGIN_MERGE_WEBSITE_LIST,
    UNIVERSAL_LOGIN_WEBSITE_LIST,
    SHOW_UNIVERSAL_LOGIN_ADD_ACCOUNT_POPUP,
    SHOW_FORGOT_PASSWORD_POPUP,
    SET_FORGOT_PASSWORD_EMAIL,
    SHOW_OLD_USER_MERGE_ACCOUNT_POPUP,
    UNIVERSAL_LOGIN_OLD_USER_WEBSITE_LIST,
    UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_SITE_LIST,
    SHOW_DIFFERENT_PASSWORD_SITE_POPUP,
    SET_MERGE_OLD_USER
} from '../actions/types'

const initialState = {
    universalMergeWebsiteList: [],
    siteListOfMergeAccount: [],
    showUniversalLoginPopup: false,
    showUniversalLoginForgotPasswordPopup: false,
    email: '',
    showOldUserMergeAccountPopup: false,
    siteListOfOldUserWebsiteList: [],
    differentPasswordSiteList: [],
    showDifferentPasswordSitePopup: false,
    isOldUser: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UNIVERSAL_LOGIN_MERGE_WEBSITE_LIST:
            return {
                ...state,
                universalMergeWebsiteList: action.payload
            }
        case UNIVERSAL_LOGIN_WEBSITE_LIST:
            return {
                ...state,
                siteListOfMergeAccount: action.payload
            }
        case SHOW_UNIVERSAL_LOGIN_ADD_ACCOUNT_POPUP:
            return {
                ...state,
                showUniversalLoginPopup: action.payload
            }
        case SHOW_FORGOT_PASSWORD_POPUP:
            return {
                ...state,
                showUniversalLoginForgotPasswordPopup: action.payload
            }
        case SET_FORGOT_PASSWORD_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case SHOW_OLD_USER_MERGE_ACCOUNT_POPUP:
            return {
                ...state,
                showOldUserMergeAccountPopup: action.payload
            }
        case UNIVERSAL_LOGIN_OLD_USER_WEBSITE_LIST:
            return {
                ...state,
                siteListOfOldUserWebsiteList: action.payload
            }
        case UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_SITE_LIST:
            return {
                ...state,
                differentPasswordSiteList: action.payload
            }
        case SHOW_DIFFERENT_PASSWORD_SITE_POPUP:
            return {
                ...state,
                showDifferentPasswordSitePopup: action.payload
            }
        case SET_MERGE_OLD_USER:
            return {
                ...state,
                isOldUser: action.payload
            }
        default:
            return state
    }
}

