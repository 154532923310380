import {
    SET_GROUP_LIVE_STREAM,
    REMOVE_GROUP_LIVE_STREAM,
    ADD_GROUP_MESSAGE
} from '../actions/types'

const initialState = {
    isGroupLiveStreamStarted: false,
    groupMessages: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_GROUP_LIVE_STREAM: {
            return {
                ...state,
                isGroupLiveStreamStarted: true
            }
        }
        case REMOVE_GROUP_LIVE_STREAM: {
            return {
                groupMessages: [],
                isGroupLiveStreamStarted: false
            }
        }
        case ADD_GROUP_MESSAGE: {
            const index = state.groupMessages.findIndex((msg) => msg._id === action.payload._id)
            if (index === -1) {
                state.groupMessages.unshift(action.payload)
            }
            const groupMessages = state.groupMessages
            return {
                ...state,
                groupMessages : groupMessages
            }
        }
        default:
            return state
    }
}
