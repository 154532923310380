import axios from 'axios'
import { getAppBaseUrl } from './api'
import { removeConfirmSweetAlert, setSweetAlert } from './sweetAlertActions'
import _ from 'lodash'
import {
    SET_MEDIA,
    ADD_MEDIA,
    ADD_MEDIA_ON_SCROLL,
    ADD_CATEGORY,
    SET_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_FILTER,
    INC_MEDIA_COUNT,
    REMOVE_MEDIA,
    RESET_STATE,
    UPDATE_SELECTED_CATEGORY,
    UPDATE_CATEGORY_COUNT,
    UPDATE_MEDIA_COUNT_IN_CATEGORY
} from './types'

const BASE_URL = getAppBaseUrl()

/**
 * @description get all media
 * @param {object} data type and category Id
 */
export const getAllMedia = (data) => async (dispatch) => {
    try {
        const res = await axios.post(`${BASE_URL}/api/media/get`, data)
        const resData = _.get(res, 'data.data', { media: [], currentPage: 1, totalPages: 1 })
        dispatch({ type: SET_MEDIA, payload: resData })
        if (resData.category) {
            dispatch({ type: SET_CATEGORY, payload: resData.category })
        }
    } catch (error) {
        console.log('error', error.response)
        const errorMessage = _.get(error, 'response.data.message', 'Error occurred while get media')
        dispatch(setSweetAlert({ description: errorMessage }))
    }
}

export const getMediaOnScroll = (data) => async (dispatch) => {
    try {
        const res = await axios.post(`${BASE_URL}/api/media/get`, data)
        const resData = _.get(res, 'data.data', { media: [], currentPage: 1, totalPages: 1 })
        dispatch({ type: ADD_MEDIA_ON_SCROLL, payload: resData })
    } catch (error) {
        console.log('error', error.response)
        const errorMessage = _.get(error, 'response.data.message', 'Error while get media')
        dispatch(setSweetAlert({ description: errorMessage }))
    }
}

/**
 * @description get all media category
 */
export const getAllCategory = () => async (dispatch) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/media/category`)
        dispatch({ type: SET_CATEGORY, payload: response.data.data })
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while fetch category')
        dispatch(setSweetAlert({ description: errorMessage }))
    }
}

/**
 * @description Add new media manager category
 * @param {object} data name
 */
export const addCategory = (data) => async (dispatch) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/media/category`, data)
        dispatch({ type: ADD_CATEGORY, payload: response.data.data })
        dispatch(setSweetAlert({ description: response.data.message }))
        return response.data.data._id
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while add new category')
        dispatch(setSweetAlert({ description: errorMessage }))
    }
}

/**
 * @description update media manager category
 * @param {object} data category Id
 */
export const updateCategory = (data) => async (dispatch) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/media/category/update`, data)
        dispatch({ type: UPDATE_CATEGORY, payload: response.data.data })
        dispatch(setSweetAlert({ description: response.data.message }))
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while update category')
        dispatch(setSweetAlert({ description: errorMessage }))
    }
}

/**
 * @description delete media manager category
 * @param {object} data category Id
 */
export const deleteCategory = (data) => async (dispatch) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/media/category/delete`, data)
        const { categoryId } = response.data.data
        dispatch({ type: DELETE_CATEGORY, payload: categoryId })
        dispatch(setSweetAlert({ description: response.data.message }))
        dispatch(removeConfirmSweetAlert())
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while update category')
        dispatch(setSweetAlert({ description: errorMessage }))
        dispatch(removeConfirmSweetAlert())
    }
}

export const addMedia = (data) => (dispatch) => {
    dispatch({ type: ADD_MEDIA, payload: data })
}

export const incrementMediaCount = (type) => (dispatch) => {
    dispatch({ type: INC_MEDIA_COUNT, payload: { type } })
}

export const saveMediaData = (data) => async (dispatch) => {
    try {
        const res = await axios.post(`${BASE_URL}/api/media`, data)
        return res.data
    } catch (error) {
        console.log('error', error.response)
        const errorMessage = _.get(error, 'response.data.message', 'Error occurred while saving media')
        dispatch(setSweetAlert({ description: errorMessage }))
    }
}

export const removeMedia = (data) => async (dispatch) => {
    const url = BASE_URL + '/api/media/remove-media'
    try {
        const res = await axios.post(url, data)
        const { categories } = res.data.data.resData
        dispatch({ type: SET_CATEGORY, payload: categories })
        dispatch({ type: REMOVE_MEDIA, payload: data.mediaIds })
        dispatch(setSweetAlert({ description: res.data.message }))
        dispatch(removeConfirmSweetAlert())
        return res.data.success
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while remove media')
        dispatch(setSweetAlert({ description: errorMessage }))
        dispatch(removeConfirmSweetAlert())
        return error.response.data.success
    }
}

export const updateFilter = (data) => (dispatch) => {
    dispatch({ type: UPDATE_FILTER, payload: data })
}

/**
 * @description Add category in media
 * @param {object} data mediaIds and categoryId
 * @returns success or error response
 */
export const addCategoryInMedia = (data) => async (dispatch) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/media/add-media-from-category`, data)
        // update media count in the category
        dispatch({ type: UPDATE_CATEGORY_COUNT, payload: response.data.data })
        dispatch(setSweetAlert({ description: response.data.message }))
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Error while add category in media')
        dispatch(setSweetAlert({ description: errorMessage }))
    }
}

/**
 *
 * reset state of media reducer
 */
export const resetState = () => (dispatch) => {
    dispatch({ type: RESET_STATE })
}

/**
 *
 * update selected category
 */
export const updateSelectedCategory = (data) => (dispatch) => {
    dispatch({ type: UPDATE_SELECTED_CATEGORY, payload: data })
}

/**
 *
 * update media count in category
 */
export const updateMediaCountInCategory = (data) => (dispatch) => {
    dispatch({ type: UPDATE_MEDIA_COUNT_IN_CATEGORY, payload: data })
}
