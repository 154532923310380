import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import BootstrapNavbar from './BootstrapNavbar'
import FooterPage from './FooterLayout'
import AgeModalPage from './AgeModal'
import NewPaymentModal from './NewPaymentModal'
import CopyToClipboardToast from './CopyToClipboardToast'
import _ from 'lodash'
import MaintenancePage from './../../components/maintenance/Maintenance'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import SweetAlertsWrapper from './../../components/Layouts/SweetAlertsWrapper'
import MiscellaneousPopUp from '../Layouts/MiscellaneousPopUp'
import MigrationSubscriptionModal from './MigrationSubscriptionModal'
import PaymentForm from '../payment/PaymentForm'
import RebillSubscriptionPopup from '../payment/RebillSubscriptionPopup'
import Cookies from 'universal-cookie'
import AnnouncementBanner from './AnnouncementBanner'
import RebillNotificationPopup from './RebillNotificationPopup'
import Loader from './Loader'
import ProgressiveWebPopUp from './ProgressiveWebPopUp'
import BottomNavbar from './BottomNavbar'
import styled from 'styled-components'
import { size } from '../../utils/common'
import WebPushNotificationPrompt from './WebPushNotificationPrompt'
import { isIOS } from 'react-device-detect'
import UniversalLoginDifferentPasswordPopup from '../universal-login/UniversalLoginDifferentPasswordPopup'

const one_year_in_seconds = 60 * 60 * 24 * 365

const StyledDiv = styled.div`
    @media (min-width: ${size.mobileS}) and (max-width: ${size.tabletL}) {
        padding-bottom:${isIOS && window.matchMedia('(display-mode: standalone)').matches ? '90px' : '60px'};
    }
    
    @media (max-width: 320px) {
        padding-bottom:60px;
    }
`

class PageWrapper extends Component {
    getUTMParams(url) {
        const query = new URLSearchParams(url)
        const utm_params = {}
        let url_has_utm_params = false

        const parameters = ['source', 'medium', 'campaign', 'term', 'content']
        parameters.forEach((item) => {
            const value = query.get(`utm_${item}`)
            if (value !== null) {
                url_has_utm_params = true
                const key = `utm_${item}`
                utm_params[key] = value
            }
        })

        if (url_has_utm_params === true) {
            const cookies = new Cookies()
            cookies.set('pcp_utm_params', JSON.stringify(utm_params), { maxAge: Date.now() + one_year_in_seconds })
        }
    }

    componentDidMount() {
        const live_script_url = 'https://api.forumpay.com/pay/events/payment.js'
        const sandbox_script_url = 'https://sandbox.api.forumpay.com/pay/events/payment.js'
        const { is_crypto_payment_enabled, enable_forumpay_payment_live_mode } = this.props.auth.appSettings

        if (is_crypto_payment_enabled === false) {
            return
        }

        const script = document.createElement('script')
        script.src = enable_forumpay_payment_live_mode === true ? live_script_url : sandbox_script_url
        document.body.appendChild(script)
    }

    render() {
        this.getUTMParams(this.props.location.search)
        const pathName = window.location.pathname

        const {
            maintenance_mode,
            payment_api_version,
            enable_ccbill_rest_api,
            is_universal_login_enabled
        } = this.props.auth.appSettings
        const { showAlert } = this.props.sweetAlert
        const { setNotificationPrompt } = this.props.pushNotification
        // eslint-disable-next-line no-undef
        const enableMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true' || maintenance_mode === true
        const toastType = this.props.copyToClipboard.toastType
        const { isAdmin, isRebillFailed, logout_user_loader, role } = this.props.auth.user
        let { showDifferentPasswordSitePopup } = this.props.universalLogin

        let showProgressiveWebPopUp = true
        if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
            showProgressiveWebPopUp = false
        }

        const differentPasswordPopupReminderCount = localStorage.getItem('differentPasswordPopupReminderCount')
        if (differentPasswordPopupReminderCount && [0, 12, 23, 31, 45].includes(Number(differentPasswordPopupReminderCount))) {
            showDifferentPasswordSitePopup = true
        }

        const currentRoute = pathName.split('/')
        // Don't show popup on change-password screen
        if (currentRoute[currentRoute.length - 2] === 'change-password') showDifferentPasswordSitePopup = false

        if (pathName !== '/login' && (enableMaintenanceMode && (isAdmin === false || isAdmin === undefined)) && _.isEmpty(localStorage.getItem('dev'))) {
            return (
                <WebsiteFontColor>
                    <MaintenancePage />
                </WebsiteFontColor>
            )
        } else {
            return (
                <>
                    {logout_user_loader ?
                        <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
                            <Loader loading={logout_user_loader} size={10} />
                        </div>
                        :
                        <>
                            {(is_universal_login_enabled &&
                                isAdmin === false &&
                                showDifferentPasswordSitePopup)
                                && <UniversalLoginDifferentPasswordPopup />
                            }
                            <AnnouncementBanner />
                            <RebillNotificationPopup />
                            <BootstrapNavbar />
                            <StyledDiv id='RemovePadding'>
                                {this.props.children}
                            </StyledDiv>
                            {(pathName.includes('/private-chat')) ? <></> : <FooterPage />}
                            <AgeModalPage />
                            <NewPaymentModal />
                            <MigrationSubscriptionModal />
                            {toastType === 'copy' &&
                                <CopyToClipboardToast toastContent={'Copied to clipboard.'} />
                            }
                            <MiscellaneousPopUp />
                            <SweetAlertsWrapper />
                            {this.props.utility.showPaymentModel === true &&
                                <PaymentForm
                                    addCardAndSubscription={false}
                                />
                            }
                            {
                                (isRebillFailed === true && isAdmin === false && (payment_api_version === 'v2' || (payment_api_version === 'v1' && enable_ccbill_rest_api === true))) &&
                                <RebillSubscriptionPopup />
                            }
                            {(showProgressiveWebPopUp) && <ProgressiveWebPopUp />}
                            <BottomNavbar />
                            {(setNotificationPrompt && showAlert === false && role !== 'live_stream_manager') && <WebPushNotificationPrompt />}
                        </>
                    }
                </>
            )
        }
    }
}

PageWrapper.propTypes = {
    auth: PropTypes.object,
    children: PropTypes.node,
    utility: PropTypes.object,
    location: PropTypes.object,
    copyToClipboard: PropTypes.object,
    sweetAlert: PropTypes.object,
    pushNotification: PropTypes.object,
    universalLogin: PropTypes.object
}

const mapStateToProps = state => ({
    auth: state.auth,
    copyToClipboard: state.copyToClipboard,
    utility: state.utility,
    sweetAlert: state.sweetAlert,
    pushNotification: state.pushNotification,
    universalLogin: state.universalLogin
})

export default withRouter(connect(mapStateToProps)(PageWrapper))
