import * as React from 'react'
import PageWrapper from './../components/Layouts/PageWrapper'
import Error404Page from '../../src/pages/404Page'

function Error404() {
    return (
        <PageWrapper>
            <Error404Page />
        </PageWrapper>
    )
}

export default Error404
