import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const initialState = {}
const middleware = [thunk]

let store

if (process.env.REACT_APP_NODE_ENV === 'development') {
    store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose))
} else {
    store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)))
}

export default store
