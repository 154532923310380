import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'

function BackButton(props) {
    return <div className={classNames('d-lg-none row m-1', props.classes)}>
        <div className='col-12'>
            <Link to={props.link} >
                <h4 style={{ color: props.auth.appSettings.site_font_color }}>
                    <i className='fas fa-chevron-left mr-3'></i>
                    {props.text}
                </h4>
            </Link>
        </div>
    </div>
}

BackButton.propTypes = {
    auth: PropTypes.object.isRequired,
    classes: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(withRouter(BackButton))
