import {
    ADD_INFLUENCER_HELP_POPUP,
    INFLUENCER_HELP_POPUP_HIDE,
    ADD_INFLUENCER_HELP_DETAIL,
    ADD_INFLUENCER_HELP_LIST,
    INFLUENCER_HELP_RESUME_API_CALL,
    UPDATE_INFLUENCER_HELP_LIST,
    ADD_INFLUENCER_HELP_LIST_NOTIFICATION,
    UPDATE_INFLUENCER_HELP_LIST_NOTIFICATION,
    SET_HELP_NOTIFICATION_UNREAD_COUNT,
    RESET_HELP_NOTIFICATION,
    SET_NOTIFICATION_FILTER,
    SET_HELP_TAG_FILTER,
    SET_HELP_TAGS
} from '../actions/types'
import _ from 'lodash'

const initialState = {
    popup_data: null,
    help_list: {
        data: [],
        totalPages: 0,
        currentPage: 1,
        totalRows: 0,
        limit: 0
    },
    show_popup: false,
    help_detail: null,
    block_api_call: false,
    notification: {
        data: [],
        totalPages: 0,
        currentPage: 1,
        totalRows: 0,
        limit: 0
    },
    help_tags: [],
    notification_filter: 'unread',
    help_tag_filter: [],
    unread_count: 0,
    is_notification_filter_changed: false
}

export default function (state = initialState, action) {
    let newData = action.payload
    const unreadCount = _.get(newData, 'unread_count', 0)
    let showPopup = false

    if (newData !== undefined && newData.popup !== null) {
        showPopup = true
    }
    if (unreadCount > 0) {
        delete newData.unread_count
    }

    if (['UPDATE_INFLUENCER_HELP_LIST', 'UPDATE_INFLUENCER_HELP_LIST_NOTIFICATION'].includes(action.type)) {
        const oldData = action.type === 'UPDATE_INFLUENCER_HELP_LIST' ? [...state.help_list.data] : [...state.notification.data]
        const latestData = oldData.concat(newData.data)
        newData.data = latestData
    }

    switch (action.type) {
        case ADD_INFLUENCER_HELP_POPUP:
            return {
                ...state,
                popup_data: newData.popup,
                unread_count: unreadCount,
                show_popup: showPopup
            }
        case ADD_INFLUENCER_HELP_LIST:
            return {
                ...state,
                help_list: newData
            }
        case UPDATE_INFLUENCER_HELP_LIST:
            return {
                ...state,
                help_list: newData
            }
        case ADD_INFLUENCER_HELP_DETAIL:
            return {
                ...state,
                help_detail: newData.helpDetail,
                block_api_call: true
            }
        case INFLUENCER_HELP_POPUP_HIDE:
            return {
                ...state,
                show_popup: false,
                popup_data: null
            }
        case INFLUENCER_HELP_RESUME_API_CALL:
            return {
                ...state,
                block_api_call: false
            }
        case ADD_INFLUENCER_HELP_LIST_NOTIFICATION:
            return {
                ...state,
                notification: newData,
                is_notification_filter_changed: false
            }
        case UPDATE_INFLUENCER_HELP_LIST_NOTIFICATION:
            return {
                ...state,
                notification: newData
            }
        case SET_HELP_NOTIFICATION_UNREAD_COUNT:
            let influencerHelpData = state.notification.data
            let notificationFilter = state.notification_filter
            let isNotificationFilterChanged = state.is_notification_filter_changed

            if (newData.unreadCount === 0 && notificationFilter !== 'all') {
                notificationFilter = 'all'
                isNotificationFilterChanged = true
            }

            for (let index = 0; index < influencerHelpData.length; index++) {
                const element = influencerHelpData[index];
                
                if (notificationFilter !== 'all' && (element._id.toString() === newData.id)) {
                    influencerHelpData.splice(index, 1)
                } else if (element._id.toString() === newData.id) {
                    influencerHelpData[index].isReadNotification = true
                }
            }

            return {
                ...state,
                unread_count: newData.unreadCount,
                notification: {
                    ...state,
                    data: influencerHelpData
                },
                notification_filter: notificationFilter,
                is_notification_filter_changed: isNotificationFilterChanged
            }
        case RESET_HELP_NOTIFICATION:
            return {
                ...state,
                notification: {
                    data: [],
                    totalPages: 0,
                    currentPage: 1,
                    totalRows: 0,
                    limit: 0
                }
            }
        case SET_NOTIFICATION_FILTER:
            return {
                ...state,
                notification_filter: action.payload
            }
        case SET_HELP_TAG_FILTER:
            return {
                ...state,
                help_tag_filter: action.payload
            }
        case SET_HELP_TAGS:
            return {
                ...state,
                help_tags: action.payload
            }
        default:
            return state
    }
}
