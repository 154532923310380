import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { cancelStickyIoSubscription } from './../../actions/stickyIoActions'
import { cancelUserSubscription } from './../../actions/hybridPaymentAction'
import { cancelSubscription } from './../../actions/ccbillActions'
import _ from 'lodash'
import Button from './Button'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import { cancelUserSubscriptionForCrypto } from '../../actions/cryptoPaymentActions'
import { checkUserEligibleForOffer } from '../../actions/ResubscribeOfferActions'
import { cancelComplimentarySubscription } from '../../actions/authActions'

const ModalStyle = styled.div`
    width: 100%;
    z-index: 55 !important;

    .modal {
        display: block;
        background-color: #00000080;
    }

    .modal-content {
        color: ${props => props.siteFontColor};
    }

    .modal-header, .modal-body {
        background-color: ${props => props.cardBackgroundColor};
    }

    .modal-body {
        max-height: ${props => props.modalBodyMaxHeight};
    }
    .close {
        color: ${props => props.siteFontColor};
        opacity: 1;
    }

    .close:hover {
        color: ${props => props.siteFontColor};
        opacity: 1;
        cursor: pointer;
    }
`

function CancelSubscriptionReasonDialog(props) {
    const userHasComplimentarySubscription = _.get(props.user, 'has_complimentary_subscription', false)

    const [isCancelSubscriptionLoading, setIsCancelSubscriptionLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm()

    const onSubmit = async (data) => {
        if (data.cancellation_reason === 'Other') {
            if (data.other_reason.trim() === '') {
                const message = 'Please enter valid reason for cancellation'
                const payload = { description: message }
                return props.setSweetAlert(payload)
            }
            data.cancellation_reason = data.other_reason
            delete data.other_reason
        }
        const confirmation = window.confirm('Are you sure you want to cancel your subscription?')

        if (confirmation === false) {
            return false
        }

        setIsCancelSubscriptionLoading(true)
        if (userHasComplimentarySubscription) {
            await props.cancelComplimentarySubscription()
            setIsCancelSubscriptionLoading(false)
            props.onClose()
            return
        }
        const payment_api_version = _.get(props.appSettings, 'payment_api_version', 'v1')
        const { payment_gateway } = props.user
        if (payment_gateway === 'forumpay') {
            if (!_.isEmpty(props.requestFrom)) {
                data.request_from = props.requestFrom
            }
            await props.cancelUserSubscriptionForCrypto(data)
            props.onClose()
            setIsCancelSubscriptionLoading(false)
            return
        }
        if (payment_api_version === 'v1') {
            if (props.appSettings.is_sticky_io_enabled === true) {
                props.cancelStickyIoSubscription(data)
            } else {
                props.cancelSubscription(data)
            }
        } else {
            await props.cancelUserSubscription(data)
            props.onClose()
        }
    }

    const closePopup = () => {
        props.checkUserEligibleForOffer()
        props.onClose()
    }

    const {
        site_font_color,
        card_background_color,
        reason_for_cancellation_popup_title
    } = props.appSettings

    return (
        <ModalStyle siteFontColor={site_font_color} cardBackgroundColor={card_background_color}>
            <div className='modal fade show' role='dialog'>
                <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <h5 className='mt-2 mb-5'>{reason_for_cancellation_popup_title}</h5>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-check">
                                    <label htmlFor="reason1">
                                        <input
                                            ref={register({ required: true })}
                                            type="radio"
                                            name="cancellation_reason"
                                            value="Can't Afford Right Now, But I'll Be Back!"
                                            className="form-check-input"
                                            id="reason1"
                                        />Can&apos;t Afford Right Now, But I&apos;ll Be Back!
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label htmlFor="reason2">
                                        <input
                                            ref={register({ required: true })}
                                            type="radio"
                                            name="cancellation_reason"
                                            value="Not Enough Content"
                                            className="form-check-input"
                                            id="reason2"
                                        />
                                        Not Enough Content
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label htmlFor="reason3">
                                        <input
                                            ref={register({ required: true })}
                                            type="radio"
                                            name="cancellation_reason"
                                            value="Content Isn't Sexy Enough"
                                            className="form-check-input"
                                            id="reason3"
                                        />
                                        Content Isn&apos;t Sexy Enough
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label htmlFor="reason4">
                                        <input
                                            ref={register({ required: true })}
                                            type="radio"
                                            name="cancellation_reason"
                                            value="Other"
                                            className="form-check-input"
                                            id="reason4"
                                        />
                                        Other
                                    </label>
                                </div>
                                {watch('cancellation_reason') === 'Other' &&
                                    <div className="mb-3">
                                        <label htmlFor="other_reason" className="form-label">Enter Your Reason: </label>
                                        <textarea
                                            className="form-control"
                                            id="other_reason"
                                            rows="3"
                                            name='other_reason'
                                            ref={register({ required: true })}
                                        ></textarea>
                                        {errors.other_reason && <span className="text-danger">Please Write Reason for cancellation</span>}
                                    </div>}
                                {errors.cancellation_reason && <span className="text-danger">Please Select Reason for Cancellation</span>}
                                <div className="modal-button d-flex justify-content-center mt-4 flex-column flex-sm-row">
                                    <Button
                                        classes='btn-sm-inline btn-block m-2 '
                                        loading={isCancelSubscriptionLoading}
                                        type='submit'
                                    >{isCancelSubscriptionLoading === true && <span className="spinner-border spinner-border-sm mr-2"></span>}Cancel Subscription</Button>
                                    <Button
                                        classes='btn-sm-inline btn-block m-2 '
                                        loading={false}
                                        type='button'
                                        onClick={closePopup}
                                    >Close</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyle>)
}

CancelSubscriptionReasonDialog.propTypes = {
    appSettings: PropTypes.object.isRequired,
    cancelStickyIoSubscription: PropTypes.func.isRequired,
    cancelUserSubscription: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    cancelSubscription: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    cancelUserSubscriptionForCrypto: PropTypes.func.isRequired,
    requestFrom: PropTypes.string,
    cancelComplimentarySubscription: PropTypes.func.isRequired,
    checkUserEligibleForOffer: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    appSettings: state.auth.appSettings,
    user: state.auth.user
})

export default connect(
    mapStateToProps,
    {
        cancelStickyIoSubscription,
        cancelUserSubscription,
        setSweetAlert,
        cancelSubscription,
        cancelUserSubscriptionForCrypto,
        cancelComplimentarySubscription,
        checkUserEligibleForOffer
    }
)(withRouter(CancelSubscriptionReasonDialog))
