import axios from 'axios'
import _ from 'lodash'
import { getAppBaseUrl } from './api'
import { setSweetAlert } from './sweetAlertActions'
import { getAppSettings } from './appSettingsActions'
import { UPDATE_FEED_TAG_LIST, UPDATE_FEED_TAG_SETTINGS, SET_FEED_TAG_LIST } from './types'

const BASE_URL = getAppBaseUrl()

export const saveFeedTagsSettings = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/feed-tags/`
    try {
        const response = await axios.post(url, data)
        const payload = {
            description: response.data.message
        }

        dispatch({ type: UPDATE_FEED_TAG_SETTINGS, payload: response.data.data })
        dispatch(setSweetAlert(payload))
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
    }
}

export const getFeedTagList = () => async (dispatch) => {
    const url = `${BASE_URL}/api/feed-tags/list`
    try {
        const response = await axios.get(url)
        dispatch({ type: UPDATE_FEED_TAG_LIST, payload: response.data.data })
        dispatch({ type: SET_FEED_TAG_LIST, payload: response.data.data })
        return response.data.data
    } catch (error) {
        const payload = {
            description: error.message
        }
        dispatch(setSweetAlert(payload))
    }
}

export const addOrUpdateFeedTag = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/feed-tags/add-or-update`
    try {
        const response = await axios.post(url, data)
        dispatch({ type: UPDATE_FEED_TAG_LIST, payload: response.data.data.feed_tags })
        dispatch({ type: SET_FEED_TAG_LIST, payload: response.data.data.feed_tags })
        return response.data
    } catch (error) {
        return error.response
    }
}

export const deleteFeedTag = (tagId) => async (dispatch) => {
    const url = `${BASE_URL}/api/feed-tags/delete/${tagId}`
    try {
        const response = await axios.delete(url)
        const payload = {
            description: response.data.message
        }
        dispatch({ type: UPDATE_FEED_TAG_LIST, payload: response.data.data })
        dispatch({ type: SET_FEED_TAG_LIST, payload: response.data.data })
        dispatch(setSweetAlert(payload))
        return response.data
    } catch (error) {
        const payload = {
            description: error.message
        }
        dispatch(setSweetAlert(payload))
    }
}

export const addOrRemoveFeedTag = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/feed-tags/add-or-remove-tag`
    try {
        const res = await axios.post(url, data)
        dispatch(getAppSettings(() => { }))
        return res.data
    } catch (error) {
        return error.response
    }
}
