import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const SelectListGroup = ({
    name,
    value,
    error,
    info,
    onChange,
    options,
    id,
    label,
    isRequired,
    disabled
}) => {
    const selectOptions = options.map((option, i) => (
        <option key={i} value={option._id}>
            {option.name}
        </option>
    ))
    return (
        <div className='form-group'>
            {label &&
                <label htmlFor={id}>{label}</label>
            }
            <select
                className={classnames('form-control form-select', {
                    'is-invalid': error
                })}
                disabled={disabled}
                name={name}
                value={value}
                onChange={onChange}
                required={isRequired}>
                {selectOptions}
            </select>
            {info && <small className='form-text text-muted'>{info}</small>}
            {error && <div className='invalid-feedback'>{error}</div>}
        </div>
    )
}

SelectListGroup.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
    ]),
    info: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    disabled: PropTypes.bool
}

export default SelectListGroup
