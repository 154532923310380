import axios from 'axios'
import {
    TOGGLE_SIDEBAR,
    TOGGLE_NEW_PAYMENT_MODAL,
    TOGGLE_MISCELLANEOUS_POP_UP,
    UNSET_NOTIFICATION_POPUP,
    TOGGLE_NOTIFICATION_POPUP,
    TOGGLE_PAYMENT_MODAL,
    TOGGLE_ANNOUNCEMENT_BANNER,
    DISPLAY_MST_TIME_FOR_NOTIFICATION,
    CLOSE_PROMOTION_BANNER,
    CLOSE_RESUBSCRIPTION_BANNER,
    IS_PROGRESSIVE_WEB_APP_POPUP_VISIBLE,
    PROGRESSIVE_WEB_APP_POPUP_SHOW_AGAIN,
    SET_UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_POPUP_VISIBILITY
} from './types'
import { getAppBaseUrl } from './api'
const BASE_URL = getAppBaseUrl()

export const toggleSideBar = () => dispatch => {
    dispatch({ type: TOGGLE_SIDEBAR })
}

export const toggleNewPaymentModal = () => dispatch => {
    dispatch({ type: TOGGLE_NEW_PAYMENT_MODAL })
}

export const toggleMiscellaneousPopUp = () => dispatch => {
    dispatch({ type: TOGGLE_MISCELLANEOUS_POP_UP })
}

export const hideNotificationPopup = () => dispatch => {
    dispatch({ type: UNSET_NOTIFICATION_POPUP })
}

export const toggleNotificationPopup = () => dispatch => {
    dispatch({ type: TOGGLE_NOTIFICATION_POPUP })
}

export const togglePaymentModal = (payload) => dispatch => {
    dispatch({ type: TOGGLE_PAYMENT_MODAL, payload: payload })
}

export const showOrHideMstDateTimeForNotification = () => dispatch => {
    dispatch({ type: DISPLAY_MST_TIME_FOR_NOTIFICATION })
}

export const toggleAnnouncementBanner = (payload) => dispatch => {
    dispatch({ type: TOGGLE_ANNOUNCEMENT_BANNER, payload: payload })
}

export const closePromotionBanner = (payload) => dispatch => {
    dispatch({ type: CLOSE_PROMOTION_BANNER, payload: payload })
}

export const closeResubscriptionBanner = (payload) => dispatch => {
    dispatch({ type: CLOSE_RESUBSCRIPTION_BANNER, payload: payload })
}

export const handleIsProgressiveWebAppPopupVisible = (payload) => dispatch => {
    dispatch({ type: IS_PROGRESSIVE_WEB_APP_POPUP_VISIBLE, payload: payload })
}

export const handleProgressiveWebAppPopupShowAgain = (payload) => dispatch => {
    dispatch({ type: PROGRESSIVE_WEB_APP_POPUP_SHOW_AGAIN, payload: payload })
}

export const sendMobileInfoToServices = (data) => async () => {
    try {
        await axios.post(BASE_URL + '/api/services/send-device-info', data)
        return
    } catch (error) {
        console.log(error.message)
        return
    }
}

export const setUniversalLoginDifferentPasswordPopupVisibility = (payload) => dispatch => {
    dispatch({ type: SET_UNIVERSAL_LOGIN_DIFFERENT_PASSWORD_POPUP_VISIBILITY, payload: payload })
}
