import axios from 'axios'
import { getAppBaseUrl } from './api' 

const BASE_URL = getAppBaseUrl()

export const updateUserNotificationDate = async() => {
    try {
        const res = await axios.post(BASE_URL + '/api/users/updateRebillNotificationDate')
        return res.data
    } catch (error) {
        return error.response.data
    }
}