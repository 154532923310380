import {
    GET_ADMIN_USER,
    GET_SUBSCRIBERS,
    SET_ALL_USERS,
    SET_ALL_USERS_TRANSACTIONS,
    IS_LOADING,
    SET_TRANSACTION_AMOUNT,
    SET_ALL_TRANSACTION_AMOUNT,
    IS_BLUR_LOADING,
    SET_IS_USER_LIST_LOADING,
    STOP_IS_BLUR_LOADING
} from '../actions/types'
import moment from 'moment'

const initialState = {
    loading: true,
    admin: {},
    subscribers: [],
    rows: [],
    currentPageSubscribers: 0,
    currentPageTrasnsactions: 0,
    totalPages: 0,
    totalRows: 0,
    offset: 0,
    limit: 20,
    todaysEarning: 0,
    currentMonthEarnings: 0,
    totalEarnings: 0,
    todaysModelEarning: 0,
    currentMonthModelEarnings: 0,
    totalModelEarnings: 0,
    todaysPlatformEarning: 0,
    currentMonthPlatformEarnings: 0,
    totalPlatformEarnings: 0,
    todaysCCBillEarning: 0,
    currentMonthCBillEarnings: 0,
    totalCCBillEarnings: 0,
    todaysTotalEarnings: 0,
    currentMonthTotalEarnings: 0,
    totalLifetimeEarnings: 0,
    isBlurLoading: false,
    view: 'all',
    isUserListLoading: false,
    allUserRows: 0,
    allTransactionRows: 0,
    limitTransaction: 0

}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ADMIN_USER:
            return {
                ...state,
                admin: action.payload
            }
        case GET_SUBSCRIBERS:
            return {
                ...state,
                subscribers: action.payload,
                loading: false
            }
        case SET_ALL_USERS:
            return {
                ...state,
                subscribers: action.payload.rows,
                currentPageSubscribers: parseInt(action.payload.currentPage, 10),
                totalPages: action.payload.totalPages,
                totalRows: action.payload.totalRows,
                offset: action.payload.offset,
                view: action.payload.view,
                isUserListLoading: false,
                limit: action.payload.limit,
                allUserRows: action.payload.allRows
            }
        case SET_ALL_USERS_TRANSACTIONS:
            for (let index = 0; index < action.payload.rows.length; index++) {
                let user = action.payload.rows[index]

                user.ccbill_subscription_status = (user.ccbill_subscription_status === '1' || user.ccbill_subscription_status === '2') ? user.ccbill_subscription_status : '0'
                user.total_amount_spent = user.total_amount_spent ? `$${user.total_amount_spent.toFixed(2)}` : '$0.00'
                user.total_void_amount = user.total_void_amount ? `$${user.total_void_amount.toFixed(2)}` : '$0.00'
                user.total_refund_amount = user.total_refund_amount ? `$${user.total_refund_amount.toFixed(2)}` : '$0.00'
                user.total_chargeback_amount = user.total_chargeback_amount ? `$${user.total_chargeback_amount.toFixed(2)}` : '$0.00'
                user.total_chargeback_count = user.total_chargeback_count ? `$${(25*user.total_chargeback_count).toFixed(2)}` : '$0.00'
                user.date = moment(user.date).format('MMMM Do YYYY, hh:mm:ss a')
                if (user.expiration_date && user.expiration_date !== null) {
                    user.expiration_date = moment(user.expiration_date).format('MMMM Do YYYY, hh:mm:ss a')
                } else if (user.next_billing_date && user.next_billing_date !== null) {
                    user.expiration_date = moment(user.next_billing_date).format('MMMM Do YYYY, hh:mm:ss a')
                }

                if (user.is_photo_id_accepted === true || user.is_photo_id_accepted === false) {
                    user.is_photo_id_accepted = user.is_photo_id_accepted.toString()
                }
            }
            return {
                ...state,
                rows: action.payload.rows,
                currentPageTrasnsactions: parseInt(action.payload.currentPage, 10),
                totalPages: action.payload.totalPages,
                totalRows: action.payload.totalRows,
                offset: action.payload.offset,
                isUserListLoading: false,
                limitTransaction: action.payload.limit,
                allTransactionRows: action.payload.allRows
            }
        case SET_TRANSACTION_AMOUNT:
            return {
                ...state,
                todaysEarning: action.payload.todaysEarning[0].sum,
                currentMonthEarnings: action.payload.currentMonthEarnings[0].sum,
                totalEarnings: action.payload.totalEarnings[0].sum
            }
        case SET_ALL_TRANSACTION_AMOUNT:
            return {
                ...state,
                todaysModelEarning: action.payload.todaysModelEarnings[0].sum,
                currentMonthModelEarnings: action.payload.currentMonthModelEarnings[0].sum,
                totalModelEarnings: action.payload.totalModelEarnings[0].sum,
                todaysPlatformEarning: action.payload.todaysPlatformEarnings[0].sum,
                currentMonthPlatformEarnings: action.payload.currentMonthPlatformEarnings[0].sum,
                totalPlatformEarnings: action.payload.totalPlatformEarnings[0].sum,
                todaysCCBillEarning: action.payload.todaysCCBillEarnings[0].sum,
                currentMonthCBillEarnings: action.payload.currentMonthCBillEarnings[0].sum,
                totalCCBillEarnings: action.payload.totalCCBillEarnings[0].sum,
                todaysTotalEarnings: action.payload.todaysTotalEarnings[0].sum,
                currentMonthTotalEarnings: action.payload.currentMonthTotalEarnings[0].sum,
                totalLifetimeEarnings: action.payload.totalLifetimeEarnings[0].sum
            }
        case IS_LOADING:
            return {
                ...state,
                loading: true
            }
        case IS_BLUR_LOADING:
            return {
                ...state,
                isBlurLoading: true
            }
        case STOP_IS_BLUR_LOADING:
            return {
                ...state,
                isBlurLoading: false
            }
        case SET_IS_USER_LIST_LOADING:
            return {
                ...state,
                isUserListLoading: true
            }
        default:
            return state
    }
}
