import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {
    GET_BLOG_DATA,
    ADD_NEW_BLOG,
    IS_LOADING,
    RESET_CURRENT_PAGE,
    UPDATE_FEED_LIKE_COUNT,
    SELECTED_FEED_TAG,
    UPDATE_FEED_TAG_LIST,
    SET_FEED_TAG_LIST,
    REMOVE_BLOG_DATA,
    UPDATE_BLOG_DATA,
    RESTORE_FIRST_PAGE_BLOGS
} from './types'
import { getAppBaseUrl } from './api'
import _ from 'lodash'
import store from './../store'
import moment from 'moment'
import { setSweetAlert } from './sweetAlertActions'
import { getAppSettings } from './appSettingsActions'
import { setBlurLoading, stopBlurLoading } from './adminActions'

const BASE_URL = getAppBaseUrl()

export const getAllBlogs = (isAdmin, data, callback) => dispatch => {
    const state = store.getState()
    const isPaginationEnabled = state.auth.appSettings.is_pagination_on_exclusive_content_enabled
    let url
    let token = localStorage.getItem('AuthToken')
    if (isAdmin === undefined && token) {
        isAdmin = getAdminValue(token)
    }
    let feedTagId = _.get(data, 'feedTagId', '')
    if (state.auth.appSettings.feed_tags_enable === true && feedTagId !== '') {
        url = BASE_URL + ((isAdmin === true) ? `/api/blogs/all?page_num=${data.pageNum}&feed_tag_id=${feedTagId}` : `/api/blogs?page_num=${data.pageNum}&feed_tag_id=${feedTagId}`)
    } else {
        url = (isAdmin === true) ? BASE_URL + `/api/blogs/all?page_num=${data.pageNum}` : BASE_URL + `/api/blogs?page_num=${data.pageNum}`
    }
    // Use different API url's for admin and users

    axios.get(url)
        .then(res => {
            res.data.isPaginationEnabled = isPaginationEnabled
            dispatch({ type: GET_BLOG_DATA, payload: res.data })
            callback()
        })
        .catch(err => {
            console.log(err)
            callback()
        })
}

// Get blog by id
export const getBlogById = (id, callback) => () => {
    // Use different API url's for admin and users
    const url = BASE_URL + `/api/blogs/get_blog_data_by_id?id=${id}`

    axios.get(url)
        .then(res => {
            callback(res.data)
        })
        .catch(err => {
            console.log(err)
            callback()
        })
}

// Get blog by id
export const postReuseBlog = (reuseBlog, callback) => () => {
    // Use different API url's for admin and users
    const url = BASE_URL + '/api/blogs/reuse_blog'

    axios.post(url, reuseBlog)
        .then(res => {
            callback(res.data)
        })
        .catch(err => {
            callback(err)
        })
}

export const postReuseBlogWithNewDesign = (reuseBlog, callback) => dispatch => {
    const url = BASE_URL + '/api/blog/reuse-blog'
    const state = store.getState()

    axios.post(url, reuseBlog)
        .then(res => {
            dispatch(getAllBlogs(true, { pageNum: 1, feedTagId: state.blog.selectedTag }, () => { }))
            if (!_.isEmpty(res.data.data.feedTagList)) {
                dispatch({ type: UPDATE_FEED_TAG_LIST, payload: res.data.data.feedTagList })
                dispatch({ type: SET_FEED_TAG_LIST, payload: res.data.data.feedTagList })
            }
            callback(res.data)
        })
        .catch(err => {
            callback(err)
        })
}

// Get isAdmin value from token
const getAdminValue = (token) => {
    const decoded = jwt_decode(token)
    return decoded.isAdmin
}

export const postNewBlog = (blog, callback) => dispatch => {
    axios.post(BASE_URL + '/api/blogs', blog)
        .then(res => {
            if (res.data.blogType !== 'gallery') {
                dispatch({ type: ADD_NEW_BLOG, payload: res.data })
            }
            callback(res.data)
        })
        .catch(err => {
            console.log(err)
        })
}

export const newPostNewBlog = (blog, callback) => dispatch => {
    axios.post(BASE_URL + '/api/blog/add-new-blog', blog)
        .then(res => {
            const data = res.data.data
            dispatch({ type: ADD_NEW_BLOG, payload: data.result })
            if (!_.isEmpty(res.data.data.feedTagList)) {
                dispatch({ type: UPDATE_FEED_TAG_LIST, payload: res.data.data.feedTagList })
                dispatch({ type: SET_FEED_TAG_LIST, payload: res.data.data.feedTagList })
            }
            const selectFromContentManager = _.get(data, 'selectFromContentManager', false)
            const payload = {
                description: selectFromContentManager === true ? 'Content posted successfully.' : 'The uploaded post is being processed and will be visible in some time.'
            }
            dispatch(setSweetAlert(payload))
            callback()
        })
        .catch(err => {
            const message = _.get(err, 'response.data.message', navigator.onLine ? 'There was a problem in content post.' : 'Seems you are offline. Please check your internet connection and post again.')
            const payload = {
                description: message
            }
            dispatch(setSweetAlert(payload))
            callback()
        })
}

export const uploadBlogImage = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/upload/upload_file', data)
        .then(res => {
            const fileUrl = res.data.Location
            const fileSize = res.data.fileSize
            let obj = {}
            for (let pair of data.entries()) {
                obj[pair[0]] = pair[1]
            }

            let blog = {
                title: obj.title,
                blogType: obj.blogType,
                url: fileUrl,
                description: obj.description,
                author: obj.author,
                public: obj.public,
                privateBlur: obj.privateBlur,
                processing: false,
                blurUrl: res.data.blurLocation,
                isLocked: obj.isLocked,
                amount: obj.amount,
                model: obj.model,
                captionBlur: obj.captionBlur,
                content_size: fileSize,
                media: {
                    url: fileUrl,
                    thumbnail_url: '',
                    blur_url: res.data.blurLocation,
                    is_process: true
                },
                type: obj.blogType
            }

            if (obj.isSchedule === 'true') {
                let scheduled_post_time = moment(obj.schedulePostTime).utc()
                scheduled_post_time = new Date(obj.schedulePostTime)

                blog.date = scheduled_post_time
            }

            dispatch(setLoading(true))
            dispatch(postNewBlog(blog, callback))
        }).catch(error => {
            let errorMessage = _.get(error.response, 'data.message', 'There was an error while uploading the image.')
            alert(errorMessage)
            dispatch(setLoading(false))
            callback()
        })
}

export const deleteBlogPost = (data, callback) => dispatch => {
    dispatch(setBlurLoading())
    axios.post(BASE_URL + '/api/blogs/delete', data)
        .then(() => {
            alert('Post deleted.')
            dispatch({ type: REMOVE_BLOG_DATA, payload: data })
            dispatch(stopBlurLoading())
            dispatch(getAppSettings(() => { }))
            dispatch(stopBlurLoading())
            callback()
        })
        .catch(err => {
            console.log(err)
            dispatch(stopBlurLoading())
            callback()
        })
}

export const editPost = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/blogs/edit', data)
        .then((res) => {
            dispatch({ type: UPDATE_BLOG_DATA, payload: res.data })
            callback()
        })
        .catch(err => {
            console.log(err)
            callback()
        })
}

export const getBlogDataById = (id, callback) => () => {
    const url = BASE_URL + `/api/blog/get-blog-data-by-id?id=${id}`

    axios.get(url)
        .then(res => {
            callback(res.data)
        })
        .catch(err => {
            console.log(err)
            callback(false)
        })
}

export const postReviewedBlog = (blog, callback) => dispatch => {
    const state = store.getState()
    axios.post(BASE_URL + '/api/blog/post-reviewed-blog', blog)
        .then(res => {
            const payload = {
                description: 'Content reviewed successfully.'
            }
            dispatch(setSweetAlert(payload))
            if (!_.isEmpty(res.data.data.feedTagList)) {
                dispatch({ type: UPDATE_FEED_TAG_LIST, payload: res.data.data.feedTagList })
                dispatch({ type: SET_FEED_TAG_LIST, payload: res.data.data.feedTagList })
            }
            let data = {
                pageNum: 1,
                feedTagId: state.blog.selectedTag
            }
            dispatch(getAllBlogs(true, data, () => { }))
            callback(res.data)
        })
        .catch(err => {
            let message = _.get(err, 'response.data.message', 'There was a problem in content post.')
            const payload = {
                description: message
            }
            dispatch(setSweetAlert(payload))
            callback()
        })
}

export const setLoading = (isLoading) => {
    return {
        type: IS_LOADING,
        payload: isLoading
    }
}

export const resetCurrentPage = () => {
    return {
        type: RESET_CURRENT_PAGE,
        payload: 0
    }
}

export const addNewBlog = (blogData) => {
    return {
        type: ADD_NEW_BLOG,
        payload: blogData
    }
}

export const getBlogDetailById = (blog_id) => async () => {
    const url = BASE_URL + '/api/blog/get-blog-info'
    const data = { blog_id: blog_id }
    try {
        const res = await axios.post(url, data)
        return res.data.data
    } catch (error) {
        return false
    }
}

export const getBlogComments = (data) => async () => {
    const url = BASE_URL + '/api/blog/get-blog-comment'
    try {
        const res = await axios.post(url, data)
        return res.data
    } catch (error) {
        return false
    }
}

export const addBlogComments = (data) => async (dispatch) => {
    const url = BASE_URL + '/api/blog/save-blog-comment'
    try {
        const res = await axios.post(url, data)
        return res.data
    } catch (error) {
        const payload = {
            description: error.response.data.message
        }
        dispatch(setSweetAlert(payload))
        return false
    }
}

export const removeBlogComment = (data) => async () => {
    const url = BASE_URL + '/api/blog/remove-blog-comment'
    try {
        const res = await axios.post(url, data)
        return res.data
    } catch (error) {
        return error
    }
}

export const hideBlogComment = (data) => async () => {
    const url = BASE_URL + '/api/blog/hide-blog-comment'
    try {
        const res = await axios.post(url, data)
        return res.data
    } catch (error) {
        return false
    }
}

export const addOrDeleteFeedLike = (data) => async (dispatch) => {
    dispatch(updateLikeAction(data))
    const url = `${BASE_URL}/api/blog/like-dislike`
    try {
        const res = await axios.post(url, data)
        return res.data
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'Invalid Request')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        return false
    }
}

export const blockUserCommenting = (data) => async () => {
    const url = BASE_URL + '/api/blog/block-user-from-commenting'
    try {
        const res = await axios.post(url, data)
        return res.data
    } catch (error) {
        return false
    }
}

export const updateLikeAction = (data) => {
    return {
        type: UPDATE_FEED_LIKE_COUNT,
        payload: data
    }
}

export const selectedFeedTag = (data) => {
    return {
        type: SELECTED_FEED_TAG,
        payload: data
    }
}

export const restoreFirstPageBlogs = () => {
    return {
        type: RESTORE_FIRST_PAGE_BLOGS,
        payload: []
    }
}
