// @flow

import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import './c3jscustom.css'

import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import App from './App.react'
// import * as serviceWorker from './serviceWorker'

// Added for sentry tracing. Add the below code in package.json scripts section to upload sourcemaps
// "build": ". ./sentry.config.sh && react-scripts build && node uploadSourceMaps.js && rm build/static/js/*.map",

// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
// import package_data from './../package.json'

const rootElement = document.getElementById('root')
// const packageVersion = package_data.version

// if (window.location.hostname !== 'localhost') {
//     Sentry.init({
//         dsn: 'https://89beac87958242498064e23197071c67@o392495.ingest.sentry.io/5932093',
//         beforeSend(event, hint) {
//             const error = hint.originalException;
//             const chunkFailedMessage = /Loading chunk [\d]+ failed/;
//             if (chunkFailedMessage.test(error.message)) {
//                 return false
//             }
//             return event;
//         },
//         ignoreErrors: [
//             'TypeError: ChunkLoadError',
//         ],
//         integrations: [new Integrations.BrowserTracing()],

//         // We recommend adjusting this value in production, or using tracesSampler
//         // for finer control
//         tracesSampleRate: 0.001,
//         release: packageVersion
//     })
// }

ReactDOM.render(
    <div id='scrollBottom' className='disableSave' onContextMenu={contextMenu}>
        <App />
    </div>
    , rootElement
)

// serviceWorker.unregister()

function contextMenu(e) {
    let debug = window.location.hostname === 'localhost' || window.location.hostname === 'modelplatform.themccandlessgroup.com' || window.location.hostname === 'demo1.themccandlessgroup.com' || window.location.hostname === 'demo.themccandlessgroup.com' || window.location.hostname === 'demo2.themccandlessgroup.com' ? true : false
    if (debug) return true
    e.preventDefault()
    // Do something here....
    return false
}
