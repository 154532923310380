import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import styled from 'styled-components'
import { addOrRemoveFeedTag, deleteFeedTag } from '../../actions/FeedTagsActions'
import { getAllBlogs, setLoading } from '../../actions/blogActions'
import { SET_FEED_TAG } from '../../actions/types'
import '../../css/feed-userList.css'
import _ from 'lodash'

const FeedTagListRow = styled.div`
    padding-left: 10px;
    position: relative;
    border: 1px solid lightgray;
    cursor: pointer;
    min-height: 60px;
    margin-bottom: 8px;
    border-radius: 5px;
    :hover {
        background-color: rgba(0, 0, 0, 0.08);
    }
`

const DropdownButton = styled.button`
    box-shadow: none;
    border-radius: 20px !important;
    color: ${props => props.site_font_color};
    position: absolute;
    top: 5px;
    right: 10px;
    &:hover {
        box-shadow: none;
        border-radius: 20px;
        color: ${props => props.site_font_color};
    }
`

const DropdownActionLink = styled.div`
    cursor: pointer;

    &:hover, &:active, &:focus {
        background: transparent;
        color: #16181b;
    }

`

function FeedTagPopup(props) {
    const [loader, setLoader] = useState(true)
    const [tagId, setTagId] = useState([])
    const [tags, setTags] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [tagListData, setTagListData] = useState([])
    const [filterByName, setFilterByName] = useState('')
    const [isDropDownOpen, setDropDown] = useState(false)
    const [dropdownIndex, setDropDownIndex] = useState(false)
    const { site_font_color, content_color, content_font_color, feed_tags } = props.auth.appSettings
    const dispatch = useDispatch()

    useEffect(() => {
        getFeedTagList()
        const feed_tags = _.clone(props.feedTags)
        if (feed_tags) {
            setTags(feed_tags)
            let tagId = feed_tags.map(function (obj) { return obj.id })
            setTagId(tagId)
        }
    }, [])

    const handleChange = (e) => {
        setDropDown(false)
        const tag_id = e.target.value
        let tag = feed_tags.find(arr => arr._id === tag_id)
        let tagData = tags ? tags : []

        if (tagId.includes(tag_id)) {
            if (tag) {
                tagData = tagData.filter(obj => obj.id !== tag_id)
            }
            setTagId(tagId.filter(obj => obj !== tag_id))
        } else {
            if (tag) {
                tagData.push({ 'id': tag._id, 'slug': tag.slug })
            }
            const tagsId = tagId
            tagsId.push(tag._id)
            setTagId(tagsId)
        }
        setTags(tagData)
    }

    const getFeedTagList = async () => {
        setLoader(true)
        const feedTagList = props.auth.appSettings.feed_tags
        setTagListData(feedTagList)
        setLoader(false)
    }

    const onAddOrRemoveFeedTag = async () => {
        const data = {
            feedTags: tags,
            blogId: props.blogId
        }
        setIsLoading(true)
        setLoader(true)
        const res = await props.addOrRemoveFeedTag(data)
        setTags(res.data.feed_tags)
        dispatch({ type: SET_FEED_TAG, payload: res.data })
        setIsLoading(false)
        setLoader(false)
        props.onShowFeedTag()
        dispatch(setSweetAlert({ description: res.message }))
    }

    function addNewTag() {
        props.showAddNewTag({
            name: '',
            slug: ''
        })
    }

    const handleSearch = (event) => {
        setDropDown(false)
        let value = event.target.value
        setFilterByName(value)
        let result = []
        result = props.auth.appSettings.feed_tags.filter((data) => {
            return data.name.toLowerCase().search(value.toLowerCase()) !== -1
        })
        setTagListData(result)
    }
    const onUpdateTagList = () => {
        onAddOrRemoveFeedTag()
    }
    const openDropDown = (index) => {
        setDropDown(!isDropDownOpen)
        setDropDownIndex(index)
    }

    const deleteFeedTag = async (tagId) => {
        const feed_tags = _.clone(props.feedTags)
        if (feed_tags) {
            setTags(feed_tags)
            let tagId = feed_tags.map(function (obj) { return obj.id })
            setTagId(tagId)
        } else {
            setTags([])
            setTagId([])
        }
        if (window.confirm('Are you sure you want to delete this tag?')) {
            setLoader(true)
            const response = await props.deleteFeedTag(tagId)
            setTagListData(response.data)
            setLoader(false)
        }
        setDropDown(false)
        if (props.blog.selectedTag === tagId) {
            props.setLoading(true)
            let data = {
                pageNum: 1
            }
            await props.getAllBlogs(props.auth.user.isAdmin, data, () => { })
        }
    }

    return (<>
        {(props.isAddTag === true) &&
            <>
                <div style={{ paddingLeft: '2px' }}>
                    <div className='form-row'>
                        <div className='form-group col'>
                            <div className='d-flex align-items-end'>
                                <input
                                    type='text'
                                    id='tag-name'
                                    className='form-control input-name'
                                    placeholder='Search here...'
                                    name='tag-name'
                                    disabled={isLoading}
                                    value={filterByName}
                                    style={{ width: '100%' }}
                                    onChange={(event) => handleSearch(event)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    {isLoading === false && loader ? <div className='text-center'>
                        <div className='spinner-border' role='status' style={{ color: site_font_color }}>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                        :
                        tagListData.length > 0 ? tagListData.map((item, index) => (
                            <div key={item._id} className='form-row' style={{ pointerEvents: isLoading ? 'none' : '' }}>
                                <div className='form-group col mb-0'>
                                    <FeedTagListRow>
                                        <input
                                            className='form-check-input ml-2'
                                            style={{
                                                opacity: isLoading ? 0.5 : '',
                                                marginTop: '18px', height: '20px',
                                                width: '20px', borderRadius: '1.25em'
                                            }}
                                            onChange={handleChange}
                                            type='checkbox'
                                            name='products'
                                            value={item._id}
                                            defaultChecked={tagId && tagId.includes(item._id)}
                                            id={item._id}
                                        />
                                        <label className='form-check-label pt-4' style={{ cursor: 'pointer', height: '52px', marginTop: '2px', width: '90%', paddingLeft: '40px' }} htmlFor={item._id}>{item.name}</label>
                                        {/* <label className='form-check-label pt-2' style={{ cursor: 'pointer', height: '52px', marginTop: '2px', width: '90%', paddingLeft: '40px' }} htmlFor={item._id}>
                                            {item.name} <br /><p style={{ color: `${site_font_color}88` }}>{item.count} post</p>
                                        </label> */}
                                        <DropdownButton className='btn' site_font_color={site_font_color} onClick={() => openDropDown(index)}>
                                            <i className='fas fa-ellipsis-v'></i>
                                        </DropdownButton>
                                    </FeedTagListRow>
                                    {isDropDownOpen && index === dropdownIndex &&
                                        <div className='dropdown-menu show' style={{ right: '5px', top: '57px', left: 'auto' }}>
                                            <DropdownActionLink
                                                className='dropdown-item'
                                                onClick={() => {
                                                    props.showAddNewTag({
                                                        id: item._id,
                                                        name: item.name,
                                                        slug: item.slug
                                                    })
                                                }}>Edit</DropdownActionLink>
                                            <DropdownActionLink className='dropdown-item' onClick={() => deleteFeedTag(item._id)}>Delete</DropdownActionLink>
                                        </div>
                                    }
                                </div>
                            </div>
                        )) : <div className='text-center mb-4'>No Record Found</div>
                    }
                </>
                <div className='modal-header align-items-center border-top btn-create custom-responsive-btns p-3' style={{ borderBottom: 'none' }}>
                    <button className='btn float-left' style={{ backgroundColor: content_color, color: content_font_color }} disabled={isLoading || loader} onClick={() => addNewTag()} type='button'>
                        Create New Tag
                    </button>
                    <button className='btn float-right' style={{ backgroundColor: content_color, color: content_font_color }} disabled={isLoading || loader || tagListData.length === 0} onClick={() => onUpdateTagList()} type='button'>
                        {(isLoading || loader) && <span className='spinner-border spinner-border-sm mr-2'></span>}Update
                    </button>
                </div>
            </>
        }
    </>)
}

FeedTagPopup.propTypes = {
    auth: PropTypes.object.isRequired,
    blog: PropTypes.object.isRequired,
    getAllBlogs: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    addOrRemoveFeedTag: PropTypes.func.isRequired,
    isAddTag: PropTypes.bool.isRequired,
    blogId: PropTypes.string.isRequired,
    showAddNewTag: PropTypes.func.isRequired,
    newTagStatus: PropTypes.bool.isRequired,
    onShowFeedTag: PropTypes.func.isRequired,
    deleteFeedTag: PropTypes.func.isRequired,
    feedTags: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog,
    user: state.user
})

export default connect(
    mapStateToProps,
    {
        setSweetAlert,
        deleteFeedTag,
        addOrRemoveFeedTag,
        getAllBlogs,
        setLoading
    }
)(withRouter(FeedTagPopup))
