import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { size } from '../../utils/common'

const useStyles = theme => ({
    footer: {
        padding: '1rem',
        textAlign: 'center'
    }
})

const StyledDiv = styled.div`
    @media (max-width: ${size.tabletL}) {
        display: none;
    }
`

class FooterPage extends Component {
    render() {
        const { classes } = this.props
        const { model_name, theme_color, theme_font_color, footer_text_html, show_like_the_website_form } = this.props.auth.appSettings
        const likeTheWebsite = show_like_the_website_form ?
            <>&nbsp;|&nbsp;<Link to='/like-the-website' style={{ color: theme_font_color }}>Like the website? Contact me</Link></>
            : null
        return (
            <StyledDiv className={classes.footer} style={{ backgroundColor: theme_color, color: theme_font_color, wordBreak: 'break-word' }}>
                <div className='mb-2'>
                    <Link to='/terms-of-service' style={{ color: theme_font_color }}>Terms of Service</Link>&nbsp;|&nbsp;
                    <Link to='/privacy-policy' style={{ color: theme_font_color }}>Privacy Policy</Link>&nbsp;|&nbsp;
                    <Link to='/dmca' style={{ color: theme_font_color }}>DMCA</Link>&nbsp;|&nbsp;
                    <Link to='/2257' style={{ color: theme_font_color }}>2257</Link>&nbsp;|&nbsp;
                    <Link to='/content-removal' style={{ color: theme_font_color }}>Content Removal</Link>&nbsp;|&nbsp;
                    <Link to='/faq' style={{ color: theme_font_color }}>FAQ</Link>&nbsp;|&nbsp;
                    <Link to='/contact-us' style={{ color: theme_font_color }}>Contact</Link>
                    {likeTheWebsite}
                </div>
                {footer_text_html === '' ?
                    <>&copy; Copyright @{new Date().getFullYear()} {model_name} Powered by <a target='_blank' href='https://www.themccandlessgroup.com' rel='noopener noreferrer' style={{ color: theme_font_color }}>McCandless Group</a></>
                    :
                    <>&copy; Copyright @{new Date().getFullYear()} <span dangerouslySetInnerHTML={{ __html: footer_text_html }} style={{ color: theme_font_color }}></span></>
                }
            </StyledDiv>
        )
    }
}

FooterPage.propTypes = {
    auth: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps
)(withStyles(useStyles)(FooterPage))
