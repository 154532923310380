import { ADDRESS_LIST, LOAD_SINGLE_ADDRESS, IS_ADDRESS_LIST_LOADING } from '../actions/types'

const initialState = {
    addresses: [],
    address: {},
    isAddressListLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
    case ADDRESS_LIST:
        return {
            ...state,
            addresses : action.payload,
            isAddressListLoading: false
        }
    case LOAD_SINGLE_ADDRESS:
        return {
            ...state,
            address : action.payload
        }
    case IS_ADDRESS_LIST_LOADING:
        return {
            ...state,
            isAddressListLoading: true
        }
    default:
        return state
    }
}
