import {
    SET_SWEET_ALERT,
    REMOVE_SWEET_ALERT,
    SET_CONFIRM_SWEET_ALERT,
    REMOVE_CONFIRM_SWEET_ALERT,
    SET_LOADER
} from '../actions/types'

const initialState = {
    showAlert: false,
    description: '',
    onConfirmUrl: '',
    confirmDescription: '',
    showConfirmAlert: false,
    isLoading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SWEET_ALERT:
            return {
                ...state,
                showAlert: true,
                description: action.payload.description === undefined ? state.description : action.payload.description,
                onConfirmUrl: action.payload.onConfirmUrl === undefined ? state.onConfirmUrl : action.payload.onConfirmUrl
            }
        case REMOVE_SWEET_ALERT:
            return {
                ...state,
                showAlert: false,
                description: '',
                onConfirmUrl: ''
            }
        case SET_CONFIRM_SWEET_ALERT:
            return {
                ...state,
                isLoading: false,
                showConfirmAlert: true,
                confirmDescription: action.payload.description === undefined ? state.description : action.payload.description,
                onConfirmUrl: action.payload.onConfirmUrl === undefined ? state.onConfirmUrl : action.payload.onConfirmUrl
            }
        case REMOVE_CONFIRM_SWEET_ALERT:
            return {
                ...state,
                isLoading: false,
                showConfirmAlert: false,
                confirmDescription: '',
                onConfirmUrl: ''
            }
        case SET_LOADER:
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state
    }

}

