import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function HelperModelForPreview(props) {

    useEffect(() => {
        document.querySelector('body').style.overflow = 'hidden'
        return (() => {
            document.querySelector('body').style.overflow = 'visible'
        })
    })

    const { site_font_color, card_background_color } = props.auth.appSettings

    return (
        <div className='modal fade show text-left' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                <div className='modal-content'>
                    <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                        <button className='close' onClick={(e) => {
                            e.preventDefault()
                            props.setHelperModelOpen(false)
                        }} style={{ color: site_font_color, opacity: 1 }} />
                    </div>
                    <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                        <p>
                            A preview can be an image or video that the subscriber can view at no cost to help sell the locked content. While previews are a great way to give your subscribers a sneak peak of your content, it can also backfire if the preview does not give the subscriber a true indication of what you are selling.
                            <br />
                            <br />
                            For example, if you have a 5 photo gallery and choose to use your most PG13 photo from the gallery as the preview, the subscriber might get the wrong impression and think that the rest of the gallery is not what they are interested in.  For this reason, if you do use previews, make sure it helps to SELL the gallery. Some ideas that we have found to be successful:
                        </p>
                        <ul>
                            <li>
                                take your most sexy image from the gallery and cover up the parts you don&apos;t want them to see with a stickers - for this method we suggest you make it clear in the caption that the full unlocked gallery does not include stickers.
                            </li>
                            <li>
                                blur one of the photos just enough to allow the subscribers to see what it is they are buying, without giving away too much of the photo.
                            </li>
                            <li>
                                for videos, you can use a 5-10 second clip of the full video and end it just as you are about to start the sexy part of the full video.
                            </li>
                        </ul>
                        <p>
                            In summary, use your imagination, there is no right or wrong method.  However, remember that the preview is meant to sell the full upload, so be sure that it accomplishes that goal!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

HelperModelForPreview.propTypes = {
    auth: PropTypes.object.isRequired,
    setHelperModelOpen: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps, {}
)((HelperModelForPreview))
