import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateUserOptInStatus } from '../../actions/optInEmailActions'
import { resendChangeEmailRequest, dismissChangeEmailRequest } from '../../actions/authActions'
import _ from 'lodash'

function OptInEmailBanner(props) {
    const appSettings = props.auth.appSettings
    const emailOptInStatus = _.get(props.auth.user, 'emailOptInStatus', false)
    const emailEventType = _.get(props.auth.user, 'emailEventType', 'none')
    const [isResendMessage, setIsResendMessage] = useState(false)
    let emailLog = _.get(props.auth.user, 'emailLog', null)
    const [isTimer, updateIsTimer] = useState(false)
    const [timer, updateTimer] = useState('')

    const updateStatus = (status) => {
        const data = {}
        let flag = false
        if (status === 'resend') {
            let date = localStorage.getItem('link_sent_date_time')
            let expectedDate = new Date(date).setMinutes(new Date(date).getMinutes() + 2)
            if (expectedDate <= new Date()) {
                flag = true
                data.opt_in_status = 'link_sent'
                localStorage.setItem('link_sent_date_time', new Date())
            } else {
                updateIsTimer(true)
                setIsResendMessage(true)
            }
        } else if (status === 'dismiss') {
            flag = true
            data.opt_in_status = 'dismissed'
        }

        if (flag === true) {
            props.updateUserOptInStatus(data, () => {
                if (status === 'resend') {
                    alert('Please check your email.')
                }
            })
        }
    }
    useEffect(() => {
        if (isTimer === true) {
            setInterval(() => getResendTimer(), 1000)
        }
        emailLog = _.get(props.auth.user, 'emailLog', null)
    }, [isTimer, props.auth.user])

    const getResendTimer = () => {
        let date
        if (emailLog === null) {
            date = localStorage.getItem('link_sent_date_time')
        } else {
            date = localStorage.getItem('change_email_link_sent_date_time')
        }
        const expectedDate = new Date(date).setMinutes(new Date(date).getMinutes() + 2)
        let diff = expectedDate - new Date()

        const getSecond = Math.floor(diff / 1000)
        updateTimer(getSecond)
        if (getSecond === 0) {
            updateIsTimer(false)
            setIsResendMessage(false)
        }
    }

    const resendChangeEmailRequest = () => {
        let date = localStorage.getItem('change_email_link_sent_date_time')
        let expectedDate = new Date(new Date(date).setMinutes(new Date(date).getMinutes() + 2))
        if (expectedDate <= new Date()) {
            props.resendChangeEmailRequest()
            localStorage.setItem('change_email_link_sent_date_time', new Date())
        } else {
            updateIsTimer(true)
            setIsResendMessage(true)
        }
    }

    const dismissChangeEmailRequest = () => {
        props.dismissChangeEmailRequest()
    }
    const emailRibbonPaddingTop = appSettings.email_ribbon_padding_top + 'px'
    const emailRibbonPaddingBottom = appSettings.email_ribbon_padding_bottom + 'px'
    if (emailLog !== null) {
        return (
            <div style={{ backgroundColor: appSettings.email_ribbon_bg_color, color: appSettings.email_ribbon_text_color, fontSize: appSettings.email_ribbon_font_size, paddingTop: emailRibbonPaddingTop, paddingBottom: emailRibbonPaddingBottom, fontFamily: appSettings.email_ribbon_font_family }}>
                <span className='mt-2'> You have requested to change your email to {emailLog.email}. Please check your email and click on the confirmation link. If you don’t see the email, be sure to check your spam folder.
                    <br />
                    <span className='mr-4' style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => resendChangeEmailRequest()}
                    >
                        Resend
                    </span>
                    <span className='mr-4' style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => dismissChangeEmailRequest()}
                    >
                        Dismiss
                    </span>
                    {isResendMessage === true ? <div className='col-12 text-center text-red mx-3'>Please wait {timer} second{timer > 1 ? 's' : ''}  before requesting an email resend.</div> : null}
                </span>
            </div>
        )
    }

    return (
        <>
            {emailOptInStatus === 'link_sent' && emailEventType === 'none' &&
                <div style={{ backgroundColor: appSettings.email_ribbon_bg_color, color: appSettings.email_ribbon_text_color, fontSize: appSettings.email_ribbon_font_size, paddingTop: emailRibbonPaddingTop, paddingBottom: emailRibbonPaddingBottom, fontFamily: appSettings.email_ribbon_font_family }}>
                    Please check your email to confirm that you wish to receive notifications.
                    <br />
                    <span className='mr-1 ml-1' style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => updateStatus('resend')}
                    >
                        Resend
                    </span>|
                    <span className='ml-1' style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => updateStatus('dismiss')}
                    >
                        Dismiss
                    </span>
                    {isResendMessage === true && emailLog === null ? <div className='col-12 text-center text-red mx-3'>Please wait {timer} second{timer > 1 ? 's' : ''}  before requesting an email resend.</div> : null}
                </div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

OptInEmailBanner.propTypes = {
    auth: PropTypes.object.isRequired,
    updateUserOptInStatus: PropTypes.func.isRequired,
    resendChangeEmailRequest: PropTypes.func.isRequired,
    dismissChangeEmailRequest: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { updateUserOptInStatus, resendChangeEmailRequest, dismissChangeEmailRequest })(withRouter(OptInEmailBanner))
