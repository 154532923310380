import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { getFileExtension } from '../../utils/common'

const PreviousImage = styled.div`
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    cursor: pointer;
    z-index: 1;
`

const NextImage = styled.div`
    position: absolute;
    top: calc(50% - 15px);
    right: 0;
    cursor: pointer;
    z-index: 1;
`

const Image = styled.img`
    cursor: ${props => props.isLocked ? 'inherit' : 'pointer'};
    max-height: 80vh;
    height: 70vh;
    object-fit: cover;
    width: 100%;
`

const GalleryIcon = styled.div`
    position: absolute;
    top: calc(48.5%);
    left: calc(47.5%);
    cursor: pointer;

    .video-button-span {
        font-size: 30px;
        color: rgb(211, 211, 211);
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
    }

    .play-icon {
        font-size: 30px;
        position: absolute;
        border-radius: 51px;
        left: -5px;
        top: -5px;
    }
`

const BlogGalleryImage = (props) => {

    const [index, setIndex] = useState(0)
    let thumbnailUrlGallery = props.thumbnailUrlGallery
    if (props.thumbnailUrlGallery === undefined || props.thumbnailUrlGallery.length === 0 || props.isLocked === true) {
        thumbnailUrlGallery = props.galleryImages
    }
    let showNextAndPreviousButton = false
    let fileExtension = ''
    let contentExtension = ''
    let contentLeftForProcessing = _.get(props, 'contentLeftForProcessing', 0)
    showNextAndPreviousButton = contentLeftForProcessing > 0 ? false : (props.isLocked === false) ? true : false

    if (contentLeftForProcessing === 0 && props.galleryImages !== undefined) {
        if (props.galleryImages[index] !== undefined) {
            fileExtension = getFileExtension(props.galleryImages[index])

            if (fileExtension !== undefined) {
                contentExtension = fileExtension.split('?')
            }
        }
    }

    const previousImage = () => {
        let currentIndex = index - 1
        if (index <= 0) {
            currentIndex = thumbnailUrlGallery.length - 1
            props.setGalleryImageIndex(currentIndex)
            return setIndex(currentIndex)
        }
        props.setGalleryImageIndex(currentIndex)
        setIndex(currentIndex)
    }

    const nextImage = () => {
        let currentIndex = index + 1
        if (index >= (thumbnailUrlGallery.length - 1)) {
            props.setGalleryImageIndex(0)
            return setIndex(0)
        }
        props.setGalleryImageIndex(currentIndex)
        setIndex(currentIndex)
    }

    return <div style={{ position: 'relative' }}>
        {showNextAndPreviousButton ? <PreviousImage onClick={previousImage}>
            <i className='fas fa-chevron-left fa-2x'></i>
        </PreviousImage> : null}
        <div className='text-center'>
            <div style={{ position: 'relative' }}
                onClick={() => {
                    if (Boolean(props.isLocked) !== true) {
                        props.openDlgBlog(props.i, index)
                    }
                }}>
                {Number(contentLeftForProcessing) > 0 || props.galleryImages === undefined ?
                    <span className='mb-2' style={{ height: '150px', display: 'inline-block' }}>This post is under process. It will be visible once processing is completed.</span>
                    :
                    <>
                        {props.is_published === false && props.gallery_process_error_index.includes(index) ?
                            <span className='mb-2' style={{ height: '150px', display: 'inline-block' }}>Error: There was a problem with this content. we will investigate and let you know.</span>
                            :
                            <Image
                                draggable={false}
                                src={(contentExtension[0] === 'mp4') ? thumbnailUrlGallery[index] : props.galleryImages[index]}
                                alt=''
                                isLocked={props.isLocked}
                            />
                        }
                    </>
                }
                {['mp4', 'mov'].includes(contentExtension[0]) &&
                    <GalleryIcon>
                        <span className='video-button-span'>
                            <i className='fas fa-play-circle play-icon' />
                        </span>
                    </GalleryIcon>
                }
            </div>
        </div>
        {showNextAndPreviousButton ? <NextImage onClick={nextImage}>
            <i className='fas fa-chevron-right fa-2x'></i>
        </NextImage> : null}
    </div>
}

BlogGalleryImage.propTypes = {
    auth: PropTypes.object.isRequired,
    galleryImages: PropTypes.array,
    isLocked: PropTypes.bool.isRequired,
    i: PropTypes.number.isRequired,
    openDlgBlog: PropTypes.func,
    setGalleryImageIndex: PropTypes.func.isRequired,
    thumbnailUrlGallery: PropTypes.array,
    contentLeftForProcessing: PropTypes.number,
    isPreviewEnable: PropTypes.bool,
    is_published: PropTypes.bool,
    gallery_process_error_index: PropTypes.array
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps
)(BlogGalleryImage)
