import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'

const AbsolutePositionDiv = styled.div`
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 70vh;
    z-index: 99999;
`

const CopyToClipboard = (props) => {
    const { card_background_color } = props.auth.appSettings
    const openCopyToClipboardToast = props.copyToClipboard.isCopyToClipboardToastOpen
    return openCopyToClipboardToast ?
        <WebsiteFontColor>
            <AbsolutePositionDiv className='toast text-center show' style={{ backgroundColor: card_background_color }}>
                <div className='toast-body'>
                    {props.toastContent}
                </div>
            </AbsolutePositionDiv>
        </WebsiteFontColor>
        : <></>
}

CopyToClipboard.propTypes = {
    auth: PropTypes.object.isRequired,
    copyToClipboard: PropTypes.object.isRequired,
    toastContent: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    copyToClipboard: state.copyToClipboard
})

export default connect(
    mapStateToProps
)((CopyToClipboard))
