import store from './../store'
const RECAPTCHA_ACTION_REGISTER = 'register'
const RECAPTCHA_ACTION_LOGIN = 'login'
const RECAPTCHA_ACTION_SUBSCRIPTION = 'subscription'
const RECAPTCHA_ACTION_ADD_CARD = 'add_card'

const createReCaptchaScript = () => {
    const state = store.getState()
    const { is_recaptcha_enabled, recaptcha_site_key } = state.auth.appSettings
    if (is_recaptcha_enabled === false) {
        return true
    }
    const scriptId = 'recaptcha-key'
    const isScriptExist = document.getElementById(scriptId)

    if (!isScriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://www.google.com/recaptcha/api.js?render=${recaptcha_site_key}`
        script.id = scriptId
        document.body.appendChild(script)
    }
}

const generateRecaptchaToken = async (action) => {
    return await new Promise((resolve) => {
        const state = store.getState()
        const { is_recaptcha_enabled, recaptcha_site_key } = state.auth.appSettings
        if (is_recaptcha_enabled === false) {
            return resolve({})
        }
        console.log({ action })
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(recaptcha_site_key, { action }).then(token => {
                return resolve({ 'g-recaptcha': token })
            })
        })
    })
}

const checkForRecaptchaStatus = () => {
    const state = store.getState()
    const { is_recaptcha_enabled } = state.auth.appSettings
    return is_recaptcha_enabled
}

export {
    createReCaptchaScript,
    generateRecaptchaToken,
    checkForRecaptchaStatus,
    RECAPTCHA_ACTION_REGISTER,
    RECAPTCHA_ACTION_LOGIN,
    RECAPTCHA_ACTION_SUBSCRIPTION,
    RECAPTCHA_ACTION_ADD_CARD
}
