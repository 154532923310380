export function getFormattedTimeString(days, hours, minutes, seconds) {
    let formattedDay, formattedHour, formattedMinute

    if (days === 1) {
        formattedDay = days + ' Day, '
    }
    if (days > 1) {
        formattedDay = days + ' Days, '
    }
    if (hours < 2) {
        formattedHour = hours + ' Hour, '
    }
    if (hours > 1) {
        formattedHour = hours + ' Hours, '
    }
    if (minutes < 2) {
        formattedMinute = minutes + ' Minute'
    }
    if (minutes > 1) {
        formattedMinute = minutes + ' Minutes'
    }
    if (days > 0) {
        return formattedDay + formattedHour + formattedMinute
    }
    if (days === 0 && hours > 0) {
        return formattedHour + formattedMinute
    }
    if (days === 0 && hours === 0 && minutes > 0) {
        return formattedMinute + ', ' + seconds + ' Seconds'
    }
    if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
        if (seconds === 1) {
            return seconds + ' Second'
        }
        return seconds + ' Seconds'
    }
}

