import axios from 'axios'
import _ from 'lodash'
import { getAppBaseUrl } from './api'
import { setSweetAlert } from './sweetAlertActions'
import { SET_DEFAULT_PAYMENT_METHOD } from './types'

const BASE_URL = getAppBaseUrl()

/**
 * @description get user's payment methods.
 * @param {function} callback payment method list.
 */
export const getUsersPaymentMethod = (callback) => () => {
    axios.get(BASE_URL + '/api/ccbill/get-payment-card')
        .then((res) => {
            callback(res.data)
        }).catch((error) => {
            alert(error.response.data.message)
            callback(error.response)
        })
}

/**
 * @description mark card as primary.
 * @param {object} data card id which is make as primary.
 * @param {function} callback message.
 */
export const markCardAsPrimary = (data, callback) => (dispatch) => {
    axios.post(BASE_URL + '/api/ccbill/set-primary-card', data)
        .then((res) => {
            callback(res.data)
            // update payment method in redux state while update primary card and current payment method is crypto currency
            if (res.data.data.paymentMethod === 'crypto_currency') {
                const responseData = {
                    payment_method: 'credit_card'
                }
                dispatch(updateDefaultPaymentMethod(responseData))
            }
        }).catch((error) => {
            const payload = {
                description: error.response.data.message
            }
            dispatch(setSweetAlert(payload))
            callback(error.response)
        })
}

/**
 * @description Remove card.
 * @param {object} data card id which is needs to remove.
 * @param {function} callback message.
 */
export const removeCard = (data, callback) => (dispatch) => {
    axios.post(BASE_URL + '/api/ccbill/remove-card', data)
        .then((res) => {
            callback(res.data)
        }).catch((error) => {
            const payload = {
                description: error.response.data.message
            }
            dispatch(setSweetAlert(payload))
            callback(error.response)
        })
}

/**
 * @description get user's payment methods.
 */
export const getUsersNewPaymentMethod = () => async () => {
    try {
        const res = await axios.get(BASE_URL + '/api/v2/get-payment-card')
        return res.data
    } catch (error) {
        alert(error.response.data.message)
        return error.response
    }
}

/**
 * @description get user's payment methods.
 */
export const saveUserDefaultPaymentMethod = (data) => async (dispatch) => {
    try {
        await axios.post(BASE_URL + '/user/change-payment-method', data)
        dispatch(updateDefaultPaymentMethod(data))
        return true
    } catch (error) {
        const errorMessage = _.get(error, 'response.data.message', 'There was problem while update payment method')
        dispatch(setSweetAlert({ description: errorMessage }))
        return false
    }
}


export const updateDefaultPaymentMethod = (data) => {
    return {
        type: SET_DEFAULT_PAYMENT_METHOD,
        payload: data
    }
}
