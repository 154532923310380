import axios from 'axios'
import { getAppBaseUrl } from './api'

const BASE_URL = getAppBaseUrl()

/**
 * @description get country list.
 * @param {function} callback country list.
 */
export const getCountries = (callback) => () => {
    axios.get(BASE_URL + '/api/users/get_all_countries')
        .then(res => {
            callback(res.data)
        })
}

/**
 * @description get state list of country.
 * @param {object} data object of country id.
 * @param {function} callback state list.
 */
export const getStates = (data, callback) => () => {
    axios.post(BASE_URL + '/api/users/get_states_of_country', data)
        .then(res => {
            callback(res.data)
        })
}

/**
 * @description get city list of state.
 * @param {object} data object of state id.
 * @param {function} callback city list.
 */
export const getCities = (data, callback) => () => {
    axios.post(BASE_URL + '/api/users/get_cities_of_state', data)
        .then(res => {
            callback(res.data)
        })
}
