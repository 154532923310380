import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

const WebsiteFontsColor = styled.div`
    color: ${props => props.siteFontColor} !important;

    .styled-link a, a.styled-link {
        color: ${props => props.siteFontColor} !important;
        text-decoration: underline !important;
        cursor: pointer;
    }

    .breadcrumb-item.active.styled-link, .breadcrumb-item + .breadcrumb-item::before {
        color: ${props => props.siteFontColor} !important;
    }
`

class WebsiteFontColor extends Component {
    render() {
        const { site_font_color, site_heading_font_color } = this.props.auth.appSettings

        return (
            <WebsiteFontsColor siteFontColor={site_font_color} siteHeadingColor={site_heading_font_color}>
                {this.props.children}
            </WebsiteFontsColor>
        )
    }
}

WebsiteFontColor.propTypes = {
    auth: PropTypes.object.isRequired,
    children: PropTypes.node
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps
)(withRouter(WebsiteFontColor))
