import { GET_CART, ADD_TO_CART, REMOVE_FROM_CART, EMPTY_CART } from '../actions/types'
import update from 'immutability-helper'

const initialState = {
    cart: [],
    shippingCharge: 0,
    isLoading: true
}

export default function (state = initialState, action) {
    switch (action.type) {
    case GET_CART:
        return {
            ...state,
            cart: action.payload.cartItems,
            shippingCharge: action.payload.shippingCharge,
            isLoading: false
        }
    case ADD_TO_CART:
        return {
            ...state,
            cart: update(state.cart, { $push: [action.payload] })
        }
    case REMOVE_FROM_CART:
        return {
            ...state,
            cart: state.cart.splice(action.payload, 1)
        }
    case EMPTY_CART:
        return {
            ...state,
            cart: []
        }
    default:
        return state
    }
}