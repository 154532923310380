export const timezoneList = [
    {
        _id: 'Pacific/Niue',
        name: '(GMT - 11: 00) Niue Time'
    },
    {
        _id: 'Pacific/Pago_Pago',
        name: '(GMT - 11: 00) Samoa Standard Time'
    },
    {
        _id: 'Pacific/Rarotonga',
        name: '(GMT - 10: 00) Cook Islands Standard Time'
    },
    {
        _id: 'Pacific/Honolulu',
        name: '(GMT - 10: 00) Hawaii- Aleutian Standard Time'
    },
    {
        _id: 'Pacific/Tahiti',
        name: '(GMT - 10: 00) Tahiti Time'
    },
    {
        _id: 'Pacific/Marquesas',
        name: '(GMT - 09: 30) Marquesas Time'
    },
    {
        _id: 'Pacific/Gambier',
        name: '(GMT - 09: 00) Gambier Time'
    },
    {
        _id: 'America/Adak',
        name: '(GMT - 09: 00) Hawaii - Aleutian Time(Adak)'
    },
    {
        _id: 'America/Anchorage',
        name: '(GMT - 08: 00) Alaska Time - Anchorage'
    },
    {
        _id: 'America/Juneau',
        name: '(GMT - 08: 00) Alaska Time - Juneau'
    },
    {
        _id: 'America/Metlakatla',
        name: '(GMT - 08: 00) Alaska Time - Metlakatla'
    },
    {
        _id: 'America/Nome',
        name: '(GMT - 08: 00) Alaska Time - Nome'
    },
    {
        _id: 'America/Sitka',
        name: '(GMT - 08: 00) Alaska Time - Sitka'
    },
    {
        _id: 'America/Yakutat',
        name: '(GMT - 08: 00) Alaska Time - Yakutat'
    },
    {
        _id: 'Pacific/Pitcairn',
        name: '(GMT - 08: 00) Pitcairn Time'
    },
    {
        _id: 'America/Hermosillo',
        name: '(GMT - 07: 00) Mexican Pacific Standard Time'
    },
    {
        _id: 'America/Chihuahua',
        name: '(GMT - 07: 00) Mexican Pacific Time - Chihuahua'
    },
    {
        _id: 'America/Mazatlan',
        name: '(GMT - 07: 00) Mexican Pacific Time - Mazatlan'
    },
    {
        _id: 'America/Creston',
        name: '(GMT - 07: 00) Mountain Standard Time - Creston'
    },
    {
        _id: 'America/Dawson_Creek',
        name: '(GMT - 07: 00) Mountain Standard Time - Dawson Creek'
    },
    {
        _id: 'America/Fort_Nelson',
        name: '(GMT - 07: 00) Mountain Standard Time - Fort Nelson'
    },
    {
        _id: 'America/Phoenix',
        name: '(GMT - 07: 00) Mountain Standard Time - Phoenix'
    },
    {
        _id: 'America/Dawson',
        name: '(GMT - 07: 00) Mountain Time(Dawson)'
    },
    {
        _id: 'America/Whitehorse',
        name: '(GMT - 07: 00) Mountain Time(Whitehorse)'
    },
    {
        _id: 'America/Los_Angeles',
        name: '(GMT - 07: 00) Pacific Time - Los Angeles'
    },
    {
        _id: 'America/Tijuana',
        name: '(GMT - 07: 00) Pacific Time - Tijuana'
    },
    {
        _id: 'America/Vancouver',
        name: '(GMT - 07: 00) Pacific Time - Vancouver'
    },
    {
        _id: 'America/Belize',
        name: '(GMT - 06: 00) Central Standard Time - Belize'
    },
    {
        _id: 'America/Costa_Rica',
        name: '(GMT - 06: 00) Central Standard Time - Costa Rica'
    },
    {
        _id: 'America/El_Salvador',
        name: '(GMT - 06: 00) Central Standard Time - El Salvador'
    },
    {
        _id: 'America/Guatemala',
        name: '(GMT - 06: 00) Central Standard Time - Guatemala'
    },
    {
        _id: 'America/Managua',
        name: '(GMT - 06: 00) Central Standard Time - Managua'
    },
    {
        _id: 'America/Regina',
        name: '(GMT - 06: 00) Central Standard Time - Regina'
    },
    {
        _id: 'America/Swift_Current',
        name: '(GMT - 06: 00) Central Standard Time - Swift Current'
    },
    {
        _id: 'America/Tegucigalpa',
        name: '(GMT - 06: 00) Central Standard Time - Tegucigalpa'
    },
    {
        _id: 'America/Bahia_Banderas',
        name: '(GMT - 06: 00) Central Time(Bahia Banderas)'
    },
    {
        _id: 'America/Merida',
        name: '(GMT - 06: 00) Central Time(Merida)'
    },
    {
        _id: 'America/Mexico_City',
        name: '(GMT - 06: 00) Central Time(Mexico)'
    },
    {
        _id: 'America/Monterrey',
        name: '(GMT - 06: 00) Central Time(Monterrey)'
    },
    {
        _id: 'Pacific/Galapagos',
        name: '(GMT - 06: 00) Galapagos Time'
    },
    {
        _id: 'America/Boise',
        name: '(GMT - 06: 00) Mountain Time - Boise'
    },
    {
        _id: 'America/Cambridge_Bay',
        name: '(GMT - 06: 00) Mountain Time - Cambridge Bay'
    },
    {
        _id: 'America/Denver',
        name: '(GMT - 06: 00) Mountain Time - Denver'
    },
    {
        _id: 'America/Edmonton',
        name: '(GMT - 06: 00) Mountain Time - Edmonton'
    },
    {
        _id: 'America/Inuvik',
        name: '(GMT - 06: 00) Mountain Time - Inuvik'
    },
    {
        _id: 'America/Ojinaga',
        name: '(GMT - 06: 00) Mountain Time - Ojinaga'
    },
    {
        _id: 'America/Yellowknife',
        name: '(GMT - 06: 00) Mountain Time - Yellowknife'
    },
    {
        _id: 'America/Eirunepe',
        name: '(GMT - 05: 00) Acre Standard Time - Eirunepe'
    },
    {
        _id: 'America/Rio_Branco',
        name: '(GMT - 05: 00) Acre Standard Time - Rio Branco'
    },
    {
        _id: 'America/North_Dakota/Beulah',
        name: '(GMT - 05: 00) Central Time - Beulah, North Dakota'
    },
    {
        _id: 'America/North_Dakota/Center',
        name: '(GMT - 05: 00) Central Time - Center, North Dakota'
    },
    {
        _id: 'America/Chicago',
        name: '(GMT - 05: 00) Central Time - Chicago'
    },
    {
        _id: 'America/Indiana/Kno',
        name: '(GMT - 05: 00) Central Time - Knox, Indiana'
    },
    {
        _id: 'America/Matamoros',
        name: '(GMT - 05: 00) Central Time - Matamoros'
    },
    {
        _id: 'America/Menominee',
        name: '(GMT - 05: 00) Central Time - Menominee'
    },
    {
        _id: 'America/North_Dakota/New_Sale',
        name: '(GMT - 05: 00) Central Time - New Salem, North Dakota'
    },
    {
        _id: 'America/Rainy_River',
        name: '(GMT - 05: 00) Central Time - Rainy River'
    },
    {
        _id: 'America/Rankin_Inlet',
        name: '(GMT - 05: 00) Central Time - Rankin Inlet'
    },
    {
        _id: 'America/Resolute',
        name: '(GMT - 05: 00) Central Time - Resolute'
    },
    {
        _id: 'America/Indiana/Tell_Cit',
        name: '(GMT - 05: 00) Central Time - Tell City, Indiana'
    },
    {
        _id: 'America/Winnipeg',
        name: '(GMT - 05: 00) Central Time - Winnipeg'
    },
    {
        _id: 'America/Bogota',
        name: '(GMT - 05: 00) Colombia Standard Time'
    },
    {
        _id: 'Pacific/Easter',
        name: '(GMT - 05: 00) Easter Island Time'
    },
    {
        _id: 'America/Atikokan',
        name: '(GMT - 05: 00) Eastern Standard Time - Atikokan'
    },
    {
        _id: 'America/Cancun',
        name: '(GMT - 05: 00) Eastern Standard Time - Cancun'
    },
    {
        _id: 'America/Jamaica',
        name: '(GMT - 05: 00) Eastern Standard Time - Jamaica'
    },
    {
        _id: 'America/Panama',
        name: '(GMT - 05: 00) Eastern Standard Time - Panama'
    },
    {
        _id: 'America/Guayaquil',
        name: '(GMT - 05: 00) Ecuador Time'
    },
    {
        _id: 'America/Lima',
        name: '(GMT - 05: 00) Peru Standard Time'
    },
    {
        _id: 'America/Boa_Vista',
        name: '(GMT - 04: 00) Amazon Standard Time - Boa Vista'
    },
    {
        _id: 'America/Campo_Grande',
        name: '(GMT - 04: 00) Amazon Standard Time - Campo Grande'
    },
    {
        _id: 'America/Cuiaba',
        name: '(GMT - 04: 00) Amazon Standard Time - Cuiaba'
    },
    {
        _id: 'America/Manaus',
        name: '(GMT - 04: 00) Amazon Standard Time - Manaus'
    },
    {
        _id: 'America/Porto_Velho',
        name: '(GMT - 04: 00) Amazon Standard Time - Porto Velho'
    },
    {
        _id: 'America/Barbados',
        name: '(GMT - 04: 00) Atlantic Standard Time - Barbados'
    },
    {
        _id: 'America/Blanc-Sablon',
        name: '(GMT - 04: 00) Atlantic Standard Time - Blanc - Sablon'
    },
    {
        _id: 'America/Curacao',
        name: '(GMT - 04: 00) Atlantic Standard Time - Curaçao'
    },
    {
        _id: 'America/Martinique',
        name: '(GMT - 04: 00) Atlantic Standard Time - Martinique'
    },
    {
        _id: 'America/Port_of_Spain',
        name: '(GMT - 04: 00) Atlantic Standard Time - Port of Spain'
    },
    {
        _id: 'America/Puerto_Rico',
        name: '(GMT - 04: 00) Atlantic Standard Time - Puerto Rico'
    },
    {
        _id: 'America/Santo_Domingo',
        name: '(GMT - 04: 00) Atlantic Standard Time - Santo Domingo'
    },
    {
        _id: 'America/La_Paz',
        name: '(GMT - 04: 00) Bolivia Time'
    },
    {
        _id: 'America/Havana',
        name: '(GMT - 04: 00) Cuba Time'
    },
    {
        _id: 'America/Detroit',
        name: '(GMT - 04: 00) Eastern Time - Detroit'
    },
    {
        _id: 'America/Grand_Turk',
        name: '(GMT - 04: 00) Eastern Time - Grand Turk'
    },
    {
        _id: 'America/Indiana/Indianapolis',
        name: '(GMT - 04: 00) Eastern Time - Indianapolis'
    },
    {
        _id: 'America/Iqaluit',
        name: '(GMT - 04: 00) Eastern Time - Iqaluit'
    },
    {
        _id: 'America/Kentucky/Louisville',
        name: '(GMT - 04: 00) Eastern Time - Louisville'
    },
    {
        _id: 'America/Indiana/Marengo',
        name: '(GMT - 04: 00) Eastern Time - Marengo, Indiana'
    },
    {
        _id: 'America/Kentucky/Monticello',
        name: '(GMT - 04: 00) Eastern Time - Monticello, Kentucky'
    },
    {
        _id: 'America/Nassau',
        name: '(GMT - 04: 00) Eastern Time - Nassau'
    },
    {
        _id: 'America/New_York',
        name: '(GMT - 04: 00) Eastern Time - New York'
    },
    {
        _id: 'America/Nipigon',
        name: '(GMT - 04: 00) Eastern Time - Nipigon'
    },
    {
        _id: 'America/Pangnirtung',
        name: '(GMT - 04: 00) Eastern Time - Pangnirtung'
    },
    {
        _id: 'America/Indiana/Petersburg',
        name: '(GMT - 04: 00) Eastern Time - Petersburg, Indiana'
    },
    {
        _id: 'America/Port - a',
        name: '(GMT - 04: 00) Eastern Time - Port - au - Prince'
    },
    {
        _id: 'America/Thunder_Bay',
        name: '(GMT - 04: 00) Eastern Time - Thunder Bay'
    },
    {
        _id: 'America/Toronto',
        name: '(GMT - 04: 00) Eastern Time - Toronto'
    },
    {
        _id: 'America/Indiana/Vevay',
        name: '(GMT - 04: 00) Eastern Time - Vevay, Indiana'
    },
    {
        _id: 'America/Indiana/Vincennes',
        name: '(GMT - 04: 00) Eastern Time - Vincennes, Indiana'
    },
    {
        _id: 'America/Indiana/Winamac',
        name: '(GMT - 04: 00) Eastern Time - Winamac, Indiana'
    },
    {
        _id: 'America/Guyana',
        name: '(GMT - 04: 00) Guyana Time'
    },
    {
        _id: 'America/Caracas',
        name: '(GMT - 04: 00) Venezuela Time'
    },
    {
        _id: 'America/Argentina/Buenos_Aires',
        name: '(GMT - 03: 00) Argentina Standard Time - Buenos Aires'
    },
    {
        _id: 'America/Argentina/Catamarca',
        name: '(GMT - 03: 00) Argentina Standard Time - Catamarca'
    },
    {
        _id: 'America/Argentina/Cordoba',
        name: '(GMT - 03: 00) Argentina Standard Time - Cordoba'
    },
    {
        _id: 'America/Argentina/Jujuy',
        name: '(GMT - 03: 00) Argentina Standard Time - Jujuy'
    },
    {
        _id: 'America/Argentina/La_Rioja',
        name: '(GMT - 03: 00) Argentina Standard Time - La Rioja'
    },
    {
        _id: 'America/Argentina/Mendoza',
        name: '(GMT - 03: 00) Argentina Standard Time - Mendoza'
    },
    {
        _id: 'America/Argentina/Rio_Gallegos',
        name: '(GMT - 03: 00) Argentina Standard Time - Rio Gallegos'
    },
    {
        _id: 'America/Argentina/Salta',
        name: '(GMT - 03: 00) Argentina Standard Time - Salta'
    },
    {
        _id: 'America/Argentina/San_Juan',
        name: '(GMT - 03: 00) Argentina Standard Time - San Juan'
    },
    {
        _id: 'America/Argentina/San_Luis',
        name: '(GMT - 03: 00) Argentina Standard Time - San Luis'
    },
    {
        _id: 'America/Argentina/Tucuman',
        name: '(GMT - 03: 00) Argentina Standard Time - Tucuman'
    },
    {
        _id: 'America/Argentina/Ushuaia',
        name: '(GMT - 03: 00) Argentina Standard Time - Ushuaia'
    },
    {
        _id: 'Atlantic/Bermuda',
        name: '(GMT - 03: 00) Atlantic Time - Bermuda'
    },
    {
        _id: 'America/Glace_Bay',
        name: '(GMT - 03: 00) Atlantic Time - Glace Bay'
    },
    {
        _id: 'America/Goose_Bay',
        name: '(GMT - 03: 00) Atlantic Time - Goose Bay'
    },
    {
        _id: 'America/Halifax',
        name: '(GMT - 03: 00) Atlantic Time - Halifax'
    },
    {
        _id: 'America/Moncton',
        name: '(GMT - 03: 00) Atlantic Time - Moncton'
    },
    {
        _id: 'America/Thule',
        name: '(GMT - 03: 00) Atlantic Time - Thule'
    },
    {
        _id: 'America/Araguaina',
        name: '(GMT - 03: 00) Brasilia Standard Time - Araguaina'
    },
    {
        _id: 'America/Bahia',
        name: '(GMT - 03: 00) Brasilia Standard Time - Bahia'
    },
    {
        _id: 'America/Belem',
        name: '(GMT - 03: 00) Brasilia Standard Time - Belem'
    },
    {
        _id: 'America/Fortaleza',
        name: '(GMT - 03: 00) Brasilia Standard Time - Fortaleza'
    },
    {
        _id: 'America/Maceio',
        name: '(GMT - 03: 00) Brasilia Standard Time - Maceio'
    },
    {
        _id: 'America/Recife',
        name: '(GMT - 03: 00) Brasilia Standard Time - Recife'
    },
    {
        _id: 'America/Santarem',
        name: '(GMT - 03: 00) Brasilia Standard Time - Santarem'
    },
    {
        _id: 'America/Sao_Paulo',
        name: '(GMT - 03: 00) Brasilia Standard Time - Sao Paulo'
    },
    {
        _id: 'America/Santiago',
        name: '(GMT - 03: 00) Chile Time'
    },
    {
        _id: 'Atlantic/Stanley',
        name: '(GMT - 03: 00) Falkland Islands Standard Time'
    },
    {
        _id: 'America/Cayenne',
        name: '(GMT - 03: 00) French Guiana Time'
    },
    {
        _id: 'Antarctica/Palmer',
        name: '(GMT - 03: 00) Palmer Time'
    },
    {
        _id: 'America/Asuncion',
        name: '(GMT - 03: 00) Paraguay Time'
    },
    {
        _id: 'America/Punta_Arenas',
        name: '(GMT - 03: 00) Punta Arenas Time'
    },
    {
        _id: 'Antarctica/Rothera',
        name: '(GMT - 03: 00) Rothera Time'
    },
    {
        _id: 'America/Paramaribo',
        name: '(GMT - 03: 00) Suri_id Time'
    },
    {
        _id: 'America/Montevideo',
        name: '(GMT - 03: 00) Uruguay Standard Time'
    },
    {
        _id: 'America/St_Johns',
        name: '(GMT - 02: 30) Newfoundland Time'
    },
    {
        _id: 'America/Noronha',
        name: '(GMT - 02: 00) Fernando de Noronha Standard Time'
    },
    {
        _id: 'Atlantic/South_Georgia',
        name: '(GMT - 02: 00) South Georgia Time'
    },
    {
        _id: 'America/Miquelon',
        name: '(GMT - 02: 00) St.Pierre & amp; Miquelon Time'
    },
    {
        _id: 'Atlantic/Azores',
        name: '(GMT - 01: 00) Azores Time'
    },
    {
        _id: 'Atlantic/Cape_Verde',
        name: '(GMT - 01: 00) Cape Verde Standard Time'
    },
    {
        _id: 'America/Scoresbysund',
        name: '(GMT - 01: 00) East Greenland Time'
    },
    {
        _id: 'UTC',
        name: '(GMT + 00: 00) Coordinated Universal Time'
    },
    {
        _id: 'Etc/GMT',
        name: '(GMT + 00: 00) Greenwich Mean Time'
    },
    {
        _id: 'Africa/Abidjan',
        name: '(GMT + 00: 00) Greenwich Mean Time - Abidjan'
    },
    {
        _id: 'Africa/Accra',
        name: '(GMT + 00: 00) Greenwich Mean Time - Accra'
    },
    {
        _id: 'Africa/Bissau',
        name: '(GMT + 00: 00) Greenwich Mean Time - Bissau'
    },
    {
        _id: 'America/Danmarkshavn',
        name: '(GMT + 00: 00) Greenwich Mean Time - Danmarkshavn'
    },
    {
        _id: 'Africa/Monrovia',
        name: '(GMT + 00: 00) Greenwich Mean Time - Monrovia'
    },
    {
        _id: 'Atlantic/Reykjavik',
        name: '(GMT + 00: 00) Greenwich Mean Time - Reykjavik'
    },
    {
        _id: 'Africa/Sao_Tome',
        name: '(GMT + 00: 00) Greenwich Mean Time - São Tomé'
    },
    {
        _id: 'Europe/Dublin',
        name: '(GMT + 00: 00) Ireland Time'
    },
    {
        _id: 'Antarctica/Troll',
        name: '(GMT + 00: 00) Troll Time'
    },
    {
        _id: 'Europe/London',
        name: '(GMT + 00: 00) United Kingdom Time'
    },
    {
        _id: 'Atlantic/Canary',
        name: '(GMT + 00: 00) Western European Time - Canary'
    },
    {
        _id: 'Atlantic/Faroe',
        name: '(GMT + 00: 00) Western European Time - Faroe'
    },
    {
        _id: 'Europe/Lisbon',
        name: '(GMT + 00: 00) Western European Time - Lisbon'
    },
    {
        _id: 'Atlantic/Madeira',
        name: '(GMT + 00: 00) Western European Time - Madeira'
    },
    {
        _id: 'Africa/Algiers',
        name: '(GMT + 01: 00) Central European Standard Time - Algiers'
    },
    {
        _id: 'Africa/Tunis',
        name: '(GMT + 01: 00) Central European Standard Time - Tunis'
    },
    {
        _id: 'Europe/Amsterdam',
        name: '(GMT + 01: 00) Central European Time - Amsterdam'
    },
    {
        _id: 'Europe/Andorra',
        name: '(GMT + 01: 00) Central European Time - Andorra'
    },
    {
        _id: 'Europe/Belgrade',
        name: '(GMT + 01: 00) Central European Time - Belgrade'
    },
    {
        _id: 'Europe/Berlin',
        name: '(GMT + 01: 00) Central European Time - Berlin'
    },
    {
        _id: 'Europe/Brussels',
        name: '(GMT + 01: 00) Central European Time - Brussels'
    },
    {
        _id: 'Europe/Budapest',
        name: '(GMT + 01: 00) Central European Time - Budapest'
    },
    {
        _id: 'Africa/Ceuta',
        name: '(GMT + 01: 00) Central European Time - Ceuta'
    },
    {
        _id: 'Europe/Copenhagen',
        name: '(GMT + 01: 00) Central European Time - Copenhagen'
    },
    {
        _id: 'Europe/Gibraltar',
        name: '(GMT + 01: 00) Central European Time - Gibraltar'
    },
    {
        _id: 'Europe/Luxembourg',
        name: '(GMT + 01: 00) Central European Time - Luxembourg'
    },
    {
        _id: 'Europe/Madrid',
        name: '(GMT + 01: 00) Central European Time - Madrid'
    },
    {
        _id: 'Europe/Malta',
        name: '(GMT + 01: 00) Central European Time - Malta'
    },
    {
        _id: 'Europe/Monaco',
        name: '(GMT + 01: 00) Central European Time - Monaco'
    },
    {
        _id: 'Europe/Oslo',
        name: '(GMT + 01: 00) Central European Time - Oslo'
    },
    {
        _id: 'Europe/Paris',
        name: '(GMT + 01: 00) Central European Time - Paris'
    },
    {
        _id: 'Europe/Prague',
        name: '(GMT + 01: 00) Central European Time - Prague'
    },
    {
        _id: 'Europe/Rome',
        name: '(GMT + 01: 00) Central European Time - Rome'
    },
    {
        _id: 'Europe/Stockholm',
        name: '(GMT + 01: 00) Central European Time - Stockholm'
    },
    {
        _id: 'Europe/Tirane',
        name: '(GMT + 01: 00) Central European Time - Tirane'
    },
    {
        _id: 'Europe/Vienna',
        name: '(GMT + 01: 00) Central European Time - Vienna'
    },
    {
        _id: 'Europe/Warsaw',
        name: '(GMT + 01: 00) Central European Time - Warsaw'
    },
    {
        _id: 'Europe/Zurich',
        name: '(GMT + 01: 00) Central European Time - Zurich'
    },
    {
        _id: 'Africa/Casablanca',
        name: '(GMT + 01: 00) Morocco Time'
    },
    {
        _id: 'Africa/Lagos',
        name: '(GMT + 01: 00) West Africa Standard Time - Lagos'
    },
    {
        _id: 'Africa/Ndjamena',
        name: '(GMT + 01: 00) West Africa Standard Time - Ndjamena'
    },
    {
        _id: 'Africa/El_Aaiun',
        name: '(GMT + 01: 00) Western Sahara Time'
    },
    {
        _id: 'Africa/Juba',
        name: '(GMT + 02: 00) Central Africa Time - Juba'
    },
    {
        _id: 'Africa/Khartoum',
        name: '(GMT + 02: 00) Central Africa Time - Khartoum'
    },
    {
        _id: 'Africa/Maputo',
        name: '(GMT + 02: 00) Central Africa Time - Maputo'
    },
    {
        _id: 'Africa/Windhoek',
        name: '(GMT + 02: 00) Central Africa Time - Windhoek'
    },
    {
        _id: 'Africa/Cairo',
        name: '(GMT + 02: 00) Eastern European Standard Time - Cairo'
    },
    {
        _id: 'Europe/Kaliningrad',
        name: '(GMT + 02: 00) Eastern European Standard Time - Kaliningrad'
    },
    {
        _id: 'Africa/Tripoli',
        name: '(GMT + 02: 00) Eastern European Standard Time - Tripoli'
    },
    {
        _id: 'Asia/Amman',
        name: '(GMT + 02: 00) Eastern European Time - Amman'
    },
    {
        _id: 'Europe/Athens',
        name: '(GMT + 02: 00) Eastern European Time - Athens'
    },
    {
        _id: 'Asia/Beirut',
        name: '(GMT + 02: 00) Eastern European Time - Beirut'
    },
    {
        _id: 'Europe/Bucharest',
        name: '(GMT + 02: 00) Eastern European Time - Bucharest'
    },
    {
        _id: 'Europe/Chisinau',
        name: '(GMT + 02: 00) Eastern European Time - Chisinau'
    },
    {
        _id: 'Asia/Damascus',
        name: '(GMT + 02: 00) Eastern European Time - Damascus'
    },
    {
        _id: 'Asia/Gaza',
        name: '(GMT + 02: 00) Eastern European Time - Gaza'
    },
    {
        _id: 'Asia/Hebron',
        name: '(GMT + 02: 00) Eastern European Time - Hebron'
    },
    {
        _id: 'Europe/Helsinki',
        name: '(GMT + 02: 00) Eastern European Time - Helsinki'
    },
    {
        _id: 'Europe/Kiev',
        name: '(GMT + 02: 00) Eastern European Time - Kiev'
    },
    {
        _id: 'Asia/Nicosia',
        name: '(GMT + 02: 00) Eastern European Time - Nicosia'
    },
    {
        _id: 'Europe/Riga',
        name: '(GMT + 02: 00) Eastern European Time - Riga'
    },
    {
        _id: 'Europe/Sofia',
        name: '(GMT + 02: 00) Eastern European Time - Sofia'
    },
    {
        _id: 'Europe/Tallinn',
        name: '(GMT + 02: 00) Eastern European Time - Tallinn'
    },
    {
        _id: 'Europe/Uzhgorod',
        name: '(GMT + 02: 00) Eastern European Time - Uzhhorod'
    },
    {
        _id: 'Europe/Vilnius',
        name: '(GMT + 02: 00) Eastern European Time - Vilnius'
    },
    {
        _id: 'Europe/Zaporozhye',
        name: '(GMT + 02: 00) Eastern European Time - Zaporozhye'
    },
    {
        _id: 'Asia/Famagusta',
        name: '(GMT + 02: 00) Famagusta Time'
    },
    {
        _id: 'Asia/Jerusalem',
        name: '(GMT + 02: 00) Israel Time'
    },
    {
        _id: 'Africa/Johannesburg',
        name: '(GMT + 02: 00) South Africa Standard Time'
    },
    {
        _id: 'Asia/Baghdad',
        name: '(GMT + 03: 00) Arabian Standard Time - Baghdad'
    },
    {
        _id: 'Asia/Qatar',
        name: '(GMT + 03: 00) Arabian Standard Time - Qatar'
    },
    {
        _id: 'Asia/Riyadh',
        name: '(GMT + 03: 00) Arabian Standard Time - Riyadh'
    },
    {
        _id: 'Africa/Nairobi',
        name: '(GMT + 03: 00) East Africa Time'
    },
    {
        _id: 'Europe/Kirov',
        name: '(GMT + 03: 00) Kirov Time'
    },
    {
        _id: 'Europe/Minsk',
        name: '(GMT + 03: 00) Moscow Standard Time - Minsk'
    },
    {
        _id: 'Europe/Moscow',
        name: '(GMT + 03: 00) Moscow Standard Time - Moscow'
    },
    {
        _id: 'Europe/Simferopol',
        name: '(GMT + 03: 00) Moscow Standard Time - Simferopol'
    },
    {
        _id: 'Antarctica/Syowa',
        name: '(GMT + 03: 00) Syowa Time'
    },
    {
        _id: 'Europe/Istanbul',
        name: '(GMT + 03: 00) Turkey Time'
    },
    {
        _id: 'Europe/Volgograd',
        name: '(GMT + 03: 00) Volgograd Standard Time'
    },
    {
        _id: 'Asia/Tehran',
        name: '(GMT + 03: 30) Iran Time'
    },
    {
        _id: 'Asia/Yerevan',
        name: '(GMT + 04: 00) Armenia Standard Time'
    },
    {
        _id: 'Europe/Astrakhan',
        name: '(GMT + 04: 00) Astrakhan Time'
    },
    {
        _id: 'Asia/Baku',
        name: '(GMT + 04: 00) Azerbaijan Standard Time'
    },
    {
        _id: 'Asia/Tbilisi',
        name: '(GMT + 04: 00) Georgia Standard Time'
    },
    {
        _id: 'Asia/Dubai',
        name: '(GMT + 04: 00) Gulf Standard Time'
    },
    {
        _id: 'Indian/Mauritius',
        name: '(GMT + 04: 00) Mauritius Standard Time'
    },
    {
        _id: 'Indian/Reunion',
        name: '(GMT + 04: 00) Réunion Time'
    },
    {
        _id: 'Europe/Samara',
        name: '(GMT + 04: 00) Samara Standard Time'
    },
    {
        _id: 'Europe/Saratov',
        name: '(GMT + 04: 00) Saratov Time'
    },
    {
        _id: 'Indian/Mahe',
        name: '(GMT + 04: 00) Seychelles Time'
    },
    {
        _id: 'Europe/Ulyanovsk',
        name: '(GMT + 04: 00) Ulyanovsk Time'
    },
    {
        _id: 'Asia/Kabul',
        name: '(GMT + 04: 30) Afghanistan Time'
    },
    {
        _id: 'Indian/Kerguelen',
        name: '(GMT + 05: 00) French Southern & amp; Antarctic Time'
    },
    {
        _id: 'Indian/Maldives',
        name: '(GMT + 05: 00) Maldives Time'
    },
    {
        _id: 'Antarctica/Mawson',
        name: '(GMT + 05: 00) Mawson Time'
    },
    {
        _id: 'Asia/Karachi',
        name: '(GMT + 05: 00) Pakistan Standard Time'
    },
    {
        _id: 'Asia/Dushanbe',
        name: '(GMT + 05: 00) Tajikistan Time'
    },
    {
        _id: 'Asia/Ashgabat',
        name: '(GMT + 05: 00) Turkmenistan Standard Time'
    },
    {
        _id: 'Asia/Samarkand',
        name: '(GMT + 05: 00) Uzbekistan Standard Time - Samarkand'
    },
    {
        _id: 'Asia/Tashkent',
        name: '(GMT + 05: 00) Uzbekistan Standard Time - Tashkent'
    },
    {
        _id: 'Asia/Aqtau',
        name: '(GMT + 05: 00) West Kazakhstan Time - Aqtau'
    },
    {
        _id: 'Asia/Aqtobe',
        name: '(GMT + 05: 00) West Kazakhstan Time - Aqtobe'
    },
    {
        _id: 'Asia/Atyrau',
        name: '(GMT + 05: 00) West Kazakhstan Time - Atyrau'
    },
    {
        _id: 'Asia/Oral',
        name: '(GMT + 05: 00) West Kazakhstan Time - Oral'
    },
    {
        _id: 'Asia/Qyzylorda',
        name: '(GMT + 05: 00) West Kazakhstan Time - Qyzylorda'
    },
    {
        _id: 'Asia/Yekaterinburg',
        name: '(GMT + 05: 00) Yekaterinburg Standard Time'
    },
    {
        _id: 'Asia/Colombo',
        name: '(GMT + 05: 30) India Standard Time - Colombo'
    },
    {
        _id: 'Asia/Kolkata',
        name: '(GMT + 05: 30) India Standard Time - Kolkata'
    },
    {
        _id: 'Asia/Kathmandu',
        name: '(GMT + 05: 45) Nepal Time'
    },
    {
        _id: 'Asia/Dhaka',
        name: '(GMT + 06: 00) Bangladesh Standard Time'
    },
    {
        _id: 'Asia/Thimphu',
        name: '(GMT + 06: 00) Bhutan Time'
    },
    {
        _id: 'Asia/Almaty',
        name: '(GMT + 06: 00) East Kazakhstan Time - Almaty'
    },
    {
        _id: 'Asia/Qostanay',
        name: '(GMT + 06: 00) East Kazakhstan Time - Kostanay'
    },
    {
        _id: 'Indian/Chagos',
        name: '(GMT + 06: 00) Indian Ocean Time'
    },
    {
        _id: 'Asia/Bishkek',
        name: '(GMT + 06: 00) Kyrgyzstan Time'
    },
    {
        _id: 'Asia/Omsk',
        name: '(GMT + 06: 00) Omsk Standard Time'
    },
    {
        _id: 'Asia/Urumqi',
        name: '(GMT + 06: 00) Urumqi Time'
    },
    {
        _id: 'Antarctica/Vostok',
        name: '(GMT + 06: 00) Vostok Time'
    },
    {
        _id: 'Indian/Cocos',
        name: '(GMT + 06: 30) Cocos Islands Time'
    },
    {
        _id: 'Asia/Yangon',
        name: '(GMT + 06: 30) Myanmar Time'
    },
    {
        _id: 'Asia/Barnaul',
        name: '(GMT + 07: 00) Barnaul Time'
    },
    {
        _id: 'Indian/Christmas',
        name: '(GMT + 07: 00) Christmas Island Time'
    },
    {
        _id: 'Antarctica/Davis',
        name: '(GMT + 07: 00) Davis Time'
    },
    {
        _id: 'Asia/Hovd',
        name: '(GMT + 07: 00) Hovd Standard Time'
    },
    {
        _id: 'Asia/Bangkok',
        name: '(GMT + 07: 00) Indochina Time - Bangkok'
    },
    {
        _id: 'Asia/Ho_Chi_Minh',
        name: '(GMT + 07: 00) Indochina Time - Ho Chi Minh City'
    },
    {
        _id: 'Asia/Krasnoyarsk',
        name: '(GMT + 07: 00) Krasnoyarsk Standard Time - Krasnoyarsk'
    },
    {
        _id: 'Asia/Novokuznetsk',
        name: '(GMT + 07: 00) Krasnoyarsk Standard Time - Novokuznetsk'
    },
    {
        _id: 'Asia/Novosibirsk',
        name: '(GMT + 07: 00) Novosibirsk Standard Time'
    },
    {
        _id: 'Asia/Tomsk',
        name: '(GMT + 07: 00) Tomsk Time'
    },
    {
        _id: 'Asia/Jakarta',
        name: '(GMT + 07: 00) Western Indonesia Time - Jakarta'
    },
    {
        _id: 'Asia/Pontianak',
        name: '(GMT + 07: 00) Western Indonesia Time - Pontianak'
    },
    {
        _id: 'Australia/Perth',
        name: '(GMT + 08: 00) Australian Western Standard Time'
    },
    {
        _id: 'Asia/Brunei',
        name: '(GMT + 08: 00) Brunei Darussalam Time'
    },
    {
        _id: 'Asia/Makassar',
        name: '(GMT + 08: 00) Central Indonesia Time'
    },
    {
        _id: 'Asia/Macau',
        name: '(GMT + 08: 00) China Standard Time - Macao'
    },
    {
        _id: 'Asia/Shanghai',
        name: '(GMT + 08: 00) China Standard Time - Shanghai'
    },
    {
        _id: 'Asia/Hong_Kong',
        name: '(GMT + 08: 00) Hong Kong Standard Time'
    },
    {
        _id: 'Asia/Irkutsk',
        name: '(GMT + 08: 00) Irkutsk Standard Time'
    },
    {
        _id: 'Asia/Kuala_Lumpur',
        name: '(GMT + 08: 00) Malaysia Time - Kuala Lumpur'
    },
    {
        _id: 'Asia/Kuching',
        name: '(GMT + 08: 00) Malaysia Time - Kuching'
    },
    {
        _id: 'Asia/Manila',
        name: '(GMT + 08: 00) Philippine Standard Time'
    },
    {
        _id: 'Asia/Singapore',
        name: '(GMT + 08: 00) Singapore Standard Time'
    },
    {
        _id: 'Asia/Taipei',
        name: '(GMT + 08: 00) Taipei Standard Time'
    },
    {
        _id: 'Asia/Choibalsan',
        name: '(GMT + 08: 00) Ulaanbaatar Standard Time - Choibalsan'
    },
    {
        _id: 'Asia/Ulaanbaatar',
        name: '(GMT + 08: 00) Ulaanbaatar Standard Time - Ulaanbaatar'
    },
    {
        _id: 'Australia/Eucla',
        name: '(GMT + 08: 45) Australian Central Western Standard Time'
    },
    {
        _id: 'Asia/Dili',
        name: '(GMT + 09: 00) East Timor Time'
    },
    {
        _id: 'Asia/Jayapura',
        name: '(GMT + 09: 00) Eastern Indonesia Time'
    },
    {
        _id: 'Asia/Tokyo',
        name: '(GMT + 09: 00) Japan Standard Time'
    },
    {
        _id: 'Asia/Pyongyang',
        name: '(GMT + 09: 00) Korean Standard Time - Pyongyang'
    },
    {
        _id: 'Asia/Seoul',
        name: '(GMT + 09: 00) Korean Standard Time - Seoul'
    },
    {
        _id: 'Pacific/Palau',
        name: '(GMT + 09: 00) Palau Time'
    },
    {
        _id: 'Asia/Chita',
        name: '(GMT + 09: 00) Yakutsk Standard Time - Chita'
    },
    {
        _id: 'Asia/Khandyga',
        name: '(GMT + 09: 00) Yakutsk Standard Time - Khandyga'
    },
    {
        _id: 'Asia/Yakutsk',
        name: '(GMT + 09: 00) Yakutsk Standard Time - Yakutsk'
    },
    {
        _id: 'Australia/Darwin',
        name: '(GMT + 09: 30) Australian Central Standard Time'
    },
    {
        _id: 'Australia/Brisbane',
        name: '(GMT + 10: 00) Australian Eastern Standard Time - Brisbane'
    },
    {
        _id: 'Australia/Lindeman',
        name: '(GMT + 10: 00) Australian Eastern Standard Time - Lindeman'
    },
    {
        _id: 'Pacific/Guam',
        name: '(GMT + 10: 00) Chamorro Standard Time'
    },
    {
        _id: 'Pacific/Chuuk',
        name: '(GMT + 10: 00) Chuuk Time'
    },
    {
        _id: 'Antarctica/DumontDUrville',
        name: '(GMT + 10: 00) Dumont - d’Urville Time'
    },
    {
        _id: 'Pacific/Port_Moresby',
        name: '(GMT + 10: 00) Papua New Guinea Time'
    },
    {
        _id: 'Asia/Ust - Ner',
        name: '(GMT + 10: 00) Vladivostok Standard Time - Ust - Nera'
    },
    {
        _id: 'Asia/Vladivostok',
        name: '(GMT + 10: 00) Vladivostok Standard Time - Vladivostok'
    },
    {
        _id: 'Australia/Adelaide',
        name: '(GMT + 10: 30) Central Australia Time - Adelaide'
    },
    {
        _id: 'Australia/Broken_Hill',
        name: '(GMT + 10: 30) Central Australia Time - Broken Hill'
    },
    {
        _id: 'Pacific/Bougainville',
        name: '(GMT + 11: 00) Bougainville Time'
    },
    {
        _id: 'Antarctica/Casey',
        name: '(GMT + 11: 00) Casey Time'
    },
    {
        _id: 'Australia/Hobart',
        name: '(GMT + 11: 00) Eastern Australia Time - Hobart'
    },
    {
        _id: 'Antarctica/Macquarie',
        name: '(GMT + 11: 00) Eastern Australia Time - Macquarie'
    },
    {
        _id: 'Australia/Melbourne',
        name: '(GMT + 11: 00) Eastern Australia Time - Melbourne'
    },
    {
        _id: 'Australia/Sydney',
        name: '(GMT + 11: 00) Eastern Australia Time - Sydney'
    },
    {
        _id: 'Pacific/Kosrae',
        name: '(GMT + 11: 00) Kosrae Time'
    },
    {
        _id: 'Australia/Lord_Howe',
        name: '(GMT + 11: 00) Lord Howe Time'
    },
    {
        _id: 'Asia/Magadan',
        name: '(GMT + 11: 00) Magadan Standard Time'
    },
    {
        _id: 'Pacific/Noumea',
        name: '(GMT + 11: 00) New Caledonia Standard Time'
    },
    {
        _id: 'Pacific/Pohnpei',
        name: '(GMT + 11: 00) Ponape Time'
    },
    {
        _id: 'Asia/Sakhalin',
        name: '(GMT + 11: 00) Sakhalin Standard Time'
    },
    {
        _id: 'Pacific/Guadalcanal',
        name: '(GMT + 11: 00) Solomon Islands Time'
    },
    {
        _id: 'Asia/Srednekolymsk',
        name: '(GMT + 11: 00) Srednekolymsk Time'
    },
    {
        _id: 'Pacific/Efate',
        name: '(GMT + 11: 00) Vanuatu Standard Time'
    },
    {
        _id: 'Asia/Anadyr',
        name: '(GMT + 12: 00) Anadyr Standard Time'
    },
    {
        _id: 'Pacific/Fiji',
        name: '(GMT + 12: 00) Fiji Time'
    },
    {
        _id: 'Pacific/Tarawa',
        name: '(GMT + 12: 00) Gilbert Islands Time'
    },
    {
        _id: 'Pacific/Kwajalein',
        name: '(GMT + 12: 00) Marshall Islands Time - Kwajalein'
    },
    {
        _id: 'Pacific/Majuro',
        name: '(GMT + 12: 00) Marshall Islands Time - Majuro'
    },
    {
        _id: 'Pacific/Nauru',
        name: '(GMT + 12: 00) Nauru Time'
    },
    {
        _id: 'Pacific/Norfolk',
        name: '(GMT + 12: 00) Norfolk Island Time'
    },
    {
        _id: 'Asia/Kamchatka',
        name: '(GMT + 12: 00) Petropavlovsk - Kamchatski Standard Time'
    },
    {
        _id: 'Pacific/Funafuti',
        name: '(GMT + 12: 00) Tuvalu Time'
    },
    {
        _id: 'Pacific/Wake',
        name: '(GMT + 12: 00) Wake Island Time'
    },
    {
        _id: 'Pacific/Wallis',
        name: '(GMT + 12: 00) Wallis & amp; Futuna Time'
    },
    {
        _id: 'Pacific/Auckland',
        name: '(GMT + 13: 00) New Zealand Time'
    },
    {
        _id: 'Pacific/Enderbury',
        name: '(GMT + 13: 00) Phoenix Islands Time'
    },
    {
        _id: 'Pacific/Fakaofo',
        name: '(GMT + 13: 00) Tokelau Time'
    },
    {
        _id: 'Pacific/Tongatapu',
        name: '(GMT + 13: 00) Tonga Standard Time'
    },
    {
        _id: 'Pacific/Chatham',
        name: '(GMT + 13: 45) Chatham Time'
    },
    {
        _id: 'Pacific/Apia',
        name: '(GMT + 14: 00) Apia Time'
    },
    {
        _id: 'Pacific/Kiritimati',
        name: '(GMT + 14: 00) Line Islands Time'
    }
]
