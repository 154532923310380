import React, { Component } from 'react'
import axios from 'axios'
import { getAppBaseUrl } from '../../actions/api'
import HTMLDiv from '../Layouts/htmlDivStyledComponent'

const BASE_URL = getAppBaseUrl()

class MaintenancePage extends Component {
    constructor() {
        super()

        this.state = {
            textMaintenance: ''
        }
    }
    componentDidMount() {
        axios.get(BASE_URL + '/api/pages/get_html?slug=maintenance').then(res => {
            this.setState({ textMaintenance: res.data.html })
        })
    }
    render() {
        return (
            <>
                <div className='container'>
                    <div className='row'>
                        <HTMLDiv
                            className='col pt-5 pb-5'
                            dangerouslySetInnerHTML={{ __html: this.state.textMaintenance }}
                        >
                        </HTMLDiv>
                    </div>
                </div>
            </>
        )
    }
}

export default MaintenancePage
