import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

function TagTags(props) {
    const { content_color, content_font_color, site_background_color, site_font_color } = props.auth.appSettings
    const showCount = _.get(props, 'showCount', false)

    return <div className='button-group text-center'>
        {props.tagList.map(data => {
            if (data._id !== undefined) {
                let btnStyle = props.selectedTag === data._id ? {
                    backgroundColor: site_font_color,
                    color: site_background_color,
                    textTransform: 'none'
                } : {
                    backgroundColor: site_background_color,
                    color: site_font_color,
                    borderColor: site_font_color,
                    textTransform: 'none'
                }
                return <button className={classNames('btn col-auto rounded-pill shadow-none px-4 py-1')}
                    onClick={() => props.onClick(data._id)}
                    key={data._id}
                    style={btnStyle}
                    disabled={props.disabled}
                >
                    {data.name}  {(props.auth.isAdmin && showCount) ? <>({data.count})</> : null}
                </button>
            }
            return null
        })}
        <button className='btn col-auto shadow-none'
            onClick={props.onReset}
            style={{
                backgroundColor: content_color,
                color: content_font_color
            }}
            disabled={props.disabled}
        >
            Reset
        </button>
    </div >
}

TagTags.propTypes = {
    auth: PropTypes.object,
    tagList: PropTypes.array.isRequired,
    showCount: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    disabled: PropTypes.bool,
    selectedTag: PropTypes.string
}

const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog
})

export default connect(mapStateToProps)(withRouter(TagTags))
