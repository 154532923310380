import {
    UPDATE_PAYMENT_PROCESSING_STATUS
} from '../actions/types'

const initialState = {
    isPaymentProcessing: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_PAYMENT_PROCESSING_STATUS:
            return {
                ...state,
                isPaymentProcessing: action.payload
            }
        default:
            return state
    }
}
