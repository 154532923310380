import {
    GET_PRODUCTS,
    ADD_NEW_PRODUCT,
    IS_PRODUCT_LIST_LOADING,
    SET_PRODUCT,
    IS_PRODUCT_LOADING,
    GET_ORDERS,
    IS_ORDER_LIST_LOADING,
    GET_SINGLE_ORDER
} from '../actions/types'
import update from 'immutability-helper'

const initialState = {
    products: [],
    isLoading: false,
    totalPages: 0,
    currentPage: 0,
    product: {},
    isProductLoading: false,
    orders: [],
    isOrderListLoading: false,
    ordersCurrentPage: 1,
    ordersTotalPages: 1,
    itemsPerPage: 10,
    totalRows: 1,
    order: {},
    orderDetails: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                totalPages: action.payload.totalPages,
                currentPage: parseInt(action.payload.currentPage),
                isLoading: false
            }
        case GET_ORDERS:
            return {
                ...state,
                orders: action.payload.orders,
                ordersTotalPages: parseInt(action.payload.totalPages, 10),
                ordersCurrentPage: parseInt(action.payload.currentPage, 10),
                itemsPerPage: parseInt(action.payload.limit, 10),
                totalRows: parseInt(action.payload.totalRows, 10),
                isOrderListLoading: false
            }
        case GET_SINGLE_ORDER:
            return {
                ...state,
                order: action.payload.order,
                orderDetails: action.payload.orderDetails
            }
        case ADD_NEW_PRODUCT:
            return {
                ...state,
                products: update(state.products, { $push: [action.payload] })
            }
        case SET_PRODUCT:
            return {
                ...state,
                product: action.payload,
                isProductLoading: false
            }
        case IS_PRODUCT_LIST_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case IS_PRODUCT_LOADING:
            return {
                ...state,
                isProductLoading: true
            }
        case IS_ORDER_LIST_LOADING:
            return {
                ...state,
                isOrderListLoading: true
            }
        default:
            return state
    }
}
