import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { uploadPhotoId, closePhotoIdModal } from './../../actions/authActions'

class PhotoIdModal extends React.Component {
    constructor() {
        super();
        this.state = {
            photoId: null
        };

        this.setPhotoId = this.setPhotoId.bind(this)
        this.handlePhotoIdDialogClose = this.handlePhotoIdDialogClose.bind(this)
    }

    handlePhotoIdSelectedFile = event => {
        this.setState({
            [event.target.name]: event.target.files[0]
        })
    }

    handlePhotoIdDialogClose = () => {
        this.props.closePhotoIdModal()
    }

    setPhotoId(e) {
        e.preventDefault();

        if (this.state.photoId === null) {
            return alert("Please select photo ID file.")
        }

        let data = new FormData()

        if (this.state.photoId !== null) {
            data.set('photoId', this.state.photoId, this.state.photoId.name)
        }

        this.props.uploadPhotoId(data)
        this.setState({ photoId: null })
    }

    render() {
        const { content_color, content_font_color } = this.props.auth.appSettings
        const { isPhotoIdUploading } = this.props.auth
        const { isPhotoIdAccepted } = this.props.auth.user

        return (
            <div>
                <div className="modal fade show" role="dialog" style={{ display: 'block', backgroundColor: '#000000ef' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Upload Photo ID</h5>
                                <button className="close" onClick={this.handlePhotoIdDialogClose} />
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col">
                                        <form onSubmit={this.setPhotoId}>
                                            <div className="form-row">
                                                <div className="col">
                                                    {isPhotoIdAccepted === undefined ?
                                                        <div className="mb-4">To prevent fraud, please provide a picture of your Photo ID for verification. Once this is provided, you will no longer have any limits for your account.</div>
                                                        :
                                                        <div className="mb-4">The Photo ID that you provided is not valid. Please upload a valid Photo ID and you will no longer have any limits for your account.</div>
                                                    }
                                                    <input type="file" accept="image/*, application/pdf" src={this.state.photoId} alt="" onChange={this.handlePhotoIdSelectedFile} name="photoId" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col text-right">
                                                    <button className="btn mt-4" type="submit" style={{ backgroundColor: content_color, color: content_font_color }} disabled={isPhotoIdUploading}>
                                                        {isPhotoIdUploading ? <>Uploading</> : <>Upload</>}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PhotoIdModal.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { uploadPhotoId, closePhotoIdModal }
)(withRouter(PhotoIdModal));
